<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-row class="" style="margin: 0px -6px;">
          <b-col cols="3"><strong class="">No Resep</strong></b-col>
          <b-col cols="1" class="text-right">:</b-col>
          <b-col cols="8"><span class="">{{dataResep.no_resep || '-'}}</span></b-col>
          <b-col cols="3"><strong class="">Dokter</strong></b-col>
          <b-col cols="1" class="text-right">:</b-col>
          <b-col cols="8"><span class="">{{dataResep.nama_dokter || '-'}}</span></b-col>
          <b-col cols="3"><strong class="">Perawat</strong></b-col>
          <b-col cols="1" class="text-right">:</b-col>
          <b-col cols="8"><span class="">{{dataResep.nama_perawat || '-'}}</span></b-col>
          <b-col cols="3"><strong class="">Gudang</strong></b-col>
          <b-col cols="1" class="text-right">:</b-col>
          <b-col cols="8"><span class="">{{dataResep.nama_gudang || '-'}}</span></b-col>
        </b-row>
      </b-col>
      <b-col cols="12">
        <div class="d-flex justify-content-between mb-2">
          <h6
            class="mt-3"
            style="font-weight: 700; color: #9c4098; font-size: 18px; margin-bottom: 0"
          >
            List Rekonsiliasi
          </h6>
        </div>
        <b-table
          v-if="dataResep.data_rekonsiliasi"
          :items="dataResep.data_rekonsiliasi"
          :fields="fieldsRekonsiliasi"
          responsive
          show-empty
          small
          bordered
          striped
          hover
          :busy="busy"
        >
          <template #cell(no)="item">{{item.index+1}}</template>
          <template #cell(actions)="item">
            <div class="d-flex nowrap justify-content-center">
              <b-button
                variant="info"
                size="sm"
                class="ml-1"
                @click="item.toggleDetails"
                v-c-tooltip.hover.click="'Detail Obat'"
              >
                <CIcon name="cil-info" />
              </b-button>
            </div>
          </template>
          <template #row-details="row">
            <b-card class="m-0">
              <b-row class="m-0 p-0">
                <b-col cols="4"><h6 class="m-0 p-0">Obat</h6></b-col>
                <b-col cols="8"><h6 class="m-0 p-0">: {{row.item.nama_obat}}</h6></b-col>
                <b-col cols="4"><h6 class="m-0 p-0">Signa</h6></b-col>
                <b-col cols="8"><h6 class="m-0 p-0">: {{row.item.signa_rekonsilisasi}}</h6></b-col>
                <b-col cols="4"><h6 class="m-0 p-0">Frekuensi</h6></b-col>
                <b-col cols="8"><h6 class="m-0 p-0">: {{row.item.frekuensi}}</h6></b-col>
                <b-col cols="4"><h6 class="m-0 p-0">Cara Pemberian</h6></b-col>
                <b-col cols="8"><h6 class="m-0 p-0">: {{row.item.cara_pemberian}}</h6></b-col>
                <b-col cols="4"><h6 class="m-0 p-0">Status Pemberian Obat</h6></b-col>
                <b-col cols="8"><h6 class="m-0 p-0">: {{row.item.status_pemberian_obat}}</h6></b-col>
                <b-col v-if="row.item.perubahan_aturan_pakai" cols="4"><h6 class="m-0 p-0">Perubahan Aturan Pakai</h6></b-col>
                <b-col v-if="row.item.perubahan_aturan_pakai" cols="8"><h6 class="m-0 p-0">: {{row.item.perubahan_aturan_pakai}}</h6></b-col>
                <b-col cols="4"><h6 class="m-0 p-0">Keterangan</h6></b-col>
                <b-col cols="8"><h6 class="m-0 p-0">: {{row.item.keterangan}}</h6></b-col>
              </b-row>
            </b-card>
          </template>
        </b-table>
      </b-col>
      <b-col cols="12" md="12" lg="12">
        <div class="d-flex justify-content-between mb-2">
          <h6
            class="mt-3"
            style="font-weight: 700; color: #9c4098; font-size: 18px; margin-bottom: 0"
          >
            List Resep
          </h6>
        </div>
        <b-table-simple v-if="dataResep.data_racikan" hover small responsive bordered>
          <b-thead class="table-color">
            <b-tr>
              <b-th v-for="field in fieldsObat" :key="field.key" :class="field.class">{{ field.label }}</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <tr v-for="(item, index) in listResep" :key="index">
              <th v-if="item.type == 'head'" scope="row" class="text-center">{{ item.no }}.</th>
              <td v-if="item.type == 'head' && item.no_racikan" colspan="7">{{ `${item.no_racikan} - ${item.jumlah_bungkus} bungkus - ${item.indikasi_head_racikan}` }}</td>
              <!-- <td v-if="item.type == 'head' && !item.no_racikan" colspan="9"></td> -->
              <td v-if="item.type == 'head' && !item.no_racikan" colspan="7">Tanpa Racikan</td>
             
              <td v-if="item.type == 'sub'"></td>
              <td v-if="item.type == 'sub'">{{ item.nama_barang }}</td>
              <td v-if="item.type == 'sub'">{{ item.qty_barang }}</td>
              <td v-if="item.type == 'sub'">{{ item.nama_qty_permintaan }}</td>
              <td v-if="item.type == 'sub'">{{ item.aturan_pakai_racikan }}</td>
              <td v-if="item.type == 'sub'">{{ item.signa_racikan }}</td>
              <td v-if="item.type == 'sub'">{{ item.nama_harga_satuan_racikan }}</td>
              <td v-if="item.type == 'sub'">{{ item.nama_tanggal_kadaluarsa_racikan }}</td>
              <!-- <td v-if="item.type == 'sub'">{{ item.indikasi_racikan }}</td> -->
              <!-- <td v-if="item.type == 'sub'">{{ item.keterangan_racikan }}</td> -->
              
              <td v-if="item.type == 'detail'"></td>
              <td v-if="item.type == 'detail'" colspan="8">
                <b-card class="m-0">
                  <b-row class="m-0 p-0" style="row-gap: 1rem;">
                    <b-col cols="6">
                      <div>
                        <h6><strong>Indikasi</strong></h6>
                        <p>{{item.indikasi_racikan || '-'}}</p>
                      </div>
                      <div>
                        <h6><strong>Keterangan</strong></h6>
                        <p>{{item.keterangan_racikan || '-'}}</p>
                      </div>
                    </b-col>
                    <b-col cols="6">
                      <div class="mt-2" v-for="(value, key) in item.obat_pasien" :key="key">
                        <b-card class="m-0">
                          <b-row class="m-0 p-0">
                            <b-col cols="4"><h6 class="m-0 p-0">Kode Batch</h6></b-col>
                            <b-col cols="8"><h6 class="m-0 p-0">: {{value.kode_batch}}</h6></b-col>
                            <b-col cols="4"><h6 class="m-0 p-0">Jumlah</h6></b-col>
                            <!-- <b-col cols="8"><h6 class="m-0 p-0">: {{value.qty_item_obat_jual}}</h6></b-col> -->
                            <b-col cols="8"><h6 class="m-0 p-0">: -</h6></b-col>
                            <b-col cols="4"><h6 class="m-0 p-0">Kadaluarsa</h6></b-col>
                            <b-col cols="8"><h6 class="m-0 p-0">: {{value.tanggal_kadaluarsa_racikan ? $moment(value.tanggal_kadaluarsa_racikan).format("LL") : '-'}}</h6></b-col>
                          </b-row>
                        </b-card>
                      </div>
                    </b-col>
                  </b-row>
                </b-card>
              </td>
              <td class="text-center">
                <div class="d-flex">
                  <b-button
                    v-if="item.type == 'sub'"
                    variant="info"
                    class="mr-1"
                    v-c-tooltip.hover.click="'Detail Sub Obat'"
                    @click="detailSub(index)"
                    size="sm"
                  ><CIcon name="cil-info" /></b-button>
                </div>
              </td>
            </tr>
          </b-tbody>
        </b-table-simple>
      </b-col>
    </b-row>
    <!-- <pre>listResep {{listResep}}</pre> -->
    <!-- <pre>dataResep {{dataResep}}</pre> -->
    <!-- <pre>listRekonsiliasi {{listRekonsiliasi}}</pre> -->
    <!-- <pre>listResep {{listResep}}</pre> -->
  </div>
</template>
<script>
export default {
  props: [
    'dataKunjungan',
  ],
  data() {
    return {
      busy: false,
      listRekonsiliasi: [],
      listResep: [],
      dataResep: {},
      fieldsRekonsiliasi: [
        {
          key: "no",
          label: "No",
          sortDirection: "desc",
          sortable: true,
          class: "table-number text-center",
        },
        {
          key: "nama_obat",
          label: "Obat",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "signa_rekonsiliasi",
          label: "Signa",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "frekuensi",
          label: "Frekuensi",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "cara_pemberian",
          label: "Pemberian",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "status_pemberian_obat",
          label: "Status",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        // {
        //   key: "perubahan_aturan_pakai",
        //   label: "Aturan Pakai",
        //   sortable: true,
        //   sortDirection: "desc",
        //   class: "text-left",
        // },
        // {
        //   key: "keterangan",
        //   label: "Keterangan",
        //   sortable: true,
        //   sortDirection: "desc",
        //   class: "text-left",
        // },
        {
          key: "actions",
          label: "Actions",
          class: "table-option-2 text-center",
        },
      ],
      fieldsObat: [
        {
          key: "no",
          label: "No",
          sortDirection: "desc",
          sortable: true,
          class: "table-number text-center",
        },
        {
          key: "nama_barang",
          label: "Obat",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "qty_barang",
          label: "Diberikan",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "nama_qty_permintaan",
          label: "Permintaan",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "aturan_pakai_obat",
          label: "Pakai",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "signa_obat",
          label: "Signa",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "nama_harga_satuan_racikan",
          label: "Harga",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "nama_tanggal_kadaluarsa_racikan",
          label: "Kadaluarsa",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        // {
        //   key: "indikasi_obat",
        //   label: "Indikasi",
        //   sortable: true,
        //   sortDirection: "desc",
        //   class: "text-left",
        // },
        // {
        //   key: "keterangan_obat",
        //   label: "Keterangan",
        //   sortable: true,
        //   sortDirection: "desc",
        //   class: "text-left",
        // },
        {
          key: "actions",
          label: "Actions",
          class: "table-option-2 text-center",
        },
      ],
    }
  },
  computed: {
    
  },
  mounted() {
    const vm = this
    vm.getData()
  },
  methods: {
    detailSub(idx){
      const vm = this
      if(!vm.listResep[idx+1]) vm.listResep[idx+1] = {}
      vm.listResep[idx+1].type == 'detail' ? vm.listResep.splice(idx+1, 1) : vm.listResep.splice(idx+1, 0, {...vm.listResep[idx], type: 'detail'})
    },
    async getData(){
      const vm = this
      vm.busy = true
      await new Promise(resolve => setTimeout(resolve, 100));
      try {
        // console.log('id', vm.$route.params.id)
        let dataResep = await vm.$axios.get('/resep/details_by_kunjungan/' + vm.dataKunjungan.kunjungan_id)
        // console.log('dataResep', dataResep)
        if(dataResep.data.status == 200 && dataResep.data.data.length > 0){
          vm.dataResep = dataResep.data.data[0]
          await new Promise(resolve => setTimeout(resolve, 100));
          // console.log('{resep_id: vm.dataResep.resep_id}', {resep_id: vm.dataResep.resep_id})
          // let listRekonsiliasi = await vm.$axios.post('/rekonsiliasi_obat/list', {resep_id: vm.dataResep.resep_id})
          // console.log('listRekonsiliasi', listRekonsiliasi)
          // if(listRekonsiliasi.data.status == 200 && listRekonsiliasi.data.data.length > 0){
          //   vm.listRekonsiliasi = listRekonsiliasi.data.data.map(x => {
          //     return {
          //       ...x,
          //       nama_tanggal: vm.$moment(x.tanggal_daftar).format('LL'),
          //     }
          //   })
          // }
          for (let i = 0; i < vm.dataResep.data_racikan.length; i++) {
            const obat = vm.dataResep.data_racikan[i];
            vm.listResep.push({
              no: i + 1,
              ...obat,
              // data_sub: undefined,
              type: 'head',
            })
            if (obat.racikan) {
              for (let x = 0; x < obat.racikan.length; x++) {
                const sub = obat.racikan[x];
                vm.listResep.push({
                  no: x + 1,
                  ...sub,
                  type: 'sub',
                  qty_barang: vm.$numberFormat(sub.qty_barang),
                  nama_qty_permintaan: vm.$numberFormat(sub.qty_permintaan),
                  nama_harga_satuan_racikan: vm.$numberFormat(sub.harga_satuan_racikan, 'idr'),
                  nama_tanggal_kadaluarsa_racikan: sub.tanggal_kadaluarsa_racikan ? vm.$moment(sub.tanggal_kadaluarsa_racikan).format('LL') : '-',
                })
              }
            }
          }
          // let listResep = await vm.$axios.post('/racikan/list', {resep_id: vm.dataResep.resep_id})
          // console.log('listResep', listResep)
          // if(listResep.data.status == 200 && listResep.data.data.length > 0){
          //   vm.listResep = listResep.data.data.map(x => {
          //     return {
          //       ...x,
          //       nama_tanggal: vm.$moment(x.tanggal_daftar).format('LL'),
          //     }
          //   })
          // }
        }else{
          // vm.triggerAlert({ variant: "warning", msg: 'Resep Tidak Ditemukan', showing: true })
        }
      } catch (error) {
        console.log(error)
        vm.triggerAlert({ variant: "warning", msg: 'Terjadi kesalahan sistem', showing: true });
      } finally {
        vm.busy = false
      }
    },
    triggerAlert(event) {
      let vm = this;
      vm.$store.commit("set_alert", event);
    },
  },
}
</script>