<template>
    <b-container>
        <b-row>
            <b-col cols="6" sm="6" md="6" lg="6">
                <b-card class="mt-3" bg-variant="light">
                    <b-card-title>
                        <h5 class="mb-0">Mata</h5>
                    </b-card-title>
                    <table class="table">
                        <thead>
                            <tr>
                                <th> </th>
                                <th>KIRI</th>
                                <th>KANAN</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Refraksi</td>
                                <td>
                                    <b-form-radio-group
                                        id="refraksi_kiri"
                                        v-model="co_mata.refraksi_kiri"
                                        stacked
                                    >
                                        <b-form-radio value="normal">Normal</b-form-radio>
                                        <b-form-radio value="myopia">Myopia</b-form-radio>
                                        <b-form-radio value="presbiopia">Presbiopia</b-form-radio>
                                        <b-form-radio value="hyperopia">Hyperopia</b-form-radio>
                                        <b-form-radio value="astigmatisma">Astigmatisma</b-form-radio>
                                        <b-form-radio value="glaukoma">Glaukoma</b-form-radio>
                                    </b-form-radio-group>
                                </td>
                                <td>
                                    <b-form-radio-group
                                        id="refraksi_kanan"
                                        v-model="co_mata.refraksi_kanan"
                                        stacked
                                    >
                                        <b-form-radio value="normal">Normal</b-form-radio>
                                        <b-form-radio value="myopia">Myopia</b-form-radio>
                                        <b-form-radio value="presbiopia">Presbiopia</b-form-radio>
                                        <b-form-radio value="hyperopia">Hyperopia</b-form-radio>
                                        <b-form-radio value="astigmatisma">Astigmatisma</b-form-radio>
                                        <b-form-radio value="glaukoma">Glaukoma</b-form-radio>
                                    </b-form-radio-group>
                                </td>
                            </tr>
                            <tr>
                                <td>Lensa</td>
                                <td>
                                    <b-form-radio-group
                                        id="lensa_kiri"
                                        v-model="co_mata.lensa_kiri"
                                        stacked
                                    >
                                        <b-form-radio value="tidak_keruh">Tidak Keruh</b-form-radio>
                                        <b-form-radio value="keruh">Keruh</b-form-radio>
                                    </b-form-radio-group>
                                </td>
                                <td>
                                    <b-form-radio-group
                                        id="lensa_kanan"
                                        v-model="co_mata.lensa_kanan"
                                        stacked
                                    >
                                        <b-form-radio value="tidak_keruh">Tidak Keruh</b-form-radio>
                                        <b-form-radio value="keruh">Keruh</b-form-radio>
                                    </b-form-radio-group>
                                </td>
                            </tr>
                            <tr>
                                <td>Retina</td>
                                <td>
                                    <b-form-radio-group
                                        id="retina_kiri"
                                        v-model="co_mata.retina_kiri"
                                        stacked
                                    >
                                        <b-form-radio value="normal">Normal</b-form-radio>
                                        <b-form-radio value="retinopati_diabetik">Retinopati Diabetik</b-form-radio>
                                        <b-form-radio value="retinopati_hipertensi">Retinopati Hipertensi</b-form-radio>
                                    </b-form-radio-group>
                                </td>
                                <td>
                                    <b-form-radio-group
                                        id="retina_kanan"
                                        v-model="co_mata.retina_kanan"
                                        stacked
                                    >
                                        <b-form-radio value="normal">Normal</b-form-radio>
                                        <b-form-radio value="retinopati_diabetik">Retinopati Diabetik</b-form-radio>
                                        <b-form-radio value="retinopati_hipertensi">Retinopati Hipertensi</b-form-radio>
                                    </b-form-radio-group>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <b-form>
                        <b-form-group label-cols-md="4">
                            <template v-slot:label>
                               Rujuk RS
                            </template>
                            <b-form-radio-group
                                id="rujuk_rs_co_mata"
                                class="pt-1"
                                v-model="co_mata.rujuk_rs_co_mata"
                            >
                                <b-form-radio value="true">Rujuk</b-form-radio>
                                <b-form-radio value="false">Tidak Rujuk</b-form-radio>
                            </b-form-radio-group>
                        </b-form-group>
                    </b-form>
                </b-card>
                <b-card bg-variant="light">
                    <b-card-title>
                        <h5 class="mb-0">Kardiovaskular</h5>
                    </b-card-title>
                    <b-form>
                        <b-form-group label-cols-md="4">
                            <template v-slot:label>
                               Carta
                            </template>
                            <b-form-radio-group
                                id="carta"
                                v-model="co_kardiovaskular.carta"
                            >
                                <b-form-radio color="success" value="<5%"> 5% </b-form-radio>
                                <b-form-radio value="5%-<10%">5%-10%</b-form-radio>
                                <b-form-radio value="10%-<20%">10%-20%</b-form-radio>
                                <b-form-radio value="20%-<30%">20%-30%</b-form-radio>
                                <b-form-radio value=">=30%">=30%</b-form-radio>
                            </b-form-radio-group>
                        </b-form-group>
                        <b-form-group label-cols-md="4">
                            <template v-slot:label>
                               EKG
                            </template>
                            <b-form-textarea
                                v-model="co_kardiovaskular.keterangan_ekg"
                            ></b-form-textarea>
                            <b-form-radio-group
                                id="is_normal_ekg"
                                v-model="co_kardiovaskular.is_normal_ekg"
                            >
                                <b-form-radio value="true">Normal</b-form-radio>
                                <b-form-radio value="false">Tidak Normal</b-form-radio>
                            </b-form-radio-group>
                        </b-form-group>
                        <b-form-group label-cols-md="4">
                            <template v-slot:label>
                               Rujuk RS
                            </template>
                            <b-form-radio-group
                                id="rujuk_rs_co_kardiovaskular"
                                class="pt-1"
                                v-model="co_kardiovaskular.rujuk_rs_co_kardiovaskular"
                            >
                                <b-form-radio value="true">Rujuk</b-form-radio>
                                <b-form-radio value="false">Tidak Rujuk</b-form-radio>
                            </b-form-radio-group>
                        </b-form-group>
                    </b-form>
                </b-card>
                <b-card bg-variant="light">
                    <b-card-title>
                        <h5 class="mb-0">Ginjal</h5>
                    </b-card-title>
                    <b-form>
                        <b-form-group label-cols-md="4">
                            <template v-slot:label>
                               Urinalisis - Proteinuria
                            </template>
                            <b-form-radio-group
                                id="urinalis_proteinuria"
                                v-model="co_ginjal.urinalis_proteinuria"
                            >
                                <b-form-radio value="0">Negatif</b-form-radio>
                                <b-form-radio value="1">Positif 1</b-form-radio>
                                <b-form-radio value="2">Positif 2</b-form-radio>
                                <b-form-radio value="3">Positif 3</b-form-radio>
                            </b-form-radio-group>
                            <b-form-textarea
                                v-model="co_ginjal.keterangan_urinalis_proteinuria"
                            ></b-form-textarea>
                            <b-form-radio-group
                                id="is_normal_urinalis_proteinuria"
                                v-model="co_ginjal.is_normal_urinalis_proteinuria"
                            >
                                <b-form-radio value="true">Normal</b-form-radio>
                                <b-form-radio value="false">Tidak Normal</b-form-radio>
                            </b-form-radio-group>
                        </b-form-group>
                        <b-form-group label-cols-md="4">
                            <template v-slot:label>
                               Ureum
                            </template>
                            <b-form-input
                                v-model="co_ginjal.ureum"
                                type="number"
                            ></b-form-input>
                            <b-form-radio-group
                                id="is_normal_ureum"
                                v-model="co_ginjal.is_normal_ureum"
                            >
                                <b-form-radio value="true">Normal</b-form-radio>
                                <b-form-radio value="false">Tidak Normal</b-form-radio>
                            </b-form-radio-group>
                        </b-form-group>
                        <b-form-group label-cols-md="4">
                            <template v-slot:label>
                               Kreatinin
                            </template>
                            <b-form-input
                                v-model="co_ginjal.kreatinin"
                                type="number"
                            ></b-form-input>
                            <b-form-radio-group
                                id="is_normal_kreatinin"
                                v-model="co_ginjal.is_normal_kreatinin"
                            >
                                <b-form-radio value="true">Normal</b-form-radio>
                                <b-form-radio value="false">Tidak Normal</b-form-radio>
                            </b-form-radio-group>
                        </b-form-group>
                        <b-form-group label-cols-md="4">
                            <template v-slot:label>
                               e-GFR
                            </template>
                            <b-form-input
                                v-model="co_ginjal.e_gfr"
                                type="number"
                            ></b-form-input>
                        </b-form-group>
                        <b-form-group label-cols-md="4">
                            <template v-slot:label>
                               Rujuk RS
                            </template>
                            <b-form-radio-group
                                id="rujuk_rs_co_ginjal"
                                class="pt-1"
                                v-model="co_ginjal.rujuk_rs_co_ginjal"
                            >
                                <b-form-radio value="true">Rujuk</b-form-radio>
                                <b-form-radio value="false">Tidak Rujuk</b-form-radio>
                            </b-form-radio-group>
                        </b-form-group>
                    </b-form>
                </b-card>
                <b-card bg-variant="light">
                    <b-card-title>
                        <h5 class="mb-0">Edukasi</h5>
                    </b-card-title>
                    <b-form>
                        <b-form-group label-cols-md="4">
                            <template v-slot:label>
                               Edukasi
                            </template>
                            <b-form-radio-group
                                id="is_edukasi"
                                class="pt-1"
                                v-model="skrining.is_edukasi"
                            >
                                <b-form-radio value="true">Ya</b-form-radio>
                                <b-form-radio value="false">Tidak</b-form-radio>
                            </b-form-radio-group>
                        </b-form-group>
                    </b-form>
                </b-card>
            </b-col>
            <b-col cols="6" sm="6" md="6" lg="6">
                <b-card class="mt-3" bg-variant="light">
                    <b-card-title>
                        <h5 class="mb-0">Saraf dan Otot</h5>
                    </b-card-title>
                    <b-form>
                        <b-form-group label-cols-md="4">
                            <template v-slot:label>
                               Ulkus diabetikum
                            </template>
                            <b-form-select
                                id="grade_ulkus_diabetikum"
                                v-model="co_saraf_otot.grade_ulkus_diabetikum"
                            >
                                <option value="null">Pilih Grade</option>
                                <option value="derajat_0">Derajat 0</option>
                                <option value="derajat_1">Derajat 1</option>
                                <option value="derajat_2">Derajat 2</option>
                                <option value="derajat_3">Derajat 3</option>
                                <option value="derajat_4">Derajat 4</option>
                                <option value="derajat_5">Derajat 5</option>
                            </b-form-select>
                        </b-form-group>
                        <b-form-group label-cols-md="4">
                            <template v-slot:label>
                               <b>Neuropati DM</b>
                            </template>
                            <b-form-radio-group
                                id="neuropati_dm"
                                v-model="co_saraf_otot.neuropati_dm"
                            >
                                <b-form-radio value="true">Ya</b-form-radio>
                                <b-form-radio value="false">Tidak</b-form-radio>
                            </b-form-radio-group>
                        </b-form-group>
                        <b-form-group label-cols-md="4">
                            <template v-slot:label>
                               Motorik
                            </template>
                            <b-form-radio-group
                                id="gangguan_motorik"
                                v-model="co_saraf_otot.gangguan_motorik"
                            >
                                <b-form-radio value="true">Ya</b-form-radio>
                                <b-form-radio value="false">Tidak</b-form-radio>
                            </b-form-radio-group>
                        </b-form-group>
                        <b-form-group label-cols-md="4">
                            <template v-slot:label>
                               Sensorik
                            </template>
                            <b-form-radio-group
                                id="gangguan_sensorik"
                                v-model="co_saraf_otot.gangguan_sensorik"
                            >
                                <b-form-radio value="true">Ya</b-form-radio>
                                <b-form-radio value="false">Tidak</b-form-radio>
                            </b-form-radio-group>
                        </b-form-group>
                        <b-form-group label-cols-md="4">
                            <template v-slot:label>
                               Rujuk RS
                            </template>
                            <b-form-radio-group
                                id="rujuk_rs_co_saraf_otot"
                                class="pt-1"
                                v-model="co_saraf_otot.rujuk_rs_co_saraf_otot"
                            >
                                <b-form-radio value="true">Rujuk</b-form-radio>
                                <b-form-radio value="false">Tidak Rujuk</b-form-radio>
                            </b-form-radio-group>
                        </b-form-group>
                    </b-form>
                </b-card>
                <b-card bg-variant="light">
                    <b-card-title>
                        <h5 class="mb-0">Fungsi Hati</h5>
                    </b-card-title>
                    <b-form>
                        <b-form-group label-cols-md="4">
                            <template v-slot:label>
                               SGOT
                            </template>
                            <b-form-input
                                v-model="co_hati.sgot"
                                type="number"
                            ></b-form-input>
                            <b-form-radio-group
                                id="is_normal_sgot"
                                v-model="co_hati.is_normal_sgot"
                            >
                                <b-form-radio value="true">Normal</b-form-radio>
                                <b-form-radio value="false">Tidak Normal</b-form-radio>
                            </b-form-radio-group>
                        </b-form-group>
                        <b-form-group label-cols-md="4">
                            <template v-slot:label>
                               SGPT
                            </template>
                            <b-form-input
                                v-model="co_hati.sgpt"
                                type="number"
                            ></b-form-input>
                            <b-form-radio-group
                                id="is_normal_sgpt"
                                v-model="co_hati.is_normal_sgpt"
                            >
                                <b-form-radio value="true">Normal</b-form-radio>
                                <b-form-radio value="false">Tidak Normal</b-form-radio>
                            </b-form-radio-group>
                        </b-form-group>
                        <b-form-group label-cols-md="4">
                            <template v-slot:label>
                               Rujuk RS
                            </template>
                            <b-form-radio-group
                                id="rujuk_rs_co_hati"
                                class="pt-1"
                                v-model="co_hati.rujuk_rs_co_hati"
                            >
                                <b-form-radio value="true">Rujuk</b-form-radio>
                                <b-form-radio value="false">Tidak Rujuk</b-form-radio>
                            </b-form-radio-group>
                        </b-form-group>
                    </b-form>
                </b-card>
                <b-card bg-variant="light">
                    <b-card-title>
                        <h5 class="mb-0">Profil Lipid</h5>
                    </b-card-title>
                    <b-form>
                        <b-form-group label-cols-md="4">
                            <template v-slot:label>
                               Cholesterol Total
                            </template>
                            <b-form-input
                                v-model="co_profil_lipid.cholesterol_total"
                                type="number"
                            ></b-form-input>
                            <b-form-radio-group
                                id="is_normal_cholesterol"
                                v-model="co_profil_lipid.is_normal_cholesterol"
                            >
                                <b-form-radio value="true">Normal</b-form-radio>
                                <b-form-radio value="false">Tidak Normal</b-form-radio>
                            </b-form-radio-group>
                        </b-form-group>
                        <b-form-group label-cols-md="4">
                            <template v-slot:label>
                               LDL
                            </template>
                            <b-form-input
                                v-model="co_profil_lipid.ldl"
                                type="number"
                            ></b-form-input>
                            <b-form-radio-group
                                id="is_normal_ldl"
                                v-model="co_profil_lipid.is_normal_ldl"
                            >
                                <b-form-radio value="true">Normal</b-form-radio>
                                <b-form-radio value="false">Tidak Normal</b-form-radio>
                            </b-form-radio-group>
                        </b-form-group>
                        <b-form-group label-cols-md="4">
                            <template v-slot:label>
                               HDL
                            </template>
                            <b-form-input
                                v-model="co_profil_lipid.hdl"
                                type="number"
                            ></b-form-input>
                            <b-form-radio-group
                                id="is_normal_hdl"
                                v-model="co_profil_lipid.is_normal_hdl"
                            >
                                <b-form-radio value="true">Normal</b-form-radio>
                                <b-form-radio value="false">Tidak Normal</b-form-radio>
                            </b-form-radio-group>
                        </b-form-group>
                        <b-form-group label-cols-md="4">
                            <template v-slot:label>
                               Trigliserida
                            </template>
                            <b-form-input
                                v-model="co_profil_lipid.trigliserida"
                                type="number"
                            ></b-form-input>
                            <b-form-radio-group
                                id="is_normal_trigliserida"
                                v-model="co_profil_lipid.is_normal_trigliserida"
                            >
                                <b-form-radio value="true">Normal</b-form-radio>
                                <b-form-radio value="false">Tidak Normal</b-form-radio>
                            </b-form-radio-group>
                        </b-form-group>
                        <b-form-group label-cols-md="4">
                            <template v-slot:label>
                               Rujuk RS
                            </template>
                            <b-form-radio-group
                                class="pt-1"
                                id="rujuk_rs_co_profil_lipid"
                                v-model="co_profil_lipid.rujuk_rs_co_profil_lipid"
                            >
                                <b-form-radio value="true">Rujuk</b-form-radio>
                                <b-form-radio value="false">Tidak Rujuk</b-form-radio>
                            </b-form-radio-group>
                        </b-form-group>
                    </b-form>
                </b-card>
                <b-card bg-variant="light">
                    <b-card-title>
                        <h5 class="mb-0">Rontgen</h5>
                    </b-card-title>
                    <b-form>
                        <b-form-group label-cols-md="4">
                            <template v-slot:label>
                               Rontgen
                            </template>
                            <b-form-radio-group
                                id="rontgen"
                                v-model="skrining.rontgen"
                                stacked
                            >
                                <b-form-radio value="kelainan_jantung">Kelainan Jantung</b-form-radio>
                                <b-form-radio value="kelainan_paru">Kelainan Paru</b-form-radio>
                                <b-form-radio value="normal">Normal</b-form-radio>
                            </b-form-radio-group>
                        </b-form-group>
                    </b-form>
                </b-card>
                <b-card bg-variant="light">
                    <b-card-title>
                        <h5 class="mb-0">Skrining</h5>
                    </b-card-title>
                    <b-form>
                        <b-form-group label-cols-md="4">
                            <template v-slot:label>
                               Ginjal +1 organ
                            </template>
                            <b-form-radio-group
                                class="pt-1"
                                id="is_risiko_ginjal_dan_organ"
                                v-model="skrining.is_risiko_ginjal_dan_organ"
                            >
                                <b-form-radio value="true">Risiko</b-form-radio>
                                <b-form-radio value="false">Tidak Risiko</b-form-radio>
                            </b-form-radio-group>
                        </b-form-group>
                    </b-form>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="d-flex justify-content-end">
                <b-button v-if="!kirim" variant="success" @click="simpan()">
                    Simpan
                </b-button>
                <b-button v-else variant="warning" @click="simpan()">
                    Update
                </b-button>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
  import moment from 'moment';
  moment.locale('id');
  export default {
    data() {
      return {
        co_mata: {
            refraksi_kiri: null,
            refraksi_kanan: null,
            lensa_kiri: null,
            lensa_kanan: null,
            retina_kiri: null,
            retina_kanan: null,
            rujuk_rs_co_mata: null,
        },
        co_kardiovaskular: {
            carta: null,
            keterangan_ekg: null,
            is_normal_ekg: null,
            rujuk_rs_co_kardiovaskular: null,
        },
        co_hati: {
            sgot: null,
            is_normal_sgot: null,
            sgpt: null,
            is_normal_sgpt: null,
            rujuk_rs_co_hati: null,
        },
        co_saraf_otot: {
            grade_ulkus_diabetikum: null,
            neuropati_dm: null,
            gangguan_motorik: null,
            gangguan_sensorik: null,
            rujuk_rs_co_saraf_otot: null,
        },
        co_profil_lipid: {
            cholesterol_total: null,
            is_normal_cholesterol: null,
            ldl: null,
            is_normal_ldl: null,
            hdl: null,
            is_normal_hdl: null,
            trigliserida: null,
            is_normal_trigliserida: null,
            rujuk_rs_co_profil_lipid: null,
        },
        co_ginjal: {
            urinalis_proteinuria: null,
            keterangan_urinalis_proteinuria: null,
            is_normal_urinalis_proteinuria: null,
            ureum: null,
            is_normal_ureum: null,
            kreatinin: null,
            is_normal_kreatinin: null,
            e_gfr:null,
            rujuk_rs_co_ginjal: null,
        },
        skrining: {
            is_edukasi: null,
            rontgen: null,
            is_risiko_ginjal_dan_organ: null,
        },
        kirim: false,
      }
    },
    async mounted() {
    //   const vm = this
    //   vm.getData()
    },
    methods: {
      onFiltered(filteredItems) {
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      },
      simpan(){
        let vm = this
        let data_co = {}
        data_co.co_mata = vm.co_mata
        data_co.co_kardiovaskular = vm.co_kardiovaskular
        data_co.co_ginjal = vm.co_ginjal
        data_co.co_hati = vm.co_hati
        data_co.co_profil_lipid = vm.co_profil_lipid
        data_co.co_saraf_otot = vm.co_saraf_otot
        data_co.skrining = vm.skrining
        // console.log(data_co, 'ini datanya');
        vm.kirim = true
        vm.$emit("dataFromChild", data_co)
      },
      triggerAlert(event) {
        let vm = this;
        vm.$store.commit("set_alert", event);
      },
    },
  }
  </script>