<template>
  <b-card style="margin-bottom: 15px;">
    <b-row>
      <b-col cols="12" md="12" lg="12" xl="12">
        <h5 class="custom-title-color-black">
          Alergi
        </h5>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <label for="ms_jenis_alergi_id">Jenis Alergi <span class="text-danger">*</span></label>
        <Multiselect
          id="ms_jenis_alergi_id"
          v-model="$v.dataForm.ms_jenis_alergi_id.$model"
          :options="listJenisAlergi "
          :multiple="false"
          :searchable="true"
          :close-on-select="true"
          :show-labels="false"
          label="nama_jenis_alergi"
          track-by="ms_jenis_alergi_id"
          placeholder="-- Pilih Jenis Alergi --"
          size="sm"
          @select="getAlergi"
          @remove="getAlergi"
        ></Multiselect>
      </b-col>
      <b-col cols="12">
        <label for="ms_alergi_id">Alergi <span class="text-danger">*</span></label>
        <Multiselect
          id="ms_alergi_id"
          v-model="$v.dataForm.ms_alergi_id.$model"
          :options="listAlergi "
          :multiple="false"
          :searchable="true"
          :close-on-select="true"
          :show-labels="false"
          label="nama_alergi"
          track-by="ms_alergi_id"
          placeholder="-- Pilih Alergi --"
          size="sm"
        ></Multiselect>
      </b-col>
      <b-col cols="12">
        <div class="d-flex justify-content-end">
          <b-button
            class="w-100 mt-1"
            :disabled="busy"
            @click="simpan()"
            variant="success"
          >Tambah</b-button>
        </div>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12" md="12" lg="12" xl="12">
        <hr>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" md="12" lg="12" xl="12">
        <b-table
          :items="items"
          :fields="fields"
          responsive
          show-empty
          small
          bordered
          striped
          hover
          :busy="busy"
          class="custom-table"
        >
          <template #cell(actions)="item">
            <b-button
              variant="danger"
              size="sm"
              style="padding: 5px !important;"
              v-c-tooltip.hover.click="'Hapus Data'"
              v-b-modal.modal-delete-alergi
              @click="is_data = item.item"
              ><CIcon name="cil-trash" /> {{ item.actions }}</b-button
            >
          </template>
        </b-table>
      </b-col>
    </b-row>
    <modal-delete
      :data_delete="is_data"
      @alertFromChild="triggerAlert($event), getDatas()"
    />
  </b-card>
</template>
<script>
import moment from 'moment';
moment.locale('id');
import ModalDelete from "./modalDelete.vue";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
export default {
  components:{
    ModalDelete,
  },
  props: [
    'kunjungan'
  ],
  data() {
    return {
      listJenisAlergi: [],
      listAlergi: [],
      dataForm: {
        ms_jenis_alergi_id: null,
        ms_alergi_id: null,
      },
      is_data: {},
      items: [],
      fields: [
        // {
        //   key: "no",
        //   label: "No.",
        //   sortDirection: "desc",
        //   sortable: true,
        //   class: "table-number text-center",
        // },
        {
          key: "nama_jenis_alergi",
          label: "Jenis",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "nama_alergi",
          label: "Alergi",
          sortable: true,
          sortDirection: "desc",
          class: "table-option-4 text-left",
        },
        {
          key: "nama_tanggal",
          label: "Tanggal",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "actions",
          label: "Aksi",
          class: "table-option-2 text-center",
        },
      ],
      busy: false,
    }
  },
  async mounted() {
    const vm = this
    vm.getList()
    vm.getDatas()
  },
  computed: {
    isValid() {
      return !this.$v.dataForm.$invalid;
    },
    isDirty() {
      return this.$v.dataForm.$anyDirty;
    },
  },
  mixins: [validationMixin],
  validations: {
    dataForm: {
      ms_jenis_alergi_id: {  },
      ms_alergi_id: { required },
    }
  },
  watch: {
    "kunjungan.kunjungan_id"(newVal, oldVal){
      if(newVal != oldVal){
        this.getDatas()
      }
    }
  },
  methods: {
    async getDatas(){
      const vm = this
      vm.busy = true
      try {
        vm.items = []
        if (vm.kunjungan.rm_id) {
          const items = await vm.$axios.post('/pool_alergi/list', {rm_id: vm.kunjungan.rm_id})
          // console.log('pool_alergi', items)
          vm.items = items.data.status == 200 ? items.data.data.map((x, i) => { return {
            ...x,
            no: i + 1,
            nama_tanggal: x.createdat_anamnesa ? vm.$moment(x.createdat_anamnesa).format('DD-MM-YYYY') : '-',
          }}) : []
          vm.$emit("changeVar", { name: 'listAlergiPasien', value: vm.items});
          vm.totalRows = vm.items.length
        }
      } catch (err) {
        if (err.message) {
          vm.triggerAlert({ variant: "danger", msg:err.message.toUpperCase(), showing: true });
        } else {
          vm.triggerAlert({ variant: "danger", msg:err.toUpperCase(), showing: true });
        }
      } finally {
        vm.busy = false;
      }
    },
    async getList(){
      const vm = this
      vm.busy = true
      try {
        //jenis alergi
        let listJenisAlergi = await vm.$axios.post('/ms_jenis_alergi/list')
        // console.log('listJenisAlergi', listJenisAlergi)
        vm.listJenisAlergi = listJenisAlergi.data.status == 200 ? listJenisAlergi.data.data : []
      } catch (err) {
        if (err.message) {
          vm.triggerAlert({ variant: "danger", msg:err.message.toUpperCase(), showing: true });
        } else {
          vm.triggerAlert({ variant: "danger", msg:err.toUpperCase(), showing: true });
        }
      } finally {
        vm.busy = false;
      }
    },
    async getAlergi(e){
      const vm = this
      vm.busy = true
      try {
        //alergi
        if(e){
          let listAlergi = await vm.$axios.post('/ms_alergi/list', {ms_jenis_alergi_id: e.ms_jenis_alergi_id})
          // console.log('listAlergi', listAlergi)
          vm.listAlergi = listAlergi.data.status == 200 ? listAlergi.data.data : []
        }else{
          vm.listAlergi = []
        }
      } catch (err) {
        if (err.message) {
          vm.triggerAlert({ variant: "danger", msg:err.message.toUpperCase(), showing: true });
        } else {
          vm.triggerAlert({ variant: "danger", msg:err.toUpperCase(), showing: true });
        }
      } finally {
        vm.busy = false;
      }
    },
    async simpan(){
      const vm = this
      vm.busy = true
      try {
        await this.$v.dataForm.$touch();
        if (vm.isValid && vm.isDirty) {
          let res = await vm.$axios.post('/pool_alergi/register', {
            ms_alergi_id: vm.dataForm.ms_alergi_id.ms_alergi_id,
            rm_id: vm.kunjungan.rm_id,
            anamnesa_id: vm.kunjungan.anamnesa_id,
          })
          // console.log('register diagnosa', res)
          if(res.data.status == 200){
            vm.triggerAlert({ variant: "success", msg: 'Berhasil Menambahkan Alergi Pasien', showing: true });
            vm.reset()
          }else{
            vm.triggerAlert({ variant: "danger", msg: 'Gagal Menambahkan Alergi Pasien', showing: true });
          }
        }else{
          vm.triggerAlert({ variant: "warning", msg: 'Data Tidak Valid', showing: true });
        }
      } catch (error) {
        console.log(error)
        if (error.message) {
          vm.triggerAlert({ variant: "danger", msg: error.message.toUpperCase(), showing: true });
        } else {
          vm.triggerAlert({ variant: "danger", msg: error.toUpperCase(), showing: true });
        }
      } finally {
        vm.busy = false
      }
    },
    reset(){
      const vm = this
      vm.dataForm = {
        ms_jenis_alergi_id: null,
        ms_alergi_id: null,
      }
      vm.$v.$reset()
      vm.getDatas()
    },
    triggerAlert(event) {
      let vm = this;
      vm.$store.commit("set_alert", event);
    },
  },
}
</script>

<style scoped>
.custom-title-card{
  font-weight: 700;
  font-size: 16px;
  color: #D52F65;
}
</style>