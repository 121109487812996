<template>
  <div>
    <b-row>
      <b-col cols="6">
        <b-row>
          <b-col cols="5"><strong class="">No Kunjungan</strong></b-col>
          <b-col cols="1" class="text-right">:</b-col>
          <b-col cols="6"><span class="">{{dataKunjungan.no_kunjungan || '-'}}</span></b-col>
          <b-col cols="5"><strong class="">Poli / Ruangan</strong></b-col>
          <b-col cols="1" class="text-right">:</b-col>
          <b-col cols="6"><span class="">{{dataKunjungan.nama_poli || '-'}}</span></b-col>
          <b-col cols="5"><strong class="">Kamar</strong></b-col>
          <b-col cols="1" class="text-right">:</b-col>
          <b-col cols="6"><span class="">{{dataKunjungan.nama_kamar || '-'}}</span></b-col>
          <b-col cols="5"><strong class="">Tanggal Daftar</strong></b-col>
          <b-col cols="1" class="text-right">:</b-col>
          <b-col cols="6"><span class="">{{dataKunjungan.tanggal_daftar ? $moment(dataKunjungan.tanggal_daftar).format('LLLL') : '-'}}</span></b-col>
          <b-col cols="5"><strong class="">Tanggal Mulai</strong></b-col>
          <b-col cols="1" class="text-right">:</b-col>
          <b-col cols="6"><span class="">{{dataKunjungan.waktu_mulai ? $moment(dataKunjungan.waktu_mulai).format('LLLL') : '-'}}</span></b-col>
          <b-col cols="5"><strong class="">Tanggal Selesai</strong></b-col>
          <b-col cols="1" class="text-right">:</b-col>
          <b-col cols="6"><span class="">{{dataKunjungan.waktu_selesai ? $moment(dataKunjungan.waktu_selesai).format('LLLL') : '-'}}</span></b-col>
          <b-col cols="5"><strong class="">No. Pendaftaran</strong></b-col>
          <b-col cols="1" class="text-right">:</b-col>
          <b-col cols="6"><span class="">{{dataKunjungan.no_pendaftaran || '-'}}</span></b-col>
          <b-col cols="5"><strong class="">Asuransi</strong></b-col>
          <b-col cols="1" class="text-right">:</b-col>
          <b-col cols="6"><span class="">{{dataKunjungan.no_bpjs ? 'BPJS' : 'UMUM'}}</span></b-col>
          <!-- <b-col cols="5"><strong class="">Dibuat Pada / Oleh</strong></b-col>
          <b-col cols="1" class="text-right">:</b-col>
          <b-col cols="6"><span class="">{{dataKunjungan.tanggal_dibuat ? $moment(dataKunjungan.tanggal_dibuat).format('LLLL') : '-'}} / {{dataKunjungan.dibuat_oleh || '-'}}</span></b-col>
          <b-col cols="5"><strong class="">Diubah Pada / Oleh</strong></b-col>
          <b-col cols="1" class="text-right">:</b-col>
          <b-col cols="6" v-if="dataKunjungan.diperbarui_oleh"><span class="">{{dataKunjungan.tanggal_diubah ? $moment(dataKunjungan.tanggal_diubah).format('LLLL') : '-'}} / {{dataKunjungan.diperbarui_oleh || '-'}}</span></b-col>
          <b-col cols="6" v-else>-</b-col> -->
        </b-row>
      </b-col>
      <b-col cols="6">
        <b-row class="ml-3">
          <b-col cols="5"><strong class="">NIK</strong></b-col>
          <b-col cols="1" class="text-right">:</b-col>
          <b-col cols="6"><span class="">{{dataKunjungan.nik || '-'}}</span></b-col>
          <b-col cols="5"><strong class="">Nama Lengkap</strong></b-col>
          <b-col cols="1" class="text-right">:</b-col>
          <b-col cols="6"><span class="">{{dataKunjungan.nama_lengkap || '-'}}</span></b-col>
          <b-col cols="5"><strong class="">Nama Ibu</strong></b-col>
          <b-col cols="1" class="text-right">:</b-col>
          <b-col cols="6"><span class="">{{dataKunjungan.nama_ibu || '-'}}</span></b-col>
          <b-col cols="5"><strong class="">Jenis Kelamin</strong></b-col>
          <b-col cols="1" class="text-right">:</b-col>
          <b-col cols="6"><span class="">{{dataKunjungan.jenis_kelamin == "L" ? 'Laki - Laki' : 'Perempuan'}}</span></b-col>
          <b-col cols="5"><strong class="">Tempat Tanggal Lahir </strong></b-col>
          <b-col cols="1" class="text-right">:</b-col>
          <b-col cols="6"><span class="">{{dataKunjungan.tempat_lahir || '-'}}, {{dataKunjungan.tanggal_lahir ? $moment(dataKunjungan.tanggal_lahir).format('LL') : '-'}}</span></b-col>
          <b-col cols="5"><strong class="">Umur</strong></b-col>
          <b-col cols="1" class="text-right">:</b-col>
          <b-col cols="6"><span class="">{{dataKunjungan.umur}}</span></b-col>
          <b-col cols="5"><strong class="">Alamat Sekarang</strong></b-col>
          <b-col cols="1" class="text-right">:</b-col>
          <b-col cols="6"><span class="">{{dataKunjungan.alamat_sekarang || '-'}}</span></b-col>
          <b-col cols="5"><strong class="">Alamat KTP</strong></b-col>
          <b-col cols="1" class="text-right">:</b-col>
          <b-col cols="6"><span class="">{{dataKunjungan.alamat_ktp || '-'}}</span></b-col>
        </b-row>
      </b-col>
      <b-col cols="12" md="12" lg="12" xl="12">
        <div class="d-flex justify-content-between mb-2">
          <h6
            class="mt-3"
            style="font-weight: 700; color: #9c4098; font-size: 18px; margin-bottom: 0"
          >
            List Alergi
          </h6>
        </div>
        <b-table
          :items="listAlergi"
          :fields="fieldsAlergi"
          responsive
          show-empty
          small
          bordered
          striped
          hover
          :busy="busy"
          class="table-alergi"
        >
        </b-table>
      </b-col>
      <b-col cols="12" md="12" lg="12" xl="12">
        <div class="d-flex justify-content-between mb-2">
          <h6
            class="mt-3"
            style="font-weight: 700; color: #9c4098; font-size: 18px; margin-bottom: 0"
          >
            List Rujuk Internal
          </h6>
        </div>
        <b-table
          :items="listRujukInternal"
          :fields="fieldsRujukInternal"
          responsive
          show-empty
          small
          bordered
          striped
          hover
          :busy="busy"
          class="table-alergi"
        >
        </b-table>
      </b-col>
    </b-row>
    <!-- <pre>{{dataKunjungan}}</pre> -->
  </div>
</template>
<script>
export default {
  props: [
    'dataKunjungan',
  ],
  data() {
    return {
      busy: false,
      dataRm: {},
      listRujukInternal: [],
      listAlergi: [],
      fieldsRujukInternal: [
        {
          key: "no",
          label: "No",
          sortDirection: "desc",
          sortable: false,
          class: "table-number text-center",
        },
        {
          key: "nama_tanggal",
          label: "Tanggal",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "instalasi",
          label: "Instanlasi",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "nama_poli",
          label: "Poli / Ruangan",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
      ],
      fieldsAlergi: [
        {
          key: "no",
          label: "No.",
          sortDirection: "desc",
          sortable: false,
          class: "table-number text-center",
        },
        {
          key: "nama_jenis_alergi",
          label: "Jenis",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "nama_alergi",
          label: "Alergi",
          sortable: true,
          sortDirection: "desc",
          class: "table-option-4 text-left",
        },
        {
          key: "nama_tanggal",
          label: "Tanggal",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
      ],
    }
  },
  computed: {
    
  },
  mounted() {
    const vm = this
    vm.getData()
  },
  methods: {
    async getData(){
      const vm = this
      vm.busy = true
      try {
        await new Promise(resolve => setTimeout(resolve, 100));
        console.log('dataKunjungan', vm.dataKunjungan)
        // console.log('id', vm.$route.params.id)
        const listAlergi = await vm.$axios.post('/pool_alergi/list', {rm_id: vm.dataKunjungan.rm_id})
        console.log('listAlergi', listAlergi)
        vm.listAlergi = []
        if(listAlergi.data.status == 200){
          vm.listAlergi = listAlergi.data.status == 200 ? listAlergi.data.data.map((x, i) => { return {
            ...x,
            no: i + 1,
            nama_tanggal: x.createdat_anamnesa ? vm.$moment(x.createdat_anamnesa).format('DD-MM-YYYY') : '-',
          }}) : []
        }
        const listRujukInternal = await vm.$axios.post('/rujuk_internal/list', {registrasi_id_lama: vm.dataKunjungan.pendaftaran_id})
        // console.log('listRujukInternal', listRujukInternal)
        vm.listRujukInternal = []
        if(listRujukInternal.data.status == 200){
          vm.listRujukInternal = listRujukInternal.data.status == 200 ? listRujukInternal.data.data.map((x, i) => { return {
            ...x,
            no: i + 1,
            nama_tanggal: vm.$moment(x.tanggal_daftar).format('LLLL'),
          }}) : []
        }
      } catch (error) {
        console.log(error)
        vm.triggerAlert({ variant: "warning", msg: 'Terjadi kesalahan sistem', showing: true });
      } finally {
        vm.busy = false
      }
    },
    triggerAlert(event) {
      let vm = this;
      vm.$store.commit("set_alert", event);
    },
  },
}
</script>