<template>
  <b-card>
    <b-row>
      <b-col cols="12">
        <b-row class="mx-0">
          <b-col cols="12" v-if="dataForm.no_resep_obat">
            <label for="no_resep_obat">No Resep <span class="text-danger">*</span></label>
            <b-form-input
              id="no_resep_obat"
              type="text"
              v-model="dataForm.no_resep_obat"
              disabled
            ></b-form-input>
          </b-col>
          <b-col cols="6">
            <label for="ms_gudang_id">Gudang <span class="text-danger">*</span></label>
            <Multiselect
              id="ms_gudang_id"
              v-model="$v.dataForm.ms_gudang_id.$model"
              :options="listGudang"
              :multiple="false"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              label="nama_gudang"
              track-by="ms_gudang_id"
              placeholder="-- Pilih Gudang --"
              size="sm"
              @select="getBarang()"
              @remove="getBarang()"
            ></Multiselect>
          </b-col>
          <b-col cols="6">
            <label for="tanggal_obat_jual">Tanggal <span class="text-danger">*</span></label>
            <date-picker
              style="width: 100%"
              id="tanggal_obat_jual"
              format="DD-MM-YYYY"
              v-model="$v.dataForm.tanggal_obat_jual.$model"
            ></date-picker>
          </b-col>
          <b-col cols="6">
            <label for="ms_dokter_id">Dokter <span class="text-danger">*</span></label>
            <Multiselect
              id="ms_dokter_id"
              v-model="$v.dataForm.ms_dokter_id.$model"
              :options="listDokter"
              :multiple="false"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              label="nama_tenaga_medis"
              track-by="tenaga_medis_id"
              placeholder="-- Pilih Dokter --"
              size="sm"
            ></Multiselect>
          </b-col>
          <b-col cols="6">
            <label for="ms_apoteker_id">Apoteker <span class="text-danger">*</span></label>
            <Multiselect
              id="ms_apoteker_id"
              v-model="$v.dataForm.ms_apoteker_id.$model"
              :options="listPerawat"
              :multiple="false"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              label="nama_tenaga_medis"
              track-by="tenaga_medis_id"
              placeholder="-- Pilih Perawat --"
              size="sm"
            ></Multiselect>
          </b-col>
          <b-col cols="12">
            <div class="d-flex justify-content-end">
              <b-button 
                v-if="dataForm.id"
                :disabled="busy"
                @click="update()"
                variant="warning"
              >Update</b-button>
              <b-button
                v-else
                :disabled="busy || kunjunganSelesai"
                @click="simpan()"
                variant="primary"
              >Simpan</b-button>
            </div>
          </b-col>
        </b-row>
      </b-col>      

      <b-col cols="12" v-show="dataForm.id">
        <hr>
        <b-button v-b-toggle.collapse-1 variant="success"><h5 class="m-0 p-0"><strong>REKONSILIASI</strong></h5></b-button>
        <b-collapse id="collapse-1" class="mt-2">
          <!-- <h5><strong>REKONSILIASI</strong></h5> -->
          <b-row>
            <b-col cols="12">
              <b-row class="m-0 mb-3 mt-3">
                <b-col cols="6">
                  <label for="nama_obat">Nama Obat <span class="text-danger">*</span></label>
                  <b-form-input
                    id="nama_obat"
                    type="text"
                    v-model="dataFormRekonsiliasi.nama_obat"
                  ></b-form-input>
                </b-col>
                <b-col cols="6">
                  <label for="signa_rekonsilisasi">Signa </label>
                  <Multiselect
                    id="signa_rekonsilisasi"
                    v-model="$v.dataFormRekonsiliasi.signa_rekonsilisasi.$model"
                    :options="$store.state.data_static.signa"
                    :multiple="false"
                    :searchable="true"
                    :close-on-select="true"
                    :show-labels="false"
                    placeholder="-- Pilih Signa --"
                    size="sm"
                  ></Multiselect>
                </b-col>
                <b-col cols="6">
                  <label for="frekuensi">Frekuensi </label>
                  <b-input-group>
                    <b-form-input
                      v-model="$v.dataFormRekonsiliasi.frekuensi.$model"
                      type="number"
                    ></b-form-input>
                    <b-input-group-append>
                      <b-button disabled>Jam</b-button>
                    </b-input-group-append>
                  </b-input-group>
                  <!-- <Multiselect
                    id="frekuensi"
                    v-model="$v.dataFormRekonsiliasi.frekuensi.$model"
                    :options="$store.state.data_static.frekuensi"
                    :multiple="false"
                    :searchable="true"
                    :close-on-select="true"
                    :show-labels="false"
                    placeholder="-- Pilih Frekuensi --"
                    size="sm"
                  ></Multiselect> -->
                </b-col>
                <b-col cols="6">
                  <label for="cara_pemberian">Cara Pemberian </label>
                  <b-form-input
                    id="cara_pemberian"
                    type="text"
                    v-model="dataFormRekonsiliasi.cara_pemberian"
                  ></b-form-input>
                </b-col>
                <b-col :cols="dataFormRekonsiliasi.status_pemberian_obat == 'Lanjut aturan pakai berubah' ? '6' : '12'">
                  <label for="status_pemberian_obat">Status Pemberian Obat <span class="text-danger">*</span></label>
                  <Multiselect
                    id="status_pemberian_obat"
                    v-model="$v.dataFormRekonsiliasi.status_pemberian_obat.$model"
                    :options="$store.state.data_static.status_pemberian_obat"
                    :multiple="false"
                    :searchable="true"
                    :close-on-select="true"
                    :show-labels="false"
                    placeholder="-- Pilih Status Pemberian Obat --"
                    size="sm"
                    @select="dataFormRekonsiliasi.perubahan_aturan_pakai = null"
                    @remove="dataFormRekonsiliasi.perubahan_aturan_pakai = null"
                  ></Multiselect>
                </b-col>
                <b-col cols="6" v-if="dataFormRekonsiliasi.status_pemberian_obat == 'Lanjut aturan pakai berubah'">
                  <label for="perubahan_aturan_pakai">Perubahan Aturan Pakai</label>
                  <b-form-input
                    id="perubahan_aturan_pakai"
                    type="text"
                    v-model="dataFormRekonsiliasi.perubahan_aturan_pakai"
                  ></b-form-input>
                </b-col>
                <b-col cols="12">
                  <label for="keterangan">Keterangan</label>
                  <b-form-textarea
                    id="keterangan"
                    v-model="dataFormRekonsiliasi.keterangan"
                  ></b-form-textarea>
                </b-col>
                <b-col cols="12">
                  <div class="d-flex justify-content-end">
                    <b-button
                      :disabled="busy || kunjunganSelesai"
                      @click="simpanRekonsiliasi()"
                      variant="primary"
                    >Tambah</b-button>
                  </div>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="12">
              <b-table
                :items="itemsRekonsiliasi"
                :fields="fieldsRekonsiliasi"
                responsive
                show-empty
                small
                bordered
                striped
                hover
                :busy="busy"
                table-class="custom-table"
              >
                <template #cell(actions)="item">
                  <div class="d-flex nowrap">
                    <b-button
                      variant="info"
                      size="sm"
                      class="ml-1"
                      @click="item.toggleDetails"
                      v-c-tooltip.hover.click="'Detail Obat'"
                    >
                      <CIcon name="cil-info" />
                    </b-button>
                    <b-button
                      variant="danger"
                      size="sm"
                      class="ml-1"
                      v-c-tooltip.hover.click="'Hapus Data'"
                      v-b-modal.modal-delete-rekonsilisasi
                      :disabled="kunjunganSelesai"
                      @click="is_data_rekonsilisasi = item.item"
                      ><CIcon name="cil-trash" /> {{ item.actions }}</b-button
                    >
                  </div>
                </template>
                <template #row-details="row">
                  <b-card class="m-0">
                    <b-row class="m-0 p-0">
                      <b-col cols="4"><h6 class="m-0 p-0">Obat</h6></b-col>
                      <b-col cols="8"><h6 class="m-0 p-0">: {{row.item.nama_obat}}</h6></b-col>
                      <b-col cols="4"><h6 class="m-0 p-0">Signa</h6></b-col>
                      <b-col cols="8"><h6 class="m-0 p-0">: {{row.item.signa_rekonsilisasi}}</h6></b-col>
                      <b-col cols="4"><h6 class="m-0 p-0">Frekuensi</h6></b-col>
                      <b-col cols="8"><h6 class="m-0 p-0">: {{row.item.frekuensi}}</h6></b-col>
                      <b-col cols="4"><h6 class="m-0 p-0">Cara Pemberian</h6></b-col>
                      <b-col cols="8"><h6 class="m-0 p-0">: {{row.item.cara_pemberian}}</h6></b-col>
                      <b-col cols="4"><h6 class="m-0 p-0">Status Pemberian Obat</h6></b-col>
                      <b-col cols="8"><h6 class="m-0 p-0">: {{row.item.status_pemberian_obat}}</h6></b-col>
                      <b-col v-if="row.item.perubahan_aturan_pakai" cols="4"><h6 class="m-0 p-0">Perubahan Aturan Pakai</h6></b-col>
                      <b-col v-if="row.item.perubahan_aturan_pakai" cols="8"><h6 class="m-0 p-0">: {{row.item.perubahan_aturan_pakai}}</h6></b-col>
                      <b-col cols="4"><h6 class="m-0 p-0">Keterangan</h6></b-col>
                      <b-col cols="8"><h6 class="m-0 p-0">: {{row.item.keterangan}}</h6></b-col>
                    </b-row>
                  </b-card>
                </template>
              </b-table>
            </b-col>
          </b-row>
        </b-collapse>
      </b-col>

      <b-col v-if="dataForm.id" cols="12"><hr></b-col>
      <b-col v-if="dataForm.id" cols="12">
        <h5 class="custom-title-card"><strong>OBAT</strong></h5>
      </b-col>
      <b-col v-if="dataForm.id && !kunjunganSelesai" cols="12">
        <div class="mt-2 d-flex justify-content-end">
          <b-button
            :disabled="busy"
            @click="is_data_detail = {}, $bvModal.show('modal-kunjungan-obat')"
            variant="primary"
          >Tambah</b-button>
        </div>
      </b-col>

      <b-col v-if="dataForm.id" cols="12" md="12" lg="12">
        <b-table-simple hover small responsive bordered>
          <b-thead class="custom-table">
            <b-tr>
              <b-th v-for="field in fieldsObat" :key="field.key" :class="field.class">{{ field.label }}</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <tr v-for="(item, index) in itemsObat" :key="index">
              <th v-if="item.type == 'head'" scope="row" class="text-center">{{ item.no }}.</th>
              <td v-if="item.type == 'head' && item.no_racikan" colspan="7">{{ `${item.no_racikan} - ${item.jumlah_bungkus} bungkus ${item.indikasi_head_obat_jual ? ` - ${item.indikasi_head_obat_jual}` : ''}` }}</td>
              <!-- <td v-if="item.type == 'head' && !item.no_racikan" colspan="9"></td> -->
              <td v-if="item.type == 'head' && !item.no_racikan" colspan="7">Tanpa Racikan</td>
             
              <td v-if="item.type == 'sub'"></td>
              <td v-if="item.type == 'sub'">{{ item.nama_barang }}</td>
              <td v-if="item.type == 'sub'">{{ item.nama_qty_obat }}</td>
              <td v-if="item.type == 'sub'">{{ item.nama_qty_permintaan }}</td>
              <td v-if="item.type == 'sub'">{{ item.aturan_pakai_obat }}</td>
              <td v-if="item.type == 'sub'">{{ item.signa_obat }}</td>
              <td v-if="item.type == 'sub'">{{ item.nama_harga_sub_obat_jual }}</td>
              <td v-if="item.type == 'sub'">{{ item.nama_tanggal_kadaluarsa_obat }}</td>
              <!-- <td v-if="item.type == 'sub'">{{ item.indikasi_obat }}</td> -->
              <!-- <td v-if="item.type == 'sub'">{{ item.keterangan_obat }}</td> -->
              
              <td v-if="item.type == 'detail'"></td>
              <td v-if="item.type == 'detail'" colspan="8">
                <b-card class="m-0">
                  <b-row class="m-0 p-0" style="row-gap: 1rem;">
                    <b-col cols="6">
                      <div>
                        <h6><strong>Indikasi</strong></h6>
                        <p>{{item.indikasi_obat || '-'}}</p>
                      </div>
                      <div>
                        <h6><strong>Keterangan</strong></h6>
                        <p>{{item.keterangan_obat || '-'}}</p>
                      </div>
                    </b-col>
                    <b-col cols="6">
                      <div class="" v-for="(value, key) in item.item_obat_jual" :key="key">
                        <b-card class="m-0">
                          <b-row class="m-0 p-0">
                            <b-col cols="4"><h6 class="m-0 p-0">Kode Batch</h6></b-col>
                            <b-col cols="8"><h6 class="m-0 p-0">: {{value.kode_batch}}</h6></b-col>
                            <b-col cols="4"><h6 class="m-0 p-0">Jumlah</h6></b-col>
                            <b-col cols="8"><h6 class="m-0 p-0">: {{value.qty_item_obat_jual}}</h6></b-col>
                            <b-col cols="4"><h6 class="m-0 p-0">Kadaluarsa</h6></b-col>
                            <b-col cols="8"><h6 class="m-0 p-0">: {{$moment(value.tanggal_kadaluarsa).format("LL")}}</h6></b-col>
                          </b-row>
                        </b-card>
                      </div>
                    </b-col>
                  </b-row>
                </b-card>
              </td>
              <td class="text-center">
                <div class="d-flex">
                  <b-button
                    v-if="item.type == 'head'"
                    variant="info"
                    class="mr-1"
                    v-c-tooltip.hover.click="'Detail Racikan'"
                    @click="is_data_detail = {...item, head_obat_jual_id: item.head_obat_jual_id || true}, $bvModal.show('modal-kunjungan-obat')"
                  ><CIcon name="cil-info" /></b-button>
                  <!-- <b-button
                    v-if="item.type == 'head'"
                    variant="warning"
                    class="mr-1"
                    v-c-tooltip.hover.click="'Edit Racikan'"
                    @click="getDetailRacikan(item)"
                  ><CIcon name="cil-pencil" /></b-button> -->
                  <b-button
                    v-if="item.type == 'sub'"
                    variant="info"
                    class="mr-1"
                    v-c-tooltip.hover.click="'Detail Sub Obat'"
                    @click="detailSub(index)"
                  ><CIcon name="cil-info" /></b-button>
                  <b-button
                    v-if="item.type == 'sub' && !kunjunganSelesai"
                    variant="danger"
                    class="mr-1"
                    v-c-tooltip.hover.click="'Delete Sub Obat'"
                    @click="is_data = item, $bvModal.show('modal-delete-sub')"
                  ><CIcon name="cil-trash" /></b-button>
                </div>
              </td>
            </tr>
          </b-tbody>
        </b-table-simple>
      </b-col>
    </b-row>
    <modal-obat
      :is_data_detail="is_data_detail"
      :dataObat="dataForm"
      :dataSubObat="is_data"
      :listBarang="listBarang"
      :listAlergiPasien="listAlergiPasien"
      @alertFromChild="triggerAlert($event), getDatas()"
    />
    <modal-delete
      :data_delete="is_data"
      @alertFromChild="triggerAlert($event), getDatas()"
    />
    <modal-delete-rekonsilisasi
      :data_delete="is_data_rekonsilisasi"
      @alertFromChild="triggerAlert($event), getDatas()"
    />
    <ModalDeleteHead 
      :data_delete="is_data_detail" 
      @alertFromChild="triggerAlert($event), getDatas()"
    />
  </b-card>
</template>
<script>
import moment from 'moment';
moment.locale('id');
import ModalDelete from "./modalDeleteSub.vue";
import ModalDeleteRekonsilisasi from "./modalDeleteRekonsilisasi.vue";
import ModalDeleteHead from "./modalDeleteHead.vue"
import ModalObat from "./modalObat.vue";
import { validationMixin } from "vuelidate";
import { required, numeric, minValue } from "vuelidate/lib/validators";
export default {
  components:{
    ModalDelete,
    ModalDeleteRekonsilisasi,
    ModalObat,
    ModalDeleteHead,
  },
  props: [
    'kunjungan',
    'dataAnamnesa',
    'listAlergiPasien',
    'listDokter',
    'listPerawat',
    'kunjunganSelesai',
  ],
  data() {
    return {
      listGudang: [],
      listBarang: [],
      dataForm: {
        ms_gudang_id: null,
        tanggal_obat_jual: new Date(this.tanggal_sesi),
        ms_dokter_id: null,
        ms_apoteker_id: null,
      },
      dataFormRekonsiliasi: {
        nama_obat: null,
        signa_rekonsilisasi: null,
        frekuensi: null,
        cara_pemberian: null,
        status_pemberian_obat: null,
        perubahan_aturan_pakai: null,
        keterangan: null,
      },
      is_data: {},
      is_data_detail: {},
      is_data_rekonsilisasi: {},
      itemsObat: [],
      itemsRekonsiliasi: [],
      fieldsObat: [
        {
          key: "no",
          label: "No",
          sortDirection: "desc",
          sortable: true,
          class: "table-number text-center",
        },
        {
          key: "nama_barang",
          label: "Obat",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "nama_qty_obat",
          label: "Diberikan",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "nama_qty_permintaan",
          label: "Permintaan",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "aturan_pakai_obat",
          label: "Pakai",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "signa_obat",
          label: "Signa",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "nama_harga_sub_obat_jual",
          label: "Harga",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "nama_tanggal_kadaluarsa_obat",
          label: "Kadaluarsa",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        // {
        //   key: "indikasi_obat",
        //   label: "Indikasi",
        //   sortable: true,
        //   sortDirection: "desc",
        //   class: "text-left",
        // },
        // {
        //   key: "keterangan_obat",
        //   label: "Keterangan",
        //   sortable: true,
        //   sortDirection: "desc",
        //   class: "text-left",
        // },
        {
          key: "actions",
          label: "Actions",
          class: "table-option-2 text-center",
        },
      ],
      fieldsRekonsiliasi: [
        {
          key: "no",
          label: "No",
          sortDirection: "desc",
          sortable: true,
          class: "table-number text-center",
        },
        {
          key: "nama_obat",
          label: "Obat",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "signa_rekonsilisasi",
          label: "Signa",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "frekuensi",
          label: "Frekuensi",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "cara_pemberian",
          label: "Pemberian",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "status_pemberian_obat",
          label: "Status",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        // {
        //   key: "perubahan_aturan_pakai",
        //   label: "Aturan Pakai",
        //   sortable: true,
        //   sortDirection: "desc",
        //   class: "text-left",
        // },
        // {
        //   key: "keterangan",
        //   label: "Keterangan",
        //   sortable: true,
        //   sortDirection: "desc",
        //   class: "text-left",
        // },
        {
          key: "actions",
          label: "Actions",
          class: "table-option-2 text-center",
        },
      ],
      busy: false,
    }
  },
  async mounted() {
    const vm = this
    await vm.reset()
    await vm.getList()
  },
  computed: {
    tanggal_sesi(){
      return this.$store.state.tanggal_sesi
    },
    isValid() {
      return !this.$v.dataForm.$invalid;
    },
    isDirty() {
      return this.$v.dataForm.$anyDirty;
    },
    isValidRekonsiliasi() {
      return !this.$v.dataFormRekonsiliasi.$invalid;
    },
    isDirtyRekonsiliasi() {
      return this.$v.dataFormRekonsiliasi.$anyDirty;
    },
  },
  mixins: [validationMixin],
  validations: {
    dataForm: {
      ms_gudang_id: { required },
      tanggal_obat_jual: { required },
      ms_dokter_id: { required },
      ms_apoteker_id: { required },
    },
    dataFormRekonsiliasi: {
      nama_obat:  { required },
      signa_rekonsilisasi:  {  },
      frekuensi:  { numeric, minValue: minValue(0) },
      cara_pemberian:  {  },
      status_pemberian_obat:  { required },
      perubahan_aturan_pakai:  {  },
      keterangan:  {  },
    },
  },
  watch: {
    tanggal_sesi(newVal, oldVal){
      if(newVal != oldVal){
        if(!this.dataForm.id){
          this.dataForm.tanggal_obat_jual = new Date(this.tanggal_sesi)
        }
      }
    },
    'kunjungan': {
      handler() {
        this.onRefresh()
      },
      deep: true
    },
    'dataAnamnesa': {
      handler() {
        this.onRefresh()
      },
      deep: true
    },
  },
  methods: {
    async onRefresh(){
      const vm = this
      await vm.reset()
      await vm.getList()
      await vm.getDatas()
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async clickSelectorAll(selector){
      let domAll = document.querySelectorAll(selector)
      // console.log('domAll', domAll)
      for (let i = 0; i < domAll.length; i++) {
        const x = domAll[i];
        x.click()
      }
    },
    detailSub(idx){
      const vm = this
      if(!vm.itemsObat[idx+1]) vm.itemsObat[idx+1] = {}
      vm.itemsObat[idx+1].type == 'detail' ? vm.itemsObat.splice(idx+1, 1) : vm.itemsObat.splice(idx+1, 0, {...vm.itemsObat[idx], type: 'detail'})
    },
    async getDatas(){
      const vm = this
      vm.busy = true
      try {
        if(vm.kunjungan && vm.kunjungan.kunjungan_id){
          const obat = await vm.$axios.post('/obat_jual/list', {kunjungan_id: vm.kunjungan.kunjungan_id})
          // console.log('obat_jual/list', obat)
          if(obat.data.status == 200 && obat.data.data.length > 0){
            const x = obat.data.data[0]
            vm.dataForm = {
              tanggal_obat_jual: new Date(x.tanggal_obat_jual),
              ms_gudang_id: vm.$findKey(vm.listGudang, x.ms_gudang_id, true, 'ms_gudang_id'),
              ms_dokter_id: vm.$findKey(vm.listDokter, x.ms_dokter_id, true, 'tenaga_medis_id'),
              ms_apoteker_id: vm.$findKey(vm.listPerawat, x.ms_apoteker_id, true, 'tenaga_medis_id'),
              no_resep_obat: x.no_resep_obat,
              id: x.obat_jual_id,
              obat_jual_id: x.obat_jual_id,
              kunjungan_id: x.kunjungan_id,
            }
            vm.getBarang()
            // list sub obat pasien
            // const listObat = []
            const resObat = await vm.$axios.post('/sub_obat_jual/list_obat_jual', {kunjungan_id: vm.kunjungan.kunjungan_id, obat_jual_id: x.obat_jual_id})
            // console.log('sub obat pasien', resObat)
            const listObat = resObat.data.status == 200 ? resObat.data.data.map((x, i) => { 
              return {
                ...x,
                no: i + 1,
              }
            }) : []
            vm.itemsObat = [] 
            // console.log('listObat', listObat)
            for (let i = 0; i < listObat.length; i++) {
              const obat = listObat[i];
              vm.itemsObat.push({
                no: i + 1,
                ...obat,
                // data_sub: undefined,
                type: 'head',
              })
              for (let x = 0; x < obat.data_sub.length; x++) {
                const sub = obat.data_sub[x];
                vm.itemsObat.push({
                  no: x + 1,
                  ...sub,
                  type: 'sub',
                  nama_qty_obat: vm.$numberFormat(sub.qty_obat),
                  nama_qty_permintaan: vm.$numberFormat(sub.qty_permintaan),
                  nama_harga_sub_obat_jual: vm.$numberFormat(sub.harga_sub_obat_jual, 'idr'),
                  nama_tanggal_kadaluarsa_obat: sub.tanggal_kadaluarsa_obat ? vm.$moment(sub.tanggal_kadaluarsa_obat).format('LL') : '-',
                })
              }
            }
            //list rekonsilisasi obat pasien
            const itemsRekonsiliasi = await vm.$axios.post('/rekonsilisasi_obat_pasien/list', {kunjungan_id: vm.kunjungan.kunjungan_id, obat_jual_id: x.obat_jual_id})
            // console.log('sub obat pasien', itemsRekonsiliasi)
            vm.itemsRekonsiliasi = itemsRekonsiliasi.data.status == 200 ? itemsRekonsiliasi.data.data.map((x, i) => { return {
              ...x,
              no: i + 1,
            }}) : []
            vm.totalRows = vm.itemsObat.length
          }
        }
      } catch (err) {
        console.log(err)
        if (err.message) {
          vm.triggerAlert({ variant: "danger", msg:err.message.toUpperCase(), showing: true });
        } else {
          vm.triggerAlert({ variant: "danger", msg:err.toUpperCase(), showing: true });
        }
      } finally {
        vm.busy = false;
      }
    },
    async getList(){
      const vm = this
      vm.busy = true
      try {
        // console.log('vm.kunjungan', vm.kunjungan)
        if (vm.kunjungan.ms_poli_id) {
          //gudang
          let listGudang = await vm.$axios.post('/ms_gudang/list')
          vm.listGudang = []
          for (let i = 0; i < listGudang.data.data.length; i++) {
            let x = listGudang.data.data[i];
            if(x.ms_poli_id == vm.kunjungan.ms_poli_id.ms_poli_id){
              vm.listGudang.push(x)
            }else if(!x.ms_gudang_utama_id){
              vm.listGudang.push(x)
            }
          }
          
          // vm.listGudang.map(x => { return {
          //   ...x,
          //   nama: x.nama_gudang + ' - ' + x.tipe_gudang,
          // }})
          // console.log(vm.listGudang, 'ini list gudang');
          // if(!vm.dataForm.ms_gudang_id && vm.listGudang.length > 0) vm.dataForm.ms_gudang_id = vm.listGudang[0]
        }
      } catch (err) {
        if (err.message) {
          vm.triggerAlert({ variant: "danger", msg:err.message.toUpperCase(), showing: true });
        } else {
          vm.triggerAlert({ variant: "danger", msg:err.toUpperCase(), showing: true });
        }
      } finally {
        vm.busy = false;
      }
    },
    async getBarang(){
      const vm = this
      vm.busy = true
      try {
        if(vm.dataForm.ms_gudang_id){
          //barang
          let listBarang = await vm.$axios.post('/stock/list_stock_by_gudang', {
            ms_gudang_id: vm.dataForm.ms_gudang_id.ms_gudang_id,
            nama_jenis_barang: 'OBAT',
          })
          vm.listBarang = listBarang.data.status == 200 ? listBarang.data.data.map(x => { return {
            ...x,
            nama: x.kode_produk + ' - ' + x.nama_barang + ' - ' + vm.$numberFormat(x.qty_stock)
          }}) : []
          // console.log('list_stock_by_gudang', listBarang)
        }else{
          vm.listBarang = []
        }
      } catch (err) {
        if (err.message) {
          vm.triggerAlert({ variant: "danger", msg:err.message.toUpperCase(), showing: true });
        } else {
          vm.triggerAlert({ variant: "danger", msg:err.toUpperCase(), showing: true });
        }
      } finally {
        vm.busy = false;
      }
    },
    async simpan(){
      const vm = this
      vm.busy = true
      try {
        await this.$v.dataForm.$touch();
        if (vm.isValid && vm.isDirty) {
          let res = await vm.$axios.post('/obat_jual/register', {
            ...vm.dataForm,
            ms_gudang_id: vm.dataForm.ms_gudang_id.ms_gudang_id,
            ms_dokter_id: vm.dataForm.ms_dokter_id.tenaga_medis_id,
            ms_apoteker_id: vm.dataForm.ms_apoteker_id.tenaga_medis_id,
            kunjungan_id: vm.kunjungan.kunjungan_id,
          })
          // console.log('register diagnosa', res)
          if(res.data.status == 200){
            vm.triggerAlert({ variant: "success", msg: 'Berhasil Membuat Obat', showing: true });
            vm.reset()
          }else{
            vm.triggerAlert({ variant: "danger", msg: 'Gagal Membuat Obat', showing: true });
          }
        }else{
          vm.triggerAlert({ variant: "warning", msg: 'Data Tidak Valid', showing: true });
        }
      } catch (error) {
        console.log(error)
        if (error.message) {
          vm.triggerAlert({ variant: "danger", msg: error.message.toUpperCase(), showing: true });
        } else {
          vm.triggerAlert({ variant: "danger", msg: error.toUpperCase(), showing: true });
        }
      } finally {
        vm.busy = false
      }
    },
    async update(){
      const vm = this
      vm.busy = true
      try {
        await this.$v.dataForm.$touch();
        if (vm.isValid && vm.isDirty) {
          let res = await vm.$axios.post('/obat_jual/update', {
            ...vm.dataForm,
            ms_gudang_id: vm.dataForm.ms_gudang_id.ms_gudang_id,
            ms_dokter_id: vm.dataForm.ms_dokter_id.tenaga_medis_id,
            ms_apoteker_id: vm.dataForm.ms_apoteker_id.tenaga_medis_id,
            kunjungan_id: vm.kunjungan.kunjungan_id,
          })
          // console.log('update diagnosa', res)
          if(res.data.status == 200){
            vm.triggerAlert({ variant: "success", msg: 'Berhasil Mengubah Obat', showing: true });
            vm.reset()
          }else{
            vm.triggerAlert({ variant: "danger", msg: 'Gagal Mengubah Obat', showing: true });
          }
        }else{
          vm.triggerAlert({ variant: "warning", msg: 'Data Tidak Valid', showing: true });
        }
      } catch (error) {
        console.log(error)
        if (error.message) {
          vm.triggerAlert({ variant: "danger", msg: error.message.toUpperCase(), showing: true });
        } else {
          vm.triggerAlert({ variant: "danger", msg: error.toUpperCase(), showing: true });
        }
      } finally {
        vm.busy = false
      }
    },
    async simpanRekonsiliasi(){
      const vm = this
      vm.busy = true
      try {
        await this.$v.dataFormRekonsiliasi.$touch();
        // console.log('dataFormRekonsiliasi', vm.dataFormRekonsiliasi)
        if (vm.isValidRekonsiliasi && vm.isDirtyRekonsiliasi) {
          let res = await vm.$axios.post('/rekonsilisasi_obat_pasien/register', {
            ...vm.dataFormRekonsiliasi,
            obat_jual_id: vm.dataForm.obat_jual_id,
            kunjungan_id: vm.kunjungan.kunjungan_id,
          })
          // console.log('register diagnosa', res)
          if(res.data.status == 200){
            vm.triggerAlert({ variant: "success", msg: 'Berhasil Membuat Rekonsiliasi Obat', showing: true });
            vm.reset()
          }else{
            vm.triggerAlert({ variant: "danger", msg: 'Gagal Membuat Rekonsiliasi Obat', showing: true });
          }
        }else{
          vm.triggerAlert({ variant: "warning", msg: 'Data Tidak Valid', showing: true });
        }
      } catch (error) {
        console.log(error)
        if (error.message) {
          vm.triggerAlert({ variant: "danger", msg: error.message.toUpperCase(), showing: true });
        } else {
          vm.triggerAlert({ variant: "danger", msg: error.toUpperCase(), showing: true });
        }
      } finally {
        vm.busy = false
      }
    },
    async reset(){
      const vm = this
      vm.dataForm = {
        ms_gudang_id: null,
        tanggal_obat_jual: new Date(this.tanggal_sesi),
        ms_dokter_id: vm.$findKey(vm.listDokter, vm.dataAnamnesa.ms_dokter_id, true, 'tenaga_medis_id'),
        ms_apoteker_id: vm.$findKey(vm.listPerawat, vm.dataAnamnesa.ms_perawat_id, true, 'tenaga_medis_id'),
      }
      vm.dataFormRekonsiliasi = {
        nama_obat: null,
        signa_rekonsilisasi: null,
        frekuensi: null,
        cara_pemberian: null,
        status_pemberian_obat: null,
        perubahan_aturan_pakai: null,
        keterangan: null,
      }
      vm.$v.$reset()
      await vm.getList()
      await vm.getDatas()
    },
    triggerAlert(event) {
      let vm = this;
      vm.$store.commit("set_alert", event);
    },
  },
}
</script>


<style scoped>
.table-color {
  background-color: #2ecc71 !important ;
  color: #fff;
  font-weight: 600;
}
.hidden-header {
  display: none;
}
.cetak-rol-btn {
  background-color: #9C4098 !important;
  color: #FFFFFF;
}
.cetak-btn {
  background-color: #F2994A !important;
  color: #FFFFFF;
}

/* .custom-table th{
  background-color: #2ecc71 !important ;
  color: #fff;
  font-weight: 600;
}

.custom-table th:first-child{
  border-top-left-radius: 8px;
}

.custom-table th:last-child{
  border-top-right-radius: 8px;
} */
</style>