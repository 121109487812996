<template>
  <div>
    <b-modal
      id="modal-antrian"
      size="lg"
      centered
      title="Tambah Tambah Antrian"
      header-bg-variant="primary"
      header-text-variant="light"
      @show="openModal()"
    >
      <b-form>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Nama Pasien <span class="text-danger">*</span>
          </template>
          <div class="">
            <b-form-input
              id="nama_lengkap"
              v-model="dataForm.nama_lengkap"
              disabled
            ></b-form-input>
          </div>
        </b-form-group>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Nama Asuransi <span class="text-danger">*</span>
          </template>
          <div class="">
            <b-form-input
              id="nama_asuransi"
              v-model="dataForm.nama_asuransi"
              disabled
            ></b-form-input>
          </div>
        </b-form-group>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Tanggal <span class="text-danger">*</span>
          </template>
          <div class="">
            <date-picker
              style="width: 100%"
              id="tgl_registrasi"
              format="DD-MM-YYYY"
              show-hour
              show-minute
              v-model="dataForm.tgl_registrasi"
              :state="checkValidasi('tgl_registrasi').s"
              disabled
            ></date-picker>
            <span class="text-danger">{{checkValidasi('tgl_registrasi').m}}</span>
          </div>
        </b-form-group>
        <b-form-group label-cols-md="3" v-if="!isPoli">
          <template v-slot:label>
            Tanggal <span class="text-danger">*</span>
          </template>
          <div class="">
            <multiselect
              id="layanan"
              v-model="dataForm.layanan"
              :options="listLayanan"
              :multiple="false"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              label="nama_layanan"
              track-by="id"
              placeholder="-- Pilih Layanan --"
              size="sm"
              :state="checkValidasi('layanan').s"
            ></multiselect>
            <span class="text-danger">{{checkValidasi('layanan').m}}</span>
          </div>
        </b-form-group>
        <b-form-group label-cols-md="3" v-if="isPoli">
          <template v-slot:label>
            Poliklinik <span class="text-danger">*</span>
          </template>
          <div class="">
            <multiselect
              id="poliklinik"
              v-model="dataForm.poliklinik"
              :options="listPoliklinik"
              :multiple="false"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              label="nama_poliklinik"
              track-by="id"
              placeholder="-- Pilih Poliklinik --"
              size="sm"
              :state="checkValidasi('poliklinik').s"
            ></multiselect>
            <span class="text-danger">{{checkValidasi('poliklinik').m}}</span>
          </div>
        </b-form-group>
        <b-form-group label-cols-md="3" v-if="isPoli">
          <template v-slot:label>
            Dokter <span class="text-danger">*</span>
          </template>
          <!-- {{listDokter}} -->
          <div class="">
            <multiselect
              id="dokter"
              v-model="dataForm.dokter"
              :options="listDokter"
              :multiple="false"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              label="nama_dokter"
              track-by="id"
              placeholder="-- Pilih Dokter --"
              size="sm"
              disabled
              :state="checkValidasi('dokter').s"
            ></multiselect>
            <span class="text-danger">{{checkValidasi('dokter').m}}</span>
          </div>
        </b-form-group>
        <b-form-group label-cols-md="3" v-if="isPoli">
          <template v-slot:label>
            Jadwal <span class="text-danger">*</span>
          </template>
          <div class="">
            <multiselect
              id="jadwal"
              v-model="dataForm.jadwal_dokter"
              :options="listJadwalDokter"
              :multiple="false"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              label="nama_jadwal"
              track-by="id"
              placeholder="-- Pilih Jadwal --"
              size="sm"
              :disabled="!dataForm.poliklinik"
              :state="checkValidasi('jadwal_dokter').s"
            ></multiselect>
            <span class="text-danger">{{checkValidasi('jadwal_dokter').m}}</span>
          </div>
        </b-form-group>
      </b-form>
      <template #modal-footer>
        <b-button variant="secondary" @click="$bvModal.hide('modal-antrian')">
          Batal
        </b-button>
        <b-button
          variant="primary"
          :disabled="busy || !isValid"
          @click="simpan()"
          >{{ button }}</b-button
        >
      </template>
      <!-- {{dataForm}} -->
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment'
import axios from "axios";
import { ipBackend } from "@/ipBackend";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import useVuelidate from '@vuelidate/core';

import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
export default {
  components: {
    Multiselect,
    DatePicker,
  },
  name: "modalInput",
  props: ['dataAntrian'],
  setup() { return { v$: useVuelidate({ $lazy: true, $autoDirty: true }) } },
  data() {
    return {
      dataForm: {
        tgl_registrasi: null,
        layanan: null,
        poliklinik: null,
        dokter: null,
        jadwal_dokter: null,
      },
      show: false,
      selected: null,
      busy: false,
      button: "Simpan",
      listDokter: [{nama_dokter: '11', id: 'aa`'}],
      listPoliklinik: [],
      listJadwalDokter: [],
      listLayanan: [],
    };
  },
  computed: {
    formString() {
      return JSON.stringify(this.dataForm, null, 4);
    },
    isValid() {
      return !this.v$.dataForm.$invalid;
    },
    isDirty() {
      return this.v$.dataForm.$anyDirty;
    },
    isPoli(){
      return this.dataForm.ms_dokter_id != null
    }
  },
  mixins: [validationMixin],
  validations: {
    dataForm: {
      tgl_registrasi: { required },
      layanan: { required: (value, sibiling, vm) => { return !vm.isPoli ? value : true } },
      poliklinik: { required: (value, sibiling, vm) => { return vm.isPoli ? value : true } },
      dokter: { required: (value, sibiling, vm) => { return vm.isPoli ? value : true } },
      jadwal_dokter: { 
        required: (value, sibiling, vm) => { return vm.isPoli ? value : true },
        kuotaDokter: (value, sibiling, vm) => { return value ? (vm.isAsuransiBPJS ? value.bpjs : value.umum) > 0 : true },
      },
    },
  },
  watch: {
    'dataForm.poliklinik'(newVar, oldVar){
      if(newVar != oldVar){
        this.dataForm.jadwal_dokter = null
      }
    },
  },
  methods: {
    async openModal(){
      const vm = this
      console.log(this.dataAntrian)
      const x = this.dataAntrian
      x.tgl_registrasi = new Date(x.tgl_registrasi)
      if(this.dataAntrian) this.dataForm = {...this.dataForm, ...this.dataAntrian}
      this.dataForm.nama_asuransi = this.dataAntrian.nama_asuransi ? this.dataAntrian.nama_asuransi : '-'
      if(vm.isPoli){
        //Poliklinik / dokter / jadwal
        let res = await axios.post(ipBackend + "jadwalDokter/listKuotaJadwalDokterByMsDokterId", {
          ms_dokter_id: this.dataForm.ms_dokter_id, 
          tanggal: moment(this.dataForm.tgl_registrasi).format('YYYY-MM-DD')
        });
        console.log('ini result jadwal dokter by dokter id ', res)
        if(res.data.status == 200 && res.data.data.length){
          this.listDokter = [res.data.data[0]]
          this.listPoliklinik = []
          this.listJadwalDokter = []
  
          for (let i = 0; i < res.data.data.length; i++) {
            const x = res.data.data[i];
            //hitung sisa kuota
            x.sisa_umum = x.quota - (x.offline_bpjs + x.offline_umum + x.online_bpjs + x.online_umum)
            x.sisa_bpjs = x.quota_jkn - (x.offline_bpjs + x.online_bpjs)
            x.umum = x.sisa_umum
            x.bpjs = x.sisa_umum < x.sisa_bpjs ? x.sisa_umum : x.sisa_bpjs
            x.nama_jadwal = x.waktu_mulai_perubahan && x.waktu_selesai_perubahan ? 
              `${moment(x.waktu_mulai_perubahan, 'HH:mm:SS').format('HH:mm')} - ${moment(x.waktu_selesai_perubahan, 'HH:mm:SS').format('HH:mm')}, ${x.sisa_umum} umum, ${x.bpjs} BPJS`:
              `${moment(x.waktu_mulai, 'HH:mm:SS').format('HH:mm')} - ${moment(x.waktu_selesai, 'HH:mm:SS').format('HH:mm')}, ${x.sisa_umum} umum, ${x.bpjs} BPJS`;
            let y = 0
            for (; y < this.listPoliklinik.length; y++) {
              const itemDokter = this.listPoliklinik[y];
              if(itemDokter.ms_poliklinik_id == x.ms_poliklinik_id) break
            }
            if(this.listPoliklinik.length != y && x !== 0){
              this.listPoliklinik[y].jadwal.push(x)
            }else{
              this.listPoliklinik.push({...x, jadwal: [x]})
            }
          }
          this.dataForm.dokter = this.listDokter[0]
          this.dataForm.poliklinik = this.listPoliklinik[0]
          this.listJadwalDokter = this.listPoliklinik[0].jadwal
          setTimeout(() => { //ntah kenapa harus dijeda
            this.dataForm.jadwal_dokter = this.listJadwalDokter[0]
          }, 10);
        }
      }else{ 
        //Layanan Ruang
        let listLayanan = await axios.get(ipBackend + "layananRuang/list");
        this.listLayanan = listLayanan.data.status == 200 ? listLayanan.data.data.filter(item => { 
          return this.dataForm.layanan_ruang_id ? item.layanan_ruang_id == this.dataForm.layanan_ruang_id : true 
        }) : []
      }
      this.v$.$reset()
    },
    checkIfValid(fieldName) {
      const field = this.$v.dataForm[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    async simpan() {
      let vm = this;
      vm.busy = true;
      vm.button = "Mohon Tunggu";
      this.v$.dataForm.$touch();
      console.log(vm.dataForm)
      console.log(vm.isDirty)
      console.log(vm.isValid)
      if (vm.isValid && vm.isDirty) {
        //cari jenis antrian id
        const jenisAntrian = await axios.get(ipBackend + "jenisAntrian/list");
        const jenis_antrian_id = jenisAntrian.data.status == 200 ? jenisAntrian.data.data.filter(item => { return item.nama_jenis_antrian == 'Rawat Jalan' })[0].id : null
        // tgl_antrian, jadwal_dokter_id, registrasi_id, antrian_id, is_master, initial, poli_layanan, 
        // booking_id, status_antrian, antrian_no, jenis_antrian_id, ms_loket_id, layanan_ruang_id
        const x = vm.dataForm
        const data = {
          is_master: 1,
          jenis_antrian_id,
          antrian_id: x.antrian_loket_id, 
          registrasi_id: x.registrasi_id,
          tgl_antrian: x.tgl_registrasi,
        }
        if(vm.isPoli){
          data.poli_layanan = 2 
          data.jadwal_dokter_id = x.jadwal_dokter.jadwal_dokter_id
          data.initial = x.jadwal_dokter.initial_jadwal
        }else{
          data.poli_layanan = 3 
          data.layanan_ruang_id = x.layanan.layanan_ruang_id
          data.initial = x.layanan.initial_layanan_ruang
        }
        console.log('data untuk soket apm umum = ', data)
        const apmid = this.$store.state.apm_id
        const resSocket = await vm.$socket.emit("pindahAntrianRajalLoket", data, `${apmid}`);
        console.log(resSocket)
        vm.$bvModal.hide('modal-antrian')
        vm.$store.commit("set_alert", { variant: "success", msg: "Berhasil Mendaftarkan Ke Poli", showing: true });
        console.log('berhasil')
        
        this.$socket.off("refresh_pindah_antrian_rajal_loket")
        this.$socket.on("refresh_pindah_antrian_rajal_loket", (data) => {
          console.log(data, 'ini refresh_pindah_antrian_rajal_loket');
          // this.print(data)
          // this.getDatas()
        });
      }else{
        vm.$store.commit("set_alert", { variant: "warning", msg: "Gagal Mendaftarkan Ke Poli", showing: true });
      }
      vm.button = "Simpan";
      vm.busy = false;
    },
    reset() {
      this.$v.$reset();
      this.data = {
        nama_poliklinik: "",
        kode_poliklinik: "",
      };
    },
    checkValidasi(fieldName) {
      // console.log(fieldName)
      const field = this.v$.dataForm[fieldName];
      const x = field.$error === true && field.$errors.length ? field.$errors[0].$validator : '' ;
      if (x == 'required') {
        return { s: false, m: '* Data wajib diisi' }
      } else if (x == 'email') {
        return { s: false, m: '* Data harus berbentuk email' }
      } else if (x == 'numeric') {
        return { s: false, m: '* Data harus terdiri hanxa dari angka' }
      } else if (x == 'minLength') {
        return { s: false, m: `* Data belum mencapai minimal digits` }
      } else if (x == 'maxLength') {
        return { s: false, m: `* Data melebihi maksimal digits` }
      } else if (x == 'alphaNum') {
        return { s: false, m: `* Data tidak boleh mengandung spasi atau simbol` }
      } else if (x == 'official') {
        return { s: false, m: `* Data tidak boleh mengandung spasi atau simbol` }
      } else if (x == 'kuotaDokter'){
        return { b: false, s: false, m: '* Kuota Habis' }
      } else {
        return { s: null, m: null }
      }
    },
  },
};
</script>
