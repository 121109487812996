import { render, staticRenderFns } from "./tekanan_darah.vue?vue&type=template&id=ca19b400"
import script from "./tekanan_darah.vue?vue&type=script&lang=js"
export * from "./tekanan_darah.vue?vue&type=script&lang=js"
import style0 from "./tekanan_darah.vue?vue&type=style&index=0&id=ca19b400&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports