<template>
  <div id="page-kunjungan">         
    <b-overlay :show="is_kunjungan_ready" no-center rounded="sm">
      <template #overlay>
        <div class="position-absolute" style="top: 15%; left: 50%;">
          <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
          
            <b-icon icon="three-dots" animation="fade" font-scale="4"></b-icon>
            <p>Mohon Tunggu</p>
          </div>
          <!-- <b-spinner
            v-for="variant in variants"
            :variant="variant"
            :key="variant"
            type="grow"
            class="mx-1"
          ></b-spinner> -->
        </div>
      </template>
      <b-container fluid>
        <b-row style="padding-top: 15px">
          <b-col cols="12" md="12" lg="8" xl="8">
            <div
              style="
                width: 100%;
                height: 40px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                background-color: ;
              "
              class="align-items-center"
            >
              <h6
                style="font-weight: 700; color: #9c4098; font-size: 22px; margin-bottom: 0">
                <img v-if="dataPasien.no_kartu" src="/img/logo-bpjs-1.svg" alt="" srcset="" style="height: 3.3rem;" class="mr-2">
                <span>Kunjungan</span>
              </h6>
            </div>
          </b-col>
          <b-col cols="12" md="12" lg="4" xl="4">
            <div style="display: flex; justify-content: flex-end">
              <b-button
                variant="success"
                @click="$bvModal.show('modal-sync')"
                style="margin-right: 10px"
              >
                <!-- <CIcon name="cil-list" />  -->
                <img src="/img/logo-bpjs-1.svg" alt="" srcset="" style="height: 1.5rem;margin-right:5px;">Sync
              </b-button>
              <b-button variant="primary" @click="$bvModal.show('modal-kunjungan')">
                <CIcon name="cil-list" /> Kunjungan
              </b-button>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="12" lg="12" xl="12" class="p-0">
            <hr />
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="4" sm="12" md="12" lg="4" xl="4">
            <b-card style="margin-bottom: 15px">
              <b-row>
                <b-col cols="12" md="12" lg="12" xl="12">
                  <h5 class="custom-title-card">Data Pasien</h5>
                </b-col>
              </b-row>
              <b-row v-if="dataPasien">
                <b-col cols="5" class="fw-600">No RM</b-col>
                <b-col cols="7">: {{ dataPasien.no_rm || "-" }}</b-col>
                <b-col cols="5" class="fw-600">No RM Lama</b-col>
                <b-col cols="7">: {{ dataPasien.no_rm_lama || "-" }}</b-col>
                <b-col cols="5" class="fw-600">Nama pasien</b-col>
                <b-col cols="7">: {{ dataPasien.nama_lengkap || "-" }}</b-col>
                <b-col cols="5" class="fw-600">Jenis kelamin</b-col>
                <b-col cols="7">: {{ dataPasien.nama_jenis_kelamin || "-" }}</b-col>
                <b-col cols="5" class="fw-600">Umur</b-col>
                <b-col cols="7">: {{ dataPasien.umur || "-" }}</b-col>
                <b-col cols="5" class="fw-600">NIK</b-col>
                <b-col cols="7">: {{ dataPasien.nik || "-" }}</b-col>
                <b-col cols="5" class="fw-600">No Telepon</b-col>
                <b-col cols="7">: {{ dataPasien.no_telp || "-" }}</b-col>
                <b-col cols="5" class="fw-600">No Kunjungan BPJS</b-col>
                <b-col cols="7">: {{ dataPasien.no_kunjungan_bpjs || "-" }}</b-col>
                <b-col cols="5" class="fw-600">No BPJS</b-col>
                <b-col cols="7">: {{ dataPasien.no_kartu || "-" }}</b-col>
                <b-col cols="5" class="fw-600">Alamat sekarang</b-col>
                <b-col cols="7">: {{ dataPasien.alamat_sekarang || "-" }}</b-col>
                <b-col cols="5" class="fw-600">Pekerjaan</b-col>
                <b-col cols="7">: {{ dataPasien.pekerjaan || "-" }}</b-col>
                <b-col cols="5" class="fw-600">Nama Ayah</b-col>
                <b-col cols="7">: {{ dataPasien.nama_ayah || "-" }}</b-col>
                <b-col cols="5" class="fw-600">Nama Ibu</b-col>
                <b-col cols="7">: {{ dataPasien.nama_ibu || "-" }}</b-col>
                <b-col cols="5" class="fw-600">Penjamin</b-col>
                <b-col cols="7">: {{ dataPasien.nama_penjamin || "-" }}</b-col>
              </b-row>
            </b-card>
            <b-card style="margin-bottom: 15px">
              <b-row>
                <b-col cols="12" md="12" lg="12" xl="12">
                  <h5 class="custom-title-card">Data Kunjungan</h5>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12" md="12" lg="12" xl="12"></b-col>
              </b-row>
              <b-row>
                <b-col cols="12" md="12" lg="12" xl="12">
                  <label for="kd_tkp">Jenis Layanan</label>
                  <multiselect
                    id="kd_tkp"
                    v-model="dataForm.kd_tkp"
                    :options="$store.state.data_static.tkp"
                    :multiple="false"
                    :searchable="true"
                    :close-on-select="true"
                    :show-labels="false"
                    label="text"
                    track-by="kdTkp"
                    size="sm"
                    disabled
                  ></multiselect>
                </b-col>
                <b-col cols="12" md="12" lg="12" xl="12">
                  <label for="no_urut">No Urut Pendaftaran</label>
                  <b-form-input
                    id="no_urut"
                    v-model="dataForm.no_urut"
                    trim
                    disabled
                  ></b-form-input>
                </b-col>
                <b-col cols="12" md="12" lg="12" xl="12">
                  <label for="tanggal_daftar">Tanggal Daftar</label>
                  <date-picker
                    style="width: 100%"
                    id="tanggal_daftar"
                    format="DD-MM-YYYY"
                    v-model="dataForm.tanggal_daftar"
                    disabled
                  ></date-picker>
                </b-col>
                <b-col cols="12" md="12" lg="12" xl="12">
                  <label for="ms_poli_id">Poliklinik</label>
                  <multiselect
                    id="ms_poli_id"
                    v-model="dataForm.ms_poli_id"
                    :options="listPoliklinik"
                    :multiple="false"
                    :searchable="true"
                    :close-on-select="true"
                    :show-labels="false"
                    label="nama_poli"
                    track-by="ms_poli_id"
                    placeholder="-- Pilih Poliklinik --"
                    size="sm"
                    disabled
                    :class="{ invalid: !checkValidasi('ms_poli_id').b }"
                  ></multiselect>
                  <span class="text-danger">{{ checkValidasi("ms_poli_id").m }}</span>
                </b-col>
                <b-col cols="12" md="12" lg="12" xl="12">
                  <label for="no_kartu">No BPJS</label>
                  <b-form-input
                    id="no_kartu"
                    v-model="dataForm.no_kartu"
                    :state="checkValidasi('no_kartu').s"
                    disabled
                  ></b-form-input>
                  <span class="text-danger">{{
                    checkValidasi("no_kartu").m
                  }}</span>
                </b-col>
                <b-col cols="12" md="12" lg="12" xl="12">
                  <label for="kdppk">Faskes Pertama</label>
                  <multiselect
                    id="kdppk"
                    v-model="dataForm.kdppk"
                    :options="listProviderPeserta"
                    :multiple="false"
                    :searchable="true"
                    :close-on-select="true"
                    :show-labels="false"
                    label="nmProvider"
                    track-by="kdProvider"
                    placeholder="-- Pilih Faskes Pertama --"
                    size="sm"
                    :class="{ invalid: !checkValidasi('kdppk').b }"
                    disabled
                  ></multiselect>
                  <span class="text-danger">{{ checkValidasi("kdppk").m }}</span>
                </b-col>
                <b-col cols="12" md="12" lg="12" xl="12">
                  <label for="kd_tacc">TACC</label>
                  <multiselect
                    id="kd_tacc"
                    v-model="dataForm.kd_tacc"
                    :options="$store.state.data_static.TACC"
                    :multiple="false"
                    :searchable="true"
                    :close-on-select="true"
                    :show-labels="false"
                    label="nmTacc"
                    track-by="kdTacc"
                    placeholder="-- Pilih TACC --"
                    size="sm"
                    :class="{ invalid: !checkValidasi('kd_tacc').b }"
                    @input="(dataForm.alasan_tacc = null), v$.$reset()"
                  ></multiselect>
                  <span class="text-danger">{{ checkValidasi("kd_tacc").m }}</span>
                </b-col>
                <b-col
                  v-if="dataForm.kd_tacc && dataForm.kd_tacc.kdTacc == '3'"
                  cols="12"
                  md="12"
                  lg="12"
                  xl="12"
                >
                  <label for="alasan_tacc">Alasan TACC</label>
                  <b-form-input
                    id="alasan_tacc"
                    v-model="dataForm.alasan_tacc"
                    :state="checkValidasi('alasan_tacc').s"
                  ></b-form-input>
                  <span class="text-danger">{{ checkValidasi("alasan_tacc").m }}</span>
                </b-col>
                <b-col
                  v-if="dataForm.kd_tacc && dataForm.kd_tacc.kdTacc >= 1"
                  cols="12"
                  md="12"
                  lg="12"
                  xl="12"
                >
                  <label for="alasan_tacc">Alasan TACC</label>
                  <multiselect
                    id="alasan_tacc"
                    v-model="dataForm.alasan_tacc"
                    :options="dataForm.kd_tacc.alasanTacc"
                    :multiple="false"
                    :searchable="true"
                    :close-on-select="true"
                    :show-labels="false"
                    placeholder="-- Pilih Alasan TACC --"
                    size="sm"
                    class="w-100"
                    :class="{ invalid: !checkValidasi('alasan_tacc').b }"
                  ></multiselect>
                  <span class="text-danger">{{ checkValidasi("alasan_tacc").m }}</span>
                </b-col>
                <b-col cols="12" md="12" lg="12" xl="12">
                  <label for="no_surat_meninggal">No Surat Meninggal</label>
                  <b-form-input
                    id="no_surat_meninggal"
                    v-model="dataForm.no_surat_meninggal"
                    :state="checkValidasi('no_surat_meninggal').s"
                  ></b-form-input>
                  <span class="text-danger">{{
                    checkValidasi("no_surat_meninggal").m
                  }}</span>
                </b-col>
                <b-col cols="12" md="12" lg="12" xl="12">
                  <label for="catatan_kunjungan">Catatan Kunjungan</label>
                  <b-form-textarea
                    id="catatan_kunjungan"
                    v-model="dataForm.catatan_kunjungan"
                    :state="checkValidasi('catatan_kunjungan').s"
                  ></b-form-textarea>
                  <span class="text-danger">{{
                    checkValidasi("catatan_kunjungan").m
                  }}</span>
                </b-col>
                <b-col cols="12" md="12" lg="12" xl="12">
                  <label for="waktu_mulai">Mulai</label>
                  <date-picker
                    style="width: 100%"
                    id="waktu_mulai"
                    format="DD-MM-YYYY HH:mm"
                    v-model="dataForm.waktu_mulai"
                    :state="checkValidasi('waktu_mulai').s"
                    disabled
                  ></date-picker>
                  <span class="text-danger">{{ checkValidasi("waktu_mulai").m }}</span>
                </b-col>
                <b-col cols="12" md="12" lg="12" xl="12">
                  <label for="waktu_selesai">Selesai</label>
                  <date-picker
                    type="datetime"
                    style="width: 100%"
                    id="waktu_selesai"
                    format="DD-MM-YYYY HH:mm"
                    v-model="dataForm.waktu_selesai"
                    show-hour
                    show-minute
                    default-value
                    :state="checkValidasi('waktu_selesai').s"
                  ></date-picker>
                  <span class="text-danger">{{ checkValidasi("waktu_selesai").m }}</span>
                </b-col>
                <b-col cols="12" md="12" lg="12" xl="12">
                  <label for="ms_status_pulang_id">Status Pulang</label>
                  <Multiselect
                    id="ms_status_pulang_id"
                    v-model="dataForm.ms_status_pulang_id"
                    :options="listStatusPulang"
                    :multiple="false"
                    :searchable="true"
                    :close-on-select="true"
                    :show-labels="false"
                    label="nm_status_pulang"
                    track-by="ms_status_pulang_id"
                    placeholder="-- Pilih Status Pulang --"
                    size="sm"
                    
                  ></Multiselect>
                </b-col>
                <!-- <b-col cols="12">
                  <b-button class="w-100" variant="primary" @click="$bvModal.show('modal-rujuk-internal')">
                    Rujuk Internal
                  </b-button>
                </b-col> -->
              </b-row>
            </b-card>
            <!-- <b-card style="margin-bottom: 15px">
              <b-row>
                <b-col cols="12" md="12" lg="12" xl="12">
                  <h5 class="custom-title-card">Rujukan</h5>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" md="12" lg="12" xl="12">
                  <label for="kd_poli_rujuk_internal">Poliklinik Rujuk Internal</label>
                  <multiselect
                    id="kd_poli_rujuk_internal"
                    v-model="dataForm.kd_poli_rujuk_internal"
                    :options="listPoliklinik"
                    :multiple="false"
                    :searchable="true"
                    :close-on-select="true"
                    :show-labels="false"
                    label="nama_poli"
                    track-by="ms_poli_id"
                    placeholder="-- Pilih Poliklinik Rujuk Internal --"
                    size="sm"
                    :class="{
                      invalid: !checkValidasi('kd_poli_rujuk_internal').b,
                    }"
                  ></multiselect>
                  <span class="text-danger">{{
                    checkValidasi("kd_poli_rujuk_internal").m
                  }}</span>
                </b-col>
                <b-col cols="12" md="12" lg="12" xl="12">
                  <label for="jenis_rujuk_lanjut">Jenis Rujuk Lanjut</label>
                  <multiselect
                    id="jenis_rujuk_lanjut"
                    v-model="dataForm.jenis_rujuk_lanjut"
                    :options="$store.state.data_static.jenis_rujuk_lanjut"
                    :multiple="false"
                    :searchable="true"
                    :close-on-select="true"
                    :show-labels="false"
                    label="text"
                    track-by="value"
                    placeholder="-- Pilih Jenis Rujuk Lanjut --"
                    size="sm"
                    @input="
                      (dataForm.kd_spesialis = null),
                        (dataForm.sub_spesialis = null),
                        (dataForm.kd_khusus = null),
                        v$.$reset()
                    "
                    :class="{ invalid: !checkValidasi('jenis_rujuk_lanjut').b }"
                  ></multiselect>
                  <span class="text-danger">{{
                    checkValidasi("jenis_rujuk_lanjut").m
                  }}</span>
                </b-col>
                <b-col
                  cols="12"
                  md="12"
                  lg="12"
                  xl="12"
                  v-if="
                    dataForm.jenis_rujuk_lanjut &&
                    dataForm.jenis_rujuk_lanjut.value == 'khusus'
                  "
                >
                  <label for="kd_khusus">Khusus</label>
                  <multiselect
                    id="kd_khusus"
                    v-model="dataForm.kd_khusus"
                    :options="listKhusus"
                    :multiple="false"
                    :searchable="true"
                    :close-on-select="true"
                    :show-labels="false"
                    label="nmKhusus"
                    track-by="kdKhusus"
                    placeholder="-- Pilih Khusus --"
                    size="sm"
                    :class="{ invalid: !checkValidasi('kd_khusus').b }"
                  ></multiselect>
                  <span class="text-danger">{{ checkValidasi("kd_khusus").m }}</span>
                </b-col>
                <b-col
                  cols="12"
                  md="12"
                  lg="12"
                  xl="12"
                  v-if="
                    dataForm.jenis_rujuk_lanjut &&
                    dataForm.jenis_rujuk_lanjut.value == 'spesialis'
                  "
                >
                  <label for="kd_spesialis">Spesialis</label>
                  <multiselect
                    id="kd_spesialis"
                    v-model="dataForm.kd_spesialis"
                    :options="listSpesialis"
                    :multiple="false"
                    :searchable="true"
                    :close-on-select="true"
                    :show-labels="false"
                    label="nm_spesialis"
                    track-by="kd_spesialis"
                    placeholder="-- Pilih Spesialis --"
                    size="sm"
                    :class="{ invalid: !checkValidasi('kd_spesialis').b }"
                    @select="getSubSpesialis(), (dataForm.kd_sub_spesialis = null)"
                    @remove="getSubSpesialis(), (dataForm.kd_sub_spesialis = null)"
                  ></multiselect>
                  <span class="text-danger">{{ checkValidasi("kd_spesialis").m }}</span>
                </b-col>
                <b-col
                  cols="12"
                  md="12"
                  lg="12"
                  xl="12"
                  v-if="
                    dataForm.jenis_rujuk_lanjut &&
                    dataForm.jenis_rujuk_lanjut.value == 'spesialis'
                  "
                >
                  <label for="kd_sub_spesialis">Sub Spesialis</label>
                  <multiselect
                    id="kd_sub_spesialis"
                    v-model="dataForm.kd_sub_spesialis"
                    :options="listSubSpesialis"
                    :multiple="false"
                    :searchable="true"
                    :close-on-select="true"
                    :show-labels="false"
                    label="nm_sub_spesialis"
                    track-by="kd_sub_spesialis"
                    placeholder="-- Pilih Sub Spesialis --"
                    size="sm"
                    :class="{ invalid: !checkValidasi('kd_sub_spesialis').b }"
                  ></multiselect>
                  <span class="text-danger">{{ checkValidasi("kd_sub_spesialis").m }}</span>
                </b-col>
              </b-row>
            </b-card> -->
            <b-card style="margin-bottom: 15px">
              <b-row>
                <b-col cols="12" md="12" lg="12" xl="12">
                  <h5 class="custom-title-card">Submit</h5>
                </b-col>
              </b-row>
              <b-row>
                <!-- <b-col cols="12" md="12" lg="12" xl="12" v-if="dataForm.no_rm">
                  <b-form-checkbox
                    id="checkbox-1"
                    v-model="dataForm.satu_sehat"
                    name="checkbox-1"
                    value="1"
                    unchecked-value="0"
                  >
                    <h6>Satu Sehat</h6>
                  </b-form-checkbox>
                </b-col> -->
                <b-col cols="12" md="12" lg="12" xl="12" >
                </b-col>
                <b-col cols="12" md="12" lg="12" xl="12">
                  <b-button :disabled="!dataForm.waktu_selesai" class="w-100 mt-2" variant="success" @click="updateSatuSehat()">
                    Kirim data ke satu sehat
                  </b-button>
                  <b-button class="w-100 mt-2 text-light" variant="warning" @click="pembatalanPulang()" v-if="kunjunganSelesai">
                    Pembatalan Pulang
                  </b-button>
                  <b-button class="w-100 mt-2 text-light" variant="danger" @click="hapusKunjungan()" v-if="!kunjunganSelesai">
                    Hapus Kunjungan
                  </b-button>
                  <b-button class="w-100 mt-2 text-light" variant="warning" @click="onUpdate()" v-if="!kunjunganSelesai">
                    Update Kunjungan
                  </b-button>
                  <b-button class="w-100 mt-2" variant="light" @click="refresh()">
                    Reset
                  </b-button>
                </b-col>
              </b-row>
            </b-card>
            <Alergi :kunjungan="dataForm" @changeVar="changeVar" />
            <UploadFoto v-if="false" :kunjungan="dataForm" />
            <b-card style="margin-bottom: 15px">
              <b-row>
                <b-col cols="12" md="12" lg="12" xl="12">
                  <h5 class="custom-title-card">Data Skrining</h5>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-table
                    :items="listSkrining"
                    :fields="fieldsSkrining"
                    responsive
                    show-empty
                    small
                    bordered
                    striped
                    hover
                    class="table-skrining"
                  >
                    <template #cell(actions)="item">
                      <b-button
                        variant="light"
                        style="padding: 5px !important"
                        v-c-tooltip.hover.click="'Detail'"
                        @click="openTab(item.item), (fetching = !fetching)"
                        ><CIcon name="cil-search" /> {{ item.actions }}</b-button
                      >
                    </template>
                  </b-table>
                </b-col>
              </b-row>
            </b-card>
            <b-card style="margin-bottom: 15px">
              <b-row>
                <b-col cols="12" md="12" lg="12" xl="12">
                  <h5 class="custom-title-card">Cetak Surat</h5>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group label-cols-md="3">
                    <template v-slot:label>
                      Cetak <span class="text-danger">*</span>
                    </template>
                    <b-form-select v-model="cetak_print">
                      <b-form-select-option :value="null">-- Pilih Cetak --</b-form-select-option>
                      <b-form-select-option value="print_label_1">Print Label 1</b-form-select-option>
                      <b-form-select-option value="print_label_2">Print Label 2</b-form-select-option>
                      <b-form-select-option value="pendaftaran">Pendaftaran</b-form-select-option>
                      <b-form-select-option value="surat_rujukan_bpjs">Surat Rujukan BPJS</b-form-select-option>
                      <b-form-select-option value="kunjungan_bpjs">Kunjungan BPJS</b-form-select-option>
                      <b-form-select-option value="pengantar_rujukan">Rujuk Internal</b-form-select-option>
                      <b-form-select-option value="riwayat_pasien">Riwayat Pasien</b-form-select-option>
                    </b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col class="d-flex justify-content-end">
                  <b-button :disabled="!cetak_print" @click="print()" variant="success">
                    Cetak
                  </b-button>
                </b-col>
              </b-row>
            </b-card>

            <RiwayatKunjungan 
              :dataKunjungan="dataForm"
              :dataPasien="dataPasien"
            />
          </b-col>
          <b-col cols="8" sm="12" md="12" lg="8" xl="8">
            <div class="">
              <div class="">
                <!-- <b-tabs content-class="mt-2" pills>
                  <b-tab :hidden="ptm" title="SKRINING PTM" lazy>
                    <PTM 
                      show
                      :kunjungan="dataForm" 
                      @cekPTM="cekPTM()"
                    />
                  </b-tab>
                  <b-tab :hidden="detail_ptm" title="SKRINING PTM" lazy>
                    <DetailPTM 
                      show
                      :kunjungan="dataForm" 
                      :fetching="fetching" 
                    />
                  </b-tab>
                  <b-tab title="ANAMNESA" lazy>
                    <Anamnesa
                      show
                      :kunjungan="dataForm"
                      :dataPendaftaran="dataPendaftaran"
                      :listKesadaran="listKesadaran"
                      :listDokter="listDokter"
                      :listPerawat="listPerawat"
                      @sendAnamnesa="getAnamnesa"
                    />
                  </b-tab>
                </b-tabs> -->
                <!-- <CTabs variant="pills" :fill="true" :activeTab="active_tab" @update:activeTab="jajal"> -->
                  <!-- haveDiagnosa {{haveDiagnosa}} -->
                <CTabs variant="pills" :fill="true" :activeTab="active_tab" @update:activeTab="jajal">
                  <CTab title="PTM">
                    <PTM 
                      :kunjungan="dataForm" 
                      :listDokter="listDokter"
                      :listPerawat="listPerawat"
                      @cekPTM="cekPTM()"
                    />
                  </CTab>
                  <CTab :hidden="detail_ptm" title="DETAIL PTM">
                    <DetailPTM 
                      :kunjungan="dataForm" 
                      :fetching="fetching"
                        />
                  </CTab>
                  <CTab title="ANAMNESA">
                    <Anamnesa
                      :kunjungan="dataForm"
                      :dataPendaftaran="dataPendaftaran"
                      :listKesadaran="listKesadaran"
                      :listDokter="listDokter"
                      :listPerawat="listPerawat"
                      @sendAnamnesa="getAnamnesa"
                      :kunjunganSelesai="kunjunganSelesai"
                    />
                  </CTab>
                  <CTab title="DIAGNOSA" :hidden="!haveAnamnesa">
                    <Diagnosa
                      :kunjungan="dataForm"
                      :dataAnamnesa="dataAnamnesa"
                      :listDokter="listDokter"
                      :listPerawat="listPerawat"
                      :kunjunganSelesai="kunjunganSelesai"
                      @createSyncBpjs="createSyncBpjs"
                    />
                  </CTab>
                  <CTab title="ALKES" :hidden="!haveAnamnesa || !haveDiagnosa">
                    <Alkes 
                      :kunjungan="dataForm" 
                      :dataAnamnesa="dataAnamnesa" 
                      :listDokter="listDokter"
                      :listPerawat="listPerawat"
                      :kunjunganSelesai="kunjunganSelesai"
                    />
                  </CTab>
                  <CTab title="OBAT" :hidden="!haveAnamnesa || !haveDiagnosa">
                    <Obat 
                      :listAlergiPasien="listAlergiPasien" 
                      :kunjungan="dataForm" 
                      :dataAnamnesa="dataAnamnesa" 
                      :listDokter="listDokter"
                      :listPerawat="listPerawat"
                      :kunjunganSelesai="kunjunganSelesai"
                    />
                  </CTab>
                  <CTab title="RESEP" :hidden="!haveAnamnesa || !haveDiagnosa">
                    <Resep
                      :listAlergiPasien="listAlergiPasien" 
                      :kunjungan="dataForm" 
                      :dataAnamnesa="dataAnamnesa" 
                      :listDokter="listDokter"
                      :listPerawat="listPerawat"
                      :kunjunganSelesai="kunjunganSelesai"
                    />
                  </CTab>
                  <CTab title="ODONTOGRAM" :hidden="!haveAnamnesa || !haveDiagnosa">
                    <Odontogram 
                      :kunjungan="dataForm" 
                      :dataAnamnesa="dataAnamnesa" 
                      :kunjunganSelesai="kunjunganSelesai"
                      ref="odonto"
                    /> 
                  </CTab>
                  <CTab title="TINDAKAN" :hidden="!haveAnamnesa || !haveDiagnosa">
                    <Tindakan
                      :data-kunjungan="dataForm"
                      :data-anamnesa="dataAnamnesa"
                      :data-tindakan="tindakan_pasien"
                      :option-tenaga-medis="listTenagaMedis"
                      :listDokter="listDokter"
                      :listPerawat="listPerawat"
                      :option-tindakan="option_tindakan"
                      :kunjunganSelesai="kunjunganSelesai"
                      @getDataTindakan="getTindakanPasien(dataForm.kunjungan_id)"
                    />
                  </CTab>
                  <CTab title="LABORATORIUM" :hidden="!haveAnamnesa || !haveDiagnosa">
                    <Laboratorium 
                      :kunjungan="dataForm" 
                      :dataAnamnesa="dataAnamnesa" 
                      :test="test" ref="laborat" 
                      :listDokter="listDokter"
                      :listPerawat="listPerawat"
                      :kunjunganSelesai="kunjunganSelesai"
                    />
                  </CTab>
                  <CTab title="KIA" :hidden="!haveAnamnesa || !haveDiagnosa || kelamin">
                    <KesehatanIbuAnak 
                      :kunjungan="dataForm" 
                      :dataAnamnesa="dataAnamnesa" 
                      :listDokter="listDokter" 
                      :listPerawat="listPerawat"
                      :kunjunganSelesai="kunjunganSelesai"
                    />
                  </CTab>
                  <!-- <CTab title="PEMERIKSAAN GIZI" :hidden="!haveAnamnesa || !haveDiagnosa">
                    <PeriksaGizi
                      :dataKunjungan="dataForm"
                      :dataAnamnesa="dataAnamnesa"
                      :dataPasien="dataPasien"
                      :listDokter="listDokter"
                      :listPerawat="listPerawat"
                      :kunjunganSelesai="kunjunganSelesai"
                    />
                  </CTab>
                  <CTab title="KARTU BAYI" :hidden="!haveAnamnesa || !haveDiagnosa">
                    <KartuBayi
                      :data-kunjungan="dataForm"
                      :data-anamnesa="dataAnamnesa"
                      :data-kartu-bayi="kartu_bayi"
                      :option-tenaga-medis="listTenagaMedis"
                      :option-golongan-darah="option_golongan_darah"
                      :kunjunganSelesai="kunjunganSelesai"
                      @getDataKartuBayi="getKartuBayi(dataForm.rm_id)"
                    />
                  </CTab>
                  <CTab title="KB" :hidden="!haveAnamnesa || !haveDiagnosa || kelamin">
                    <Kb
                      :kunjungan="dataForm"
                      :dataAnamnesa="dataAnamnesa"
                      :listDokter="listDokter"
                      :listPerawat="listPerawat"
                      :listPendidikan="listPendidikan"
                      :kunjunganSelesai="kunjunganSelesai"
                    />
                  </CTab>
                  <CTab title="IMUNISASI ANAK" :hidden="!haveAnamnesa || !haveDiagnosa">
                    <ImunisasiAnak
                      :dataKunjungan="dataForm"
                      :dataPasien="dataPasien"
                      :dataAnamnesa="dataAnamnesa"
                      :listDokter="listDokter"
                      :listPerawat="listPerawat"
                      :kunjunganSelesai="kunjunganSelesai"
                    />
                  </CTab>
                  <CTab title="MTBS" :hidden="!haveAnamnesa || !haveDiagnosa">
                    <MTBS 
                      :data-kunjungan="dataForm"
                      :data-anamnesa="dataAnamnesa"
                      :data-mtbs="data_mtbs"
                      :option-tenaga-medis="listTenagaMedis"
                      :kunjunganSelesai="kunjunganSelesai"
                      @getMtbs="getMTBS(dataForm.kunjungan_id)"
                    />
                  </CTab>
                  <CTab title="KEUR" :hidden="!haveAnamnesa || !haveDiagnosa">
                    <Keur 
                      :data-sehat="data_sehat"
                      :data-sakit="data_sakit"
                      :data-buta-warna="data_buta_warna"
                      :data-keur="data_keur"
                      :option-tenaga-medis="listTenagaMedis"
                      :option-golongan-darah="option_golongan_darah"
                      :option-kesadaran="listKesadaran"
                      :kunjunganSelesai="kunjunganSelesai"
                      @get-data-keur="getDataKeur()"
                    />
                  </CTab>
                  <CTab title="PENGKAJIAN RISIKO JATUH" :hidden="!haveAnamnesa || !haveDiagnosa">
                    <PengkajianResikoJatuh
                      :data-pengkajian-resiko-jatuh="data_pengkajian_resiko_jatuh"
                      :option-tenaga-medis="listTenagaMedis"
                      :kunjunganSelesai="kunjunganSelesai"
                      @refreshPengkajian="getPengkajianResikoJatuh()"
                    />
                  </CTab>
                  <CTab title="TUMBUH KEMBANG ANAK" :hidden="!haveAnamnesa || !haveDiagnosa">
                    <TumbuhKembangAnak
                      :data-tka="data_tka"
                      :data-pemeriksaan-tka="data_pemeriksaan_tka"
                      :option-tenaga-medis="listTenagaMedis"
                      :kunjunganSelesai="kunjunganSelesai"
                      @refreshTumbuhKembangAnak="getTumbuhKembangAnak"
                      @refreshPemeriksaan="getPemeriksaanTKA"
                    />
                  </CTab>
                  <CTab title="PKPR" :hidden="!haveAnamnesa || !haveDiagnosa">
                    <PKPR
                      :dataKunjungan="dataForm"
                      :dataAnamnesa="dataAnamnesa"
                      :listDokter="listDokter"
                      :listPerawat="listPerawat"
                      :listPendidikan="listPendidikan"
                      :kunjunganSelesai="kunjunganSelesai"
                    />
                  </CTab>
                  <CTab title="MCU" :hidden="!haveAnamnesa || !haveDiagnosa">
                    <MCU
                      :dataKunjungan="dataForm"
                      :dataAnamnesa="dataAnamnesa"
                      :listDokter="listDokter"
                      :listPerawat="listPerawat"
                      :listPendidikan="listPendidikan"
                      :listTenagaMedis="listTenagaMedis"
                      :kunjunganSelesai="kunjunganSelesai"
                    />
                  </CTab> -->
                </CTabs>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
  </b-overlay>

    <modal-kunjungan
      :listPoliklinik="listPoliklinik"
      @pilihKunjungan="pilihKunjungan"
      @openAntrian="openAntrian"
    />
    <modal-antrian :dataAntrian="dataAntrian" />
    <!-- // <modal-search @pilihPasien="pilihPasien" /> -->
    <ModalSync />
    <ModalRujukInternal 
      :dataKunjungan="dataForm"
      :dataPasien="dataPasien"
      :dataAnamnesa="dataAnamnesa"
      :listDokter="listDokter"
      :listPoliklinik="listPoliklinik"
      :listKelas="listKelas"
      @changeVar="changeVar"
    />
    <b-modal
      id="modal-confirm"
      size="sm"
      title="Confirm Register"
      header-bg-variant="success"
      header-text-variant="light"
      okTitle="YES"
      cancelTitle="NO"
      footerClass="p-2"
      :hideHeaderClose="false"
      :centered="true"
    >
      <b-container fluid>
        <div v-if="dataForm.poliklinik">
          Apakah Anda Yakin Register Poliklinik
          {{ dataForm.poliklinik.nama_poliklinik }}
        </div>
        <div v-else>Apakah Anda Yakin Register IGD</div>
      </b-container>
    </b-modal>

    <b-modal
      id="modal-alert"
      size="lg"
      header-bg-variant="danger"
      header-text-variant="light"
      footerClass="p-2"
      hide-header
      :centered="true"
    >
      <b-container fluid>
        <div class="d-flex justify-content-center align-items-center">
          <CIcon style="color: red" size="9xl" name="cil-x-circle" />
        </div>
        <div class="d-flex justify-content-center align-items-center">
          <h4>
            Pasien ini belum melakukan skrining PTM. Silahkan isi form skrining PTM
            terlebih dahulu.
          </h4>
        </div>
      </b-container>
    </b-modal>

    <!-- PRINT -->
    <div style="display: none;" id="print_label_1">
      <CetakLabel1 
        :id_print="'print_label_1'" 
        :dataPasien="dataPasien"
      />
    </div>

    <div style="display: none;" id="print_label_2">
      <CetakLabel2 
        :id_print="'print_label_2'" 
        :dataPasien="dataPasien"
      />
    </div>

    <div style="display: none;" id="pendaftaran">
      <CetakPendaftaran 
        :id_print="'pendaftaran'" 
        :dataPasien="dataPasien"
      />
    </div>

    <div style="display: none;" id="surat_rujukan_bpjs">
      <section class="content-print A4" v-if="dataPrint">
        <div class="sheet">
          <div class="w-100-flex">
            <div style="width: 50%;display: flex;justify-content: flex-start;align-items: center;">
              <div style="width: 80%;height: 10mm;display: flex;justify-content: center;align-items: center;">
                <!-- <h6>IMG BPJS</h6> -->
                <img src="../../../assets/2560px-BPJS_Kesehatan_logo.svg.png" alt="" style="width: 100%;">
              </div>
            </div>
            <div style="width: 50%;">
              <table class="custom-table">
                <tbody>
                  <tr>
                    <td style="width: 40mm;font-weight: bold;">Kedeputian Wilayah</td>
                    <td style="width: 5mm;">:</td>
                    <td>{{ dataPrint.ppk.kc.kdKR.nmKR }}</td>
                  </tr>

                  <tr>
                    <td style="font-weight: bold;">Kantor Cabang</td>
                    <td>:</td>
                    <td>{{ dataPrint.ppk.kc.nmKC }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="w-100" style="margin-top: 5mm;">
            <h6 style="font-size: 14pt;text-align: center;"><strong>Surat Rujukan FKTP</strong></h6>
          </div>

          <div class="custom-border-paper" style="padding:2.5mm;margin-top: 5mm;">
            <div class="w-100-flex custom-border-paper">
              <div style="width: 50%;">
                <table class="custom-table">
                  <tbody>
                    <tr>
                      <td style="width: 35mm;">No. Rujukan</td>
                      <td style="width: 2.5mm;">:</td>
                      <td>{{ dataPrint.noRujukan }}</td>
                    </tr>
  
                    <tr>
                      <td>FKTP</td>
                      <td>:</td>
                      <!-- <td><span>Jepang (0986543)</span></td> -->
                      <td>{{ dataPrint.ppk.nmPPK }} ({{ dataPrint.ppk.kdPPK }})</td>
                    </tr>
  
                    <tr>
                      <td>Kabupaten/Kota</td>
                      <td>:</td>
                      <!-- <td><span>KAB. KUDUS (0987654)</span></td> -->
                      <td>{{ dataPrint.ppk.kc.dati.nmDati }} ({{ dataPrint.ppk.kc.dati.kdDati }})</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div style="width: 50%;display: flex;justify-content: center;align-items: center;">
                <div style="width: 90%;height: 10mm;border:1px solid black;display: flex;justify-content: center;align-items: center;">
                  <!-- <h6>barcode</h6> -->
                  <barcode :value="dataPrint.noRujukan" :height="50" :displayValue="false">
                    **{{ dataPrint.noRujukan }}**
                  </barcode>
                </div>
              </div>
              
            </div>
        
            <div class="w-100" style="padding:2.5mm">
              <table class="custom-table" >
                <tbody>
                  <tr>
                    <td style="width: 50mm;padding-left: 0;">Kepada Yth. TS Dokter</td>
                    <td style="width: 2.5mm;">:</td>
                    <td>{{ dataPrint.poli.nmPoli }}</td>
                  </tr>

                  <tr>
                    <td style="padding-left: 0;">Di</td>
                    <td>:</td>
                    <td>{{ dataPrint.ppkRujuk.nmPPK }}</td>
                  </tr>
                </tbody>
              </table>
              
            </div>

            <div class="w-100" style="padding:2.5mm">
              <h6>Mohon pemeriksaan dan penanganan lebih lanjut pasien :</h6>
            </div>

            <div class="w-100-flex" style="padding:2.5mm">
              <div style="width: 45%;">
                <table class="custom-table" >
                  <tbody>
                    <tr>
                      <td style="width: 32mm;padding-left: 0;">Nama</td>
                      <td style="width: 2.5mm;">:</td>
                      <td>{{ dataPrint.nmPst }}</td>
                    </tr>
    
                    <tr>
                      <td style="padding-left: 0;">No. Kartu BPJS</td>
                      <td>:</td>
                      <td>{{ dataPrint.nokaPst }}</td>
                    </tr>

                    <tr v-if="dataPrint.diag1">
                      <td style="padding-left: 0;">Diagnosa</td>
                      <td>:</td>
                      <td>
                        <span style="color: black">{{ dataPrint.diag1.nmDiag }} ({{ dataPrint.diag1.kdDiag }})</span>
                        <span v-if="dataPrint.diag2" style="color: black">, {{ dataPrint.diag2.nmDiag }} ({{ dataPrint.diag2.kdDiag }})</span>
                        <span v-if="dataPrint.diag3" style="color: black">, {{ dataPrint.diag3.nmDiag }} ({{ dataPrint.diag3.kdDiag }})</span>
                      </td>
                      <!-- <td><span>Non-insulin-dependent diabetes mellitus with unspecified complication (E11.8), Essential (Primary)</span></td> -->
                    </tr>

                    <tr>
                      <td style="padding-left: 0;">Telah diberikan</td>
                      <td>:</td>
                      <td>-</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div style="width: 55%;">
                <table class="custom-table" >
                  <tbody>
                    <tr>
                      <td style="width: 15mm;">Umur</td>
                      <td style="width: 2.5mm;">:</td>
                      <td>{{ getTahun(dataPrint.tglLahir) }}</td>
                      <td style="width: 10mm;">Tahun</td>
                      <td style="width: 5mm;">:</td>
                      <td>{{ dataPrint.tglLahir }}</td>
                    </tr>
                  </tbody>
                </table>

                <table class="custom-table" border="0">
                  <tbody>
                    <tr>
                      <td style="width: 15mm;vertical-align: middle;">Status</td>
                      <td style="width: 2.5mm;vertical-align: middle;">:</td>
                      <td style="width: 10mm;vertical-align: middle;">
                        <div style="width: 10mm;height: 30px;border: 1px solid black;display: flex;justify-content: center;align-items: center;">
                          {{ dataPrint.pisa }}
                        </div>
                      </td>
                      <td style="vertical-align: middle;width: 35mm;">Utama/Tanggungan</td>
                      <td style="width: 10mm;vertical-align: middle;">
                        <div style="width: 10mm;height: 30px;border: 1px solid black;display: flex;justify-content: center;align-items: center;">
                          {{ dataPrint.sex }}
                        </div>
                      </td>
                      <td style="vertical-align: middle;">(L/P)</td>
                    </tr>
                  </tbody>
                </table>

                <table class="custom-table" border="0">
                  <tbody>
                    <tr>
                      <td style="width: 15mm;">Catatan</td>
                      <td style="width: 2.5mm;">:</td>
                      <td>{{ dataPrint.catatan || '-' }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="w-100-flex" style="padding:2.5mm">
              <div style="width: 75%;">
                <h6>Atas bantuannya, diucapkan terima kasih</h6>
                <table class="custom-table" >
                  <tbody>
                    <tr>
                      <td style="width: 50mm;padding-left: 0;">Tgl. Rencana Berkunjung</td>
                      <td style="width: 2.5mm;">:</td>
                      <td>{{ dataPrint.tglEstRujuk }}</td>
                    </tr>
  
                    <tr>
                      <td style="padding-left: 0;">Jadwal Praktek</td>
                      <td>:</td>
                      <!-- <td><span>Senin :08:00 - 11:00, 14:15 - 15:15</span></td> -->
                      <td>{{ dataPrint.jadwal }}</td>
                    </tr>
                  </tbody>
                </table>
                <h6>Surat Rujukan berlaku 1 [satu] kali kunjungan, berlaku sampai dengan : {{ dataPrint.tglAkhirRujuk }}</h6>
              </div>
              <div style="width: 25%;">
                <h6 style="text-align: center;">Salam sejawat,</h6>
                <h6 style="text-align: center;">{{ dataPrint.tglKunjungan }}</h6>
                <br/>
                <br/>
                <br/>
                <h6 style="text-align: center;">{{ dataPrint.dokter.nmDokter }}</h6>
              </div>
            </div>
          </div>
          <div class="custom-border-paper" style="padding:2.5mm">
           

            <div class="w-100" style="padding:2.5mm">
              <h6 style="font-size: 14pt;text-align: center;"><strong><u>SURAT RUJUKAN BALIK</u></strong></h6>
            </div>

            <div class="w-100" style="padding:2.5mm">
              <h6>Teman Sejawat Yth.</h6>
              <h6>Mohon kontrol selanjutnya penderita :</h6>
              <div class="w-100" style="padding-left: 15mm;margin-top: 5mm;margin-bottom: 5mm;">
                <table class="custom-table" style="">
                  <tbody>
                    <tr>
                      <td style="width: 32mm;padding-left: 0;">Nama</td>
                      <td style="width: 2.5mm;">:</td>
                      <td>{{ dataPrint.nmPst }}</td>
                    </tr>

                    <tr>
                      <td style="padding-left: 0;">Diagnosa</td>
                      <td>:</td>
                      <td>......................................................................</td>
                    </tr>

                    <tr>
                      <td style="padding-left: 0;">Terapi</td>
                      <td>:</td>
                      <td>......................................................................</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <h6>Tindak lanjut yang dianjurkan</h6>
            </div>

            <div class="w-100-flex" style="padding:2.5mm">
              <div style="width: 60%;background-color: ;">
                <table class="custom-table" border="0">
                  <tbody>
                    <tr>
                      <td style="vertical-align: middle;width: 15mm;"><div style="width: 15mm;height: 10mm;border:1px solid black"></div></td>
                      <td style="vertical-align: middle;">Pengobatan dengan obat-obatan :</td>
                    </tr>

                    <tr>
                      <td>&nbsp;</td>
                      <td style="vertical-align: middle;">
                        ..................................................................
                      </td>
                    </tr>

                    <tr>
                      <td style="vertical-align: middle;"><div style="width: 15mm;height: 10mm;border:1px solid black"></div></td>
                      <td style="vertical-align: middle;">Kontrol kembali ke RS tanggal : ..............</td>
                    </tr>

                    <tr>
                      <td style="vertical-align: middle;"><div style="width: 15mm;height: 10mm;border:1px solid black"></div></td>
                      <td style="vertical-align: middle;">Lain-lain : ..................................................</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div style="width: 40%;">
                <table class="custom-table" border="0">
                  <tbody>
                    <tr>
                      <td style="vertical-align: middle;width: 15mm;"><div style="width: 15mm;height: 10mm;border:1px solid black"></div></td>
                      <td style="vertical-align: middle;">Perlu Perawatan</td>
                    </tr>
                    <tr>
                      <td style="vertical-align: middle;"><div style="width: 15mm;height: 10mm;border:1px solid black"></div></td>
                      <td style="vertical-align: middle;">Konsultasi selesai</td>
                    </tr>
                  </tbody>
                </table>
                <table class="custom-table" border="0">
                  <tbody>
                    <tr>
                      <td>{{ $store.state.puskesmas.nama_kota }} tgl ..............................</td>
                      <!-- <td>........................ tgl ..............................</td> -->
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="w-100-flex" style="padding:2.5mm;page-break-inside: avoid;">
              <div style="width: 60%;">
                &nbsp;
              </div>
              <div style="width: 40%;">
                <h6 style="text-align: center;">Dokter RS,</h6>
                <br/>
                <br/>
                <br/>
                <h6 style="text-align: center;">( ............................................. )</h6>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

    <div style="display: none" id="kunjungan_bpjs">
      <section class="content-print A4" v-if="dataPrint">
        <div class="sheet">
          <div class="w-100-flex">
            <div style="width: 50%;display: flex;justify-content: flex-start;align-items: center;">
              <div style="width: 80%;height: 10mm;border:1px solid black;display: flex;justify-content: center;align-items: center;">
                <!-- <h6>IMG BPJS</h6>                   -->
                <img src="../../../assets/2560px-BPJS_Kesehatan_logo.svg.png" alt="" style="width: 100%;">
              </div>
            </div>
            <div style="width: 50%;">
              <table class="custom-table">
                <tbody>
                  <tr>
                    <td style="width: 40mm;font-weight: bold;">Kedeputian Wilayah</td>
                    <td style="width: 5mm;">:</td>
                    <td>{{ dataPrint.ppk.kc.kdKR.nmKR }}</td>
                  </tr>

                  <tr>
                    <td style="font-weight: bold;">Kantor Cabang</td>
                    <td>:</td>
                    <td>{{ dataPrint.ppk.kc.nmKC }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="w-100" style="margin-top: 5mm;">
            <h6 style="font-size: 14pt;text-align: center;"><strong>Data Kunjungan Puskesmas / Dokter Keluarga</strong></h6>
          </div>

          <div class="custom-border-paper" style="padding:2.5mm;margin-top: 5mm;">
            <div class="w-100-flex custom-border-paper">
              <div style="width: 50%;">
                <table class="custom-table">
                  <tbody>
                    <tr>
                      <td style="width: 35mm;">No. Rujukan</td>
                      <td style="width: 2.5mm;">:</td>
                      <!-- <td><span>098765432123456 </span></td> -->
                      <td>{{ dataPrint.noRujukan }}</td>
                    </tr>
  
                    <tr>
                      <td>FKTP</td>
                      <td>:</td>
                      <td>{{ dataPrint.ppk.nmPPK }} ({{ dataPrint.ppk.kdPPK }})</td>
                      <!-- <td><span>Jepang (0986543)</span></td> -->
                    </tr>
  
                    <tr>
                      <td>Kabupaten/Kota</td>
                      <td>:</td>
                      <td>{{ dataPrint.ppk.kc.dati.nmDati }} ({{ dataPrint.ppk.kc.dati.kdDati }})</td>
                      <!-- <td><span>KAB. KUDUS (0987654)</span></td> -->
                    </tr>
                  </tbody>
                </table>
              </div>
              <div style="width: 50%;display: flex;justify-content: center;align-items: center;">
                <div style="width: 90%;height: 10mm;border:1px solid black;display: flex;justify-content: center;align-items: center;">
                  <!-- <h6>barcode</h6>                   -->
                  <barcode :value="dataPrint.noRujukan" :height="50" :displayValue="false">
                    **{{ dataPrint.noRujukan }}**
                  </barcode>
                </div>
              </div>
              
            </div>

            <div class="w-100-flex" style="padding:2.5mm">
              <div style="width: 45%;">
                <table class="custom-table" >
                  <tbody>
                    <tr>
                      <td style="width: 32mm;padding-left: 0;">Nama</td>
                      <td style="width: 2.5mm;">:</td>
                      <td>{{ dataPrint.nmPst }}</td>
                      <!-- <td><span>RUKANAH</span></td> -->
                    </tr>
    
                    <tr>
                      <td style="padding-left: 0;">No. Kartu BPJS</td>
                      <td>:</td>
                      <td>{{ dataPrint.nokaPst }}</td>
                      <!-- <td><span>09876543212345</span></td> -->
                    </tr>

                    <tr>
                      <td style="padding-left: 0;">Telah diberikan</td>
                      <td>:</td>
                      <td>-</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div style="width: 55%;">
                <table class="custom-table" >
                  <tbody>
                    <tr>
                      <td style="width: 15mm;">Umur</td>
                      <td style="width: 2.5mm;">:</td>
                      <td>{{ getTahun(dataPrint.tglLahir) }}</td>
                      <!-- <td><span>52</span></td> -->
                      <td style="width: 10mm;">Tahun</td>
                      <td style="width: 5mm;">:</td>
                      <td>{{ dataPrint.tglLahir }}</td>
                      <!-- <td><span>26-Jan-2000</span></td> -->
                    </tr>
                  </tbody>
                </table>

                <table class="custom-table" border="0">
                  <tbody>
                    <tr>
                      <td style="width: 15mm;vertical-align: middle;">Status</td>
                      <td style="width: 2.5mm;vertical-align: middle;">:</td>
                      <td style="width: 10mm;vertical-align: middle;">
                        <div style="width: 10mm;height: 30px;border: 1px solid black;display: flex;justify-content: center;align-items: center;">
                          {{ dataPrint.pisa }}
                        </div>
                      </td>
                      <td style="vertical-align: middle;width: 35mm;">Utama/Tanggunan</td>
                      <td style="width: 10mm;vertical-align: middle;">
                        <div style="width: 10mm;height: 30px;border: 1px solid black;display: flex;justify-content: center;align-items: center;">
                          {{ dataPrint.sex }}
                        </div>
                      </td>
                      <td style="vertical-align: middle;">(L/P)</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="w-100" style="padding:2.5mm">
              <h6>Demikian surat ini dibuat untuk dapat dipergunakan sebagaimana mestinya</h6>
            </div>

            <div class="w-100" style="margin-top: 10mm;">
              <table class="custom-table">
                  <tbody>
                      <tr>
                          <td style="width: 60%;">&nbsp;</td>
                          <td style="width: 40%;">
                              <h6 style="text-align: center;">Salam Sejawat, {{ dataPrint.tglKunjungan }}</h6>
                              <br/>
                              <br/>
                              <br/>
                              <br/>
                              <br/>
                              <br/>
                              <br/>
                              <br/>
                              <h6 style="text-align: center;">( {{ dataPrint.dokter.nmDokter }} )</h6>
                          </td>
                      </tr>
                  </tbody>
              </table>
          </div>
          </div>
        </div>
      </section>
    </div>

    <div style="display: none" id="pengantar_rujukan">
      <CetakPengantarRujukan
      :id_print="'pengantar_rujukan'" 
      :dataPrint="dataPrintRujukInternal"
      />
    </div>

    <div style="display: none" id="riwayat_pasien">
      <CetakRiwayatPasien
      :id_print="'pengantar_rujukan'" 
      :dataPrint="dataPrintRiwayatPasien"
      />
    </div>

    <b-modal
      id="modal-update-kunjungan"
      size="md"
      centered
      title="Perhatian .... !!!"
      header-bg-variant="danger"
      header-text-variant="light"
    >
      <p>{{messageUpdateKunjungan}}</p>

      <template #modal-footer>
        <b-button variant="secondary" @click="$bvModal.hide('modal-update-kunjungan')">
          Tidak
        </b-button>
        <b-button variant="primary" @click="update(), $bvModal.hide('modal-update-kunjungan')" :disabled="busy">
          Ya
        </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import useVuelidate from "@vuelidate/core";

// import ModalSearch from "./modal_pasien.vue";
import ModalKunjungan from "./modal_kunjungan.vue";
import ModalRujukInternal from "./rujuk_internal/modal_rujuk_internal.vue";
import ModalAntrian from "./modal_antrian.vue";
import UploadFoto from "./upload_foto/upload_foto.vue";
import ModalSync from "./sync/modal_sync.vue";
import Alergi from "./alergi/alergi.vue";

import PTM from "./ptm/ptm.vue";
import DetailPTM from "./detail_ptm/ptm.vue";
import Anamnesa from "./anamnesa/form_anamnesa.vue";
import Diagnosa from "./diagnosa/diagnosa.vue";
import Alkes from "./alkes/alkes.vue";
import Obat from "./obat/obat.vue";
// import RekamMedis from './rekam_medis/rekam_medis.vue'
// import ICDX from './icd_x/icd_x.vue'
import Tindakan from "./tindakan/tindakan.vue";
import Laboratorium from "./laboratorium/laboratorium.vue";
import Odontogram from "./odontogram/odontogram.vue";
// import PermintaanLabor from "./permintaan_labor/permintaan_labor.vue";
// import PermintaanObat from "./permintaan_obat/permintaan_obat.vue";
// import KondisiAkhir from "./kondisi_akhir/kondisi_akhir.vue";
import KesehatanIbuAnak from "./kesehatan_ibu_anak/kesehatan_ibu_anak.vue";
import Resep from "./resep/resep.vue";
// import PeriksaGizi from "./periksa_gizi/periksa_gizi.vue";
// import KartuBayi from "./kartu_bayi/kartu_bayi.vue";
// import Kb from "./kb/kb.vue";
// import ImunisasiAnak from "./imunisasi_anak/imunisasi_anak.vue";
// import MTBS from "./MTBS/ManajemenTerpaduBalitaSakit";
// import Keur from "./keur/keur";
// import PengkajianResikoJatuh from "./pengkajian_resiko_jatuh/pengkajian_resiko_jatuh";
// import TumbuhKembangAnak from "./TKA/tumbuh_kembang_anak";
import RiwayatKunjungan from "./riwayat_kunjungan/riwayat_kunjungan";
// import PKPR from "./pkpr/pkpr.vue";
// import MCU from "./mcu/mcu.vue";

import VueBarcode from 'vue-barcode';
// import imgBPJS from '@/assets/2560px-BPJS_Kesehatan_logo.svg.png'

// Cetak
import CetakPengantarRujukan from './cetak/pengantar_rujukan.vue'
import CetakLabel1 from './cetak/label_1.vue'
import CetakLabel2 from './cetak/label_2.vue'
import CetakPendaftaran from './cetak/pendaftaran.vue'
import CetakRiwayatPasien from './cetak/riwayat_pasien.vue'
export default {
  components: {
    ModalKunjungan,
    ModalRujukInternal,
    // ModalSearch,
    ModalAntrian,
    UploadFoto,
    ModalSync,
    Alergi,
    DetailPTM,
    PTM,
    Anamnesa,
    Diagnosa,
    Alkes,
    Obat,
    // RekamMedis,
    // ICDX,
    Odontogram,
    Tindakan,
    Laboratorium,
    // PermintaanLabor,
    // PermintaanObat,
    // KondisiAkhir,
    KesehatanIbuAnak,
    Resep,
    // PeriksaGizi,
    // KartuBayi,
    // Kb,
    // ImunisasiAnak,
    // MTBS,
    // Keur,
    // PengkajianResikoJatuh,
    // TumbuhKembangAnak,
    RiwayatKunjungan,
    // PKPR,
    // MCU,
    'barcode': VueBarcode,

    CetakPengantarRujukan,
    CetakLabel1,
    CetakLabel2,
    CetakPendaftaran,
    CetakRiwayatPasien,
  },
  setup() {
    return { v$: useVuelidate({ $lazy: true, $autoDirty: true }) };
  },
  data() {
    return {
      busy: false,
      messageUpdateKunjungan: '',
      kunjunganSelesai: false,
      active_tab: 2,
      ptm: true,
      detail_ptm: true,
      fetching: false,
      test:false,
      show: false,
      nik: null,
      niknya: null,
      rm_id: null,
      dataPasien: {},
      dataPendaftaran: {},
      dataAnamnesa: {},
      cetak_print: null,
      dataForm: {
        anamnesa_id: null,
        kunjungan_id: null,
        kd_spesialis: null,

        no_kunjungan: null,
        no_kartu: null,
        kd_poli_rujuk_internal: null,
        jenis_rujuk_lanjut: null,
        tgl_est_rujuk: null,
        kdppk: null,
        sub_spesialis: null,
        kd_khusus: null,
        kd_sub_spesialis: null,
        catatan_kunjungan: null,
        kd_tacc: null,
        alasan_tacc: null,
        no_surat_meninggal: null,
        waktu_mulai: null,
        waktu_selesai: null,
        pendaftaran_id: null,
        ms_poli_id: null,
        ms_status_pulang_id: null,
        is_anamnesa: false,
        is_diagnosa: false,
      },
      kelamin: null,
      rows: 100,
      currentPage: 1,
      perPage: 2,
      //list dropdown
      fieldsSkrining: [
        {
          key: "no",
          label: "No",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "tipe",
          label: "Skrining",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "tgl",
          label: "Tanggal",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "actions",
          label: "Aksi",
          sortable: true,
          sortDirection: "desc",
          class: "text-center",
        },
      ],
      listStatusPulang: [],
      listPoliRujukInternal: [],
      listKhusus: [],
      listSpesialis: [],
      listSubSpesialis: [],
      listTacc: [],
      listPPK: [],
      listProviderPeserta: [],
      listJenisLayanan: [],
      listAsuransi: [],
      listPoliklinik: [],
      listLayanan: [],
      listTypeNoAsuransi: [],
      listSkrining: [],
      listKesadaran: [],
      listTenagaMedis: [],
      listDokter: [],
      listPerawat: [],
      listPendidikan: [],
      listKelas: [],
      dataAntrian: null,
      dataSep: {},

      option_golongan_darah: [],
      listAlergiPasien: [],
      resep_pasien: {},
      kartu_bayi: {},
      data_mtbs: {},
      data_keur: {},
      data_pengkajian_resiko_jatuh: {},
      data_tka: {},
      data_pemeriksaan_tka: {},

      option_tindakan: [],
      tindakan_pasien: [],

      data_sehat: {},
      data_sakit: {},
      data_buta_warna: {},

      dataPrint: null,
      dataPrintRujukInternal: [] || null,
      dataPrintRiwayatPasien: [] || null,
      is_kunjungan_ready: false,
      variants: ['danger', 'warning', 'success', 'info']
    };
  },
  computed: {
    tanggal_sesi(){
      return this.$store.state.tanggal_sesi
    },
    formString() {
      return JSON.stringify(this.data, null, 4);
    },
    isValid() {
      return !this.v$.dataForm.$invalid;
    },
    isDirty() {
      return this.v$.dataForm.$anyDirty;
    },
    isAsuransiBPJS() {
      return this.dataForm.asuransi && this.dataForm.asuransi.value === true;
      // return this.dataForm.asuransi && this.dataForm.asuransi.tipe_asuransi == 2
    },
    isAsuransiUmum() {
      return this.dataForm.asuransi && this.dataForm.asuransi.value === false;
      // return this.dataForm.asuransi && this.dataForm.asuransi.tipe_asuransi == 1
    },
    haveAnamnesa() {
      return this.dataForm.anamnesa_id ? true : false;
    },
    haveDiagnosa() {
      return this.dataForm.is_diagnosa;
    },
  },
  validations: {
    dataForm: {
      no_kunjungan: {},
      no_kartu: {},
      kd_poli_rujuk_internal: {},
      jenis_rujuk_lanjut: {},
      tgl_est_rujuk: {},
      kdppk: {},
      sub_spesialis: {},
      kd_khusus: {},
      kd_spesialis: {},
      kd_sub_spesialis: {},
      catatan_kunjungan: {},
      kd_tacc: {},
      alasan_tacc: {},
      no_surat_meninggal: {},
      waktu_mulai: {  },
      waktu_selesai: {},
      pendaftaran_id: { required },
      ms_poli_id: { required },
      ms_status_pulang_id: {},
    },
  },
  watch: {
    tanggal_sesi(newVal, oldVal){
      if(newVal != oldVal){
        // this.dataForm.tanggal_daftar = new Date(this.tanggal_sesi)
      }
    },
    dataPasien: {
      handler(data) {
        localStorage.setItem("dataPasienRawatJalan", JSON.stringify(data));
      },
      deep: true,
    },
    dataForm: {
      handler(data) {
        const vm = this
        // console.log("ubah dataform", data);
        localStorage.setItem(
          "rawatJalan",
          JSON.stringify({
            ...data,
            status_no_antrian: null,
            status_no_asuransi: null,
          })
        );
        if(data.waktu_mulai && data.waktu_selesai && vm.$moment(data.waktu_mulai) > vm.$moment(data.waktu_selesai)){
          vm.dataForm.waktu_selesai = new Date(data.waktu_mulai) 
        }
        vm.getPTM();
        if (data.ms_status_pulang_id) {
          if (data.ms_status_pulang_id.kd_status_pulang == '1') {
            vm.cekStatusPasienMeninggal(data.rm_id)
          }
        }
      },
      deep: true,
    },
  },
  async activated() {
    const vm = this;
    vm.$bvModal.show("modal-kunjungan");
    await vm.getData();
    vm.v$.$reset();
  },
  methods: {
    async print(){
      const options_58 = {
        name: '_blank',
        specs: [
          'fullscreen=yes',
          'titlebar=yes',
          'scrollbars=yes'
        ],
        styles: [
          '/style-paper-58mm.css',
        ],
        timeout: 1000, // default timeout before the print window appears
        autoClose: true, // if false, the window will not close after printing
        windowTitle: window.document.title, // override the window title
      }
      if (this.cetak_print == 'surat_rujukan_bpjs') {
        await this.getDataRujukanBPJS()
      } else if (this.cetak_print == 'kunjungan_bpjs') {
        await this.getDataKunjunganBPJS()
      } else if (this.cetak_print == 'pengantar_rujukan') {
        await this.getPengantarRujukInternal()
      } else {
        await this.$htmlToPaper(this.cetak_print, options_58);
      }
    },
    jajal(){
      this.$refs.laborat.getData()
      this.$refs.odonto.getData()
    },
    getAnamnesa(x) {
      // memasukkan anamnesa ke kunjungan dari tab anamnesa
      const vm = this;
      vm.dataForm.anamnesa_id = x.anamnesa_id;
      vm.dataAnamnesa = x;
    },
    createSyncBpjs(x){
      const vm = this;
      // sync bpjs dari tab diagnosa
      vm.dataForm.is_diagnosa = x
    },
    openTab(x) {
      let vm = this;
      if (x.tipe == "PTM") {
        vm.active_tab = 1;
        vm.detail_ptm = false;
      }
    },
    async getPendaftaran(kunjungan_id) {
      const vm = this;
      try {
        if (kunjungan_id) {
          const pendaftaran = await vm.$axios.post("/pendaftaran/list", {
            kunjungan_id,
          });
          if (pendaftaran.data.status == 200 && pendaftaran.data.data.length) {
            vm.dataPendaftaran = pendaftaran.data.data[0];
          } else {
            vm.pendaftaran == {};
          }
        } else {
          vm.pendaftaran == {};
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getData() {
      const vm = this;
      //jenis layanan
      vm.dataForm.jenis_layanan = vm.$store.state.data_static.tkp[0];
      //dokter
      let listTenagaMedis = await vm.$axios.post('/tenaga_medis/list', {non_tenaga_medis: 2})
      vm.listTenagaMedis = listTenagaMedis.data.status == 200 ? listTenagaMedis.data.data.map((x) => ({
        ...x,
        value: x.tenaga_medis_id,
        text: x.nama_tenaga_medis,
      })) : [];
      // antisipasi jika list dokter dan perawat jika berbeda dengan tenaga medis
      vm.listDokter = vm.listTenagaMedis
      vm.listPerawat = vm.listTenagaMedis
      //asuransi
      let listAsuransi = await vm.$axios.post("/ms_asuransi/list");
      vm.listAsuransi = listAsuransi.data.status == 200 ? listAsuransi.data.data.map((x) => {
        return {
          ...x,
          text: x.nama_asuransi + " - " + vm.$findKey( vm.$store.state.data_static.tipe_asuransi, x.tipe_asuransi, false, "value", "text" ),
        }
      }) : [];
      //Poliklinik
      let listPoliklinik = await vm.$axios.post("/ms_poli/list");
      // let listPoliklinik = await vm.$axios.post("/ms_poli/list", {is_lab: 'false'});
      vm.listPoliklinik = listPoliklinik.data.status == 200 ? listPoliklinik.data.data : [];
      // kelas
      let listKelas = await vm.$axios.post("/ms_kelas/list");
      vm.listKelas = listKelas.data.status == 200 ? listKelas.data.data : [];
      // spesial
      let listSpesialis = await vm.$axios.post("/spesialis/list");
      vm.listSpesialis = listSpesialis.data.status == 200 ? listSpesialis.data.data : [];
      // khusus
      let listKhusus = await vm.$axios.post("/khusus/list");
      vm.listKhusus = listKhusus.data.status == 200 ? listKhusus.data.data : [];
      // provider
      let listProviderPeserta = await vm.$axios.post("/provider/list");
      vm.listProviderPeserta = listProviderPeserta.data.status == 200 ? listProviderPeserta.data.data : [];
      // Kesadaran
      let listKesadaran = await vm.$axios.post('/ms_kesadaran/list')
      vm.listKesadaran = listKesadaran.data.status == 200 ? listKesadaran.data.data : []
      vm.listKesadaran.forEach(el => { el.text = el.nama_sadar; el.value = el.ms_kesadaran_id })
      // Pendidikan
      let listPendidikan = await vm.$axios.post('/ms_pendidikan/list')
      vm.listPendidikan = listPendidikan.data.status == 200 ? listPendidikan.data.data : []
      // Tindakan
      vm.option_tindakan.splice(0);
      let option_tindakan = await vm.$axios.post("/ms_tindakan/list");
      vm.option_tindakan = option_tindakan.data.status == 200 ? option_tindakan.data.data.map((o) => ({
        ...o,
        value: o.ms_tindakan_id,
        text: o.nama_tindakan,
      })) : [];
      // golongan darah
      vm.option_golongan_darah.splice(0);
      let option_golongan_darah = await vm.$axios.post("/ms_golongan_darah/list");
      vm.option_golongan_darah = option_golongan_darah.data.status == 200 ? option_golongan_darah.data.data.map((o) => ({
        ...o,
        value: o.ms_golongan_darah_id,
        text: o.nama_golongan_darah,
      })) : [];
      vm.v$.$reset();
    },
    async getStatusPulang(){
      const vm = this
      vm.busy = false
      try {
        //status pulang
        let listStatusPulang = await vm.$axios.post("/ms_status_pulang/list", {
          is_rajal: vm.dataForm.kd_tkp && vm.dataForm.kd_tkp.tipe_rawat == 'RJ' ? '1' : undefined,
          is_ranap: vm.dataForm.kd_tkp && vm.dataForm.kd_tkp.tipe_rawat == 'RI' ? '1' : undefined,
        });
        vm.listStatusPulang = listStatusPulang.data.status == 200 ? listStatusPulang.data.data : [];
      } catch (error) {
        vm.triggerAlert({ variant: "danger", msg: 'Terjadi kesalahan system', showing: true });
      } finally {
        vm.busy = false
      }
    },
    async getAlergiPasien(rm_id) {
      const vm = this
      // Alergi
      let x = { rm_id: rm_id };
      vm.listAlergiPasien.splice(0);
      let listAlergiPasien = await vm.$axios.post("/pool_alergi/list", x);
      vm.listAlergiPasien = listAlergiPasien.data.status == 200 ? listAlergiPasien.data.data : [];
      // console.log("listAlergiPasien", vm.listAlergiPasien);
    },
    async getResepPasien(kunjungan_id) {
      const vm = this
      // let x = { kunjungan_id }
      vm.resep_pasien = {}
      vm.resep_pasien.dokter_id = vm.dataAnamnesa.ms_dokter_id
      vm.resep_pasien.nama_dokter = vm.dataAnamnesa.nama_dokter
      vm.resep_pasien.perawat_id = vm.dataAnamnesa.ms_perawat_id
      vm.resep_pasien.nama_perawat = vm.dataAnamnesa.nama_perawat
      vm.resep_pasien.rm_id = vm.dataForm.rm_id
      vm.resep_pasien.no_rm = vm.dataForm.no_rm
      vm.resep_pasien.tanggal_kunjungan = vm.dataForm.tanggal_daftar
      vm.resep_pasien.kunjungan_id = kunjungan_id
      vm.resep_pasien.anamnesa_id = vm.dataForm.anamnesa_id
      vm.resep_pasien.kunjungan_id = kunjungan_id
      try {
        let res = await vm.$axios("/resep/details_by_kunjungan/" + kunjungan_id);
        // console.log("res >>>>>>>>>>>>>>.", res.data.data);
        if (res.data.status == 200 && res.data.message == 'sukses') {
          if (res.data.data.length) {
            vm.resep_pasien = res.data.data[0]
            vm.resep_pasien.anamnesa_id = vm.dataForm.anamnesa_id
          } else {
            vm.resep_pasien.dokter_id = vm.dataAnamnesa.ms_dokter_id
            vm.resep_pasien.nama_dokter = vm.dataAnamnesa.nama_dokter
            vm.resep_pasien.perawat_id = vm.dataAnamnesa.ms_perawat_id
            vm.resep_pasien.nama_perawat = vm.dataAnamnesa.nama_perawat
            vm.resep_pasien.rm_id = vm.dataForm.rm_id
            vm.resep_pasien.no_rm = vm.dataForm.no_rm
            vm.resep_pasien.tanggal_kunjungan = vm.dataForm.tanggal_daftar
            vm.resep_pasien.kunjungan_id = kunjungan_id
            vm.resep_pasien.anamnesa_id = vm.dataForm.anamnesa_id
            vm.resep_pasien.kunjungan_id = kunjungan_id
          }
        }
        // console.log("resep_pasien >>>>>>>>>>>>>>.", vm.resep_pasien);
      } catch (error) {
        console.log(error);
      }
    },
    async getKartuBayi(rm_id) {
      const vm = this
      // Kartu Bayi
      let x = { rm_id: rm_id };
      vm.kartu_bayi = {}
      let kartu_bayi = await vm.$axios.post("/kartu_bayi/list", x);
      if (kartu_bayi.data.status == 200 && kartu_bayi.data.message == 'sukses') {
        // console.log("dataAnamnesa >>>>>>>>>>", vm.dataAnamnesa);
        if (kartu_bayi.data.data.length) {
          vm.kartu_bayi = kartu_bayi.data.data[0]
          vm.kartu_bayi.umur = vm.dataForm.umur
          vm.kartu_bayi.dokter_id = vm.dataAnamnesa.ms_dokter_id
        } else {
          vm.kartu_bayi = {
            nomor_bayi: "",
            nama_lengkap: vm.dataForm.nama_lengkap,
            alamat_sekarang: vm.dataForm.alamat_sekarang,
            tanggal_lahir: vm.dataForm.tanggal_lahir,
            ms_golongan_darah_id: vm.dataForm.ms_golongan_darah_id,
            umur: vm.dataForm.umur,
            nama_ayah: vm.dataForm.nama_ayah,
            nama_ibu: vm.dataForm.nama_ibu,
            ms_dokter_id: vm.dataAnamnesa.ms_dokter_id,
            berat_badan: vm.dataAnamnesa.berat_badan,
            panjang_badan: vm.dataAnamnesa.tinggi_badan
          }
        }
      } 
    },
    async getTindakanPasien(kunjungan_id) {
      const vm = this
      let x = { kunjungan_id }
      let tindakan_pasien = await vm.$axios.post("/tindakan/list", x);
      vm.tindakan_pasien = tindakan_pasien.data.status == 200 ? tindakan_pasien.data.data : [];
      // console.log("tindakan_pasien", vm.tindakan_pasien);
    },
    async getSubSpesialis() {
      const vm = this;
      const x = vm.dataForm.kd_spesialis;
      try {
        if (x && x.spesialis_id) {
          let listSubSpesialis = await vm.$axios.post("/sub_spesialis/list", {
            spesialis_id: x.spesialis_id,
          });
          vm.listSubSpesialis = listSubSpesialis.data.status == 200 ? listSubSpesialis.data.data : [];
          // console.log('listSubSpesialis', listSubSpesialis)
        } else {
          vm.listSubSpesialis = [];
        }
      } catch (error) {
        console.log("error", error);
      }
    },
    openAntrian(data) {
      const vm = this;
      vm.dataAntrian = data;
      setTimeout(() => {
        this.$bvModal.show("modal-antrian");
      }, 100);
    },
    pilihPasien(data) {
      const vm = this;
      // console.log("data", data);
      data.nama_jenis_kelamin = vm.$findKey(
        vm.$store.state.data_static.jenis_kelamin,
        data.jenis_kelamin,
        false
      );
      let expired = data.tanggal_lahir ? vm.$moment(data.tanggal_lahir) : vm.$moment();
      let now = vm.$moment();
      let hasil = vm.$moment.duration(now.diff(expired));
      data.umur = `${hasil.years()} tahun ${hasil.months()} bulan ${hasil.days()} hari`;
      vm.dataPasien = data;
      // console.log(vm.dataPasien, 'ini data pasien');
      vm.v$.$reset();
    },
    async pilihKunjungan(x) {
      const vm = this;
      vm.is_kunjungan_ready = true
      setTimeout(() => {
        vm.is_kunjungan_ready = false
      }, 5000);

      // console.log("pilih kunjungan ", x);
      if(x.jenis_kelamin == "L"){
        vm.kelamin = true
      }else {
        vm.kelamin = false
      }
      vm.$store.commit("set_kia", null)
      vm.$store.commit("set_usia_kehamilan", null)
      vm.$store.commit("set_antenatal", null)
      vm.$store.commit("set_partograf", null)
      vm.$store.commit("set_reset", false)
      await vm.refresh();
      await vm.pilihPasien(x);
      await vm.cekPTM();
      await vm.getAlergiPasien(x.rm_id);
      await vm.getTindakanPasien(x.kunjungan_id);
      await vm.getPendaftaran(x.kunjungan_id);
      vm.kunjunganSelesai = x.ms_status_pulang_id ? true : false
      vm.dataForm = {
        ...vm.dataForm,
        ...x,
        tanggal_daftar: new Date(x.tanggal_daftar),
        // kunjungan_id: null,
        kd_tkp: vm.$findKey(vm.$store.state.data_static.tkp, x.kd_tkp, true, "kdTkp"),
        no_kunjungan: x.no_kunjungan,
        no_kartu: x.no_kartu,
        kd_poli_rujuk_internal: vm.$findKey(vm.listPoliklinik, x.kd_poli_rujuk_internal, true, "kode_poli_bpjs"),
        jenis_rujuk_lanjut: vm.$findKey(vm.$store.state.data_static.jenis_rujuk_lanjut, x.jenis_rujuk_lanjut, true, "value"),
        tgl_est_rujuk: x.tgl_est_rujuk,
        kdppk: vm.$findKey(vm.listProviderPeserta, x.kdppk || x.kd_provider_peserta, true, "kdProvider"),
        kd_khusus: vm.$findKey(vm.listKhusus, x.kd_khusus, true, "kdKhusus"),
        kd_spesialis: vm.$findKey(vm.listSpesialis, x.kd_spesialis, true, "kd_spesialis"),
        kd_sub_spesialis: x.kd_sub_spesialis, //belum
        sub_spesialis: x.sub_spesialis, //belum
        catatan_kunjungan: x.catatan_kunjungan,
        kd_tacc: vm.$findKey(vm.$store.state.data_static.TACC, x.kd_tacc, true, "kdTacc"),
        alasan_tacc: x.alasan_tacc,
        no_surat_meninggal: x.no_surat_meninggal,
        waktu_mulai: x.waktu_mulai ? new Date(x.waktu_mulai) : null,
        waktu_selesai: x.waktu_selesai ? new Date(x.waktu_selesai) : null,
        pendaftaran_id: x.pendaftaran_id,
        ms_poli_id: vm.$findKey(vm.listPoliklinik, x.ms_poli_id, true, "ms_poli_id"),
        ms_status_pulang_id: vm.$findKey(vm.listStatusPulang, x.ms_status_pulang_id, true, "ms_status_pulang_id"),
      };
      await vm.cekPTM();
      await vm.getSubSpesialis();
      await vm.getPTM();
      vm.dataForm.kd_sub_spesialis = vm.$findKey(vm.listSubSpesialis, x.kd_sub_spesialis, true, "kd_sub_spesialis");
      vm.dataForm.sub_spesialis = vm.$findKey(vm.listSubSpesialis, x.kd_sub_spesialis, true, "kd_sub_spesialis", "nm_sub_spesialis");
      // console.log('hasil dataForm ', vm.dataForm)
      // console.log('hasil listStatusPulang ', vm.listStatusPulang)
      this.v$.$reset();
      vm.$store.commit("set_rm_id", vm.dataForm.rm_id);
      vm.$store.commit("set_ptm_id", vm.dataForm.ptm_id);
      await vm.getKartuBayi(x.rm_id)
      await vm.getMTBS(x.kunjungan_id)
      await vm.cekStatusPasienMeninggal(x.rm_id)
      await vm.getDataKeur()
      await vm.getPengkajianResikoJatuh()
      await vm.getTumbuhKembangAnak()
      await vm.getPemeriksaanTKA()
      await vm.getResepPasien(x.kunjungan_id);
      await vm.getStatusPulang()
    },
    cekPTM() {
      let vm = this;
      // console.log('cekPTM')
      if(vm.dataForm && vm.dataForm.rm_id){
        vm.$axios
          .post("ptm/cek_ptm", {
            rm_id: vm.dataForm.rm_id,
          })
          .then((res) => {
            // console.log(res, "ini ressnya");
            if (res.data.data[0].sudah_ptm == true) {
              vm.ptm = true;
            } else {
              vm.ptm = false;
              vm.$bvModal.show("modal-alert");
              vm.active_tab = 0;
            }
            vm.getPTM()
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    getPTM() {
      let vm = this;
      if(vm.dataForm && vm.dataForm.rm_id){
        vm.$axios
          .post("rm/details_screening_by_rm_id", {
            rm_id: vm.dataForm.rm_id,
          })
          .then((res) => {
            // console.log(res, "ini list skrining");   
            if (res.data.status == 200 && res.data.message == "sukses") {
              vm.listSkrining = res.data.data;
              vm.test = !vm.test
              for (let i = 0; i < vm.listSkrining.length; i++) {
                let x = vm.listSkrining[i];
                x.no = i + 1;
                x.tgl = vm.$moment(x.tanggal).format("YYYY-MM-DD");
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    async onUpdate(){
      const vm = this
      if(vm.dataForm.ms_status_pulang_id){
        // vm.messageUpdateKunjungan = 'Apakah Anda Yakin Akan Menyelesaikan Kunjungan'
        vm.messageUpdateKunjungan = 'Apakah anda yakin akan menyelesaikan kunjungan ?'
      }else{
        // vm.messageUpdateKunjungan = 'Apakah Anda Yakin Ingin Mengupdate Kunjungan Ini ?'
        vm.messageUpdateKunjungan = 'Apakah anda yakin ingin mengupdate kunjungan ini ?'
      }
      vm.$bvModal.show("modal-update-kunjungan");
    },
    async update() {
      const vm = this;
      this.v$.dataForm.$touch();
      if (vm.isValid && vm.isDirty) {
        const x = vm.dataForm;
        // console.log('x dataForm ', x)
        
        let data = {
          ...x,
          id: x.kunjungan_id,
          kdppk: x.kdppk ? x.kdppk.kdProvider : null,
          jenis_rujuk_lanjut: x.jenis_rujuk_lanjut ? x.jenis_rujuk_lanjut.value : null,
          kd_khusus: x.kd_khusus ? x.kd_khusus.kdKhusus : null,
          sub_spesialis: x.kd_sub_spesialis ? x.kd_sub_spesialis.nm_sub_spesialis : null,
          kd_sub_spesialis: x.kd_sub_spesialis ? x.kd_sub_spesialis.kd_sub_spesialis : null,
          kd_tacc: x.kd_tacc ? x.kd_tacc.kdTacc : null,
          kd_poli_rujuk_internal: x.kd_poli_rujuk_internal ? x.kd_poli_rujuk_internal.kode_poli_bpjs : null,
          ms_poli_id: x.ms_poli_id ? x.ms_poli_id.ms_poli_id : null,
          waktu_selesai: x.waktu_selesai ? x.waktu_selesai : this.$moment(new Date),
          ms_status_pulang_id: x.ms_status_pulang_id ? x.ms_status_pulang_id.ms_status_pulang_id : null,
          kd_status_pulang: x.ms_status_pulang_id ? x.ms_status_pulang_id.kd_status_pulang : null,
          asuransi: x.no_kartu ? "BPJS" : "UMUM"
        };
        // console.log('data', data)
        if(x.ms_status_pulang_id && x.ms_status_pulang_id.kd_status_pulang == '5'){
          data.ms_status_pulang_id = undefined
          vm.$bvModal.show("modal-rujuk-internal");
        }
        let res = await vm.$axios.post("/kunjungan/selesai_kunjungan", data);
        // console.log('ini res ', res)
        if (res.data.status == 200) {
          vm.$store.commit("set_alert", {
            variant: "success",
            msg: "Berhasil Update Kunjungan",
            showing: true,
          });
          // status_pulang == meninggal
          this.$socket.emit("selesai_kunjungan", {
            room_id: `${localStorage.getItem('idp')}${x.ms_poli_id.ms_poli_id}`,
          });
          if (x.ms_status_pulang_id && x.ms_status_pulang_id.kd_status_pulang == '1') {
            // console.log("status pulang", x.ms_status_pulang_id);
            this.$router.push({ path: '/pasien/input_penyebab_kematian/' + x.kunjungan_id })
          }else if (x.ms_status_pulang_id && x.ms_status_pulang_id.kd_status_pulang == '4') {
            // console.log("status pulang", x.ms_status_pulang_id);
            this.$router.push({ path: '/registrasi/rujuk_eksternal/' + x.kunjungan_id })
          }
          if(data.ms_status_pulang_id) {
            vm.kunjunganSelesai = true
          }
          
          // vm.refresh()
        } else {
          vm.$store.commit("set_alert", {
            variant: "danger",
            msg: res.data.message,
            showing: true,
          });
          await new Promise(resolve => setTimeout(resolve, 500));
          vm.$bvModal.hide("modal-rujuk-internal");
        }
      } else {
        vm.$store.commit("set_alert", {
          variant: "warning",
          msg: "Data Tidak Valid",
          showing: true,
        });
      }
    },
    async pembatalanPulang() {
      const vm = this;
      try {
        // let res = await vm.$axios.post("/kunjungan/selesai_kunjungan", {
        let res = await vm.$axios.post("/kunjungan/ubah_status_pulang", {
          ms_status_pulang_id: null,
          pendaftaran_id: vm.dataForm.pendaftaran_id,
          kunjungan_id: vm.dataForm.kunjungan_id,
          asuransi: vm.dataForm.no_kartu ? "BPJS" : "UMUM",
        });
        // console.log('ini res ', res)
        if (res.data.status == 200) {
          vm.$store.commit("set_alert", {
            variant: "success",
            msg: "Berhasil Mereset Status Pulang",
            showing: true,
          });
          vm.dataForm.ms_status_pulang_id = undefined
          vm.kunjunganSelesai = false
        } else {
          vm.$store.commit("set_alert", {
            variant: "danger",
            msg: res.data.message,
            showing: true,
          });
        }
      } catch (error) {
        vm.$store.commit("set_alert", {
          variant: "danger",
          msg: 'Terjadi Kesalahan System',
          showing: true,
        });
      }
    },
    async hapusKunjungan() {
      const vm = this;
      try {
        let res = await vm.$axios.post("/pendaftaran/delete_pendaftaran_bpjs", {
          pendaftaran_id: vm.dataForm.pendaftaran_id,
        });
        // console.log('ini res ', res)
        if (res.data.status == 200) {
          vm.$store.commit("set_alert", {
            variant: "success",
            msg: "Berhasil Menghapus Kunjungan",
            showing: true,
          });
          vm.refresh()
        } else {
          // console.log('123123', res.data.data)
          // console.log(res.data.data == true, res.data.data.length > 0 == true, res.data.data[0].response == true)
          // if(res.data.data){
          if(res.data.data && res.data.data.length > 0 && res.data.data[0].response){
            vm.$store.commit("set_alert", {
              variant: "danger",
              msg: res.data.data[0].response.message,
              showing: true,
            });
          }else{
            vm.$store.commit("set_alert", {
              variant: "danger",
              msg: res.data.message,
              showing: true,
            });
          }
        }
      } catch (error) {
        vm.$store.commit("set_alert", {
          variant: "danger",
          msg: 'Terjadi Kesalahan System',
          showing: true,
        });
      }
    },
    async refresh() {
      const vm = this;
      // console.log('vm.$store.state.data_static.tkp[0]', vm.$store.state.data_static.tkp[0])
      vm.dataPasien = {};
      vm.dataForm = {
        anamnesa_id: null,
        kunjungan_id: null,
        kd_spesialis: null,

        no_kunjungan: null,
        no_kartu: null,
        kd_poli_rujuk_internal: null,
        jenis_rujuk_lanjut: null,
        tgl_est_rujuk: null,
        kdppk: null,
        sub_spesialis: null,
        kd_khusus: null,
        kd_sub_spesialis: null,
        catatan_kunjungan: null,
        kd_tacc: null,
        alasan_tacc: null,
        no_surat_meninggal: null,
        waktu_mulai: null,
        waktu_selesai: null,
        pendaftaran_id: null,
        ms_poli_id: null,
        ms_status_pulang_id: null,
        is_anamnesa: false,
        is_diagnosa: false,
      };
      vm.v$.$reset();
    },
    checkValidasi(fieldName) {
      // console.log(fieldName)
      const field = this.v$.dataForm[fieldName];
      const x =
        field.$error === true && field.$errors.length ? field.$errors[0].$validator : "";
      if (x == "required") {
        return { b: false, s: false, m: "* Data wajib diisi" };
      } else if (x == "email") {
        return { b: false, s: false, m: "* Data harus berbentuk email" };
      } else if (x == "numeric") {
        return { b: false, s: false, m: "* Data harus terdiri hanya dari angka" };
      } else if (x == "minLength") {
        return { b: false, s: false, m: `* Data belum mencapai minimal digits` };
      } else if (x == "maxLength") {
        return { b: false, s: false, m: `* Data melebihi maksimal digits` };
      } else if (x == "maxLengthValue") {
        return { b: false, s: false, m: `* Data melebihi maksimal` };
      } else if (x == "alphaNum") {
        return { b: false, s: false, m: `* Data tidak boleh mengandung spasi atau simbol` };
      } else if (x == "official") {
        return { b: false, s: false, m: `* Data tidak boleh mengandung spasi atau simbol` };
      } else if (x == "validasiNoKontrolRujukan") {
        return { b: false, s: false, m: "* Isi no rujukan / no kontrol" };
      } else if (x == "validasiPoliLayanan") {
        return { b: false, s: false, m: "* Isi Layanan / Poliklinik" };
      } else if (x == "validasiNoAsuransi") {
        return { b: false, s: false, m: "* No Asuransi Tidak Valid" };
      } else if (x == "validasiNoAntrian") {
        return { b: false, s: false, m: "* No Antrian Tidak Valid" };
      } else if (x == "validasiNoRujukan") {
        return { b: false, s: false, m: "* No Rujukan Tidak Valid" };
      } else if (x == "validasiNoKontrol") {
        return { b: false, s: false, m: "* No Kontrol Tidak Valid" };
      } else if (x == "kuotaDokter") {
        return { b: false, s: false, m: "* Kuota Habis" };
      } else {
        return { b: true, s: null, m: null };
      }
    },
    changeVar({name, value}){
      this[name] = value
    },
    async getMTBS(kunjungan_id) {
      const vm = this
      // MTBS
      let x = { kunjungan_id };
      let res = await vm.$axios.post("/mtbs/list", x);
      if (res.data.status == 200 && res.data.message == 'sukses') {
        // console.log("MTBS >>>>>>>>>>", res.data.data);
        if (res.data.data.length) {
          vm.data_mtbs = res.data.data[0]
          vm.data_mtbs.kunjungan_id = kunjungan_id
        } else {
          vm.data_mtbs = {
            data_anak: {
              nama_anak: vm.dataForm.nama_lengkap,
              nama_ibu: vm.dataForm.nama_ibu, 
              nik_anak: vm.dataForm.nik, 
              alamat_anak: vm.dataForm.alamat_sekarang,
              umur_anak_tahun: vm.dataForm.usia_tahun,
              umur_anak_bulan: vm.dataForm.usia_bulan,
              jenis_kelamin: vm.dataForm.jenis_kelamin,
            },

            dokter_id: vm.dataAnamnesa.ms_dokter_id,
            perawat_id: vm.dataAnamnesa.ms_perawat_id,
            kunjungan_id: kunjungan_id,
          }
        }
      } 
    },
    updateSatuSehat(){
      let vm = this
      // console.log(vm.dataForm, 'ihiy');
      let waktu = vm.$moment(vm.dataForm.waktu_selesai).utcOffset('+0700').format("YYYY-MM-DD HH:mm")
      vm.$axios.post("/kunjungan/sync_satu_sehat", {
        kunjungan_id: vm.dataForm.kunjungan_id,
        waktu_selesai: waktu
      })
      .then((res) => {
        // console.log(res);
        if(res.data.status == 200 && res.data.message == "sukses"){
          vm.$store.commit("set_alert", { variant: "success", msg: "BERHASIL UPDATE DATA SATU SEHAT" })
        }else{
          vm.$store.commit("set_alert", { variant: "warning", msg: res.data.message })
        }
      })
      .catch((err) => {
        vm.$store.commit("set_alert", { variant: "warning", msg: err.message })
      })
    },
    async cekStatusPasienMeninggal(rm) {
      const vm = this
      let res = await vm.$axios.post('/meninggal/check_rm', { rm_id: rm })
      // if (res.data.status == 200) {
      //   return { status_pasien: res.data.meninggal, cek_pasien: 'sukses'}
      // } else {
      //   vm.$store.commit("set_alert", {
      //     variant: "warning",
      //     msg: "GAGAL MENGECEK STATUS PASIEN",
      //     showing: true,
      //   });
      //   return { status_pasien: null, cek_pasien: 'gagal'}
      // }
      if (res.data.status == 200 && res.data.meninggal == 'true') {
        vm.$store.commit("set_alert", {
          variant: "warning",
          msg: "STATUS PASIEN SUDAH MENINGGAL",
          showing: true,
        });
        vm.dataForm.ms_status_pulang_id = null
      }
    },
    async getDataKeur() {
      const vm = this
      vm.data_keur = {
        kunjungan_id: vm.dataForm.kunjungan_id,
        dokter_id: vm.dataAnamnesa.ms_dokter_id,
        perawat_id: vm.dataAnamnesa.ms_perawat_id,
        tanggal_kunjungan: vm.dataForm.tanggal_daftar,
        suhu_keur: vm.dataAnamnesa.suhu,
        golongan_darah_id: vm.dataForm.ms_golongan_darah_id,
        berat_badan: vm.dataAnamnesa.berat_badan,
        panjang_badan: vm.dataAnamnesa.tinggi_badan,
        tekanan_darah: `${vm.dataAnamnesa.sistole}/${vm.dataAnamnesa.diastole}`,
        kesadaran_id: vm.dataAnamnesa.ms_kesadaran_id,
        // kesadaran: vm.listKesadaran.find(el => { el.ms_kesadaran_id == vm.dataAnamnesa.ms_kesadaran_id }),
        nafas: vm.dataAnamnesa.resp_rate,
        detak_jantung: vm.dataAnamnesa.heart_rate,

        data_print: {}
      }

      let dataKeteranganSehat = await vm.$axios.post('/keterangan_sehat/list', { kunjungan_id: vm.dataForm.kunjungan_id })
      let dataKeteranganSakit = await vm.$axios.post('/keterangan_sakit/list', { kunjungan_id: vm.dataForm.kunjungan_id })
      let dataKeteranganButaWarna = await vm.$axios.post('/keterangan_buta_warna/list', { kunjungan_id: vm.dataForm.kunjungan_id })

      vm.data_sehat = dataKeteranganSehat.data.status == 200 ? dataKeteranganSehat.data.data[0] : {}
      vm.data_sakit = dataKeteranganSakit.data.status == 200 ? dataKeteranganSakit.data.data[0] : {}
      vm.data_buta_warna = dataKeteranganButaWarna.data.status == 200 ? dataKeteranganButaWarna.data.data[0] : {}

      vm.data_keur = {
        kunjungan_id: vm.dataForm.kunjungan_id,
        dokter_id: vm.dataAnamnesa.ms_dokter_id,
        perawat_id: vm.dataAnamnesa.ms_perawat_id,
        tanggal_kunjungan: vm.dataForm.tanggal_daftar,
        suhu_keur: vm.dataAnamnesa.suhu,
        golongan_darah_id: vm.dataForm.ms_golongan_darah_id,
        berat_badan: vm.dataAnamnesa.berat_badan,
        panjang_badan: vm.dataAnamnesa.tinggi_badan,
        tekanan_darah: `${vm.dataAnamnesa.sistole}/${vm.dataAnamnesa.diastole}`,
        kesadaran_id: vm.dataAnamnesa.ms_kesadaran_id,
        nafas: vm.dataAnamnesa.resp_rate,
        detak_jantung: vm.dataAnamnesa.heart_rate,

        data_print: {
          nama_pasien: vm.dataForm.nama_lengkap,
          jenis_kelamin: vm.dataForm.jenis_kelamin,
          alamat_sekarang: vm.dataForm.alamat_sekarang,
          tanggal_lahir: vm.$moment(vm.dataForm.tanggal_lahir).format("LL"),
          tempat_lahir: vm.dataForm.tempat_lahir,
          pekerjaan: vm.dataForm.pekerjaan,
          tinggi_badan: vm.dataAnamnesa.tinggi_badan,
          berat_badan: vm.dataAnamnesa.berat_badan,
          tekanan_darah: `${vm.dataAnamnesa.sistole}/${vm.dataAnamnesa.diastole}`,
          suhu: vm.dataAnamnesa.suhu,
          nafas: vm.dataAnamnesa.resp_rate,
          detak_jantung: vm.dataAnamnesa.heart_rate,
          tanggal_kunjungan: vm.$moment(vm.dataForm.tanggal_daftar).format("LL"),
          umur: vm.dataForm.umur,
          nik: vm.dataForm.nik,
          no_telp: vm.dataForm.no_telp,
          keluhan_utama: vm.dataAnamnesa.keluhan_utama
        }
      }

      if (vm.data_sehat) {
        vm.data_keur.keterangan_sehat_id = vm.data_sehat.keterangan_sehat_id
        vm.data_keur.data_keterangan_sehat = vm.data_sehat.data_keterangan_sehat
      }
      if (vm.data_sakit) {
        vm.data_keur.keterangan_sakit_id = vm.data_sakit.keterangan_sakit_id
        vm.data_keur.data_keterangan_sakit = vm.data_sakit.data_keterangan_sakit
      }
      if (vm.data_buta_warna) {
        vm.data_keur.keterangan_buta_warna_id = vm.data_buta_warna.keterangan_buta_warna_id
        vm.data_keur.data_keterangan_buta_warna = vm.data_buta_warna.data_keterangan_buta_warna
      }
    },
    async getPengkajianResikoJatuh() {
      const vm = this
      vm.data_pengkajian_resiko_jatuh = {}
      try {
        let res = await vm.$axios.post('/pengkajian_risiko_jatuh/list', { kunjungan_id: vm.dataForm.kunjungan_id })
        // console.log("getPengkajianResikoJatuh ..........", res.data);
        
        if (res.data.status == 200 && res.data.message == 'sukses') {
          if (res.data.data.length) {
            vm.data_pengkajian_resiko_jatuh = res.data.data[0]
          } else {
            vm.data_pengkajian_resiko_jatuh = {
              kunjungan_id: vm.dataForm.kunjungan_id,
              dokter_id: vm.dataAnamnesa.ms_dokter_id,
              perawat_id: vm.dataAnamnesa.ms_perawat_id,
              tanggal_kunjungan: vm.dataForm.tanggal_daftar,
            }
          }
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getTumbuhKembangAnak() {
      const vm = this
      try {
        let res = await vm.$axios.post('/tka/list', { rm_id: vm.dataForm.rm_id })
        // console.log("getTKA ..........", res.data);
        
        if (res.data.status == 200 && res.data.message == 'sukses') {
          vm.data_tka = {}

          if (res.data.data.length) {
            vm.data_tka = res.data.data[0]
          } else {
            vm.data_tka = {
              rm_id: vm.dataForm.rm_id,
              kunjungan_id: vm.dataForm.kunjungan_id,
              dokter_id: vm.dataAnamnesa.ms_dokter_id,
              perawat_id: vm.dataAnamnesa.ms_perawat_id,
              tanggal_periksa: vm.dataForm.tanggal_daftar,
            }
          }
          // console.log(vm.data_tka);
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getPemeriksaanTKA() {
      const vm = this
      try {
        let res = await vm.$axios.post('/pemeriksaan_tka/list', { kunjungan_id: vm.dataForm.kunjungan_id })
        
        if (res.data.status == 200 && res.data.message == 'sukses') {
          vm.data_pemeriksaan_tka = {}

          if (res.data.data.length) {
            vm.data_pemeriksaan_tka = res.data.data[0]
            vm.data_pemeriksaan_tka.keluhan_utama = vm.dataAnamnesa.keluhan_utama
          } else {
            vm.data_pemeriksaan_tka = {
              rm_id: vm.dataForm.rm_id,
              kunjungan_id: vm.dataForm.kunjungan_id,
              dokter_id: vm.dataAnamnesa.ms_dokter_id,
              perawat_id: vm.dataAnamnesa.ms_perawat_id,
              tanggal_periksa: vm.dataForm.tanggal_daftar,
              bb_tka: vm.dataAnamnesa.berat_badan,
              tb_tka: vm.dataAnamnesa.tinggi_badan,
              keluhan_utama: vm.dataAnamnesa.keluhan_utama
            }
          }
          // console.log("pemeriksaan TKA ..........", vm.data_pemeriksaan_tka);
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getDataRujukanBPJS() {
      const vm = this
      let x = {
        rujuk_external_id: vm.dataForm.rujuk_external_id,
      }
      
      const options_A4 = {
        name: '_blank',
        specs: [
          'fullscreen=yes',
          'titlebar=yes',
          'scrollbars=yes'
        ],
        styles: [
        '/style-paper-a4.css',
        ],
        timeout: 1000, // default timeout before the print window appears
        autoClose: true, // if false, the window will not close after printing
        windowTitle: window.document.title, // override the window title
      }
      try {
        let res = await vm.$axios.post('/rujuk_external/surat_rujukan_FKTP', x)
        if (res.data.status == 200) {
          vm.dataPrint = res.data.data[0]
          vm.dataPrint.umur_pasien = vm.getTahun(vm.dataPrint.tglLahir)
          // console.log(vm.dataPrint);
          if (vm.dataPrint) {
            await vm.$htmlToPaper(this.cetak_print, options_A4);
          }
        }
      } catch (err) {
        console.log(err)
      }
    },
    async getDataKunjunganBPJS() {
      const vm = this
      let x = {
        kunjungan_id: vm.dataForm.kunjungan_id,
      }
      
      const options_A4 = {
        name: '_blank',
        specs: [
          'fullscreen=yes',
          'titlebar=yes',
          'scrollbars=yes'
        ],
        styles: [
        '/style-paper-a4.css',
        ],
        timeout: 1000, // default timeout before the print window appears
        autoClose: true, // if false, the window will not close after printing
        windowTitle: window.document.title, // override the window title
      }
      try {
        let res = await vm.$axios.post('/cetak/cetak_data_kunjungan_puskesmas', x)
        if (res.data.status == 200) {
          vm.dataPrint = res.data.data[0]
          vm.dataPrint.umur_pasien = vm.getTahun(vm.dataPrint.tglLahir)
          // console.log(vm.dataPrint);
          if (vm.dataPrint) {
            await vm.$htmlToPaper(this.cetak_print, options_A4);
          }
        }
      } catch (err) {
        console.log(err)
      }
    },
    getTahun(val) {
      let a = this.$moment();
      let b = this.$moment(val, 'DD-MM-YYYY');
      let diffDuration = this.$moment.duration(a.diff(b));
      return diffDuration.years()
    },
    async getPengantarRujukInternal() {
      const vm = this
      let x = {
        kunjungan_id: vm.dataForm.kunjungan_id,
      }
      const options_A4_landscape = {
        name: '_blank',
        specs: [
          'fullscreen=yes',
          'titlebar=yes',
          'scrollbars=yes'
        ],
        styles: [
        '/paper-a4-landscape.css',
        ],
        timeout: 1000, // default timeout before the print window appears
        autoClose: true, // if false, the window will not close after printing
        windowTitle: window.document.title, // override the window title
      }

      try {
        let res = await vm.$axios.post('/cetak/cetak_pengantar_rujukan_internal', x)
        console.log("pengantar rujuk internal", res.data);
        if (res.data.status == 200) {
          vm.dataPrintRujukInternal = res.data.data[0]
          console.log(vm.dataPrintRujukInternal);
          if (vm.dataPrintRujukInternal) {
            await vm.$htmlToPaper(this.cetak_print, options_A4_landscape);
          }
        } else {
          vm.$store.commit("set_alert", {
            variant: "warning",
            msg: _.toUpper(res.data.message),
            showing: true,
          });
        }
      } catch (err) {
        console.log(err);
        vm.$store.commit("set_alert", {
            variant: "danger",
            msg: "TERJADI GANGGUAN PADA JARINGAN",
            showing: true,
          });
      }
    },
    async getPengantarRujukInternal() {
      const vm = this
      let x = {
        kunjungan_id: vm.dataForm.kunjungan_id,
      }
      const options_A4_landscape = {
        name: '_blank',
        specs: [
          'fullscreen=yes',
          'titlebar=yes',
          'scrollbars=yes'
        ],
        styles: [
        '/paper-a4-landscape.css',
        ],
        timeout: 1000, // default timeout before the print window appears
        autoClose: true, // if false, the window will not close after printing
        windowTitle: window.document.title, // override the window title
      }
      try {
        let res = await vm.$axios.post('/cetak/cetak_riwayat_pasien', x)
        console.log("riwayat pasien", res.data);
        if (res.data.status == 200) {
          vm.dataPrintRiwayatPasien = res.data.data[0]
          console.log(vm.dataPrintRiwayatPasien);
          if (vm.dataPrintRiwayatPasien) {
            await vm.$htmlToPaper(this.cetak_print, options_A4_landscape);
          }
        } else {
          vm.$store.commit("set_alert", {
            variant: "warning",
            msg: _.toUpper(res.data.message),
            showing: true,
          });
        }
      } catch (err) {
        console.log(err);
        vm.$store.commit("set_alert", {
            variant: "danger",
            msg: "TERJADI GANGGUAN PADA JARINGAN",
            showing: true,
          });
      }
    }
  },
};
</script>

<style scoped>
#page-kunjungan .btn-primary {
  background-color: #9c4098;
}

#page-kunjungan .custom-title-color-black {
  font-weight: 700;
  font-size: 16px;
  color: #d52f65;
}

#page-kunjungan .fw-600 {
  font-weight: 600;
  color: #202020;
}

</style>
