<template>
  <div v-if="dataAnamnesa">
    <b-card>
      <b-row>
        <b-col>
          <h5 class="custom-title-color-black">
            <strong> {{ pageName }} </strong>
          </h5>
        </b-col>
      </b-row>
      <!-- <pre>{{ is_data }}</pre> -->
      <b-row>
        <b-col cols="6">
          <b-form-group>
            <template #label>
              Dokter / Tenaga Medis <span class="text-danger">*</span>
            </template>
            <b-form-select
              id="dokter_id"
              disabled
              v-model="$v.is_data.ms_dokter_id.$model"
              :options="listDokter"
              placeholder="-- Pilih Tenaga Medis --"
            ></b-form-select>
          </b-form-group>
          <b-form-group>
            <template #label> Tindakan <span class="text-danger">*</span></template>
            <Multiselect
              id="ms_tindakan_id"
              v-model="$v.is_data.obj_tindakan.$model"
              placeholder="-- Pilih Tindakan --"
              :options="optionTindakan"
              :state="checkIfValid('ms_dokter_id')"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              track-by="ms_tindakan_id"
              label="nama_tindakan"
              @select="cekTindakan($event)"
            />
          </b-form-group>

          <b-form-group>
            <template #label> Jumlah <span class="text-danger">*</span> </template>
            <b-form-input
              v-model="$v.is_data.jumlah_tindakan.$model"
              :state="checkIfValid('jumlah_tindakan')"
              type="number"
            ></b-form-input>
          </b-form-group>
          <b-form-group>
            <template #label> Tarif <span class="text-danger">*</span> </template>
            <b-form-input
              v-model="$v.is_data.tarif.$model"
              :state="checkIfValid('tarif')"
            ></b-form-input>
          </b-form-group>
          <b-form-group>
            <template #label> Total <span class="text-danger">*</span> </template>
            <b-form-input readonly v-model="totalTarif"></b-form-input>
          </b-form-group>
        </b-col>

        <b-col cols="6">
          <b-form-group>
            <template #label>
              Perawat / Bidan / Nutrisionist / Sanitarian
              <span class="text-danger">*</span>
            </template>
            <b-form-select
              id="dokter_id"
              disabled
              v-model="$v.is_data.ms_perawat_id.$model"
              :options="listPerawat"
              placeholder="-- Pilih Tenaga Medis --"
            ></b-form-select>
          </b-form-group>
          <!-- <b-form-group>
            <template #label>
              Tanggal Rencana <span class="text-danger">*</span>
            </template>
            <DatePicker
              style="width: 100%"
              v-model="$v.is_data.tanggal_rencana.$model"
              :state="checkIfValid('tanggal_rencana')"
              type="datetime"
              placeholder="-- Pilih Tanggal --"
            />
          </b-form-group> -->
          <b-form-group>
            <template #label> Lama Tindakan <span class="text-danger">*</span> </template>
            <template #default>
              <b-container>
                <b-row>
                  <b-col class="pl-0" cols="6">
                    <b-input-group>
                      <b-form-input
                        v-model="$v.is_data.lama_tindakan_jam.$model"
                        :state="checkIfValid('lama_tindakan_jam')"
                        type="number"
                      ></b-form-input>
                      <b-input-group-append>
                        <b-button disabled>Jam</b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-col>
                  <b-col class="pr-0" cols="6">
                    <b-input-group>
                      <b-form-input
                        v-model="$v.is_data.lama_tindakan_menit.$model"
                        :state="checkIfValid('lama_tindakan_menit')"
                        type="number"
                      ></b-form-input>
                      <b-input-group-append>
                        <b-button disabled>Menit</b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-col>
                </b-row>
              </b-container>
            </template>
          </b-form-group>

          <b-form-group>
            <template #label> Hasil <span class="text-danger">*</span> </template>
            <b-form-input
              v-model="$v.is_data.hasil_tindakan.$model"
              :state="checkIfValid('hasil_tindakan')"
              type="number"
            />
          </b-form-group>

          <b-form-group>
            <template #label> Prosedur / Tindakan </template>
            <b-form-textarea
              v-model="$v.is_data.keterangan.$model"
              :state="checkIfValid('keterangan')"
              placeholder="Silahkan isi keterangan"
              rows="6"
            ></b-form-textarea>
          </b-form-group>

          <!-- <b-form-group>
            <template #label> Keterangan <span class="text-danger">*</span> </template>
            <Multiselect
              v-model="$v.is_data.ms_dokter_id.$model"
              :state="checkIfValid('ms_dokter_id')"
              :options="listDokter"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              track-by="tenaga_medis_id"
              label="nama_tenaga_medis"
              placeholder="-- Pilih Tenaga Medis --"
            />
          </b-form-group> -->
        </b-col>
      </b-row>

      <b-row align-h="end">
        <b-col cols="auto">
          <b-button variant="outline-light" class="text-dark" @click="resetData()">
            Clear
          </b-button>
        </b-col>
        <b-col cols="auto" class="ml-1">
          <b-button variant="primary" :disabled="kunjunganSelesai" @click="tambahTindakan()">
            {{ buttonTambah }}
          </b-button>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" md="12" lg="12">
          <hr />
        </b-col>
      </b-row>
      <b-row>
        <b-col md="3">
          <b-form-group
            label="Per Halaman"
            label-for="per-page-select"
            label-cols-md="6"
            label-align-md="left"
            label-size="md"
            style="background-color: "
          >
            <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
              size="md"
            ></b-form-select>
          </b-form-group>
        </b-col>

        <b-col md="6" offset-md="3">
          <b-form-group
            label="Cari"
            label-for="filter-input"
            label-cols-md="3"
            label-align-md="right"
            label-size="md"
          >
            <b-input-group size="md">
              <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                placeholder="Ketik disini untuk mencari ..."
              ></b-form-input>

              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''" variant="danger"
                  >Hapus</b-button
                >
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="12" lg="12">
          <b-table
            :items="dataTindakan"
            :fields="fields"
            :current-page="currentPage"
            :per-page="perPage"
            :filter="filter"
            :filter-included-fields="filterOn"
            responsive
            show-empty
            small
            @filtered="onFiltered"
            bordered
            striped
            hover
            :busy="tableBusy"
          >
            <template #cell(lama_tindakan)="data">
              {{ data.item.lama_tindakan_jam }} Jam
              {{ data.item.lama_tindakan_menit }} Menit
            </template>
            <template #cell(actions)="item">
              <b-button
                variant="warning"
                class="mr-1"
                v-c-tooltip.hover.click="'Edit Data'"
                @click="editDataTindakan(item.item)"
                ><CIcon name="cil-pencil" /> {{ item.actions }}</b-button
              >

              <b-button
                variant="danger"
                class="mr-1"
                v-c-tooltip.hover.click="'Hapus Data'"
                v-b-modal.modal-delete-tindakan-kunjungan
                :disabled="kunjunganSelesai"
                @click="data_delete = item.item"
                ><CIcon name="cil-trash" /> {{ item.actions }}</b-button
              >
            </template>
          </b-table>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="5" offset-md="7">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="fill"
          ></b-pagination>
        </b-col>
      </b-row>

      <modal-delete
        :fileName="file_name"
        :data_delete="data_delete"
        @alertFromChild="triggerAlert($event), getDatas()"
      />
    </b-card>

    <b-container>
      <b-row align-h="end">
        <!-- <b-col cols="auto">
          <b-button variant="outline-light" class="text-dark"> Reset </b-button>
        </b-col> -->
        <b-col cols="auto" class="ml-1">
          <b-button variant="secondary" @click="printTindakan('cetak_persetujuan')"> Cetak Persetujuan / Penolakan </b-button>
        </b-col>
        <b-col cols="auto" class="ml-1">
          <b-button variant="secondary" @click="printTindakan('cetak_tindakan')"> Cetak Tindakan </b-button>
        </b-col>
        <!-- <b-col cols="auto" class="ml-1">
          <b-button variant="primary"> Simpan </b-button>
        </b-col> -->
      </b-row>
    </b-container>

    <!-- PRINT TINDAKAN -->
    <div style="position: relative; display: none;" id="cetak_tindakan">
      <section class="content-print A4" v-if="dataTindakan">
        <div class="sheet">
          <div class="w-100">
            <table border="0" class="custom-table">
              <tbody>
                <tr>
                  <td style="width: 15%;vertical-align: middle;padding-left: 1.5mm;padding-right: 1.5mm;"><img :src="logo" alt="" style="width: 100%;"></td>
                  <td style="width: 85%;vertical-align: middle;">
                    <h6 style="text-align: center;font-weight: bold;margin-bottom: 0;font-size: 16pt;">PEMERINTAH {{ $store.state.puskesmas.nama_kota }} </h6>
                    <h6 style="text-align: center;font-weight: bold;margin-bottom: 0;font-size: 16pt;">DINAS KESEHATAN</h6>
                    <h6 style="text-align: center;font-weight: bold;margin-bottom: 0;font-size: 16pt;">{{ $store.state.puskesmas.nama_puskesmas }}</h6>
                    <h6 style="text-align: center;font-weight: normal;margin-bottom: 0;font-size: 10pt;">{{ $store.state.puskesmas.alamat_puskesmas }}</h6>
                    <!-- <h6 style="text-align: center;font-weight: normal;font-size: 10pt;"><span>Email : emailpuskesmas@gmil.com</span> <span>Telp. : 0987654321</span></h6> -->
                  </td>
                    <!-- <td style="width: 15%;vertical-align: middle;border-bottom: 1px solid #333;padding-left: 1.5mm;padding-right: 1.5mm;"><img src="puskesmas-logo.png" alt="" style="width: 100%;"></td> -->
                </tr>
              </tbody>
            </table>
          </div>

          <div class="w-100" style="margin-top: 2.5mm;">
            <div class="line"></div>
          </div>

          <div class="w-100" style="margin-top: 5mm;">
            <table border="0" class="custom-table">
              <tbody>
                <tr>
                  <td style="width: 75mm;">Tanggal</td>
                  <td class="autowidth">:</td>
                  <td>{{ $moment(itemPrint.tanggal_daftar).format("DD MMMM YYYY") }}</td>
                </tr>

                <tr>
                  <td>Poli/Ruangan</td>
                  <td>:</td>
                  <td>{{ itemPrint.poli }}</td>
                </tr>

                <tr>
                  <td>No. eRM</td>
                  <td>:</td>
                  <td>{{ itemPrint.no_rm }}</td>
                </tr>

                <tr>
                  <td>NIK</td>
                  <td>:</td>
                  <td>{{ itemPrint.nik }}</td>
                </tr>

                <tr>
                  <td>Nama Pasien</td>
                  <td>:</td>
                  <td>{{ itemPrint.nama_pasien }}</td>
                </tr>

                <tr>
                  <td>Jenis Kelamin</td>
                  <td>:</td>
                  <td>{{ itemPrint.jenis_kelamin == 'P' ? 'Perempuan' : 'Laki-laki' }}</td>
                </tr>

                <tr>
                  <td>Tempat / Tanggal Lahir</td>
                  <td>:</td>
                  <td>{{ itemPrint.tempat_lahir }} / {{ $moment(itemPrint.tanggal_lahir).format("DD MMMM YYYY") }}</td>
                </tr>

                <tr>
                  <td>Asuransi</td>
                  <td>:</td>
                  <td>{{ itemPrint.asuransi }}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="w-100" style="margin-top: 5mm; display: flex;" v-for="(data, index) in dataTindakan" :key="index">
            <div>
              <table border="0" class="custom-table">
                <tbody>
                  <tr>
                    <td style="width: 2.5mm;">{{ index + 1 }}.</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="w-100" style="border: 1px solid #333;">
              <table border="0" class="custom-table">
                <tbody>
                  <tr>
                    <td style="width: 68mm;">Tindakan</td>
                    <td class="autowidth">:</td>
                    <td>{{ data.nama_tindakan }}</td>
                  </tr>

                  <tr>
                    <td>Dokter / Tenaga Medis</td>
                    <td>:</td>
                    <td>{{ data.nama_dokter }}</td>
                  </tr>

                  <tr>
                    <td>Perawat / Tenaga Medis</td>
                    <td>:</td>
                    <td>{{ data.nama_perawat }}</td>
                  </tr>

                  <tr>
                    <td>Tanggal Rencana</td>
                    <td>:</td>
                    <td>{{ data.tanggal_dibuat ? $moment(data.tanggal_dibuat).format("LLL") : "-" }}</td>
                  </tr>

                  <tr>
                    <td>Lama Tindakan</td>
                    <td>:</td>
                    <td>
                      {{ data.lama_tindakan_jam }} Jam
                      {{ data.lama_tindakan_menit }} Menit
                    </td>
                  </tr>

                  <tr>
                    <td>Hasil</td>
                    <td>:</td>
                    <td>{{ data.hasil_tindakan }}</td>
                  </tr>

                  <tr>
                    <td>Jumlah Tindakan</td>
                    <td>:</td>
                    <td>{{ data.jumlah_tindakan }}</td>
                  </tr>

                  <tr>
                    <td>Tarif Tindakan</td>
                    <td>:</td>
                    <td>{{ data.total_tarif }}</td>
                  </tr>

                  <tr>
                    <td>Total Tarif</td>
                    <td>:</td>
                    <td>{{ data.jumlah_tarif_tindakan }}</td>
                  </tr>

                </tbody>
              </table>
            </div>
          </div>
          
          <div class="w-100" style="margin-top: 5mm;">
              <table border="0" class="custom-table" style="font-size: 12pt;">
                  <tbody>
                      <tr>
                          <td style="width: 50%;">
                              &nbsp;
                          </td>
                          <td style="width: 50%;">
                              <h6 style="text-align: center;"><span>{{ $store.state.puskesmas.nama_kota }}, {{ $moment(itemPrint.tanggal_daftar).format("DD MMMM YYYY") }}</span></h6>
                              <h6 style="text-align: center;">Diketahui</h6>
                              <br/>
                              <br/>
                              <br/>
                              <br/>
                              <br/>
                              <br/>
                              <br/>
                              <br/>
                              <h6 style="text-align: center;"><span>(......................................)</span></h6>
                              <h6 style="text-align: center;"><span>NAMA DIKETAHUI</span></h6>
                              <h6 style="text-align: center;"><span>SIP : </span></h6>
                          </td>
                      </tr>
                  </tbody>
              </table>
          </div>
        </div>
      </section>
    </div>

    <!-- PRINT PERSETUJUAN -->
    <div style="position: relative; display: none;" id="cetak_persetujuan">
      <section class="content-print A4" v-if="dataPersetujuan">
        <div class="sheet">
          <div class="w-100">
            <table border="0" class="custom-table">
                <tbody>
                    <tr>
                        <td style="width: 15%;vertical-align: middle;padding-left: 1.5mm;padding-right: 1.5mm;"><img :src="logo" alt="" style="width: 100%;"></td>
                        <td style="width: 70%;vertical-align: middle;">
                            <h6 style="text-align: center;font-weight: bold;margin-bottom: 0;font-size: 16pt;">PEMERINTAH {{ $store.state.puskesmas.nama_kota }} </h6>
                            <h6 style="text-align: center;font-weight: bold;margin-bottom: 0;font-size: 16pt;">DINAS KESEHATAN</h6>
                            <h6 style="text-align: center;font-weight: bold;margin-bottom: 0;font-size: 16pt;">{{ $store.state.puskesmas.nama_puskesmas }}</h6>
                            <h6 style="text-align: center;font-weight: normal;font-size: 10pt;margin-bottom: 0;">{{ $store.state.puskesmas.alamat_puskesmas }}</h6>
                            <!-- <h6 style="text-align: center;font-weight: normal;font-size: 10pt;"><span>Email : emailpuskesmas@gmil.com</span> <span>Telp. : 0987654321</span></h6> -->
                        </td>
                        <td style="width: 15%;vertical-align: middle;padding-left: 1.5mm;padding-right: 1.5mm;"><img src="../../../cetak/puskesmas-logo.png" alt="" style="width: 100%;"></td>
                    </tr>
                </tbody>
            </table>
          </div>

          <div class="w-100" style="margin-top: 2.5mm;">
            <div class="line"></div>
          </div>

          <div class="w-100" style="margin-top: 5mm;">
              <h6 style="font-size: 16pt;font-weight: bold;text-align: center;">PERSETUJUAN / PENOLAKAN TINDAKAN KEDOKTERAN</h6>
          </div>

          <div class="w-100" style="margin-top: 5mm;">
              <table border="0" class="custom-table">
                  <tbody>
                      <tr>
                          <td colspan="3" style="font-weight: bold;">PEMBERIAN INFORMASI</td>
                      </tr>
                      <tr>
                          <td style="width: 75mm;">Dokter Pelaksanaan Tindakan</td>
                          <td class="autowidth">:</td>
                          <td>{{ dataPersetujuan.nama_dokter || "-" }}</td>
                      </tr>

                      <tr>
                          <td>Pemberian Informasi</td>
                          <td>:</td>
                          <td>{{ dataPersetujuan.nama_lengkap || "-" }}</td>
                      </tr>

                      <tr>
                          <td>Penerima Informasi / Pemberi Persetujuan</td>
                          <td>:</td>
                          <td>{{ dataPersetujuan.nama_penanggungjawab ||"-" }}</td>
                      </tr>
                  </tbody>
              </table>
          </div>

          <div class="w-100" style="margin-top: 5mm;">
              <table border="1" class="custom-table">
                  <thead>
                      <tr>
                          <th style="width: 2.5mm;">No.</th>
                          <th>Jenis Informasi</th>
                          <th>Isi Informasi</th>
                          <th style="width: 50mm;">Tanda (v)</th>
                      </tr>
                  </thead>

                  <tbody>
                      <tr>
                          <td style="text-align: center;">1.</td>
                          <td>Diagnosa (WD/DD)</td>
                          <td>TIDAK ADA</td>
                          <td></td>
                      </tr>

                      <tr>
                          <td style="text-align: center;">2.</td>
                          <td>Dasar Diagnosa</td>
                          <td>TIDAK ADA</td>
                          <td></td>
                      </tr>

                      <tr>
                          <td style="text-align: center;">3.</td>
                          <td>Tindakan Kedokteran</td>
                          <td>TIDAK ADA</td>
                          <td></td>
                      </tr>

                      <tr>
                          <td style="text-align: center;">4.</td>
                          <td>Indikasi Tindakan</td>
                          <td>TIDAK ADA</td>
                          <td></td>
                      </tr>

                      <tr>
                          <td style="text-align: center;">5.</td>
                          <td>Tata Cara</td>
                          <td>TIDAK ADA</td>
                          <td></td>
                      </tr>

                      <tr>
                          <td style="text-align: center;">6.</td>
                          <td>Tujuan</td>
                          <td>TIDAK ADA</td>
                          <td></td>
                      </tr>

                      <tr>
                          <td style="text-align: center;">7.</td>
                          <td>Resiko</td>
                          <td>TIDAK ADA</td>
                          <td></td>
                      </tr>

                      <tr>
                          <td style="text-align: center;">8.</td>
                          <td>Komplikasi</td>
                          <td>TIDAK ADA</td>
                          <td></td>
                      </tr>

                      <tr>
                          <td style="text-align: center;">9.</td>
                          <td>Prognosa</td>
                          <td>TIDAK ADA</td>
                          <td></td>
                      </tr>

                      <tr>
                          <td style="text-align: center;">10.</td>
                          <td>Alternatif & Resiko</td>
                          <td>TIDAK ADA</td>
                          <td></td>
                      </tr>

                      <tr>
                          <td style="text-align: center;">11.</td>
                          <td>Lain-lain</td>
                          <td>TIDAK ADA</td>
                          <td></td>
                      </tr>

                      <tr>
                          <td colspan="3">
                              Dengan ini menyatakan bahwa Saya telah menerangkan hal-hal diatas secara benar dan jelas, memberikan kesempatan untuk bertanya atau berdiskusi
                          </td>
                          <td></td>
                      </tr>

                      <tr>
                          <td colspan="3">
                              Dengan ini menyatakan bahwa Saya telah menerima informasi sebagaimana diatas yang Saya beri tanda di kolom sisi kanan dan telah memahaminya.
                          </td>
                          <td></td>
                      </tr>

                      <tr>
                          <td colspan="3">
                              Bila pasien tidak kompeten atau tidak mau menerima informasi, maka penerima informasi adalah wali atau keluarga terdekat.
                          </td>
                          <td></td>
                      </tr>
                  </tbody>
              </table>
          </div>

          <div class="w-100" style="margin-top: 5mm;">
              <table border="0" class="custom-table">
                  <tbody>
                      <tr>
                          <td colspan="3" style="font-weight: bold;">PERSETUJUAN/PENOLAKAN TINDAKAN KEDOKTERAN</td>
                      </tr>
                      <tr>
                          <td colspan="3">Yang bertanda tangan dibawah ini :</td>
                      </tr>
                      <tr>
                          <td style="width: 75mm;">Nama</td>
                          <td class="autowidth">:</td>
                          <td></td>
                      </tr>

                      <tr>
                          <td>Usia</td>
                          <td>:</td>
                          <td></td>
                      </tr>

                      <tr>
                          <td>Jenis Kelamin</td>
                          <td>:</td>
                          <td></td>
                      </tr>

                      <tr>
                          <td>Alamat</td>
                          <td>:</td>
                          <td></td>
                      </tr>
                  </tbody>
              </table>
          </div>

          <div class="w-100" style="margin-top: 5mm;">
              <h6>Dengan ini menyatakan persetujuan / penolakan untuk dilakukannya tindakan JASA DOKTER UMUM terhadap saya :</h6>
          </div>

          <div class="w-100" style="margin-top: 5mm;">
              <table border="0" class="custom-table">
                  <tbody>
                      <tr>
                          <td style="width: 75mm;">Nama</td>
                          <td class="autowidth">:</td>
                          <td>{{ dataPersetujuan.nama_lengkap }}</td>
                      </tr>

                      <tr>
                          <td>Usia</td>
                          <td>:</td>
                          <td>
                            <span v-if="dataPersetujuan.usia_tahun">{{ dataPersetujuan.usia_tahun + " Tahun " }}</span>
                            <span v-if="dataPersetujuan.usia_bulan">{{ dataPersetujuan.usia_bulan + " Bulan " }}</span>
                            <span v-if="dataPersetujuan.usia_hari">{{ dataPersetujuan.usia_hari + " Hari " }}</span>
                          </td>
                      </tr>

                      <tr>
                          <td>Jenis Kelamin</td>
                          <td>:</td>
                          <td>{{ dataPersetujuan.jenis_kelamin == "P" ? "Perempuan" : "Laki-laki" }}</td>
                      </tr>

                      <tr>
                          <td>Alamat</td>
                          <td>:</td>
                          <td>{{ dataPersetujuan.alamat_sekarang }}</td>
                      </tr>
                  </tbody>
              </table>
          </div>

          <div class="w-100" style="margin-top: 5mm;">
              <h6>Saya memahami indikasi dan tujuan dari tindakan tersebut sebagaimana telah dijelaskan seperti diatas kepada Saya, termasuk risiko dan komplikasi yang mungkin timbul.</h6>
          </div>

          <div class="w-100" style="margin-top: 5mm;">
              <h6>Saya juga menyadari bahwa oleh karena Ilmu Kedokteran bukanlah ilmu pasti, maka keberhasilan tindakan kedokteran bukanlah keniscayaan, melainkan sangat bergantung kepada ijin Tuhan Yang Maha Esa.</h6>
          </div>
          <div class="w-100" style="margin-top: 5mm;">
              <h6 style="text-align: right;"><span>{{ $store.state.puskesmas.nama_kota }}, {{ $moment(itemPrint.tanggal_daftar).format("DD MMMM YYYY") }}</span></h6>
          </div>
          <div class="w-100" style="margin-top: 5mm;">
              <table border="0" class="custom-table" style="font-size: 12pt;">
                  <tbody>
                      <tr>
                          <td style="width: 33.33%;">
                              <h6 style="text-align: center;">Yang Menyatakan *</h6>
                              <br/>
                              <br/>
                              <br/>
                              <br/>
                              <br/>
                              <br/>
                              <br/>
                              <br/>
                              <h6 style="text-align: center;">(......................................)</h6>
                              <h6 style="text-align: center;">{{ dataPersetujuan.nama_lengkap }}</h6>
                          </td>

                          <td style="width: 33.33%;">
                              <h6 style="text-align: center;">Petugas Kesehatan *</h6>
                              <br/>
                              <br/>
                              <br/>
                              <br/>
                              <br/>
                              <br/>
                              <br/>
                              <br/>
                              <h6 style="text-align: center;">(......................................)</h6>
                              <h6 style="text-align: center;">{{ dataPersetujuan.nama_dokter }}</h6>
                          </td>

                          <td style="width: 33.33%;">
                              <h6 style="text-align: center;">Saksi *</h6>
                              <br/>
                              <br/>
                              <br/>
                              <br/>
                              <br/>
                              <br/>
                              <br/>
                              <br/>
                              <h6 style="text-align: center;">(......................................)</h6>
                              <h6 style="text-align: center;"></h6>
                          </td>
                      </tr>
                  </tbody>
              </table>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

import moment from "moment";
moment.locale("id");

import ModalDelete from "./modalDelete.vue";
import Multiselect from "vue-multiselect";
export default {
  components: {
    ModalDelete,
    Multiselect,
  },
  props: [
    "dataKunjungan",
    "dataAnamnesa",
    "dataTindakan",
    "listDokter",
    "listPerawat",
    "optionTindakan",
    'kunjunganSelesai',
  ],
  data() {
    return {
      file_name: "tindakan",
      is_data: {
        ms_dokter_id: "",
        ms_perawat_id: "",
        ms_tindakan_id: "",
        tarif: 0,
        total_tarif: 0,
        keterangan: "",
        lama_tindakan_jam: 0,
        lama_tindakan_menit: 0,
        hasil_tindakan: 0,
        jumlah_tindakan: 0,
        obj_tindakan: null,
      },
      data_delete: null,
      items: [],
      itemPrint: [],
      dataPersetujuan: null,
      fields: [
        {
          key: "tanggal_dibuat",
          label: "Tanggal Dibuat",
          sortDirection: "desc",
          sortable: true,
          class: "text-left",
          formatter: (val) => {
            return val ? this.$moment(val).format("DD-MM-YYYY HH:mm:ss") : "-";
          },
        },
        {
          key: "nama_tindakan",
          label: "Tindakan",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "nama_dokter",
          label: "Dokter / Tenaga Medis",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "nama_perawat",
          label: "Perawat / Tenaga Medis",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "lama_tindakan",
          label: "Lama Tindakan",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "jumlah_tindakan",
          label: "Jumlah",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "total_tarif",
          label: "Total Tarif",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "hasil_tindakan",
          label: "Hasil",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        // {
        //   key: "user",
        //   label: "Keterangan",
        //   sortable: true,
        //   sortDirection: "desc",
        //   class: "text-left",
        // },
        {
          key: "actions",
          label: "Aksi",
          class: "table-option-2 text-center",
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      tableBusy: false,
      buttonTambah: "Tambah",
    };
  },
  async mounted() {
    // const vm = this;
    // vm.getDatas();
    if (this.dataTindakan) {
      this.totalRows = this.dataTindakan.length;
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    pageName() {
      let words = this.file_name.includes("ms_")
        ? this.file_name.replace("ms_", "").split("_")
        : this.file_name.split("_");
      let capitalizedWords = words.map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      });
      return capitalizedWords.join(" ");
    },
    totalTarif() {
      let x = this.is_data.tarif;
      let y = this.is_data.jumlah_tindakan;
      return x * y <= 1 ? 0 : x * y;
    },
    logo() {
      return this.$store.state.logo;
    },
  },
  watch: {
    dataAnamnesa: {
      handler(data) {
        const vm = this;
        // console.log('===============')
        // console.log(vm.listDokter)
        // console.log(data)
        vm.is_data.ms_dokter_id = vm.$findKey(
          vm.listDokter,
          data.ms_dokter_id,
          false,
          "tenaga_medis_id",
          "tenaga_medis_id"
        );
        vm.is_data.ms_perawat_id = vm.$findKey(
          vm.listPerawat,
          data.ms_perawat_id,
          false,
          "tenaga_medis_id",
          "tenaga_medis_id"
        );
      },
      deep: true,
    },
  },
  mixins: [validationMixin],
  validations: {
    is_data: {
      ms_dokter_id: {},
      ms_perawat_id: {},
      ms_tindakan_id: { required },
      tarif: {},
      total_tarif: {},
      keterangan: {},
      lama_tindakan_jam: {},
      lama_tindakan_menit: {},
      hasil_tindakan: {},
      jumlah_tindakan: {},
      obj_tindakan: {},
    },
  },
  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    checkIfValid(fieldName, childField = "") {
      // console.log("fieldname", fieldName);
      const field = this.$v.is_data[fieldName];
      // console.log("field", field)
      if (childField) {
        for (let i = 0; i < field.length; i++) {
          const el = field[i];
          if (el[childField].$dirty) {
            return null;
          }
        }
      } else {
        if (!field.$dirty) {
          return null;
        }
      }
      return !(field.$invalid || field.$model === "");
    },
    getDatas() {
      console.log("emit data tindakan");
      this.$emit("getDataTindakan");
    },
    async tambahTindakan() {
      // lama_tindakan_jam, lama_tindakan_menit, nomor_gigi, jumlah_tindakan, total_tarif, tanggal_rencana, hasil_tindakan, kunjungan_id, ms_dokter_id, ms_perawat_id, ms_tindakan_id
      console.log(this.is_data);
      const vm = this;
      let endpoint = "register";
      let req = {
        ms_dokter_id: vm.is_data.ms_dokter_id,
        ms_perawat_id: vm.is_data.ms_perawat_id,
        ms_tindakan_id: vm.is_data.ms_tindakan_id,
        lama_tindakan_jam: Number(vm.is_data.lama_tindakan_jam),
        lama_tindakan_menit: Number(vm.is_data.lama_tindakan_menit),
        jumlah_tindakan: Number(vm.is_data.jumlah_tindakan),
        total_tarif: vm.totalTarif,
        hasil_tindakan: Number(vm.is_data.hasil_tindakan),
        kunjungan_id: vm.dataAnamnesa.kunjungan_id,
        keterangan: vm.is_data.keterangan,
      };
      if (vm.buttonTambah == "Simpan") {
        endpoint = "update";
        req.id = vm.is_data.id;
      }
      vm.buttonTambah = "Mohon Tunggu";
      try {
        const res = await vm.$axios.post(`/${vm.file_name}/${endpoint}`, req);
        console.log(res.data);
        if (res.data.status == 200 && res.data.message == "sukses") {
          vm.triggerAlert({
            variant: "success",
            msg: "BERHASIL MENDAFTARKAN " + vm.pageName.toUpperCase(),
            showing: true,
          });
          let x = res.data.data
          if (x.length) {
            for (let i = 0; i < x.length; i++) {
              const el = x[i];
              vm.triggerAlert({
                variant: "danger",
                msg: _.toUpper(el.message),
                showing: true,
              });
            }
          }
            
          vm.buttonTambah = "Tambah";
        }else if(res.data.status == 204){
          vm.triggerAlert({
            variant: "danger",
            msg: _.toUpper(`${res.data.data[0].field} ${res.data.data[0].message}`),
            showing: true,
          });
          vm.buttonTambah = "Tambah";
        } else {
          vm.triggerAlert({
            variant: "danger",
            msg: _.toUpper(res.data.message),
            showing: true,
          });
          vm.buttonTambah = "Tambah";
        }
      } catch (err) {
        console.log(err);
        vm.triggerAlert({
          variant: "danger",
          msg: "TERJADI KESALAHAN PADA SERVER",
          showing: true,
        });
        vm.buttonTambah = "Tambah";
      } finally {
        vm.resetData();
        vm.$emit("getDataTindakan");
      }
    },
    cekTindakan(ev) {
      console.log(ev);
      if (!ev.jumlah_tarif_tindakan) {
        this.triggerAlert({
          variant: "warning",
          msg: "DATA MASTER TINDAKAN MOHON DILENGKAPI",
          showing: true,
        });
        this.is_data.obj_tindakan = null;
      } else {
        this.is_data.ms_tindakan_id = ev.ms_tindakan_id;
        this.is_data.tarif = ev.jumlah_tarif_tindakan;
        this.is_data.jumlah_tindakan = 1;
      }
    },
    editDataTindakan(value) {
      // console.log(value);
      const vm = this;
      vm.buttonTambah = "Simpan";
      this.is_data = {
        id: value.tindakan_id,
        ms_dokter_id: value.ms_dokter_id,
        ms_perawat_id: value.ms_perawat_id,
        ms_tindakan_id: value.ms_tindakan_id,
        tarif: value.jumlah_tarif_tindakan,
        keterangan: value.keterangan,
        lama_tindakan_jam: value.lama_tindakan_jam,
        lama_tindakan_menit: value.lama_tindakan_menit,
        hasil_tindakan: value.hasil_tindakan,
        jumlah_tindakan: value.jumlah_tindakan,
        obj_tindakan: {
          ms_tindakan_id: value.ms_tindakan_id,
          nama_tindakan: value.nama_tindakan,
        },
      };
    },
    async printTindakan(val) {
      const options = {
        name: '_blank',
        specs: [
          'fullscreen=yes',
          'titlebar=yes',
          'scrollbars=yes'
        ],
        styles: [
        '/style-paper-a4.css',
        ],
        timeout: 1000, // default timeout before the print window appears
        autoClose: true, // if false, the window will not close after printing
        windowTitle: window.document.title, // override the window title
      }

      if (val == 'cetak_persetujuan') {
        let statusCetak = await this.cetakPersetujuanTindakan()
        if (statusCetak) {
          await this.$htmlToPaper(val, options);
        }
      } else {
        await this.getDataTindakanPrint()
        if (this.itemPrint) {
          // console.log("masyuk", this.itemPrint);
          await this.$htmlToPaper(val, options);
        }
      }

      // await this.$htmlToPaper(val);
    },
    async getDataTindakanPrint() {
      const vm = this
      let x = { kunjungan_id: vm.dataAnamnesa.kunjungan_id }
      try {
        let res = await vm.$axios.post("/cetak/cetak_tindakan", x)
        console.log(res);
        vm.itemPrint = res.data.status == 200 ? res.data.data[0] : []
        // vm.dataPersetujuan = vm.itemPrint.data[0]
      } catch (err) {
        console.log(err);
      }
    },
    async cetakPersetujuanTindakan() {
      const vm = this
      let x = { kunjungan_id: vm.dataAnamnesa.kunjungan_id }
      try {
        let res = await vm.$axios.post("/cetak/cetak_persetujuan_tindakan", x)
        console.log(res);
        vm.dataPersetujuan = res.data.status == 200 ? res.data.data[0] : []
        return vm.dataPersetujuan ? true : false
      } catch (error) {
        console.log(error);
        return false
      }
    },
    resetData() {
      // this.$v.reset();
      this.is_data.ms_tindakan_id = "";
      this.is_data.tarif = 0;
      this.is_data.total_tarif = 0;
      this.is_data.keterangan = "";
      this.is_data.lama_tindakan_jam = 0;
      this.is_data.lama_tindakan_menit = 0;
      // this.is_data.hasil_tindakan = "";
      this.is_data.jumlah_tindakan = 0;
      this.is_data.obj_tindakan = null;
      // this.totalTarif = 0;
    },
    triggerAlert(event) {
      let vm = this;
      vm.$store.commit("set_alert", event);
    },
  },
};
</script>

<style scoped>
.judul-tindakan {
  padding-left: 0;
  color: #d52f65;
  font-size: 16px;
  font-weight: 700;
}
</style>
