<template>
  <b-card class="card">
    <b-row style="margin: 0px -8px;">
      <b-col cols="12">
        <h5 class="custom-title-color-black"><strong>Anamnesa</strong></h5>
      </b-col>
      <b-col cols="6">
        <label for="ms_dokter_id">Dokter <span class="text-danger">*</span></label>
        <Multiselect
          id="ms_dokter_id"
          v-model="$v.dataForm.ms_dokter_id.$model"
          :options="listDokter"
          :multiple="false"
          :searchable="true"
          :close-on-select="true"
          :show-labels="false"
          label="nama_tenaga_medis"
          track-by="tenaga_medis_id"
          placeholder="-- Pilih Dokter --"
          size="sm"
        ></Multiselect>
      </b-col>
      <b-col cols="6">  
        <label for="ms_perawat_id">Perawat <span class="text-danger">*</span></label>
        <Multiselect
          id="ms_perawat_id"
          v-model="$v.dataForm.ms_perawat_id.$model"
          :options="listPerawat"
          :multiple="false"
          :searchable="true"
          :close-on-select="true"
          :show-labels="false"
          label="nama_tenaga_medis"
          track-by="tenaga_medis_id"
          placeholder="-- Pilih Perawat --"
          size="sm"
        ></Multiselect>
      </b-col>
      <b-col cols="6">
        <label for="keluhan_utama">Keluhan Utama <span class="text-danger">*</span></label>
        <b-form-textarea
          id="keluhan_utama"
          type="text"
          :state="checkIfValid('keluhan_utama')"
          v-model="$v.dataForm.keluhan_utama.$model"
        ></b-form-textarea>
      </b-col>
      <b-col cols="6">
        <label for="keluhan_tambahan">Keluhan Tambahan</label>
        <b-form-textarea
          id="keluhan_tambahan"
          type="text"
          :state="checkIfValid('keluhan_tambahan')"
          v-model="$v.dataForm.keluhan_tambahan.$model"
        ></b-form-textarea>
      </b-col>
      <b-col cols="12">
        <label>Lama Sakit <span class="text-danger">*</span></label>
        <b-row style="padding:0 8px;">
          <b-col cols="4">
            <b-input-group class="w-100">
              <b-form-input
                id="lama_sakit_tahun"
                type="number"
                :state="checkIfValid('lama_sakit_tahun')"
                v-model="$v.dataForm.lama_sakit_tahun.$model"
              ></b-form-input>
              <b-input-group-append>
                <b-button>Tahun</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-col>

          <b-col cols="4">
            <b-input-group class="w-100">
              <b-form-input
                id="lama_sakit_bulan"
                type="number"
                :state="checkIfValid('lama_sakit_bulan')"
                v-model="$v.dataForm.lama_sakit_bulan.$model"
              ></b-form-input>
              <b-input-group-append>
                <b-button>Bulan</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-col>

          <b-col cols="4">
            <b-input-group class="w-100">
              <b-form-input
                id="lama_sakit_hari"
                type="number"
                :state="checkIfValid('lama_sakit_hari')"
                v-model="$v.dataForm.lama_sakit_hari.$model"
              ></b-form-input>
              <b-input-group-append>
                <b-button>Hari</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-col>
        </b-row>
      </b-col>

      <b-col cols="12" class="mt-3">
        <h5 class="custom-title-color-black"><strong>Riwayat Penyakit</strong></h5>
      </b-col>
      <b-col cols="4">
        <label for="rps">RPS</label>
        <b-form-textarea
          id="rps"
          type="text"
          :state="checkIfValid('rps')"
          v-model="$v.dataForm.rps.$model"
        ></b-form-textarea>
      </b-col>
      <b-col cols="4">
        <label for="rpd">RPD</label>
        <b-form-textarea
          id="rpd"
          type="text"
          :state="checkIfValid('rpd')"
          v-model="$v.dataForm.rpd.$model"
        ></b-form-textarea>
      </b-col>
      <b-col cols="4">
        <label for="rpk">RPK</label>
        <b-form-textarea
          id="rpk"
          type="text"
          :state="checkIfValid('rpk')"
          v-model="$v.dataForm.rpk.$model"
        ></b-form-textarea>
      </b-col>

      <!-- <b-col cols="12" class="mt-3">
        <h5><strong>Alergi Pasien</strong></h5>
      </b-col>
      <b-col cols="4">
        <label for="rps">RPS</label>
        <b-form-textarea
          id="rps"
          type="text"
          :state="checkIfValid('rps')"
          v-model="$v.dataForm.rps.$model"
        ></b-form-textarea>
      </b-col>
      <b-col cols="4">
        <label for="rpd">RPD</label>
        <b-form-textarea
          id="rpd"
          type="text"
          :state="checkIfValid('rpd')"
          v-model="$v.dataForm.rpd.$model"
        ></b-form-textarea>
      </b-col>
      <b-col cols="4">
        <label for="rpk">RPK</label>
        <b-form-textarea
          id="rpk"
          type="text"
          :state="checkIfValid('rpk')"
          v-model="$v.dataForm.rpk.$model"
        ></b-form-textarea>
      </b-col> -->
      
      <b-col cols="12" class="mt-3">
        <h5 class="custom-title-color-black"><strong>Periksa Fisik</strong></h5>
      </b-col>
      <b-col cols="4">
        <label for="ms_kesadaran_id">Kesadaran <span class="text-danger">*</span></label>
        <Multiselect
          id="ms_kesadaran_id"
          v-model="$v.dataForm.ms_kesadaran_id.$model"
          :options="listKesadaran"
          :multiple="false"
          :searchable="true"
          :close-on-select="true"
          :show-labels="false"
          label="nama_sadar"
          track-by="ms_kesadaran_id"
          placeholder="-- Pilih Kesadaran --"
          size="sm"
        ></Multiselect>
      </b-col>
      <b-col cols="4">
        <label for="sistole">Sistole <span class="text-danger">*</span></label>
        <b-input-group class="w-100">
          <b-form-input
            id="sistole"
            type="number"
            :state="checkIfValid('sistole')"
            v-model="$v.dataForm.sistole.$model"
          ></b-form-input>
          <b-input-group-append>
            <b-button>mm</b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
      <b-col cols="4">
        <label for="diastole">Diastole <span class="text-danger">*</span></label>
        <b-input-group class="w-100">
          <b-form-input
            id="diastole"
            type="number"
            :state="checkIfValid('diastole')"
            v-model="$v.dataForm.diastole.$model"
          ></b-form-input>
          <b-input-group-append>
            <b-button>hg</b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
      <b-col cols="4">
        <label for="berat_badan">Berat Badan <span class="text-danger">*</span></label>
        <b-input-group class="w-100">
          <b-form-input
            id="berat_badan"
            type="number"
            :state="checkIfValid('berat_badan')"
            v-model="$v.dataForm.berat_badan.$model"
            @keyup="countImt()"
          ></b-form-input>
          <b-input-group-append>
            <b-button>kg</b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
      <b-col cols="4">
        <label for="tinggi_badan">Tinggi Badan <span class="text-danger">*</span></label>
        <b-input-group class="w-100">
          <b-form-input
            id="tinggi_badan"
            type="number"
            :state="checkIfValid('tinggi_badan')"
            v-model="$v.dataForm.tinggi_badan.$model"
            @keyup="countImt()"
          ></b-form-input>
          <b-input-group-append>
            <b-button>cm</b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
      <b-col cols="4">
        <label for="lingkar_perut">Lingkar Perut <span class="text-danger">*</span></label>
        <b-input-group class="w-100">
          <b-form-input
            id="lingkar_perut"
            type="number"
            :state="checkIfValid('lingkar_perut')"
            v-model="$v.dataForm.lingkar_perut.$model"
          ></b-form-input>
          <b-input-group-append>
            <b-button>cm</b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
      <b-col cols="6">
        <label for="imt">IMT <span class="text-danger">*</span></label>
        <b-input-group class="w-100">
          <b-form-input
            id="imt"
            type="number"
            :state="checkIfValid('imt')"
            v-model="$v.dataForm.imt.$model"
            disabled
          ></b-form-input>
          <b-input-group-append>
            <b-button>Kg/m<sup>2</sup></b-button>
          </b-input-group-append>
        </b-input-group>
        <small>berat badan (kg) / tinggi badan(m<sup>2</sup>)</small>
      </b-col>
      <b-col cols="6">
        <label for="hasil_imt">Hasil IMT <span class="text-danger">*</span></label>
        <b-input-group class="w-100">
          <b-form-input
            id="hasil_imt"
            type="text"
            :state="checkIfValid('hasil_imt')"
            v-model="$v.dataForm.hasil_imt.$model"
            disabled
          ></b-form-input>
        </b-input-group>
      </b-col>
      <b-col cols="6">
        <label for="resp_rate">Resp Rate <span class="text-danger">*</span></label>
        <b-input-group class="w-100">
          <b-form-input
            id="resp_rate"
            type="number"
            :state="checkIfValid('resp_rate')"
            v-model="$v.dataForm.resp_rate.$model"
          ></b-form-input>
          <b-input-group-append>
            <b-button>/menit</b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
      <b-col cols="6">
        <label for="heart_rate">Heart Rate <span class="text-danger">*</span></label>
        <b-input-group class="w-100">
          <b-form-input
            id="heart_rate"
            type="number"
            :state="checkIfValid('heart_rate')"
            v-model="$v.dataForm.heart_rate.$model"
          ></b-form-input>
          <b-input-group-append>
            <b-button>/menit</b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
      <!-- <b-col cols="4">
        <label for="detak_nadi">Detak nadi <span class="text-danger">*</span></label>
        <b-input-group class="w-100">
          <b-form-input
            id="detak_nadi"
            type="number"
            :state="checkIfValid('detak_nadi')"
            v-model="$v.dataForm.detak_nadi.$model"
          ></b-form-input>
          <b-input-group-append>
            <b-button>/menit</b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col> -->
      <!-- <b-col cols="6">
        <label for="nafas">Nafas <span class="text-danger">*</span></label>
        <b-input-group class="w-100">
          <b-form-input
            id="nafas"
            type="number"
            :state="checkIfValid('nafas')"
            v-model="$v.dataForm.nafas.$model"
          ></b-form-input>
          <b-input-group-append>
            <b-button>/menit</b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col> -->
      <b-col cols="6">
        <label for="saturasi">Saturasi (Sp02) <span class="text-danger">*</span></label>
        <b-input-group class="w-100">
          <b-form-input
            id="saturasi"
            type="number"
            :state="checkIfValid('saturasi')"
            v-model="$v.dataForm.saturasi.$model"
          ></b-form-input>
          <b-input-group-append>
            <b-button>%</b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
      <b-col cols="6">
        <label for="suhu">Suhu <span class="text-danger">*</span></label>
        <b-input-group class="w-100">
          <b-form-input
            id="suhu"
            type="number"
            :state="checkIfValid('suhu')"
            v-model="$v.dataForm.suhu.$model"
          ></b-form-input>
          <b-input-group-append>
            <b-button>°C</b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
      <b-col cols="12">
        <label for="aktifitas_fisik">Aktifitas fisik</label>
        <b-form-textarea
          id="aktifitas_fisik"
          type="text"
          :state="checkIfValid('aktifitas_fisik')"
          v-model="$v.dataForm.aktifitas_fisik.$model"
        ></b-form-textarea>
      </b-col>
      <b-col cols="12">
        <label for="detak_jantung">Detak Jantung <span class="text-danger">*</span></label>
        <b-form-group label="" v-slot="{ ariaDescribedby }">
          <b-form-radio-group
            id="detak_jantung"
            v-model="dataForm.detak_jantung"
            :aria-describedby="ariaDescribedby"
            name="detak_jantung"
          >
            <b-form-radio value="regular">
              <span class="m-0 py-1 px-2 rounded text-white bg-success">REGULAR</span>
            </b-form-radio>
            <b-form-radio value="iregular">
              <span class="m-0 py-1 px-2 rounded text-white bg-warning">IREGULAR</span>
            </b-form-radio>
          </b-form-radio-group>
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <label for="triage">Triage <span class="text-danger">*</span></label>
        <b-form-group label="" v-slot="{ ariaDescribedby }">
          <b-form-radio-group
            id="triage"
            v-model="dataForm.triage"
            :aria-describedby="ariaDescribedby"
            name="triage"
          >
            <b-form-radio value="Gawat Darurat">
              <span class="m-0 py-1 px-2 rounded text-white bg-danger">Gawat Darurat</span>
            </b-form-radio>
            <b-form-radio value="darurat">
              <span class="m-0 py-1 px-2 rounded text-white bg-warning">Darurat</span>
            </b-form-radio>
            <b-form-radio value="tidak gawat darurat">
              <span class="m-0 py-1 px-2 rounded text-white bg-success">Tidak Gawat Darurat</span>
            </b-form-radio>
            <b-form-radio value="meninggal">
              <span class="m-0 py-1 px-2 rounded text-white bg-dark">Meninggal</span>
            </b-form-radio>
          </b-form-radio-group>
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <label for="skala_nyeri">Skala nyeri <span class="text-danger">*</span></label>
        <b-img class="pl-1" src="/img/ScalePain.png" fluid alt="Responsive image"></b-img>
        <b-form-input 
          id="skala_nyeri" 
          class="pr-2" 
          v-model="dataForm.skala_nyeri_angka" 
          type="range" 
          min="0" 
          max="10"
          @change="dataForm.skala_nyeri = $findKey(listSkalaNyeri, dataForm.skala_nyeri_angka, false, 'value', 'text')"
        ></b-form-input>
        <b-button 
          class="w-100" 
          :variant="$findKey(listSkalaNyeri, dataForm.skala_nyeri_angka, false, 'value', 'color')"
        >{{dataForm.skala_nyeri}}</b-button>
      </b-col>

      <!-- <b-col cols="12"><hr class="m-0 p-0"></b-col> -->
      <b-col cols="12" class="mt-3">
        <h5 class="custom-title-color-black"><strong>Lainnya</strong></h5>
      </b-col>
      <b-col cols="12">
        <label for="rencana_tindakan">Rencana tindakan</label>
        <b-form-textarea
          id="rencana_tindakan"
          type="text"
          :state="checkIfValid('rencana_tindakan')"
          v-model="$v.dataForm.rencana_tindakan.$model"
        ></b-form-textarea>
      </b-col>
      <b-col cols="6">
        <label for="terapi">Terapi</label>
        <b-form-textarea
          id="terapi"
          type="text"
          :state="checkIfValid('terapi')"
          v-model="$v.dataForm.terapi.$model"
        ></b-form-textarea>
      </b-col>
      <b-col cols="6">
        <label for="edukasi">Edukasi</label>
        <b-form-textarea
          id="edukasi"
          type="text"
          :state="checkIfValid('edukasi')"
          v-model="$v.dataForm.edukasi.$model"
        ></b-form-textarea>
      </b-col>
      <b-col cols="6">
        <label for="observasi">Observasi</label>
        <b-form-textarea
          id="observasi"
          type="text"
          :state="checkIfValid('observasi')"
          v-model="$v.dataForm.observasi.$model"
        ></b-form-textarea>
      </b-col>
      <b-col cols="6">
        <label for="keterangan_pool_anamnesa">Keterangan lainnya</label>
        <b-form-textarea
          id="keterangan_pool_anamnesa"
          type="text"
          :state="checkIfValid('keterangan_pool_anamnesa')"
          v-model="$v.dataForm.keterangan_pool_anamnesa.$model"
        ></b-form-textarea>
      </b-col>
      <b-col cols="6">
        <label for="biopsikososial">Biopsikososial</label>
        <b-form-textarea
          id="biopsikososial"
          type="text"
          :state="checkIfValid('biopsikososial')"
          v-model="$v.dataForm.biopsikososial.$model"
        ></b-form-textarea>
      </b-col>
      <b-col cols="6">
        <label for="tindakan_keperawatan">Tindakan perawatan</label>
        <b-form-textarea
          id="tindakan_keperawatan"
          type="text"
          :state="checkIfValid('tindakan_keperawatan')"
          v-model="$v.dataForm.tindakan_keperawatan.$model"
        ></b-form-textarea>
      </b-col>
      <b-col cols="4">
        <label for="merokok">Merokok <span class="text-danger">*</span></label>
        <b-form-group label="" v-slot="{ ariaDescribedby }">
          <b-form-radio-group
            id="merokok"
            v-model="dataForm.merokok"
            :aria-describedby="ariaDescribedby"
            name="merokok"
          >
            <b-form-radio :value="false">
              <span class="m-0 py-1 px-2 rounded text-dark bg-light">Tidak</span>
            </b-form-radio>
            <b-form-radio :value="true">
              <span class="m-0 py-1 px-2 rounded text-white bg-dark">Ya</span>
            </b-form-radio>
          </b-form-radio-group>
        </b-form-group>
      </b-col>
      <b-col cols="4">
        <label for="konsumsi_alkohol">Konsumsi alkohol <span class="text-danger">*</span></label>
        <b-form-group label="" v-slot="{ ariaDescribedby }">
          <b-form-radio-group
            id="konsumsi_alkohol"
            v-model="dataForm.konsumsi_alkohol"
            :aria-describedby="ariaDescribedby"
            name="konsumsi_alkohol"
          >
            <b-form-radio :value="false">
              <span class="m-0 py-1 px-2 rounded text-dark bg-light">Tidak</span>
            </b-form-radio>
            <b-form-radio :value="true">
              <span class="m-0 py-1 px-2 rounded text-white bg-dark">Ya</span>
            </b-form-radio>
          </b-form-radio-group>
        </b-form-group>
      </b-col>
      <b-col cols="4">
        <label for="kurang_sayur_buah">Kurang sayur buah <span class="text-danger">*</span></label>
        <b-form-group label="" v-slot="{ ariaDescribedby }">
          <b-form-radio-group
            id="kurang_sayur_buah"
            v-model="dataForm.kurang_sayur_buah"
            :aria-describedby="ariaDescribedby"
            name="kurang_sayur_buah"
          >
            <b-form-radio :value="false">
              <span class="m-0 py-1 px-2 rounded text-dark bg-light">Tidak</span>
            </b-form-radio>
            <b-form-radio :value="true">
              <span class="m-0 py-1 px-2 rounded text-white bg-dark">Ya</span>
            </b-form-radio>
          </b-form-radio-group>
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <label for="tipe_askep">Tipe Askep <span class="text-danger">*</span></label>
        <b-form-group label="" v-slot="{ ariaDescribedby }">
          <b-form-radio-group
            id="tipe_askep"
            v-model="dataForm.tipe_askep"
            :aria-describedby="ariaDescribedby"
            @change="dataForm.data_soap= [], dataForm.deskripsi_askep = null"
            name="tipe_askep"
          >
            <b-form-radio value="text">
              <span class="m-0 py-1 px-2 rounded text-white bg-success">Text</span>
            </b-form-radio>
            <b-form-radio value="soap">
              <span class="m-0 py-1 px-2 rounded text-white bg-primary">SOAP</span>
            </b-form-radio>
          </b-form-radio-group>
        </b-form-group>
      </b-col>
      <b-col cols="12" v-if="dataForm.tipe_askep == 'text'">
        <label for="deskripsi_askep">Deskripsi Askep <span class="text-danger">*</span></label>
        <b-form-textarea
          id="deskripsi_askep"
          type="text"
          :state="checkIfValid('deskripsi_askep')"
          v-model="$v.dataForm.deskripsi_askep.$model"
        ></b-form-textarea>
      </b-col>

      <b-col v-if="dataForm.tipe_askep == 'soap'" cols="12" class="mt-3">
        <h5 class="custom-title-color-black"><strong>SOAP</strong></h5>
        <div class="d-flex justify-content-end">
          <b-button
            class=""
            variant="primary"
            @click="$bvModal.show('modal-register-soap')"
            > Buat Master SOAP
          </b-button>
          <b-button
            class="ml-2"
            variant="success"
            @click="addSoap()"
            > <!-- <CIcon name="cil-plus" /> --> Tambah
          </b-button>
        </div>
      </b-col>
      <b-col v-if="dataForm.tipe_askep == 'soap'" cols="12">
        <b-row v-for="(x, i) of dataForm.data_soap" :key="i" style="padding:0 8px;">
          <b-col cols="12" class="mt-2">
            <div class="d-flex justify-content-between">
              <h6 class="m-0 mt-1 p-0">SOAP {{i+1}}</h6>
              <b-button
                class=""
                variant="danger"
                @click="deleteSoap(i)"
                size="sm"
                > <!-- <CIcon name="cil-trash" /> --> Hapus
              </b-button>
            </div>
          </b-col>
          <b-col v-if="dataForm.data_soap[i] && dataForm.data_soap[i].nama_assesment && dataForm.data_soap[i].data_assesment_soap && dataForm.data_soap[i].data_assesment_soap.subjective" cols="12">
            <label for="subjective">Subjective</label>
            <Multiselect
              id="subjective"
              v-model="dataForm.data_soap[i].data_assesment_soap.subjective"
              :options="dataForm.data_soap[i].list_subjective"
              :multiple="true"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              :taggable="true" 
              @tag="addTagSubjective($event, i)"
              placeholder="-- Pilih Subjective --"
            ></Multiselect>
          </b-col>
          <b-col v-if="dataForm.data_soap[i] && dataForm.data_soap[i].nama_assesment && dataForm.data_soap[i].data_assesment_soap && dataForm.data_soap[i].data_assesment_soap.objective" cols="12">
            <label for="objective">Objective</label>
            <Multiselect
              id="objective"
              v-model="dataForm.data_soap[i].data_assesment_soap.objective"
              :options="dataForm.data_soap[i].list_objective"
              :multiple="true"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              :taggable="true" 
              @tag="addTagObjective($event, i)"
              @remove="test"
              placeholder="-- Pilih Objective --"
            ></Multiselect>
          </b-col>
          <b-col cols="12">
            <div class="d-flex justify-content-between">
              <label for="data_soap" class="mt-2">
                Assessment <span class="text-danger">*</span>
              </label>
            </div>
            <Multiselect
              id="data_soap"
              v-model="dataForm.data_soap[i]"
              @search-change="getSOAP"
              :options="listSOAP"
              :multiple="false"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              :taggable="true" 
              @tag="addTagAssesment($event, i)"
              label="nama_assesment"
              track-by="assesment_id"
              placeholder="-- Pilih Assessment --"
              size="sm"
            ></Multiselect>
          </b-col>
          <b-col v-if="dataForm.data_soap[i] && dataForm.data_soap[i].nama_assesment && dataForm.data_soap[i].data_assesment_soap && dataForm.data_soap[i].data_assesment_soap.plan" cols="12">
            <label for="plan">Plaining</label>
            <Multiselect
              id="plan"
              v-model="dataForm.data_soap[i].data_assesment_soap.plan"
              :options="dataForm.data_soap[i].list_plan"
              :multiple="true"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              :taggable="true" 
              @tag="addTagPlaining($event, i)"
              placeholder="-- Pilih Plaining --"
            ></Multiselect>
          </b-col>
          <b-col><hr></b-col>
        </b-row>
      </b-col>

      <b-col cols="12">
        <h5 class="custom-title-color-black"><strong>Anatomi Tubuh</strong></h5>
        <div id="mapContainer" style="z-index: 1;"></div>
        <div>
          <b-table
            :items="listAnatomi"
            :fields="fieldsAnatomi"
            responsive
            show-empty
            small
            bordered
            striped
            hover
            class="table-anatomi-tubuh mt-3 mb-0"
          ></b-table>
        </div>
      </b-col>
<!-- {{dataForm.data_keadaan_fisik.pemeriksaan_kulit}} -->
      <b-col cols="12" class="mt-3">
        <h5 class="custom-title-color-black"><strong>Keadaan Fisik</strong></h5>
      </b-col>
      <b-col cols="6" v-if="dataForm.data_keadaan_fisik.pemeriksaan_kulit">
        <div class="">
          <div class="mt-2">
            <b-form-checkbox
              id="kulit"
              v-model="dataForm.data_keadaan_fisik.pemeriksaan_kulit.status"
              name="kulit"
              :value="true"
              :unchecked-value="false"
            >Pemeriksaan kulit</b-form-checkbox>
            <div v-if="dataForm.data_keadaan_fisik.pemeriksaan_kulit.status" class="ml-4">
              <label for="inspeksi" class="mt-1 mb-0 p-0">inspeksi</label>
              <b-form-textarea
                id="inspeksi"
                type="text"
                v-model="dataForm.data_keadaan_fisik.pemeriksaan_kulit.inspeksi"
              ></b-form-textarea>
              <label for="palpasi" class="mt-1 mb-0 p-0">palpasi</label>
              <b-form-textarea
                id="palpasi"
                type="text"
                v-model="dataForm.data_keadaan_fisik.pemeriksaan_kulit.palpasi"
              ></b-form-textarea>
            </div>
          </div>
          <div class="mt-2">
            <b-form-checkbox
              id="kuku"
              v-model="dataForm.data_keadaan_fisik.pemeriksaan_kuku.status"
              name="kuku"
              :value="true"
              :unchecked-value="false"
            >Pemeriksaan kuku</b-form-checkbox>
            <div v-if="dataForm.data_keadaan_fisik.pemeriksaan_kuku.status" class="ml-4">
              <label for="inspeksi" class="mt-1 mb-0 p-0">inspeksi</label>
              <b-form-textarea
                id="inspeksi"
                type="text"
                v-model="dataForm.data_keadaan_fisik.pemeriksaan_kuku.inspeksi"
              ></b-form-textarea>
              <label for="palpasi" class="mt-1 mb-0 p-0">palpasi</label>
              <b-form-textarea
                id="palpasi"
                type="text"
                v-model="dataForm.data_keadaan_fisik.pemeriksaan_kuku.palpasi"
              ></b-form-textarea>
            </div>
          </div>
          <div class="mt-2">
            <b-form-checkbox
              id="kepala"
              v-model="dataForm.data_keadaan_fisik.pemeriksaan_kepala.status"
              name="kepala"
              :value="true"
              :unchecked-value="false"
            >Pemeriksaan kepala</b-form-checkbox>
            <div v-if="dataForm.data_keadaan_fisik.pemeriksaan_kepala.status" class="ml-4">
              <label for="inspeksi" class="mt-1 mb-0 p-0">inspeksi</label>
              <b-form-textarea
                id="inspeksi"
                type="text"
                v-model="dataForm.data_keadaan_fisik.pemeriksaan_kepala.inspeksi"
              ></b-form-textarea>
              <label for="palpasi" class="mt-1 mb-0 p-0">palpasi</label>
              <b-form-textarea
                id="palpasi"
                type="text"
                v-model="dataForm.data_keadaan_fisik.pemeriksaan_kepala.palpasi"
              ></b-form-textarea>
            </div>
          </div>
          <div class="mt-2">
            <b-form-checkbox
              id="wajah"
              v-model="dataForm.data_keadaan_fisik.pemeriksaan_wajah.status"
              name="wajah"
              :value="true"
              :unchecked-value="false"
            >Pemeriksaan wajah</b-form-checkbox>
            <div v-if="dataForm.data_keadaan_fisik.pemeriksaan_wajah.status" class="ml-4">
              <label for="inspeksi" class="mt-1 mb-0 p-0">inspeksi</label>
              <b-form-textarea
                id="inspeksi"
                type="text"
                v-model="dataForm.data_keadaan_fisik.pemeriksaan_wajah.inspeksi"
              ></b-form-textarea>
              <label for="palpasi" class="mt-1 mb-0 p-0">palpasi</label>
              <b-form-textarea
                id="palpasi"
                type="text"
                v-model="dataForm.data_keadaan_fisik.pemeriksaan_wajah.palpasi"
              ></b-form-textarea>
            </div>
          </div>
          <div class="mt-2">
            <b-form-checkbox
              id="mata"
              v-model="dataForm.data_keadaan_fisik.pemeriksaan_mata.status"
              name="mata"
              :value="true"
              :unchecked-value="false"
            >Pemeriksaan mata</b-form-checkbox>
            <div v-if="dataForm.data_keadaan_fisik.pemeriksaan_mata.status" class="ml-4">
              <label for="inspeksi" class="mt-1 mb-0 p-0">inspeksi</label>
              <b-form-textarea
                id="inspeksi"
                type="text"
                v-model="dataForm.data_keadaan_fisik.pemeriksaan_mata.inspeksi"
              ></b-form-textarea>
            </div>
          </div>
          <div class="mt-2">
            <b-form-checkbox
              id="telinga"
              v-model="dataForm.data_keadaan_fisik.pemeriksaan_telinga.status"
              name="telinga"
              :value="true"
              :unchecked-value="false"
            >Pemeriksaan telinga</b-form-checkbox>
            <div v-if="dataForm.data_keadaan_fisik.pemeriksaan_telinga.status" class="ml-4">
              <label for="inspeksi" class="mt-1 mb-0 p-0">inspeksi</label>
              <b-form-textarea
                id="inspeksi"
                type="text"
                v-model="dataForm.data_keadaan_fisik.pemeriksaan_telinga.inspeksi"
              ></b-form-textarea>
              <label for="palpasi" class="mt-1 mb-0 p-0">palpasi</label>
              <b-form-textarea
                id="palpasi"
                type="text"
                v-model="dataForm.data_keadaan_fisik.pemeriksaan_telinga.palpasi"
              ></b-form-textarea>
            </div>
          </div>
          <div class="mt-2">
            <b-form-checkbox
              id="hidung_sinus"
              v-model="dataForm.data_keadaan_fisik.pemeriksaan_hidung_dan_sinus.status"
              name="hidung_sinus"
              :value="true"
              :unchecked-value="false"
            >Pemeriksaan hidung dan sinus</b-form-checkbox>
            <div v-if="dataForm.data_keadaan_fisik.pemeriksaan_hidung_dan_sinus.status" class="ml-4">
              <label for="inspeksi" class="mt-1 mb-0 p-0">inspeksi</label>
              <b-form-textarea
                id="inspeksi"
                type="text"
                v-model="dataForm.data_keadaan_fisik.pemeriksaan_hidung_dan_sinus.inspeksi"
              ></b-form-textarea>
              <label for="palpasi" class="mt-1 mb-0 p-0">palpasi</label>
              <b-form-textarea
                id="palpasi"
                type="text"
                v-model="dataForm.data_keadaan_fisik.pemeriksaan_hidung_dan_sinus.palpasi"
              ></b-form-textarea>
            </div>
          </div>
          <div class="mt-2">
            <b-form-checkbox
              id="mulut_bibir"
              v-model="dataForm.data_keadaan_fisik.pemeriksaan_mulut_dan_bibir.status"
              name="mulut_bibir"
              :value="true"
              :unchecked-value="false"
            >Pemeriksaan mulut dan bibir</b-form-checkbox>
            <div v-if="dataForm.data_keadaan_fisik.pemeriksaan_mulut_dan_bibir.status" class="ml-4">
              <label for="inspeksi" class="mt-1 mb-0 p-0">inspeksi</label>
              <b-form-textarea
                id="inspeksi"
                type="text"
                v-model="dataForm.data_keadaan_fisik.pemeriksaan_mulut_dan_bibir.inspeksi"
              ></b-form-textarea>
              <label for="palpasi" class="mt-1 mb-0 p-0">palpasi</label>
              <b-form-textarea
                id="palpasi"
                type="text"
                v-model="dataForm.data_keadaan_fisik.pemeriksaan_mulut_dan_bibir.palpasi"
              ></b-form-textarea>
            </div>
          </div>
        </div>
      </b-col>
      <b-col cols="6">
        <div class="">
          <div class="mt-2">
            <b-form-checkbox
              id="leher"
              v-model="dataForm.data_keadaan_fisik.pemeriksaan_leher.status"
              name="leher"
              :value="true"
              :unchecked-value="false"
            >Pemeriksaan leher</b-form-checkbox>
            <div v-if="dataForm.data_keadaan_fisik.pemeriksaan_leher.status" class="ml-4">
              <label for="inspeksi" class="mt-1 mb-0 p-0">inspeksi leher</label>
              <b-form-textarea
                id="inspeksi"
                type="text"
                v-model="dataForm.data_keadaan_fisik.pemeriksaan_leher.inspeksi"
              ></b-form-textarea>
              <label for="inspeksi_aukultasi" class="mt-1 mb-0 p-0">inspeksi dan auskultasi arteri karotis</label>
              <b-form-textarea
                id="inspeksi_aukultasi"
                type="text"
                v-model="dataForm.data_keadaan_fisik.pemeriksaan_leher.inspeksi_aukultasi"
              ></b-form-textarea>
              <label for="inspeksi_palpas" class="mt-1 mb-0 p-0">inspeksi dan palpasi kelenjer tiroid</label>
              <b-form-textarea
                id="inspeksi_palpas"
                type="text"
                v-model="dataForm.data_keadaan_fisik.pemeriksaan_leher.inspeksi_palpas"
              ></b-form-textarea>
              <label for="akultulasi" class="mt-1 mb-0 p-0">auskultasi (bising pembuluh darah)</label>
              <b-form-textarea
                id="akultulasi"
                type="text"
                v-model="dataForm.data_keadaan_fisik.pemeriksaan_leher.akultulasi"
              ></b-form-textarea>
            </div>
          </div>
          <div class="mt-2">
            <b-form-checkbox
              id="dada_punggung"
              v-model="dataForm.data_keadaan_fisik.pemeriksaan_dada_dan_punggung.status"
              name="dada_punggung"
              :value="true"
              :unchecked-value="false"
            >Pemeriksaan dada punggung</b-form-checkbox>
            <div v-if="dataForm.data_keadaan_fisik.pemeriksaan_dada_dan_punggung.status" class="ml-4">
              <label for="inspeksi" class="mt-1 mb-0 p-0">inspeksi</label>
              <b-form-textarea
                id="inspeksi"
                type="text"
                v-model="dataForm.data_keadaan_fisik.pemeriksaan_dada_dan_punggung.inspeksi"
              ></b-form-textarea>
              <label for="palpasi" class="mt-1 mb-0 p-0">palpasi</label>
              <b-form-textarea
                id="palpasi"
                type="text"
                v-model="dataForm.data_keadaan_fisik.pemeriksaan_dada_dan_punggung.palpasi"
              ></b-form-textarea>
              <label for="perkusi" class="mt-1 mb-0 p-0">perkusi</label>
              <b-form-textarea
                id="perkusi"
                type="text"
                v-model="dataForm.data_keadaan_fisik.pemeriksaan_dada_dan_punggung.perkusi"
              ></b-form-textarea>
              <label for="auskultasi" class="mt-1 mb-0 p-0">auskultasi</label>
              <b-form-textarea
                id="auskultasi"
                type="text"
                v-model="dataForm.data_keadaan_fisik.pemeriksaan_dada_dan_punggung.auskultasi"
              ></b-form-textarea>
            </div>
          </div>
          <div class="mt-2">
            <b-form-checkbox
              id="kardiovaskuler"
              v-model="dataForm.data_keadaan_fisik.pemeriksaan_kardiovaskuler.status"
              name="kardiovaskuler"
              :value="true"
              :unchecked-value="false"
            >Pemeriksaan kardiovaskuler</b-form-checkbox>
            <div v-if="dataForm.data_keadaan_fisik.pemeriksaan_kardiovaskuler.status" class="ml-4">
              <label for="inspeksi" class="mt-1 mb-0 p-0">inspeksi</label>
              <b-form-textarea
                id="inspeksi"
                type="text"
                v-model="dataForm.data_keadaan_fisik.pemeriksaan_kardiovaskuler.inspeksi"
              ></b-form-textarea>
              <label for="palpasi" class="mt-1 mb-0 p-0">palpasi</label>
              <b-form-textarea
                id="palpasi"
                type="text"
                v-model="dataForm.data_keadaan_fisik.pemeriksaan_kardiovaskuler.palpasi"
              ></b-form-textarea>
              <label for="perkusi" class="mt-1 mb-0 p-0">perkusi</label>
              <b-form-textarea
                id="perkusi"
                type="text"
                v-model="dataForm.data_keadaan_fisik.pemeriksaan_kardiovaskuler.perkusi"
              ></b-form-textarea>
              <label for="auskultasi" class="mt-1 mb-0 p-0">auskultasi</label>
              <b-form-textarea
                id="auskultasi"
                type="text"
                v-model="dataForm.data_keadaan_fisik.pemeriksaan_kardiovaskuler.auskultasi"
              ></b-form-textarea>
            </div>
          </div>
          <div class="mt-2">
            <b-form-checkbox
              id="dada_aksila"
              v-model="dataForm.data_keadaan_fisik.pemeriksaan_dada_dan_aksila.status"
              name="dada_aksila"
              :value="true"
              :unchecked-value="false"
            >Pemeriksaan dada aksila</b-form-checkbox>
            <div v-if="dataForm.data_keadaan_fisik.pemeriksaan_dada_dan_aksila.status" class="ml-4">
              <label for="inspeksi" class="mt-1 mb-0 p-0">inspeksi dada</label>
              <b-form-textarea
                id="inspeksi"
                type="text"
                v-model="dataForm.data_keadaan_fisik.pemeriksaan_dada_dan_aksila.inspeksi"
              ></b-form-textarea>
              <label for="palpasi" class="mt-1 mb-0 p-0">palpasi dada</label>
              <b-form-textarea
                id="palpasi"
                type="text"
                v-model="dataForm.data_keadaan_fisik.pemeriksaan_dada_dan_aksila.palpasi"
              ></b-form-textarea>
              <label for="inspeksi_palpasi" class="mt-1 mb-0 p-0">inspeksi dan palpasi aksila</label>
              <b-form-textarea
                id="inspeksi_palpasi"
                type="text"
                v-model="dataForm.data_keadaan_fisik.pemeriksaan_dada_dan_aksila.inspeksi_palpasi"
              ></b-form-textarea>
            </div>
          </div>
          <div class="mt-2">
            <b-form-checkbox
              id="abdomen_perut"
              v-model="dataForm.data_keadaan_fisik.pemeriksaan_abdomen_perut.status"
              name="abdomen_perut"
              :value="true"
              :unchecked-value="false"
            >Pemeriksaan abdomen perut</b-form-checkbox>
            <div v-if="dataForm.data_keadaan_fisik.pemeriksaan_abdomen_perut.status" class="ml-4">
              <label for="inspeksi" class="mt-1 mb-0 p-0">inspeksi</label>
              <b-form-textarea
                id="inspeksi"
                type="text"
                v-model="dataForm.data_keadaan_fisik.pemeriksaan_abdomen_perut.inspeksi"
              ></b-form-textarea>
              <label for="auskultasi" class="mt-1 mb-0 p-0">auskultasi</label>
              <b-form-textarea
                id="auskultasi"
                type="text"
                v-model="dataForm.data_keadaan_fisik.pemeriksaan_abdomen_perut.auskultasi"
              ></b-form-textarea>
              <label for="perkusi_semua_kuadran" class="mt-1 mb-0 p-0">perkusi semua kuadran</label>
              <b-form-textarea
                id="perkusi_semua_kuadran"
                type="text"
                v-model="dataForm.data_keadaan_fisik.pemeriksaan_abdomen_perut.perkusi_semua_kuadran"
              ></b-form-textarea>
              <label for="perkusi_hepar" class="mt-1 mb-0 p-0">perkusi hepar</label>
              <b-form-textarea
                id="perkusi_hepar"
                type="text"
                v-model="dataForm.data_keadaan_fisik.pemeriksaan_abdomen_perut.perkusi_hepar"
              ></b-form-textarea>
              <label for="perkusi_limfa" class="mt-1 mb-0 p-0">perkusi limfa</label>
              <b-form-textarea
                id="perkusi_limfa"
                type="text"
                v-model="dataForm.data_keadaan_fisik.pemeriksaan_abdomen_perut.perkusi_limfa"
              ></b-form-textarea>
              <label for="perkusi_ginjal" class="mt-1 mb-0 p-0">perkusi ginjal</label>
              <b-form-textarea
                id="perkusi_ginjal"
                type="text"
                v-model="dataForm.data_keadaan_fisik.pemeriksaan_abdomen_perut.perkusi_ginjal"
              ></b-form-textarea>
              <label for="palpasi_semua_kuadran" class="mt-1 mb-0 p-0">palpasi semua kuadran</label>
              <b-form-textarea
                id="palpasi_semua_kuadran"
                type="text"
                v-model="dataForm.data_keadaan_fisik.pemeriksaan_abdomen_perut.palpasi_semua_kuadran"
              ></b-form-textarea>
            </div>
          </div>
          <div class="mt-2">
            <b-form-checkbox
              id="ekstermitas_atas"
              v-model="dataForm.data_keadaan_fisik.pemeriksaan_ekstermitas_atas.status"
              name="ekstermitas_atas"
              :value="true"
              :unchecked-value="false"
            >Pemeriksaan ekstermitas atas</b-form-checkbox>
            <div v-if="dataForm.data_keadaan_fisik.pemeriksaan_ekstermitas_atas.status" class="ml-4">
              <label for="inspeksi" class="mt-1 mb-0 p-0">inspeksi struktur muskuloskletal</label>
              <b-form-textarea
                id="inspeksi"
                type="text"
                v-model="dataForm.data_keadaan_fisik.pemeriksaan_ekstermitas_atas.inspeksi"
              ></b-form-textarea>
              <label for="palpasi" class="mt-1 mb-0 p-0">palpasi</label>
              <b-form-textarea
                id="palpasi"
                type="text"
                v-model="dataForm.data_keadaan_fisik.pemeriksaan_ekstermitas_atas.palpasi"
              ></b-form-textarea>
              <label for="tes_reflex" class="mt-1 mb-0 p-0">tes reflex</label>
              <b-form-textarea
                id="tes_reflex"
                type="text"
                v-model="dataForm.data_keadaan_fisik.pemeriksaan_ekstermitas_atas.tes_reflex"
              ></b-form-textarea>
            </div>
          </div>
          <div class="mt-2">
            <b-form-checkbox
              id="ekstermitas_bawah"
              v-model="dataForm.data_keadaan_fisik.pemeriksaan_ekstermitas_bawah.status"
              name="ekstermitas_bawah"
              :value="true"
              :unchecked-value="false"
            >Pemeriksaan ekstermitas bawah</b-form-checkbox>
            <div v-if="dataForm.data_keadaan_fisik.pemeriksaan_ekstermitas_bawah.status" class="ml-4">
              <label for="inspeksi" class="mt-1 mb-0 p-0">inspeksi struktur muskuloskletal</label>
              <b-form-textarea
                id="inspeksi"
                type="text"
                v-model="dataForm.data_keadaan_fisik.pemeriksaan_ekstermitas_bawah.inspeksi"
              ></b-form-textarea>
              <label for="palpasi" class="mt-1 mb-0 p-0">palpasi</label>
              <b-form-textarea
                id="palpasi"
                type="text"
                v-model="dataForm.data_keadaan_fisik.pemeriksaan_ekstermitas_bawah.palpasi"
              ></b-form-textarea>
              <label for="tes_reflex" class="mt-1 mb-0 p-0">tes reflex</label>
              <b-form-textarea
                id="tes_reflex"
                type="text"
                v-model="dataForm.data_keadaan_fisik.pemeriksaan_ekstermitas_bawah.tes_reflex"
              ></b-form-textarea>
            </div>
          </div>
          <div class="mt-2" v-if="kunjungan.jenis_kelamin == 'L'">
            <b-form-checkbox
              id="genitalia_pria"
              v-model="dataForm.data_keadaan_fisik.pemeriksaan_genitalia_pria.status"
              name="genitalia_pria"
              :value="true"
              :unchecked-value="false"
            >Pemeriksaan genitalia pria</b-form-checkbox>
            <div v-if="dataForm.data_keadaan_fisik.pemeriksaan_genitalia_pria.status" class="ml-4">
              <label for="inspeksi" class="mt-1 mb-0 p-0">inspeksi dan palpasi penis</label>
              <b-form-textarea
                id="inspeksi"
                type="text"
                v-model="dataForm.data_keadaan_fisik.pemeriksaan_genitalia_pria.inspeksi"
              ></b-form-textarea>
              <label for="inspeksi_palpasi" class="mt-1 mb-0 p-0">inspeksi dan palpasi skrotum</label>
              <b-form-textarea
                id="inspeksi_palpasi"
                type="text"
                v-model="dataForm.data_keadaan_fisik.pemeriksaan_genitalia_pria.inspeksi_palpasi"
              ></b-form-textarea>
              <label for="pemeriksaan_anus_rectum" class="mt-1 mb-0 p-0">pemeriksaan anus dan rectum</label>
              <b-form-textarea
                id="pemeriksaan_anus_rectum"
                type="text"
                v-model="dataForm.data_keadaan_fisik.pemeriksaan_genitalia_pria.pemeriksaan_anus_rectum"
              ></b-form-textarea>
            </div>
          </div>
          <div class="mt-2" v-if="kunjungan.jenis_kelamin == 'P'">
            <b-form-checkbox
              id="genitalia_wanita"
              v-model="dataForm.data_keadaan_fisik.pemeriksaan_genitalia_wanita.status"
              name="genitalia_wanita"
              :value="true"
              :unchecked-value="false"
            >Pemeriksaan genitalia wanita</b-form-checkbox>
            <div v-if="dataForm.data_keadaan_fisik.pemeriksaan_genitalia_wanita.status" class="ml-4">
              <label for="inspeksi" class="mt-1 mb-0 p-0">inspeksi genitalia eksternal</label>
              <b-form-textarea
                id="inspeksi"
                type="text"
                v-model="dataForm.data_keadaan_fisik.pemeriksaan_genitalia_wanita.inspeksi"
              ></b-form-textarea>
              <label for="inspeksi_vagina_servik" class="mt-1 mb-0 p-0">inspeksi vagina dan servik</label>
              <b-form-textarea
                id="inspeksi_vagina_servik"
                type="text"
                v-model="dataForm.data_keadaan_fisik.pemeriksaan_genitalia_wanita.inspeksi_vagina_servik"
              ></b-form-textarea>
              <label for="inspeksi_vagina_uterus_ovarium" class="mt-1 mb-0 p-0">palpasi vagina, uterus, dan ovarium</label>
              <b-form-textarea
                id="inspeksi_vagina_uterus_ovarium"
                type="text"
                v-model="dataForm.data_keadaan_fisik.pemeriksaan_genitalia_wanita.inspeksi_vagina_uterus_ovarium"
              ></b-form-textarea>
              <label for="pemeriksaan_anus_rectum" class="mt-1 mb-0 p-0">pemeriksaan anus dan rectum</label>
              <b-form-textarea
                id="pemeriksaan_anus_rectum"
                type="text"
                v-model="dataForm.data_keadaan_fisik.pemeriksaan_genitalia_wanita.pemeriksaan_anus_rectum"
              ></b-form-textarea>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12">
        <hr/>
      </b-col>
    </b-row>
    <b-row align-h="end">
      <b-col cols="auto">
        <b-button variant="info" @click="printAnamnesa"> Cetak </b-button>
        <b-button
          v-if="!haveAnamnesa"
          class="ml-2"
          @click="refresh()"
          variant="danger"
          >Reset
        </b-button>
        <b-button
          v-if="haveAnamnesa"
          class="ml-2"
          @click="update()"
          variant="warning"
          :disabled="loading"
          >Update
        </b-button>
        <b-button
          v-else
          class="ml-2"
          @click="save()"
          variant="primary"
          :disabled="loading"
          >Simpan
        </b-button>
        <!-- <b-button
          class="ml-2"
          @click="test()"
          variant="primary"
          :disabled="loading"
          >test
        </b-button> -->
      </b-col>
    </b-row>
    <ModalRegisterSoap />

    <!-- PRINT -->
    <div style="display: none;" id="form_anamnesa">
      <section class="content-print A4" v-if="print_ready">
        <div class="sheet">
          <div class="w-100">
            <table border="0" class="custom-table">
              <tbody>
                <tr>
                  <td style="width: 15%;vertical-align: middle;padding-left: 1.5mm;padding-right: 1.5mm;"><img :src="logo" alt="" style="width: 100%;"></td>
                  <td style="width: 70%;vertical-align: middle;">
                    <h6 style="text-align: center;font-weight: bold;margin-bottom: 0;font-size: 16pt;">PEMERINTAH {{ $store.state.puskesmas.nama_kota }}</h6>
                    <h6 style="text-align: center;font-weight: bold;margin-bottom: 0;font-size: 16pt;">DINAS KESEHATAN</h6>
                    <h6 style="text-align: center;font-weight: bold;margin-bottom: 0;font-size: 16pt;">{{ $store.state.puskesmas.nama_puskesmas }}</h6>
                    <h6 style="text-align: center;font-weight: normal;font-size: 10pt;margin-bottom: 0;">{{ $store.state.puskesmas.alamat_puskesmas }}</h6>
                    <h6 style="text-align: center;font-weight: normal;font-size: 10pt;margin-bottom: 0;"><span>Email : emailpuskesmas@gmil.com</span> <span>Telp. : 0987654321</span></h6>
                  </td>
                  <td style="width: 15%;vertical-align: middle;padding-left: 1.5mm;padding-right: 1.5mm;"><img src="../../../cetak/puskesmas-logo.png" alt="" style="width: 100%;"></td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="w-100" style="margin-top: 2.5mm;">
            <div class="line"></div>
          </div>

          <div class="w-100" style="margin-top: 5mm;">
            <h6 style="font-size: 16pt;font-weight: bold;text-align: center;"><u>PENGKAJIAN AWAL</u></h6>
          </div>

          <div class="custom-border-paper" style="margin-top: 10mm;padding: 2.5mm;"> 
            <div class="w-100">
              <table class="custom-table">
                <tbody>
                  <tr>
                    <td style="width: 50mm;">Tanggal</td>
                    <td style="width: 5mm;">:</td>
                    <td>{{ $moment(data_print.tanggal).format("LL") }}</td>
                  </tr>

                  <tr>
                    <td>Poli Ruangan</td>
                    <td>:</td>
                    <td>{{ data_print.nama_poli }}</td>
                  </tr>

                  <tr>
                    <td>No. eRM</td>
                    <td>:</td>
                    <td>{{ data_print.no_rm }}</td>
                  </tr>
                  
                  <tr>
                    <td>NIK</td>
                    <td>:</td>
                    <td>{{ data_print.nik }}</td>
                  </tr>

                  <tr>
                    <td>Nama</td>
                    <td>:</td>
                    <td>{{ data_print.nama_lengkap }}</td>
                  </tr>

                  <tr>
                    <td>Jenis Kelamin</td>
                    <td>:</td>
                    <td>{{ data_print.jenis_kelamin == "P" ? "Perempuan" : "Laki-laki" }}</td>
                  </tr>

                  <tr>
                    <td>Tempat/Tanggal Lahir</td>
                    <td>:</td>
                    <td>{{ data_print.tempat_lahir }} / {{ $moment(data_print.tanggal_lahir).format("LL") }}</td>
                  </tr>

                  <tr>
                    <td>Umur</td>
                    <td>:</td>
                    <td>
                      <span v-if="data_print.usia_tahun">{{ data_print.usia_tahun || "-" }} Tahun</span>
                      <span v-if="data_print.usia_bulan">{{ data_print.usia_bulan || "-" }} Bulan</span>
                      <span v-if="data_print.usia_hari">{{ data_print.usia_hari || "-" }} Hari</span>
                    </td>
                  </tr>

                  <tr>
                    <td>Asuransi</td>
                    <td>:</td>
                    <td v-if="data_print.no_bpjs">BPJS - {{ data_print.no_bpjs }}</td>
                    <td v-else>-</td>
                  </tr>
                </tbody>
              </table>
            </div>
              
          </div>

          <div class="custom-border-paper" style="margin-top: 10mm;padding: 2.5mm;"> 
            <div class="w-100" style="margin-bottom: 5mm;">
                <h6 style="font-size: 16pt;font-weight: bold;text-align: left;">Anamnesa</h6>
            </div>
            <div class="w-100" style="page-break-inside: unset;">
              <table class="custom-table">
                <tbody>
                  <tr>
                    <td style="width: 50mm;">Dokter / Tenaga Medis</td>
                    <td style="width: 5mm;">:</td>
                    <td>{{ data_print.nama_dokter || "-" }}</td>
                  </tr>

                  <tr>
                    <td>Perawat / Bidan/ Nutrisionist / Sanitarian</td>
                    <td>:</td>
                    <td>{{ data_print.nama_perawat || "-" }}</td>
                  </tr>

                  <tr>
                    <td>Keluhan Utama</td>
                    <td>:</td>
                    <td>{{ data_print.keluhan_utama || "-" }}</td>
                  </tr>

                  <tr>
                    <td>Keluhan Tambahan</td>
                    <td>:</td>
                    <td>{{ data_print.keluhan_tambahan || "-" }}</td>
                  </tr>

                  <tr>
                    <td>Lama Sakit</td>
                    <td>:</td>
                    <td>
                      <span v-if="data_print.lama_sakit_tahun">{{ data_print.lama_sakit_tahun }} Tahun </span>
                      <span v-if="data_print.lama_sakit_bulan">{{ data_print.lama_sakit_bulan }} Bulan </span>
                      <span v-if="data_print.lama_sakit_hari">{{ data_print.lama_sakit_hari }} Hari </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="w-100" style="margin-top: 2.5mm;margin-bottom: 2.5mm;">
                <div class="line"></div>
            </div>
            <div class="w-100" style="page-break-inside: unset;">
              <h6 style="font-size: 16pt;font-weight: bold;text-align: left;">Periksa Fisik</h6>
              <table class="custom-table" style="margin-top: 5mm;">
                <tbody>
                  <tr>
                    <td style="width: 50mm;">Kesadaran</td>
                    <td style="width: 5mm;">:</td>
                    <td>{{ data_print.nama_sadar || "-" }}</td>
                  </tr>

                  <tr>
                    <td>Sistole</td>
                    <td>:</td>
                    <td>{{ data_print.sistole || "-" }}</td>
                  </tr>

                  <tr>
                    <td>Diastole</td>
                    <td>:</td>
                    <td>{{ data_print.diastole || "-" }}</td>
                  </tr>

                  <tr>
                    <td>MAP</td>
                    <td>:</td>
                    <td>-</td>
                  </tr>

                  <tr>
                    <td>Tinggi Badan</td>
                    <td>:</td>
                    <td>{{ data_print.tinggi_badan || "-" }}</td>
                  </tr>

                  <tr>
                    <td>Berat Badan</td>
                    <td>:</td>
                    <td>{{ data_print.berat_badan || "-" }}</td>
                  </tr>

                  <tr>
                    <td>IMT</td>
                    <td>:</td>
                    <td>{{ data_print.imt || "-" }}</td>
                  </tr>

                  <tr>
                    <td>Hasil IMT</td>
                    <td>:</td>
                    <td>{{ data_print.hasil_imt || "-" }}</td>
                  </tr>

                  <tr>
                    <td>Detak Nadi</td>
                    <td>:</td>
                    <td>{{ data_print.detak_nadi || "-" }}</td>
                  </tr>

                  <tr>
                    <td>Nafas</td>
                    <td>:</td>
                    <td>{{ data_print.nafas || "-" }}</td>
                  </tr>

                  <tr>
                    <td>Saturasi (Sp02)</td>
                    <td>:</td>
                    <td>{{ data_print.saturasi || "-" }}</td>
                  </tr>

                  <tr>
                    <td>Suhu</td>
                    <td>:</td>
                    <td>{{ data_print.suhu || "-" }}&deg; C</td>
                  </tr>
                  
                  <tr>
                    <td>Aktifitas Fisik dan Assessment Fungsional</td>
                    <td>:</td>
                    <td>{{ data_print.aktifitas_fisik || "-" }}</td>
                  </tr>

                  <tr>
                    <td>Detak Jantung</td>
                    <td>:</td>
                    <td v-if="data_print.detak_jantung">{{ data_print.detak_jantung.toUpperCase() || "-" }}</td>
                  </tr>
                  <tr>
                    <td>Triage</td>
                    <td>:</td>
                    <td v-if="data_print.triage">{{ data_print.triage.toUpperCase() || "-" }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- <div class="w-100" style="margin-top: 2.5mm;margin-bottom: 2.5mm;">
              <div class="line"></div>
            </div> -->
            <!-- <div class="w-100" style="page-break-inside: unset;">
              <h6 style="font-size: 16pt;font-weight: bold;text-align: left;">Assesmen Nyeri</h6>
              <table class="custom-table" style="margin-top: 5mm;">
                <tbody>
                  <tr>
                    <td style="width: 50mm;">Apakah pasien merasakan nyeri ?</td>
                    <td style="width: 5mm;">:</td>
                    <td>-</td>
                  </tr>

                  <tr>
                    <td>Pencetus</td>
                    <td>:</td>
                    <td>-</td>
                  </tr>

                  <tr>
                    <td>Kualitas</td>
                    <td>:</td>
                    <td>-</td>
                  </tr>

                  <tr>
                    <td>Lokasi</td>
                    <td>:</td>
                    <td>-</td>
                  </tr>

                  <tr>
                    <td>Skala Nyeri</td>
                    <td>:</td>
                    <td>-</td>
                  </tr>

                  <tr>
                    <td>Waktu</td>
                    <td>:</td>
                    <td>-</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="w-100" style="margin-top: 2.5mm;margin-bottom: 2.5mm;">
              <div class="line"></div>
            </div>
            <div class="w-100" style="page-break-inside: unset;">
              <h6 style="font-size: 16pt;font-weight: bold;text-align: left;">Assesmen Resiko Jatuh (Get Up and Go)</h6>
              <table class="custom-table" style="margin-top: 5mm;">
                <tbody>
                  <tr>
                    <td style="width: 100mm;">Perhatikan cara berjalan pasien saat akan duduk dikursi. Apakah pasien tampak tidak seimbang (sempoyongan/tumbang) ?</td>
                    <td style="width: 5mm;">:</td>
                    <td>-</td>
                  </tr>

                  <tr>
                    <td>Apakah pasien memegang pinggiran kursi atau meja benda lain sebagai penopang saat duduk ?</td>
                    <td>:</td>
                    <td>-</td>
                  </tr>
                </tbody>
              </table>

              <table class="custom-table" >
                <tbody>
                  <tr>
                    <td style="width: 50mm;font-weight: bold;">Hasil Kegiatan</td>
                    <td style="width: 5mm;">:</td>
                    <td>Tidak Beresiko</td>
                  </tr>
                </tbody>
              </table>
            </div> -->

            <!-- <div class="w-100" style="margin-top: 2.5mm;margin-bottom: 2.5mm;">
              <div class="line"></div>
            </div>
            <div class="w-100" style="page-break-inside: unset;">
              <h6 style="font-size: 16pt;font-weight: bold;text-align: left;">Riwayat Penyakit</h6>
              <table class="custom-table" style="margin-top: 5mm;">
                <tbody>
                  <tr>
                    <td style="width: 50mm;">Riwayat Penyakit Sekarang</td>
                    <td style="width: 5mm;">:</td>
                    <td>-</td>
                  </tr>

                  <tr>
                    <td>Riwayat Penyakit Dulu</td>
                    <td>:</td>
                    <td>-</td>
                  </tr>

                  <tr>
                    <td>Riwayat Penyakit Keluarga</td>
                    <td>:</td>
                    <td>-</td>
                  </tr>
                </tbody>
              </table>
            </div> -->

            <div class="w-100" style="margin-top: 2.5mm;margin-bottom: 2.5mm;">
                <div class="line"></div>
            </div>
            <div class="w-100" style="page-break-inside: unset;">
              <h6 style="font-size: 16pt;font-weight: bold;text-align: left;">Alergi Pasien</h6>
              <table class="custom-table" style="margin-top: 5mm;">
                <tbody>
                  <tr>
                    <td style="width: 50mm;">Obat</td>
                    <td style="width: 5mm;">:</td>
                    <td v-if="data_print.obat">
                      <span v-for="i in data_print.obat">{{ i }}</span>
                    </td>
                    <td v-else>-</td>
                  </tr>

                  <tr>
                    <td>Makanan</td>
                    <td>:</td>
                    <td v-if="data_print.makanan">
                      <span v-for="i in data_print.makanan">{{ i }}</span>
                    </td>
                    <td v-else>-</td>
                  </tr>

                  <tr>
                    <td>Umum</td>
                    <td>:</td>
                    <td v-if="data_print.umum">
                      <span v-for="i in data_print.umum">{{ i }}</span>
                    </td>
                    <td v-else>-</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="w-100" style="margin-top: 2.5mm;margin-bottom: 2.5mm;">
              <div class="line"></div>
            </div>
            <div class="w-100" style="page-break-inside: unset;">
              <h6 style="font-size: 16pt;font-weight: bold;text-align: left;">Lainnya</h6>
              <table class="custom-table" style="margin-top: 5mm;">
                <tbody>
                  <tr>
                    <td style="width: 50mm;">Edukasi</td>
                    <td style="width: 5mm;">:</td>
                    <td>{{ data_print.edukasi || "-" }}</td>
                  </tr>

                  <tr>
                    <td>Terapi</td>
                    <td>:</td>
                    <td>{{ data_print.terapi || "-" }}</td>
                  </tr>

                  <tr>
                    <td>Rencana</td>
                    <td>:</td>
                    <td>{{ data_print.rencana_tindakan || "-" }}</td>
                  </tr>

                  <tr>
                    <td>Askep</td>
                    <td>:</td>
                    <td>{{ data_print.tipe_askep || "-" }}</td>
                  </tr>

                  <tr>
                    <td>Observasi</td>
                    <td>:</td>
                    <td>{{ data_print.observasi || "-" }}</td>
                  </tr>

                  <tr>
                    <td>Keterangan</td>
                    <td>:</td>
                    <td>{{ data_print.keterangan_pool_anamnesa || "-" }}</td>
                  </tr>

                  <tr>
                    <td>Biopsikososial</td>
                    <td>:</td>
                    <td>{{ data_print.biopsikososial || "-" }}</td>
                  </tr>

                  <tr>
                    <td>Merokok</td>
                    <td>:</td>
                    <td>{{ data_print.merokok ? "Ya" : "Tidak" }}</td>
                  </tr>

                  <tr>
                    <td>Konsumsi Alkohol</td>
                    <td>:</td>
                    <td>{{ data_print.konsumsi_alkohol ? "Ya" : "Tidak" }}</td>
                  </tr>

                  <tr>
                    <td>Kurang Sayur / Buah </td>
                    <td>:</td>
                    <td>{{ data_print.kurang_sayur_buah ? "Ya" : "Tidak" }}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <!-- <div class="w-100" style="margin-top: 2.5mm;margin-bottom: 2.5mm;">
              <div class="line"></div>
            </div>
            <div class="w-100" style="page-break-inside: unset;">
              <h6 style="font-size: 16pt;font-weight: bold;text-align: left;">Pemeriksaan Dasar Gigi</h6>
              <div class="w-100-flex">
                <div style="width: 33.33%;">
                  <table class="custom-table" style="margin-top: 5mm;">
                    <tbody>
                      <tr>
                        <td style="width: 25mm;">Bengkak Atas</td>
                        <td style="width: 5mm;">:</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <td>Konsistensi</td>
                        <td>:</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <td>Warna Kulit</td>
                        <td>:</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <td>Suhu Kulit</td>
                        <td>:</td>
                        <td>-</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div style="width: 33.33%;">
                  <table class="custom-table" style="margin-top: 5mm;">
                    <tbody>
                      <tr>
                        <td style="width: 25mm;">Goyang</td>
                        <td style="width: 5mm;">:</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <td>Warna Gusi</td>
                        <td>:</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <td>Karies Gigi</td>
                        <td>:</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <td>Pembengkakan</td>
                        <td>:</td>
                        <td>-</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div style="width: 33.33%;">
                  <table class="custom-table" style="margin-top: 5mm;">
                    <tbody>
                      <tr>
                        <td style="width: 25mm;">Perkusi</td>
                        <td style="width: 5mm;">:</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <td>Druk</td>
                        <td>:</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <td>Palpasi</td>
                        <td>:</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <td>Status Karies Gigi</td>
                        <td>:</td>
                        <td>-</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div> -->
          </div>

          <div class="w-100" style="margin-top: 10mm;">
            <table class="custom-table">
              <tbody>
                <tr>
                  <td style="width: 60%;">&nbsp;</td>
                  <td style="width: 40%;">
                    <h6 style="text-align: center;">{{ $store.state.puskesmas.nama_kota }}, {{ $moment(data_print.tanggal).format("LL") }}</h6>
                    <h6 style="text-align: center;">Dokter/Tenaga Medis</h6>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <h6 style="text-align: center;">{{ data_print.nama_dokter }}</h6>
                    <!-- <h6 style="text-align: center;"><span>SIP : </span></h6> -->
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </div>
  </b-card>
</template>
<script>
import {nanoid} from 'nanoid'
import { validationMixin } from "vuelidate";
import { required, numeric, decimal, minValue } from "vuelidate/lib/validators";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import ModalRegisterSoap from "./modal_register_soap.vue"
import { forIn } from 'lodash';
export default {
  components: {
    ModalRegisterSoap,
  },
  props: [
    'kunjungan',
    'dataPendaftaran',
    'listKesadaran',
    'listDokter',
    'listPerawat',
  ],
  data() {
    return {
      haveAnamnesa: false,
      id_anamnesa:null,
      loading: false,
      bunder:null,
      bunder_bunder:null,
      map: null,
      text:null,
      listSOAP: [],
      listAnatomi: [],
      listSkalaNyeri:[
        {value: 0, text: 'Tidak Nyeri', color: 'success'},
        {value: 1, text: 'Ringan', color: 'warning'},
        {value: 2, text: 'Ringan', color: 'warning'},
        {value: 3, text: 'Ringan', color: 'warning'},
        {value: 4, text: 'Sedang', color: 'danger'},
        {value: 5, text: 'Sedang', color: 'danger'},
        {value: 6, text: 'Sedang', color: 'danger'},
        {value: 7, text: 'Berat', color: 'dark'},
        {value: 8, text: 'Berat', color: 'dark'},
        {value: 9, text: 'Berat', color: 'dark'},
        {value: 10, text: 'Berat', color: 'dark'},
      ],
      fieldsAnatomi: [
        {
          key: "bagian_tubuh",
          label: "Bagian Tubuh",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "keterangan",
          label: "Keterangan",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
      ],
      dataFormOriginal: {},
      dataForm: {
        geojsonAnatomi:null,
        data_soap: [],
        data_alergi: [],
        data_diagnosa: [],
        ms_dokter_id: null,
        ms_perawat_id: null,
        keluhan_utama: null,
        keluhan_tambahan: null,
        lama_sakit_tahun: 0,
        lama_sakit_bulan: 0,
        lama_sakit_hari: 0,
        rps: null,
        rpd: null,
        rpk: null,
        ms_kesadaran_id: null,
        sistole: null,
        diastole: null,
        tinggi_badan: null,
        berat_badan: null,
        resp_rate: null,
        heart_rate: null,
        lingkar_perut: null,
        imt: null,
        hasil_imt: null,
        // detak_nadi: null,
        // nafas: null,
        saturasi: 0,
        suhu: 0,
        aktifitas_fisik: null,
        detak_jantung: 'regular',
        triage: 'tidak gawat darurat',
        skala_nyeri_angka: 0,
        skala_nyeri: 'Tidak Nyeri',
        terapi: null,
        rencana_tindakan: null,
        tipe_askep: 'soap',
        deskripsi_askep: null,
        edukasi: null,
        observasi: null,
        keterangan_pool_anamnesa: null,
        biopsikososial: null,
        tindakan_keperawatan: null,
        merokok: false,
        konsumsi_alkohol: false,
        kurang_sayur_buah: false,
        data_keadaan_fisik: {
          pemeriksaan_kulit: {
            status: false,
            inspeksi: 'Normal : kulit tidak ada ikterik/pucat/sianosis',
            palpasi: 'Normal : lembab, turgor baik/elastic, tidak ada edema',
          },
          pemeriksaan_kuku: {
            status: false,
            inspeksi: 'Normal : bersih, bentuk normal tidak ada tanda-tanda jari tabuh (clubbing finger), tidak ikterik/sianosis',
            palpasi: 'Normal : aliran darah kuku akan kembali < 3 detik',
          },
          pemeriksaan_kepala: {
            status: false,
            inspeksi: 'Normal : simetris, bersih, tidak ada lesi, tidak menunjukkan tanda-tanda kekurangan gizi(rambut jagung dan kering)',
            palpasi: 'Normal : tidak ada penonjolan /pembengkakan, rambut lebat dan kuat/tidak rapuh',
          },
          pemeriksaan_wajah: {
            status: false,
            inspeksi: 'Normal : warna sama dengan bagian tubuh lain,  tidak pucat/ikterik, simetris',
            palpasi: 'Normal : tidak ada nyeri tekan dan edema',
          },
          pemeriksaan_mata: {
            status: false,
            inspeksi: 'Normal : simetris mata kika, simetris bola mata kika, warna konjungtiva pink, dan sclera berwarna putih',
          },
          pemeriksaan_telinga: {
            status: false,
            inspeksi: 'Normal : bentuk dan posisi simetris kika, integritas kulit bagus, warna sama dengan kulit lain, tidak ada tanda-tanda infeksi, dan alat bantu dengar',
            palpasi: 'Normal : tidak ada nyeri tekan',
          },
          pemeriksaan_hidung_dan_sinus: {
            status: false,
            inspeksi: 'Normal : simetris kika, warna sama dengan warna kulit lain, tidak ada lesi, tidak ada sumbatan, perdarahan dan tanda-tanda infeksi',
            palpasi: 'Normal : tidak ada bengkak dan nyeri tekan',
          },
          pemeriksaan_mulut_dan_bibir: {
            status: false,
            inspeksi: 'Normal : warna mukosa mulut dan bibir pink, lembab, tidak ada lesi dan stomatitis ',
            palpasi: 'Normal : gigi lengkap, tidak ada tanda-tanda gigi berlobang atau kerusakan gigi, tidak ada perdarahan atau radang gusi, lidah simetris, warna pink, langit2 utuh dan tidak ada tanda infeksi',
          },
          pemeriksaan_leher: {
            status: false,
            inspeksi: 'Normal : warna sama dengan kulit lain, integritas kulit baik, bentuk simetris, tidak ada pembesaran kelenjer gondok',
            inspeksi_aukultasi: 'Normal : arteri karotis terdengar',
            inspeksi_palpas: 'Normal : tidak teraba pembesaran kel.gondok, tidak ada nyeri, tidak ada  pembesaran kel.limfe, tidak ada nyeri',
            akultulasi: 'Normal',
          },
          pemeriksaan_dada_dan_punggung: {
            status: false,
            inspeksi: 'Normal : simetris, bentuk dan postur normal, tidak ada tanda-tanda distress pernapasan, warna kulit sama dengan warna kulit lain, tidak ikterik/sianosis, tidak ada pembengkakan/penonjolan/edema',
            palpasi: 'Normal : integritas kulit baik, tidak ada nyeri tekan/massa/tanda-tanda peradangan, ekspansi simetris, taktil vremitus cendrung sebelah kanan lebih teraba jelas',
            perkusi: 'Normal : resonan (“dug dug dug”), jika bagian padat lebih daripada bagian udara = pekak (“bleg bleg bleg”), jika bagian udara lebih besar dari bagian padat=hiperesonan (“deng deng deng”), batas jantung=bunyi rensonan----hilang>>redup',
            auskultasi: 'Normal : bunyi napas vesikuler, bronchovesikuler, brochial, tracheal ',
          },
          pemeriksaan_kardiovaskuler: {
            status: false,
            inspeksi: 'Normal : denyutan aorta teraba',
            palpasi: 'Normal : denyutan aorta teraba',
            perkusi: 'Normal : batas jantung, tidak lebih dari 4,7,10 cm ke arah kiri dari garis mid sterna, pada RIC 4,5,dan 8',
            auskultasi: 'Normal : terdengar bunyi jantung I/S1 (lub) dan bunyi jantung II/S2 (dub), tidak ada bunyi jantung tambahan (S3 atau S4)',
          },
          pemeriksaan_dada_dan_aksila: {
            status: false,
            inspeksi: 'Normal',
            palpasi: 'Normal',
            inspeksi_palpasi: 'Normal',
          },
          pemeriksaan_abdomen_perut: {
            status: false,
            inspeksi: 'Normal : simetris kika, warna dengan warna kulit lain, tidak ikterik tidak terdapat ostomy, distensi, tonjolan, pelebaran vena, kelainan umbilicus',
            auskultasi: 'Normal :  suara peristaltic terdengar setiap 5-20x/dtk, terdengar denyutan arteri renalis, arteri iliaka dan aorta',
            perkusi_semua_kuadran: 'Normal',
            perkusi_hepar: 'Normal',
            perkusi_limfa: 'Normal',
            perkusi_ginjal: 'Normal',
            palpasi_semua_kuadran: 'Normal : tidak teraba penonjolan tidak ada nyeri tekan, tidak ada massa dan penumpukan cairan',
          },
          pemeriksaan_ekstermitas_atas: {
            status: false,
            inspeksi: 'Normal : simetris kika, integritas kulit baik, ROM aktif, kekuatan otot penuh',
            palpasi: 'Normal : teraba jelas',
            tes_reflex: 'Normal : reflek bisep dan trisep positif',
          },
          pemeriksaan_ekstermitas_bawah: {
            status: false,
            inspeksi: 'Normal : simetris kika, integritas kulit baik, ROM aktif, kekuatan otot penuh',
            palpasi: 'Normal : teraba jelas',
            tes_reflex: 'Normal : reflex patella dan archiles positif',
              },
          pemeriksaan_genitalia_pria: {
            status: false,
            inspeksi: 'Normal : integritas kulit baik, tidak ada masa atau pembengkakan, tidak ada pengeluaran pus atau darah',
            inspeksi_palpasi: 'Normal ',
            pemeriksaan_anus_rectum: 'Normal :  tidak ada nyeri , tidak terdapat edema / hemoroid / polip/ tanda-tanda infeksi dan pendarahan',
          },
          pemeriksaan_genitalia_wanita: {
            status: false,
            inspeksi: 'Normal : bersih, mukosa lembab, integritas kulit baik, semetris tidak ada edema dan tanda-tanda infeksi (pengeluaran pus /bau)',
            inspeksi_vagina_servik: 'Normal ',
            inspeksi_vagina_uterus_ovarium: 'Normal ',
            pemeriksaan_anus_rectum: 'Normal : tidak ada nyeri, tidak terdapat edema / hemoroid / polip/ tanda-tanda infeksi dan pendarahan',
          },
        },
      },
      data_print: {},
      print_ready: false
    }
  },
  computed: {
    isValid() {
      return !this.$v.dataForm.$invalid;
    },
    isDirty() {
      return this.$v.dataForm.$anyDirty;
    },
    logo() {
      return this.$store.state.logo;
    },
  },
  mixins: [validationMixin],
  validations: {
    dataForm: {
      data_soap: {  },
      data_alergi: {  },
      data_diagnosa: {  },
      ms_dokter_id: { required },
      ms_perawat_id: { required },
      keluhan_utama: { required },
      keluhan_tambahan: {  },
      lama_sakit_tahun: { required, numeric, minValue(value){return minValue(0)(value)} },
      lama_sakit_bulan: { required, numeric, minValue(value){return minValue(0)(value)} },
      lama_sakit_hari: { required, numeric, minValue(value){return minValue(0)(value)} },
      //riwayat penyakit
      rps: {  },
      rpd: {  },
      rpk: {  },
      //fisik
      ms_kesadaran_id: { required },
      sistole: { required, numeric, minValue(value){return minValue(0)(value)} },
      diastole: { required, numeric, minValue(value){return minValue(0)(value)} },
      tinggi_badan: { required, decimal, minValue(value){return minValue(0)(value)} },
      berat_badan: { required, decimal, minValue(value){return minValue(0)(value)} },
      lingkar_perut: { required, decimal, minValue(value){return minValue(0)(value)} },
      imt: { required },
      hasil_imt: { required },
      resp_rate: { required },
      heart_rate: { required },
      // detak_nadi: { required },
      // nafas: { required },
      saturasi: { required },
      suhu: { required },
      aktifitas_fisik: {  },
      detak_jantung: { required },
      triage: { required },
      skala_nyeri_angka: { required },
      skala_nyeri: { required },
      //lainnya
      terapi: {  },
      rencana_tindakan: {  },
      deskripsi_askep: {  },
      observasi: {  },
      tindakan_keperawatan: {  },
      edukasi: {  },
      biopsikososial: {  },

      tipe_askep: { required },
      keterangan_pool_anamnesa: {  },
      merokok: { required },
      konsumsi_alkohol: { required },
      kurang_sayur_buah: { required },

      data_keadaan_fisik: { required },
    }
  },
  activated() {
    const vm = this
    vm.getData()
    vm.getSOAP()
    vm.getDetail()
  },
  mounted() {
    const vm = this
    vm.getData()
    vm.getSOAP()
    vm.getDetail()
  },
  watch: {
    text(newVal) {
      console.log(newVal);
    },
    "kunjungan.kunjungan_id"(newVal, oldVal){
      if(newVal != oldVal){
        this.getDetail()
        this.set_map()
      }
    },
  },
  beforeDestroy(){
    if(this.map){
      this.map.remove()
    }
  },
  methods: {
    test(){
      const vm = this
      // console.log('dataFormOriginal', vm.dataFormOriginal)
      // console.log('dataForm', vm.dataForm)
      const data = {}
      for (const key in vm.dataForm) {
        if(JSON.stringify(vm.dataForm[key]) != JSON.stringify(vm.dataFormOriginal[key])){
          data[key] = vm.dataForm[key]
          // console.log('vm.dataForm[key]', vm.dataForm[key])
          // console.log('vm.dataFormOriginal[key]', vm.dataFormOriginal[key])
        }
      }
      // console.log('data', data)
    },
    addSoap(){
      const vm = this
      // console.log('vm.dataForm.data_soap', vm.dataForm.data_soap)
      if(vm.dataForm.data_soap.length == 0 || vm.dataForm.data_soap[vm.dataForm.data_soap.length-1].nama_assesment != null){
        vm.dataForm.data_soap.push({
          nama_assesment: null,
          data_assesment_soap: {
            objective: [],
            subjective: [],
            plan: [],
          },
          list_objective: [],
          list_subjective: [],
          list_plan: [],
        })
      }
      // console.log('vm.dataForm.data_soap', vm.dataForm.data_soap)
    },
    countImt(){
      const vm = this
      let imt = (vm.dataForm.berat_badan / ((vm.dataForm.tinggi_badan/100) * (vm.dataForm.tinggi_badan/100))).toFixed(1)
      vm.dataForm.imt = imt
      if (imt < 18.5) vm.dataForm.hasil_imt = 'Berat badan kurang (Underweight)'
      else if (imt >= 18.5 && imt <= 22.9) vm.dataForm.hasil_imt = 'Berat normal'
      else if (imt >= 23 && imt <= 24.9) vm.dataForm.hasil_imt = 'Kelebihan berat badan (Overweight) dengan resiko'
      else if (imt >= 25 && imt <= 29.9) vm.dataForm.hasil_imt = 'Obesitas'
      else vm.dataForm.hasil_imt = 'Obesitas II'
    },
    async getData(){
      const vm = this
      vm.loading = true
      try {
        // //Kesadaran
        // let listKesadaran = await vm.$axios.post('/ms_kesadaran/list')
        // vm.listKesadaran = listKesadaran.data.status == 200 ? listKesadaran.data.data : []
      } catch (error) {
        vm.$store.commit("set_alert", { variant: "danger", msg: 'Terjadi Kesalahan System', showing: true });
        console.log(error)
      } finally {
        vm.loading = false
      }
    },
    async getDetail(){
      const vm = this
      vm.loading = true
      try {
        // await new Promise(resolve => setTimeout(resolve, 1000));
        vm.haveAnamnesa = false
        if(vm.kunjungan && vm.kunjungan.kunjungan_id){
          let res = await vm.$axios.post('/anamnesa/details_by_id', {kunjungan_id: vm.kunjungan.kunjungan_id})
          // console.log('anamnesa', res)
          if(res.data.status == 200 && res.data.data.length > 0){
            vm.haveAnamnesa = true
            const x = res.data.data[0]
            vm.$emit("sendAnamnesa", x)
            vm.dataForm = {
              ...vm.dataForm,
              ...x,
              data_soap: [],
              ms_dokter_id: vm.$findKey(vm.listDokter, x.ms_dokter_id, true, 'tenaga_medis_id'),
              ms_perawat_id: vm.$findKey(vm.listPerawat, x.ms_perawat_id, true, 'tenaga_medis_id'),
              ms_kesadaran_id: vm.$findKey(vm.listKesadaran, x.ms_kesadaran_id, true, 'ms_kesadaran_id'),
              data_keadaan_fisik: x.data_keadaan_fisik || vm.dataForm.data_keadaan_fisik
            }
            for (let i = 0; i < x.data_soap.length; i++) {
              const y = x.data_soap[i];
              if(y.assesment_id){
                let soap = await vm.$axios.get('/assesment/details_by_id/' + y.assesment_id)
                // console.log('soap', soap)
                if(soap.data.status == 200 && soap.data.data.length > 0){
                  y.list_subjective = soap.data.data[0].data_assesment.subjective
                  y.list_objective = soap.data.data[0].data_assesment.objective
                  y.list_plan = soap.data.data[0].data_assesment.plan
                }
              }
              // console.log('y.data_assesment_soap.subjective', y)
              if(y.list_subjective && y.list_subjective.length == 0) y.list_subjective = y.data_assesment_soap.subjective
              if(y.list_objective && y.list_objective.length == 0) y.list_objective = y.data_assesment_soap.objective
              if(y.list_plan && y.list_plan.length == 0) y.list_plan = y.data_assesment_soap.plan
              vm.dataForm.data_soap[i] = y
            }
            vm.dataForm.tipe_askep = null
            vm.dataForm.tipe_askep = x.tipe_askep
            vm.getDataAnatomi();
          }else{
            vm.refresh()
          }
          // console.log('dataPendaftaran', vm.dataPendaftaran)
          // console.log(vm.dataForm.ms_dokter_id)
          // console.log(vm.dataPendaftaran.ms_dokter_id)
          if(!vm.haveAnamnesa){
            if(!vm.dataForm.sistole) vm.dataForm.sistole = vm.dataPendaftaran.sistole
            if(!vm.dataForm.diastole) vm.dataForm.diastole = vm.dataPendaftaran.diastole
            if(!vm.dataForm.berat_badan) vm.dataForm.berat_badan = vm.dataPendaftaran.berat_badan
            if(!vm.dataForm.tinggi_badan) vm.dataForm.tinggi_badan = vm.dataPendaftaran.tinggi_badan
            if(!vm.dataForm.resp_rate) vm.dataForm.resp_rate = vm.dataPendaftaran.resp_rate
            if(!vm.dataForm.lingkar_perut) vm.dataForm.lingkar_perut = vm.dataPendaftaran.lingkar_perut
            if(!vm.dataForm.heart_rate) vm.dataForm.heart_rate = vm.dataPendaftaran.heart_rate
            if(!vm.dataForm.keluhan_utama) vm.dataForm.keluhan_utama = vm.dataPendaftaran.keluhan
            vm.countImt()
          }
        }
      } catch (error) {
        console.log(error)
      } finally {
        vm.dataFormOriginal = JSON.parse(JSON.stringify(vm.dataForm))
        vm.loading = false
      }
    },
    addTagAssesment(name, i){
      const vm = this
      // console.log('vm.dataForm.data_soap', vm.dataForm.data_soap)
      // console.log('name', name)
      // console.log('i', i)
      vm.listSOAP.push({
        nama_assesment: name,
        data_assesment_soap: {
          objective: [],
          subjective: [],
          plan: [],
        },
        list_objective: [],
        list_subjective: [],
        list_plan: [],
      })
      vm.dataForm.data_soap[i] = vm.listSOAP[vm.listSOAP.length-1]
      // console.log('vm.dataForm.data_soap', vm.dataForm.data_soap)
      // console.log(vm.listSOAP)
    },
    addTagSubjective(name, i){
      const vm = this
      // vm.dataForm.data_soap[i].list_subjective.push(name)
      vm.dataForm.data_soap[i].data_assesment_soap.subjective.push(name)
    },
    addTagObjective(name, i){
      const vm = this
      // vm.dataForm.data_soap[i].list_objective.push(name)
      vm.dataForm.data_soap[i].data_assesment_soap.objective.push(name)
    },
    addTagPlaining(name, i){
      const vm = this
      // vm.dataForm.data_soap[i].list_plan.push(name)
      vm.dataForm.data_soap[i].data_assesment_soap.plan.push(name)
    },
    deleteSoap(i){
      const vm = this
      vm.dataForm.data_soap.splice(i, 1)
    },
    async getSOAP(nama_assesment){
      const vm = this
      // console.log('nama_assesment', nama_assesment)
      try {
        if(nama_assesment){
          let listSOAP = await vm.$axios.post("/assesment/list", {nama_assesment});
          vm.listSOAP = listSOAP.data.status == 200 ? listSOAP.data.data.map(x => {
            return {
              ...x,
              data_assesment_soap: x.data_assesment,
              list_subjective: x.data_assesment.subjective,
              list_objective: x.data_assesment.objective,
              list_plan: x.data_assesment.plan,
            }
          }) : []
          // console.log('listSOAP', listSOAP)
        }else{
          vm.listSOAP = []
        }
      } catch (error) {
        console.log(error)
        vm.$store.commit("set_alert", { variant: "danger", msg: 'Terjadi Kesalahan System', showing: true });
      }
    },
    create_id_anamnesa(){
      let vm = this;
      if(!vm.dataForm.anamnesa_id) vm.dataForm.anamnesa_id = nanoid(14)
    },
    async save(){
      const vm = this
      vm.loading = true
      try {
        // await this.cekNoAntrian()
        await this.$v.dataForm.$touch();
        if (vm.isValid && vm.isDirty) {
          const x = vm.dataForm
          vm.create_id_anamnesa();
          // console.log('save', x)
          // if(!x.anamnesa_id) x.anamnesa_id = nanoid(14)
          x.data_keadaan_fisik.anamnesa_id = x.anamnesa_id
          x.kunjungan_id = vm.kunjungan.kunjungan_id
          for (let i = 0; i < x.data_soap.length; i++) {
            x.data_soap[i].anamnesa_id = x.anamnesa_id
          }
          // console.log('save', {
          //   ...x,
          //   ms_dokter_id: x.ms_dokter_id.tenaga_medis_id,
          //   ms_perawat_id: x.ms_perawat_id.tenaga_medis_id,
          //   ms_kesadaran_id: x.ms_kesadaran_id.ms_kesadaran_id,
          // })
          let res = await vm.$axios.post('/anamnesa/register_bulk', {
            ...x,
            ms_dokter_id: x.ms_dokter_id.tenaga_medis_id,
            ms_perawat_id: x.ms_perawat_id.tenaga_medis_id,
            ms_kesadaran_id: x.ms_kesadaran_id.ms_kesadaran_id,
          })
          // console.log('register anamnesa ', res)
          if(res.data.status == 200){
            vm.$emit("sendAnamnesa", x.anamnesa_id)
            vm.$store.commit("set_alert", { variant: "success", msg: "Berhasil Registrasi Anamnesa", showing: true });
            vm.getDetail()
            // vm.refresh()
          }else{
            vm.$store.commit("set_alert", { variant: "danger", msg: res.data.message, showing: true });
          }
        }else{
          vm.$store.commit("set_alert", { variant: "warning", msg: 'Data Tidak Valid', showing: true });
        }
      } catch (error) {
        vm.$store.commit("set_alert", { variant: "danger", msg: 'Terjadi Kesalahan System', showing: true });
        console.log(error)
      } finally {
        vm.loading = false
      }
    },
    async update(){
      const vm = this
      vm.loading = true
      try {
        await this.$v.dataForm.$touch();
        if (vm.isValid && vm.isDirty) {
          // const x = vm.dataForm
          const x = {}
          for (const key in vm.dataForm) {
            if(JSON.stringify(vm.dataForm[key]) != JSON.stringify(vm.dataFormOriginal[key])){
              x[key] = vm.dataForm[key]
            }
          }
          // x.id = x.anamnesa_id
          // x.data_keadaan_fisik.anamnesa_id = x.anamnesa_id
          // x.kunjungan_id = vm.kunjungan.kunjungan_id
          if(x.data_soap){
            for (let i = 0; i < x.data_soap.length; i++) {
              x.data_soap[i].anamnesa_id = x.anamnesa_id
            }
          }
          let res = await vm.$axios.post('/anamnesa/update', {
            ...x,
            id: vm.dataForm.anamnesa_id,
            anamnesa_id: vm.dataForm.anamnesa_id,
            ms_dokter_id: x.ms_dokter_id ? x.ms_dokter_id.tenaga_medis_id : undefined,
            ms_perawat_id: x.ms_perawat_id ? x.ms_perawat_id.tenaga_medis_id : undefined,
            ms_kesadaran_id: x.ms_kesadaran_id ? x.ms_kesadaran_id.ms_kesadaran_id : undefined,
          })
          // console.log('register anamnesa ', res)
          if(res.data.status == 200){
            vm.$store.commit("set_alert", { variant: "success", msg: "Berhasil Update Anamnesa", showing: true });
            vm.getDetail()
            // vm.refresh()
          }else{ 
            vm.$store.commit("set_alert", { variant: "danger", msg: res.data.message, showing: true });
          }
        }else{
          vm.$store.commit("set_alert", { variant: "warning", msg: 'Data Tidak Valid', showing: true });
        }
      } catch (error) {
        vm.$store.commit("set_alert", { variant: "danger", msg: 'Terjadi Kesalahan System', showing: true });
        console.log(error)
      } finally {
        vm.loading = false
      }
    },
    async refresh(){
      const vm = this
      vm.dataForm = {
        geojsonAnatomi:null,
        data_soap: [],
        data_alergi: [],
        data_diagnosa: [],
        ms_dokter_id: null,
        ms_perawat_id: null,
        keluhan_utama: null,
        keluhan_tambahan: null,
        lama_sakit_tahun: 0,
        lama_sakit_bulan: 0,
        lama_sakit_hari: 0,
        rps: null,
        rpd: null,
        rpk: null,
        ms_kesadaran_id: null,
        sistole: null,
        diastole: null,
        tinggi_badan: null,
        berat_badan: null,
        resp_rate: null,
        heart_rate: null,
        lingkar_perut: null,
        imt: null,
        hasil_imt: null,
        // detak_nadi: null,
        // nafas: null,
        saturasi: 0,
        suhu: 0,
        aktifitas_fisik: null,
        detak_jantung: 'regular',
        triage: 'tidak gawat darurat',
        skala_nyeri_angka: 0,
        skala_nyeri: 'Tidak Nyeri',
        terapi: null,
        rencana_tindakan: null,
        tipe_askep: 'soap',
        deskripsi_askep: null,
        edukasi: null,
        observasi: null,
        keterangan_pool_anamnesa: null,
        biopsikososial: null,
        tindakan_keperawatan: null,
        merokok: false,
        konsumsi_alkohol: false,
        kurang_sayur_buah: false,
        data_keadaan_fisik: {
          pemeriksaan_kulit: {
            status: false,
            inspeksi: 'Normal : kulit tidak ada ikterik/pucat/sianosis',
            palpasi: 'Normal : lembab, turgor baik/elastic, tidak ada edema',
          },
          pemeriksaan_kuku: {
            status: false,
            inspeksi: 'Normal : bersih, bentuk normal tidak ada tanda-tanda jari tabuh (clubbing finger), tidak ikterik/sianosis',
            palpasi: 'Normal : aliran darah kuku akan kembali < 3 detik',
          },
          pemeriksaan_kepala: {
            status: false,
            inspeksi: 'Normal : simetris, bersih, tidak ada lesi, tidak menunjukkan tanda-tanda kekurangan gizi(rambut jagung dan kering)',
            palpasi: 'Normal : tidak ada penonjolan /pembengkakan, rambut lebat dan kuat/tidak rapuh',
          },
          pemeriksaan_wajah: {
            status: false,
            inspeksi: 'Normal : warna sama dengan bagian tubuh lain,  tidak pucat/ikterik, simetris',
            palpasi: 'Normal : tidak ada nyeri tekan dan edema',
          },
          pemeriksaan_mata: {
            status: false,
            inspeksi: 'Normal : simetris mata kika, simetris bola mata kika, warna konjungtiva pink, dan sclera berwarna putih',
          },
          pemeriksaan_telinga: {
            status: false,
            inspeksi: 'Normal : bentuk dan posisi simetris kika, integritas kulit bagus, warna sama dengan kulit lain, tidak ada tanda-tanda infeksi, dan alat bantu dengar',
            palpasi: 'Normal : tidak ada nyeri tekan',
          },
          pemeriksaan_hidung_dan_sinus: {
            status: false,
            inspeksi: 'Normal : simetris kika, warna sama dengan warna kulit lain, tidak ada lesi, tidak ada sumbatan, perdarahan dan tanda-tanda infeksi',
            palpasi: 'Normal : tidak ada bengkak dan nyeri tekan',
          },
          pemeriksaan_mulut_dan_bibir: {
            status: false,
            inspeksi: 'Normal : warna mukosa mulut dan bibir pink, lembab, tidak ada lesi dan stomatitis ',
            palpasi: 'Normal : gigi lengkap, tidak ada tanda-tanda gigi berlobang atau kerusakan gigi, tidak ada perdarahan atau radang gusi, lidah simetris, warna pink, langit2 utuh dan tidak ada tanda infeksi',
          },
          pemeriksaan_leher: {
            status: false,
            inspeksi: 'Normal : warna sama dengan kulit lain, integritas kulit baik, bentuk simetris, tidak ada pembesaran kelenjer gondok',
            inspeksi_aukultasi: 'Normal : arteri karotis terdengar',
            inspeksi_palpas: 'Normal : tidak teraba pembesaran kel.gondok, tidak ada nyeri, tidak ada  pembesaran kel.limfe, tidak ada nyeri',
            akultulasi: 'Normal',
          },
          pemeriksaan_dada_dan_punggung: {
            status: false,
            inspeksi: 'Normal : simetris, bentuk dan postur normal, tidak ada tanda-tanda distress pernapasan, warna kulit sama dengan warna kulit lain, tidak ikterik/sianosis, tidak ada pembengkakan/penonjolan/edema',
            palpasi: 'Normal : integritas kulit baik, tidak ada nyeri tekan/massa/tanda-tanda peradangan, ekspansi simetris, taktil vremitus cendrung sebelah kanan lebih teraba jelas',
            perkusi: 'Normal : resonan (“dug dug dug”), jika bagian padat lebih daripada bagian udara = pekak (“bleg bleg bleg”), jika bagian udara lebih besar dari bagian padat=hiperesonan (“deng deng deng”), batas jantung=bunyi rensonan----hilang>>redup',
            auskultasi: 'Normal : bunyi napas vesikuler, bronchovesikuler, brochial, tracheal ',
          },
          pemeriksaan_kardiovaskuler: {
            status: false,
            inspeksi: 'Normal : denyutan aorta teraba',
            palpasi: 'Normal : denyutan aorta teraba',
            perkusi: 'Normal : batas jantung, tidak lebih dari 4,7,10 cm ke arah kiri dari garis mid sterna, pada RIC 4,5,dan 8',
            auskultasi: 'Normal : terdengar bunyi jantung I/S1 (lub) dan bunyi jantung II/S2 (dub), tidak ada bunyi jantung tambahan (S3 atau S4)',
          },
          pemeriksaan_dada_dan_aksila: {
            status: false,
            inspeksi: 'Normal',
            palpasi: 'Normal',
            inspeksi_palpasi: 'Normal',
          },
          pemeriksaan_abdomen_perut: {
            status: false,
            inspeksi: 'Normal : simetris kika, warna dengan warna kulit lain, tidak ikterik tidak terdapat ostomy, distensi, tonjolan, pelebaran vena, kelainan umbilicus',
            auskultasi: 'Normal :  suara peristaltic terdengar setiap 5-20x/dtk, terdengar denyutan arteri renalis, arteri iliaka dan aorta',
            perkusi_semua_kuadran: 'Normal',
            perkusi_hepar: 'Normal',
            perkusi_limfa: 'Normal',
            perkusi_ginjal: 'Normal',
            palpasi_semua_kuadran: 'Normal : tidak teraba penonjolan tidak ada nyeri tekan, tidak ada massa dan penumpukan cairan',
          },
          pemeriksaan_ekstermitas_atas: {
            status: false,
            inspeksi: 'Normal : simetris kika, integritas kulit baik, ROM aktif, kekuatan otot penuh',
            palpasi: 'Normal : teraba jelas',
            tes_reflex: 'Normal : reflek bisep dan trisep positif',
          },
          pemeriksaan_ekstermitas_bawah: {
            status: false,
            inspeksi: 'Normal : simetris kika, integritas kulit baik, ROM aktif, kekuatan otot penuh',
            palpasi: 'Normal : teraba jelas',
            tes_reflex: 'Normal : reflex patella dan archiles positif',
              },
          pemeriksaan_genitalia_pria: {
            status: false,
            inspeksi: 'Normal : integritas kulit baik, tidak ada masa atau pembengkakan, tidak ada pengeluaran pus atau darah',
            inspeksi_palpasi: 'Normal ',
            pemeriksaan_anus_rectum: 'Normal :  tidak ada nyeri , tidak terdapat edema /  hemoroid/ polip/ tanda-tanda infeksi dan pendarahan',
          },
          pemeriksaan_genitalia_wanita: {
            status: false,
            inspeksi: 'Normal : bersih, mukosa lembab, integritas kulit baik, semetris tidak ada edema dan tanda-tanda infeksi (pengeluaran pus /bau)',
            inspeksi_vagina_servik: 'Normal ',
            inspeksi_vagina_uterus_ovarium: 'Normal ',
            pemeriksaan_anus_rectum: 'Normal : tidak ada nyeri, tidak terdapat edema / hemoroid / polip/ tanda-tanda infeksi dan pendarahan',
          },
        }
      }
      vm.$v.$reset()
    },
    
    async set_map() {
        let vm = this;
        let ip = localStorage.getItem('url')
        if (vm.map) {
            vm.map.remove();
        }
 
        var customCRS = L.extend(L.CRS.Simple);
        vm.map = L.map("mapContainer", {
            crs: customCRS,
        }).setView([486.00825515690394, 350.49431991577148], 0);
      
        vm.map.options.minZoom = 0;
        vm.map.options.maxZoom = 2;
        // L.imageOverlay("https://serova.id/foto/denah.png", [
        // 	[0, 0],
        // 	[1000, 1000],
        // ]).addTo(map);
        if(vm.kunjungan.jenis_kelamin == "P"){
          L.imageOverlay(ip+ '/female_anatomi.svg', [
            [0, 0],
            [1000, 1000],
          ]).addTo(vm.map);
        }else if(vm.kunjungan.jenis_kelamin == "L"){
          L.imageOverlay(ip+ '/anatomi.svg', [
            [0, 0],
            [1000, 1000],
          ]).addTo(vm.map);
        }
        
        vm.bunder_bunder = L.layerGroup()
        vm.map.on('click',function (params) {
          // console.log(params);
          let popupContent =
          '<div id="depan">'+
             
         
              '<label class="control-label col-sm-5"><strong>Bagian Tubuh: </strong></label>'+
              '<input type="text" class="bagian_tubuh">'+ 
         
              '<label class="control-label col-sm-5"><strong>Keterangan: </strong></label>'+
              '<textarea class="keterangan" rows="6"></textarea>'+
     
          '<input style="display: none;" type="text" id="lat" name="lat" value="'+params.latlng.lat.toFixed(0)+'" />'+
          '<input style="display: none;" type="text" id="lng" name="lng" value="'+params.latlng.lng.toFixed(0)+'" />'+
          '<div class="form-group">'+
            '<div style="text-align:center;" class="col-xs-4 col-xs-offset-2"><button type="button" class="btn tombol_batal">Batal</button></div>'+
            '<div style="text-align:center;" class="col-xs-4"><button type="submit" value="submit" class="tombol btn btn-primary trigger-submit" onclick="()=>{console.log(this)}">Submit</button></div>'+
          '</div></div>';
          vm.bunder = L.circle(params.latlng, 5).addTo(vm.map).bindPopup(popupContent);

          vm.bunder_bunder.addLayer(vm.bunder);
          vm.bunder.openPopup();
     
          vm.popup_event(vm.bunder._leaflet_id);

          vm.bunder.on('click', function() {
          
          vm.popup_event(this._leaflet_id);
         })
        })

         vm.geojsonAnatomi = L.geoJSON().addTo(vm.map);
        //  this.getDataAnatomi()
    },

    close_popup(){
      
      this.map.eachLayer(function (layer) {
            layer.closePopup();
      });
    },
   
    popup_event(leaflet_id){
      let vm = this;
      let tombol = document.getElementsByClassName('tombol');
      
      for (var j = 0; j < tombol.length; j++) {
       
        tombol[j].addEventListener('click', function() {
          let form = this.parentNode.parentNode.parentNode;
          let datanya = {}
          for (var i = 0; i < form.childNodes.length; i++) {
            
            if (form.childNodes[i].className == "bagian_tubuh") {
              // console.log(form.childNodes[i].value);
              datanya.bagian_tubuh = form.childNodes[i].value
            } 
            
            if (form.childNodes[i].className == "keterangan") {
              // console.log(form.childNodes[i].value);
              datanya.keterangan = form.childNodes[i].value
            }
            
            if (form.childNodes[i].id == "lat") {
              // console.log(form.childNodes[i].value);
              datanya.lat = form.childNodes[i].value
            }

            if (form.childNodes[i].id == "lng") {
              // console.log(form.childNodes[i].value);
              datanya.lng = form.childNodes[i].value
            }
            
          }
          // console.log(datanya, 'ini ini ini ini');
          // console.log('ini jalan 1', vm.kunjungan)
          if(datanya.bagian_tubuh && datanya.keterangan){
            if(vm.dataForm.anamnesa_id == null){
              vm.create_id_anamnesa();
            }
            vm.$axios.post("/anatomi_tubuh/register", {
              bagian_tubuh : datanya.bagian_tubuh,
              keterangan : datanya.keterangan,
              lat : datanya.lat,
              lng : datanya.lng,
              anamnesa_id : vm.dataForm.anamnesa_id,
              kunjungan_id : vm.kunjungan.kunjungan_id,
            })
            .then((res) => {
              vm.close_popup();
              vm.getDataAnatomi();
              // vm.getDetail()
              vm.$emit("sendAnamnesa", {
                id : vm.dataForm.anamnesa_id,
                anamnesa_id : vm.dataForm.anamnesa_id,
                kunjungan_id : vm.kunjungan.kunjungan_id,
              })
              // console.log('ini jalan 2', vm.kunjungan)
              // console.log('ini jalan 3', res)
              // vm.kunjungan
              vm.haveAnamnesa = true
              vm.map.removeLayer(vm.bunder);
              // console.log(res, 'ini respon');
            })
            .catch((err) => {
              console.log(err, 'ini err');
            });
          }else {
            vm.$store.commit('set_alert', {variant: "warning",msg: "GAGAL, DATA TIDAK LENGKAP",showing: true})
          }
      });
  }

      var tombol_batal = document.getElementsByClassName('tombol_batal');
          for (let i = 0; i < tombol_batal.length; i++) {
              
            tombol_batal[i].addEventListener('click', function() {
         
              vm.bunder_bunder.eachLayer(function (layer) {
           
                  if(leaflet_id == layer._leaflet_id){
                    vm.bunder_bunder.removeLayer(layer);
                    vm.map.removeLayer(layer)
                  }
                })
           
            });
        }




        let tombol_update = document.getElementsByClassName('tombol_update');
      
      for (var i = 0; i < tombol_update.length; i++) {
       
        tombol_update[i].addEventListener('click', function() {
          let form = this.parentNode.parentNode.parentNode;
          let datanya = {}
          for (var i = 0; i < form.childNodes.length; i++) {
            
            if (form.childNodes[i].className == "bagian_tubuh") {
              // console.log(form.childNodes[i].value);
              datanya.bagian_tubuh = form.childNodes[i].value
            } 
            
            if (form.childNodes[i].className == "keterangan") {
              // console.log(form.childNodes[i].value);
              datanya.keterangan = form.childNodes[i].value
            }
            
            if (form.childNodes[i].id == "lat") {
              // console.log(form.childNodes[i].value);
              datanya.lat = form.childNodes[i].value
            }

            if (form.childNodes[i].id == "lng") {
              // console.log(form.childNodes[i].value);
              datanya.lng = form.childNodes[i].value
            }
            if (form.childNodes[i].id == "id_anatomi") {
              // console.log(form.childNodes[i].value);
              datanya.id = form.childNodes[i].value
            }
          }
          // console.log(datanya, 'ini ini ini ini');
          if(datanya.bagian_tubuh && datanya.keterangan){
            let id_anamnesa = null
            vm.$axios.post("/anatomi_tubuh/update", {
              id:  datanya.id,
              bagian_tubuh : datanya.bagian_tubuh,
              keterangan : datanya.keterangan,
              lat : datanya.lat,
              lng : datanya.lng,
              anamnesa_id : id_anamnesa
            })
            .then((res) => {
              vm.close_popup()
              vm.getDataAnatomi();
              // console.log(res, 'ini respon');
            })
            .catch((err) => {
              console.log(err, 'ini err');
            });
          }else {
            vm.$store.commit('set_alert', {variant: "warning",msg: "GAGAL, DATA TIDAK LENGKAP",showing: true})
          }
      });
  }

      var tombol_hapus = document.getElementsByClassName('tombol_hapus');
          for (let j = 0; j < tombol_hapus.length; j++) {
              
            tombol_hapus[j].addEventListener('click', function() {
         
              vm.bunder_bunder.eachLayer(function (layer) {
           
                  if(leaflet_id == layer._leaflet_id){
                    vm.bunder_bunder.removeLayer(layer);
                    vm.map.removeLayer(layer)
                  }
                })
             

                vm.map.eachLayer(function (layer) {
                  if(leaflet_id == layer._leaflet_id){
                    vm.map.removeLayer(layer)
                  }
                });

                let form = this.parentNode.parentNode.parentNode;
                let id ="";
          for (var i = 0; i < form.childNodes.length; i++) {
            if (form.childNodes[i].id == "id_anatomi") {
              // console.log(form.childNodes[i].value);
              id = form.childNodes[i].value
            }
          }
          vm.$axios.post("/anatomi_tubuh/delete", {
              id:  id
            })
            .then((res) => {
            
              vm.close_popup();
              vm.getDataAnatomi();
              // console.log(res, 'ini respon hapus');
            })
            });

          
        }
    },
    async getDataAnatomi(){
      let vm = this
      // console.log(vm.dataForm.anamnesa_id, 'idnya');
      if(vm.dataForm.anamnesa_id != null){
        let anatomi = await vm.$axios.post("/anatomi_tubuh/list", {
          anamnesa_id : vm.dataForm.anamnesa_id
        });
        // console.log(anatomi.data.data, 'ini list anatomi');
        vm.listAnatomi = []
        for (let i = 0; i < anatomi.data.data.length; i++) {
          let x = anatomi.data.data[i];
          let penampung = {}
          penampung.bagian_tubuh = x.properties.bagian_tubuh
          penampung.keterangan = x.properties.keterangan
          vm.listAnatomi.push(penampung)
        }
        vm.geojsonAnatomi.clearLayers()
    
        vm.geojsonAnatomi.addData(anatomi.data.data);
      
        vm.geojsonAnatomi.eachLayer(function (layer) {
          vm.map.removeLayer(layer);
      
          let newLayer = L.circle(layer.getLatLng(), 5)
          
          newLayer.bindPopup(`<div id="depan"><label class="control-label col-sm-5"><strong>ID: </strong></label>
            <input type="text" id="id_anatomi" value="${layer.feature.properties.anatomi_tubuh_id}">
              <label class="control-label col-sm-5"><strong>Bagian Tubuh: </strong></label>
              <input type="text" class="bagian_tubuh" value="${layer.feature.properties.bagian_tubuh}">
          
              <label class="control-label col-sm-5"><strong>Keterangan: </strong></label>
              <textarea class="keterangan" rows="6">${layer.feature.properties.keterangan}</textarea>
          <input style="display: none;" type="text" id="lat" name="lat" value="${layer.getLatLng().lat.toFixed(0)}" />
          <input style="display: none;" type="text" id="lng" name="lng" value="${layer.getLatLng().lng.toFixed(0)}" />
          <div class="form-group">
            <div style="text-align:center;" class="col-xs-4 col-xs-offset-2"><button type="button" class="btn tombol_hapus btn-danger">Hapus</button></div>
            <div style="text-align:center;" class="col-xs-4"><button type="submit" value="submit" class="tombol_update btn btn-primary trigger-submit" onclick="()=>{console.log(this)}">Submit</button></div>
          </div></div>'`);
          newLayer.on('click', function (e) {
            e
            vm.popup_event(newLayer._leaflet_id);
          });
          vm.geojsonAnatomi.addLayer(newLayer)
          
        });
      }
    },
    checkIfValid(fieldName) {
      const field = this.$v.dataForm[fieldName];
      // console.log('fieldName', fieldName)
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    async getDataPrintAnamnesa() {
      const vm = this
      let x = { kunjungan_id: vm.kunjungan.kunjungan_id }
      try {
        let res = await vm.$axios.post("/cetak/cetak_pengkajian_awal", x)
        if (res.data.status == 200) {
          vm.data_print = res.data.data[0]
          return true
        }
      } catch (error) {
        console.log(error);
        return false
      }
    },
    async printAnamnesa() {
      const vm = this
      
      const options_A4 = {
        name: '_blank',
        specs: [
          'fullscreen=yes',
          'titlebar=yes',
          'scrollbars=yes'
        ],
        styles: [
        '/style-paper-a4.css',
        ],
        timeout: 1000, // default timeout before the print window appears
        autoClose: true, // if false, the window will not close after printing
        windowTitle: window.document.title, // override the window title
      }
      vm.print_ready = await vm.getDataPrintAnamnesa()
      if (vm.print_ready) {
        await vm.$htmlToPaper("form_anamnesa", options_A4);
      }
    }
  },

 
}


</script>
<style>
#mapContainer {
    width: 100%;
    height: 800px;
    background-color: white;
    border: 1px solid black;
}
.custom-title-color-black{
  font-weight: 700;
  font-size: 16px;
  color: #D52F65;
}

.input-group-append > button{
  height: auto !important;
}

.btn-primary{
    background-color: #9C4098;
}
</style>