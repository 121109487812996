<template>
  <div>
    <b-button
      class="text-center"
      variant="outline-info"
      v-c-tooltip.hover.click="'Cetak'"
      :disabled="!resepPasien.id"
      @click="printResep('cetak_resep')"
    > Cetak </b-button>
    <div style="position: relative; display: none" id="cetak_resep" v-if="itemResep">
      <section class="content-print A4">
        <div class="sheet">
          <div class="w-100">
            <table border="0" class="custom-table">
              <tbody>
                <tr>
                  <td style="width: 15%;vertical-align: middle;padding-left: 1.5mm;padding-right: 1.5mm;"><img :src="logo" alt="" style="width: 100%;"></td>
                  <td style="width: 70%;vertical-align: middle">
                      <h6 style="text-align: center;font-weight: bold;margin-bottom: 0;font-size: 16pt;">PEMERINTAH <span>{{ $store.state.puskesmas.nama_kota }}</span> </h6>
                      <h6 style="text-align: center;font-weight: bold;margin-bottom: 0;font-size: 16pt;"><span>DINAS KESEHATAN </span></h6>
                      <h6 style="text-align: center;font-weight: bold;margin-bottom: 0;font-size: 16pt;"><span>{{ $store.state.puskesmas.nama_puskesmas }}</span> </h6>
                      <h6 style="text-align: center;font-weight: normal;font-size: 10pt;margin-bottom: 0;"><span>{{ $store.state.puskesmas.alamat_puskesmas }}</span></h6>
                      <h6 style="text-align: center;font-weight: normal;font-size: 10pt;margin-bottom: 0;"><span>Email : emailpuskesmas@gmil.com</span> <span>Telp. : 0987654321</span></h6>
                  </td>
                  <td style="width: 15%;vertical-align: middle;padding-left: 1.5mm;padding-right: 1.5mm;"><img src="../../../cetak/puskesmas-logo.png" alt="" style="width: 100%;"></td>
                </tr>
              </tbody>
            </table>
          </div>
          
          <div class="w-100" style="margin-top: 2.5mm;">
            <div class="line"></div>
          </div>

          <div class="w-100" style="margin-top: 5mm;">
            <table border="0" class="custom-table">
              <tbody>
                <tr>
                  <td style="width: 75mm;">No. Resep</td>
                  <td class="autowidth">:</td>
                  <td>{{ resepPasien.no_resep }}</td>
                </tr>
                <tr>
                  <td>Tanggal Resep</td>
                  <td>:</td>
                  <td>{{ $moment(dataKunjungan.tanggal_daftar).format("DD MMMM YYYY") }}</td>
                </tr>
                <tr>
                  <td>No. Pendaftaran</td>
                  <td>:</td>
                  <td>{{ dataKunjungan.no_pendaftaran }}</td>
                </tr>
                <tr>
                  <td>Poli/Ruangan</td>
                  <td>:</td>
                  <td>{{ dataKunjungan.nama_poli }}</td>
                </tr>
                <tr>
                  <td>Dokter / Tenaga Medis</td>
                  <td>:</td>
                  <td>{{ resepPasien.nama_dokter }}</td>
                </tr>
                <tr>
                  <td>Perawat / Bidan / Nutrisionist / Sanitarian </td>
                  <td>:</td>
                  <td>{{ resepPasien.nama_perawat }}</td>
                </tr>
                <tr>
                  <td>No. eRM</td>
                  <td>:</td>
                  <td>{{ dataKunjungan.no_rm }}</td>
                </tr>
                <tr>
                  <td>NIK</td>
                  <td>:</td>
                  <td>{{ dataKunjungan.nik }}</td>
                </tr>
                <tr>
                  <td>Nama Pasien</td>
                  <td>:</td>
                  <td>{{ dataKunjungan.nama_lengkap }}</td>
                </tr>
                <tr>
                  <td>Jenis Kelamin</td>
                  <td>:</td>
                  <td>{{ dataKunjungan.jenis_kelamin == "P" ? "Perempuan" : "Laki-laki" }}</td>
                </tr>
                <tr>
                  <td>Tempat/Tanggal Lahir</td>
                  <td>:</td>
                  <td>{{ dataKunjungan.tempat_lahir }} / {{ $moment(dataKunjungan.tanggal_lahir).format("DD MMMM YYYY") }}</td>
                </tr>
                <tr>
                  <td>Umur</td>
                  <td>:</td>
                  <td>{{ dataKunjungan.umur }}</td>
                </tr>
                <tr>
                  <td>Alamat</td>
                  <td>:</td>
                  <td>{{ dataKunjungan.alamat_sekarang }}</td>
                </tr>
                <tr>
                  <td>Alergi</td>
                  <td>:</td>
                  <td>
                    <ul style="padding-left: 15px;">
                      <li v-for="(val, index) in listAlergiPasien" :key="index">{{ val.nama_alergi }}</li>
                    </ul>  
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="w-100" style="margin-top: 5mm;">
            <table border="1" class="custom-table">
              <thead>
                <tr>
                  <th colspan="8">RESEP</th>
                </tr>
                <tr>
                  <th>No.</th>
                  <th>Racikan</th>
                  <th>Nama Obat</th>
                  <th>Jumlah Bungkus</th>
                  <th>Permintaan</th>
                  <th>Aturan Pakai</th>
                  <th>Signa</th>
                  <th>Keterangan</th>
                </tr>
              </thead>

              <tbody v-if="itemResep && itemObat">
                <tr v-for="(item, index1) in itemResep" :key="index1+'123'">
                <!-- <tr v-for="item in itemResep"> -->
                  <td v-if="item.jumlah_bungkus" scope="row" class="text-center">{{ item._no }}.</td>
                  <td v-if="item.jumlah_bungkus" style="white-space: nowrap;">{{ item.no_racikan ? item.no_racikan : "" }}</td>
                  <td v-if="item.jumlah_bungkus"></td> 
                  <td v-if="item.jumlah_bungkus" style="text-align: center;">{{ item.jumlah_bungkus ? item.jumlah_bungkus : "" }}</td>
                  <td v-if="item.jumlah_bungkus" colspan="4"></td> 

                  <td v-if="!item.jumlah_bungkus"></td>
                  <td v-if="!item.jumlah_bungkus"></td>
                  <td v-if="!item.jumlah_bungkus" style="white-space: nowrap;">{{ item.nama_barang ? item.nama_barang : "-" }}</td>
                  <td v-if="!item.jumlah_bungkus"></td>
                  <td v-if="!item.jumlah_bungkus" style="text-align: center;" >{{ item.qty_permintaan }}</td>
                  <td v-if="!item.jumlah_bungkus" style="white-space: nowrap;">{{ item.aturan_pakai_racikan }}</td>
                  <td v-if="!item.jumlah_bungkus" style="white-space: nowrap;">{{ item.signa_racikan }}</td>
                  <td v-if="!item.jumlah_bungkus">{{ item.keterangan_racikan }}</td>
                </tr>

                <tr v-for="(data, index2) in itemObat" :key="index2+'321'">
                  <td scope="row" style="text-align: center;">{{ index2 + 1 }}.</td>
                  <td class="text-center">{{ data.no_racikan ? data.no_racikan : "-" }}</td>
                  <td style="white-space: nowrap;">{{ data.nama_barang }}</td>
                  <td style="text-align: center;">{{ data.jumlah_bungkus ? data.jumlah_bungkus : "-" }}</td>
                  <td style="text-align: center;">{{ data.qty_permintaan }}</td>
                  <td style="white-space: nowrap;">{{ data.aturan_pakai_racikan }}</td>
                  <td style="white-space: nowrap;">{{ data.signa_racikan }}</td>
                  <td>{{ data.keterangan_racikan }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="w-100" style="margin-top: 5mm;">
            <h6>SEMOGA LEKAS SEMBUH</h6>
          </div>
          <div class="w-100" style="margin-top: 5mm;">
            <table border="0" class="custom-table" style="font-size: 12pt;">
              <tbody>
                <tr>
                  <td style="width: 50%;">
                    &nbsp;
                  </td>
                  <td style="width: 50%;">
                    <h6 style="text-align: center;"><span>{{ $store.state.puskesmas.nama_kota }} / {{ $moment(dataKunjungan.tanggal_daftar).format("DD MMMM YYYY") }}</span></h6>
                    <h6 style="text-align: center;">Diketahui</h6>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <h6 style="text-align: center;"><span>(......................................)</span></h6>
                    <h6 style="text-align: center;"><span>{{ resepPasien.nama_dokter }}</span></h6>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    'itemResep',
    'itemObat',
    'listAlergiPasien',
    'dataKunjungan',
    'resepPasien',
  ],
  data() {
    return {
    }
  },
  computed: {
    logo() {
      return this.$store.state.logo;
    },
  },
  watch: {
  },
  async activated() {
    // const vm = this
    // await vm.getData()
  },
  methods: {
    async printResep(val) {
      console.log('jalan', val)
      const options = {
        name: '_blank',
        specs: [
          'fullscreen=yes',
          'titlebar=yes',
          'scrollbars=yes'
        ],
        styles: [
          // 'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
          '/style-paper-a4.css',
        ],
        timeout: 1000, // default timeout before the print window appears
        autoClose: true, // if false, the window will not close after printing
        windowTitle: window.document.title, // override the window title
      }

      await this.$htmlToPaper(val, options);
      // await this.$htmlToPaper(val);
    },
    triggerAlert(event) {
      let vm = this;
      vm.$store.commit("set_alert", event);
    },
  },
}
</script>