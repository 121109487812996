<template>
  <div id="page-laboratorium">
    <b-card style="box-shadow: 0px 4px 4px 0px #00000026;" >
      <b-row>
        <b-col cols="12" md="12" lg="12" xl="12">
          <h6 class="custom-title-color-black">Data Pemeriksaan</h6>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" md="12" lg="4" xl="4">
          <label>ID <span class="text-danger">*</span></label>
          <b-form-input type="text" v-model="idnya" disabled></b-form-input>
        </b-col>
        <b-col cols="12" md="12" lg="4" xl="4">
          <label>NO. ANTREAN</label>
          <b-form-input type="text" disabled v-model="noAntrian"></b-form-input>
        </b-col>
        <b-col cols="12" md="12" lg="4" xl="4">
          <label>TANGGAL</label>
          <b-form-input type="text" disabled v-model="tgl"></b-form-input>
        </b-col>
        <b-col cols="12" md="12" lg="4" xl="4">
          <label>DOKTER</label>
          <b-form-input type="text" disabled v-model="nama_dokter"></b-form-input>
        </b-col>
        <b-col cols="12" md="12" lg="4" xl="4">
          <label>PERAWAT</label>
          <b-form-input type="text" disabled v-model="nama_perawat"></b-form-input>
        </b-col>
      </b-row>

      <b-row style="margin-top: 30px;">
        <b-col cols="12" md="12" lg="12" xl="12">
          <b-button v-b-toggle.collapse-1 variant="light" block style="background-color: #2ecc71;color: #fff;border-bottom-left-radius: 0;border-bottom-right-radius: 0;">
            <div style="display: flex;justify-content: space-between;">
              <span>Lihat Riwayat Laboratorium</span>
              <span><CIcon name="cil-caret-bottom" /></span>
            </div>
            
          </b-button>
          <b-collapse id="collapse-1">
            <b-card style="border: 1px solid #D3D3D3">
              <b-row>
                <b-col cols="12" sm="12" md="12" lg="12">
                  <b-table-simple class="custom-table">
                    <b-thead>
                      <b-tr>
                        <b-th>No.</b-th>
                        <b-th>Tanggal</b-th>
                        <!-- <b-th>Lab Order</b-th> -->
                        <b-th class="table-number">Hasil</b-th>
                      </b-tr>
                    </b-thead>
                    <b-tr v-for="(item, idx) in list_kunjungan" :key="idx + 'list_kunjungan'">
                      <b-td>{{ idx+1 }}</b-td>
                      <b-td>{{ $moment(item.tanggal_kunjungan_lab).format('DD-MM-YYYY HH:mm') }}</b-td>
                      <!-- <b-td>001</b-td> -->
                      <b-td>
                        <b-button variant="primary" v-b-modal.modal-hasil-laborat-kunjungan @click="getDetailKunjungan(item)"> <CIcon name="cil-search" /></b-button>
                      </b-td>
                    </b-tr>
                  </b-table-simple>
                  <!-- <b-card v-for="item in list_kunjungan" :key="item.kunjungan_lab_id">
                    <div style="width:100%;display: flex;">
                      <div style="width:50%">
                        <h6 style="font-weight: 600;font-size: 14px;color: #202020;">{{ $moment(item.tanggal_kunjungan_lab).format('DD-MM-YYYY') }}</h6>
                      </div>
                      <div style="width:50%;display: flex;justify-content: flex-end;">
                        <h6 style="font-weight: 600;font-size: 14px;color: #202020;">{{ $moment(item.tanggal_kunjungan_lab).format('HH:mm') }}</h6>
                      </div>
                    </div>

                    <div style="width:100%;display: flex;margin-top: 15px;">
                      <div style="width:33.33%">
                        <h6 style="font-weight: 600;font-size: 10px;color: #7C7C7C;">PENANGGUNG JAWAB</h6>
                        <h6 style="color: #202020;font-weight: 400;font-size: 14px;">-</h6>
                      </div>
                      <div style="width:33.33%">
                        <h6 style="font-weight: 600;font-size: 10px;color: #7C7C7C;">PEMERIKSA</h6>
                        <h6 style="color: #202020;font-weight: 400;font-size: 14px;">-</h6>

                      </div>
                      <div style="width:33.33%">
                        <h6 style="font-weight: 600;font-size: 10px;color: #7C7C7C;">DOKTER PERUJUK</h6>
                        <h6 style="color: #202020;font-weight: 400;font-size: 14px;">{{ item.nama_tenaga_medis }}</h6>
                      </div>
                    </div>

                    <div style="width: 100%;margin-top: 15px;">
                      <b-table-simple class="table-riwayat-lab" style="margin-bottom: 0;">
                        <b-thead>
                          <b-tr>
                            <b-th>Pemeriksaan</b-th>
                            <b-th>Hasil</b-th>
                          </b-tr>
                        </b-thead>

                        <b-tbody>
                          <b-tr v-for="item2 in item.bulk_sub_lab_kunjungan" :key="item2.sub_tindakan_lab_id">
                            <b-td>{{ item2.nama_sub_tindakan_lab }}</b-td>
                            <b-td>-</b-td>
                          </b-tr>
                        </b-tbody>
                      </b-table-simple>
                    </div>
                  </b-card> -->

                </b-col>
              </b-row>
              <!-- <b-row>
                <b-col cols="12" sm="12" md="12" lg="12">
                  <b-pagination v-model="currentPage" pills :total-rows="rows" :per-page="perPage"></b-pagination>
                </b-col>
              </b-row> -->
            </b-card>
          </b-collapse>
        </b-col>
      </b-row>

      <b-row style="margin-top: 30px;">
        <b-col cols="12" md="12" lg="12" xl="12">
          <h6 class="custom-title-color-black">Paket Pemeriksaan Laboratorium</h6>
        </b-col>
      </b-row>

      <b-row>
        <b-col ref="itikiwir" cols="6" md="6" lg="4" xl="4" v-for="(item, idx) in list_paket" :key="idx + 'list_paket'">
          <b-button v-if="item.checked == false" variant="outline-dark" size="sm" @click="assignData(item)"><CIcon name="cil-plus" /> {{ item.nama_paket_lab }}</b-button>
          <b-button v-else variant="primary" size="sm" @click="assignData(item)"><CIcon name="cil-check-alt" /> {{ item.nama_paket_lab }}</b-button>
          <!-- <p>{{ item.nama_paket_lab }}</p> -->
        </b-col>
      </b-row>

      <b-row style="margin-top: 30px;">
        <b-col cols="12" md="12" lg="12" xl="12">
          <h6 class="custom-title-color-black">Laboratorium</h6>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" md="12" lg="12" xl="12">
          <b-card-group columns>
            <b-card style="border:1px solid #D3D3D3;" v-for="(tindakan, idx) in list_tindakan" :key="idx + 'list_tindakan'">
              <b-form-checkbox
                :id="tindakan.tindakan_laboratorium_id"
                class="mt-1 title-checkbox"
                v-model="tindakan.checked"
                @change="toggleSelect(tindakan)"
              >{{ tindakan.nama_tindakan_laboratorium }}</b-form-checkbox>

              <div v-for="(sub, idx) in tindakan.bulk_sub_tindakan_lab" :key="idx + 'bulk_sub_tindakan'">
                <b-form-checkbox
                  :id="sub.sub_tindakan_lab_id"
                  class="mt-1"
                  v-model="sub.checked"
                  ref="subTindakan"
                  :disabled="sub.disabled"
                  @change="setData()"
                ><span class="font-checkbox">{{ sub.nama_sub_tindakan_lab }}</span></b-form-checkbox>
              </div>
            </b-card>
          </b-card-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" md="12" lg="12" xl="12">
          <hr/>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" md="12" lg="12" xl="12">
          <b-table-simple hover striped style="margin-bottom: 0;" class="custom-table">
            <b-thead>
              <b-tr>
                <b-th>Pemeriksaan</b-th>
                <b-th>Tarif</b-th>
                <b-th>Hasil</b-th>
                <b-th>Nilai Rujukan</b-th>
                <b-th>Sample</b-th>
              </b-tr>
            </b-thead>

            <b-tbody>
              <b-tr v-for="(item, idx) in tampungan" :key="idx + 'tampungan'">
                <b-td v-if="item.is_paket == true"><b>{{ item.nama_paket_lab }}</b></b-td>
                <b-td v-else style="padding-left:30px;">{{ item.nama_sub_tindakan_lab }}</b-td>
                <b-td v-if="item.is_paket == true">{{ item.harga_paket_lab }}</b-td>
                <b-td v-else>{{ item.harga_tindakan_lab }}</b-td>
                <b-td>-</b-td>
                <b-td>-</b-td>
                <b-td>-</b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" md="12" lg="12" xl="12">
          <hr/>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" md="12" lg="6" xl="6">
          <h6 class="custom-title-color-black">Status</h6>

          <label>STATUS PEMERIKSAAN</label>
          <b-form-select
            :options="statusPemeriksaan"
            v-model="status_pemeriksaan"
            placeholder="-- Pilih --"
            size="sm"
          ></b-form-select>

          <!-- <Multiselect
            :options="hasilPemeriksaan"
            :multiple="false"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            placeholder="-- Pilih --"
            size="sm"
            class="mt-3 mb-3"
          ></Multiselect>

          <label>SARAN</label>
          <b-form-textarea
            type="text"
          ></b-form-textarea> -->
        </b-col>
        <!-- <b-col cols="12" md="12" lg="6" xl="6">
          <h6 class="custom-title-color-black">Rujukan laboratorium Lain ?</h6>
          <label>PUSKESMAS</label>
          <Multiselect
            :options="rujukanPuskesmas"
            :multiple="false"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            placeholder="-- Pilih --"
            size="sm"
          ></Multiselect>
        </b-col> -->
      </b-row>
    </b-card>

    <b-row>
      <b-col cols="12" md="12" lg="12" xl="12">
        <div style="width: 100%;display: flex;justify-content: flex-end;">
          <b-button variant="light" class="mr-2">Reset</b-button>
          <b-button variant="primary" class="mr-2" @click="printLab('blanko_pemeriksaan')">Cetak Blanko Pemeriksaan</b-button>
          <b-button variant="secondary" class="mr-2" @click="printLab('hasil_pemeriksaan')">Cetak Hasil Pemeriksaan</b-button>
          <b-button :disabled="busy" variant="success" @click="simpanFinalData()">
            <b-spinner v-if="busy" variant="light" label="Spinning"></b-spinner>
            <span v-else>Simpan</span>
          </b-button>
        </div>
        
      </b-col>
    </b-row>

    <!-- modal hasil laborat kunjungan -->
    <b-modal id="modal-hasil-laborat-kunjungan" size="lg" :title="$moment(datanya.tanggal_kunjungan_lab).format('DD-MM-YYYY HH:mm')">
      <b-row>
        <b-col cools="6" sm="6" md="6" lg="4" xl="4">
          <h6 class="custom-font-label">Penanggung Jawab</h6>
          <h6 class="custom-font-value">{{ datanya.nama_penanggung_jawab }}</h6>
        </b-col>
        <b-col cools="6" sm="6" md="6" lg="4" xl="4">
          <h6 class="custom-font-label">Pemeriksa</h6>
          <h6 class="custom-font-value">{{ datanya.nama_pemeriksa }}</h6>
        </b-col>
        <b-col cools="6" sm="6" md="6" lg="4" xl="4">
          <h6 class="custom-font-label">Dokter Perujuk</h6>
          <h6 class="custom-font-value">{{ datanya.nama_perujuk }}</h6>
        </b-col>
      </b-row>

      <!-- <b-row style="margin-top:30px">
        <b-col cools="6" sm="6" md="6" lg="4" xl="4">
          <h6 class="custom-font-label">Paket</h6>
          <h6 class="custom-font-value">Hematologi </h6>
        </b-col>
        <b-col cools="6" sm="6" md="6" lg="4" xl="4">
          <h6 class="custom-font-label">Pemeriksaan</h6>
          <h6 class="custom-font-value">Fungsi Pembekuan</h6>
          <h6 class="custom-font-value">Lengkap Besar</h6>
          <h6 class="custom-font-value">Lengkap Kecil</h6>
        </b-col>
        <b-col cools="6" sm="6" md="6" lg="4" xl="4">
          <h6 class="custom-font-label">Hasil</h6>
          <h6 class="custom-font-value">Normal</h6>
          <h6 class="custom-font-value">Normal</h6>
          <h6 class="custom-font-value">Normal</h6>
        </b-col>
      </b-row> -->
      <div style="width: 100%;margin-top: 15px;">
        <b-table-simple class="table-riwayat-lab" style="margin-bottom: 0;">
          <b-thead>
            <b-tr>
              <b-th>Pemeriksaan</b-th>
              <b-th>Hasil</b-th>
              <b-th>Nilai</b-th>
            </b-tr>
          </b-thead>

          <b-tbody v-if="!datanya">
            <b-tr v-for="(item, idx) in datanya2.pool_sub_lab_kunjungan" :key="idx + 'pool_sub_lab_kunjungan'">
              <b-td>{{ item.nama_sub_tindakan_lab }}</b-td>
              <b-td>-</b-td>
              <b-td>-</b-td>
            </b-tr>
          </b-tbody>

          <b-tbody v-else>
            <b-tr v-for="(item, idx) in datanya.sub_pemeriksaan_lab" :key="idx + 'sub_pemeriksaan'">
              <b-td>{{ item.nama_sub_pemeriksaan_lab }}</b-td>
              <b-td v-if="item.hasil_pemeriksaan" :class="item.textColor"><b>{{ item.hasil_pemeriksaan }}</b></b-td>
              <b-td v-else>{{ item.hasil_pemeriksaan_text }}</b-td>
              <b-td>{{ item.nilai_pemeriksaan }}</b-td>
            </b-tr>
          </b-tbody>

        </b-table-simple>
      </div>
    </b-modal>

    <!-- PRINT HASIL PEMERIKSAAN -->
    <div style="display: none;" id="hasil_pemeriksaan" v-if="kunjungan">
      <section class="content-print A4" v-if="printReady">
        <div class="sheet">
            <div class="w-100">
                <table border="0" class="custom-table">
                <tbody>
                    <tr>
                        <td style="width: 15%;vertical-align: middle;padding-left: 1.5mm;padding-right: 1.5mm;"><img :src="logo" alt="" style="width: 100%;"></td>
                        <td style="width: 70%;vertical-align: middle;">
                            <h6 style="text-align: center;font-weight: bold;margin-bottom: 0;font-size: 16pt;">PEMERINTAH {{ $store.state.puskesmas.nama_kota }} </h6>
                            <h6 style="text-align: center;font-weight: bold;margin-bottom: 0;font-size: 16pt;">DINAS KESEHATAN</h6>
                            <h6 style="text-align: center;font-weight: bold;margin-bottom: 0;font-size: 16pt;">{{ $store.state.puskesmas.nama_puskesmas }} </h6>
                            <h6 style="text-align: center;font-weight: normal;font-size: 10pt;">{{ $store.state.puskesmas.alamat_puskesmas }}</h6>
                            <h6 style="text-align: center;font-weight: normal;font-size: 10pt;"><span>Email : emailpuskesmas@gmil.com</span> <span>Telp. : 0987654321</span></h6>
                        </td>
                        <td style="width: 15%;vertical-align: middle;padding-left: 1.5mm;padding-right: 1.5mm;"><img src="../../../cetak/puskesmas-logo.png" alt="" style="width: 100%;"></td>
                    </tr>
                </tbody>
                </table>
            </div>

            <div class="w-100" style="margin-top: 2.5mm;">
                <div class="line"></div>
            </div>

            <div class="w-100" style="margin-top: 5mm;">
                <h6 style="font-size: 16pt;font-weight: bold;text-align: center;"><u>HASIL PEMERIKSAAN LABORATORIUM</u></h6>
            </div>

            <div class="w-100-flex" style="margin-top: 10mm;">
                <div style="width: 50%;">
                    <table class="custom-table">
                        <tbody>
                            <tr>
                                <td style="width: 40mm;padding-left: 0;">Dokter Pengirim</td>
                                <td style="width: 5mm;">:</td>
                                <td>{{ data_print.dokter }}</td>
                            </tr>
            
                            <tr>
                                <td style="padding-left:0">Pasien</td>
                                <td>:</td>
                                <td>{{ data_print.nama_lengkap }}</td>
                            </tr>
            
                            <tr>
                                <td style="padding-left:0">Nomor Register</td>
                                <td>:</td>
                                <td>{{ data_print.no_registrasi_lab }}</td>
                            </tr>
            
                            <tr>
                                <td style="padding-left:0">Nomor Asuransi</td>
                                <td>:</td>
                                <td>{{ data_print.no_bpjs }}</td>
                            </tr>
            
                            <tr>
                                <td style="padding-left:0">Nama</td>
                                <td>:</td>
                                <td>{{ data_print.nama_lengkap }}</td>
                            </tr>
            
                            <tr>
                                <td style="padding-left:0">NIK</td>
                                <td>:</td>
                                <td>{{ data_print.nik }}</td>
                            </tr>
    
                            <tr>
                                <td style="padding-left:0">Umur</td>
                                <td>:</td>
                                <td>
                                  <span v-if="data_print.usia_tahun">{{ data_print.usia_tahun }} Tahun</span>
                                  <span v-if="data_print.usia_bulan">{{ data_print.usia_bulan }} Bulan</span>
                                  <span v-if="data_print.usia_hari">{{ data_print.usia_hari }} Hari</span>
                                </td>
                            </tr>
    
                            <tr>
                                <td style="padding-left:0">Tanggal Lahir</td>
                                <td>:</td>
                                <td>{{ $moment(data_print.tanggal_lahir).format("LL") }}</td>
                            </tr>
    
                            <tr>
                                <td style="padding-left:0">Jenis Kelamin</td>
                                <td>:</td>
                                <td>{{ data_print.jenis_kelamin == "L" ? "Laki-laki" : data_print.jenis_kelamin == "P" ? "Perempuan" : data_print.jenis_kelamin }}</td>
                            </tr>
    
                            <tr>
                                <td style="padding-left:0">Alamat</td>
                                <td>:</td>
                                <td>{{ data_print.alamat_sekarang }}</td>
                            </tr>
    
                            <!-- <tr>
                                <td style="padding-left:0">Jenis</td>
                                <td>:</td>
                                <td>-</td>
                            </tr> -->
    
                            <tr>
                                <td style="padding-left:0">Tanggal Selesai</td>
                                <td>:</td>
                                <td>{{ $moment(data_print.tanggal_selesai_pemeriksaan_lab).format("LL") }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div style="width: 50%;">
                    <div class="custom-border-paper" style="padding: 2.5mm;">
                        <h6>SAMPLE</h6>
                        <table class="custom-table">
                            <tbody>
                                <tr>
                                    <td style="width: 35mm;padding-left: 0;">Nama</td>
                                    <td style="width: 5mm;">:</td>
                                    <td>{{ data_print.nama_sampel }}</td>
                                </tr>
                
                                <tr>
                                    <td style="padding-left:0">Takaran</td>
                                    <td>:</td>
                                    <td>{{ data_print.satuan_takaran }}</td>
                                </tr>
                
                                <tr>
                                    <td style="padding-left:0">Tanggal Ambil</td>
                                    <td>:</td>
                                    <td>{{ $moment(data_print.tanggal_sampel).format("LLL") }}</td>
                                </tr>
                
                                <tr>
                                    <td style="padding-left:0">Batas Waktu</td>
                                    <td>:</td>
                                    <td>{{ $moment(data_print.batas_waktu_sampel).format("LL") }}</td>
                                </tr>
                
                                <tr>
                                    <td style="padding-left:0">Petugas</td>
                                    <td>:</td>
                                    <td>{{ data_print.petugas }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div class="w-100" style="margin-top: 2.5mm;">
                <table class="custom-table" border="1">
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>Pemeriksaan</th>
                        <th>Hasil</th>
                        <th>Nilai Rujukan</th>
                      </tr>
                    </thead>

                    <tbody>
                      <template v-for="(item, index) in data_print.data_pemeriksaan">
                        <tr v-if="item.paket_pemeriksaan_lab_id">
                          <td class="text-center">{{ index + 1 }}</td>
                          <td><b>{{ item.nama_paket_pemeriksaan }}</b></td>
                          <td colspan="2"></td>
                        </tr>
                        <tr v-for="(data, index) in item.sub_paket" :key="index + 'sub_paket'">
                          <td v-if="item.paket_pemeriksaan_lab_id"></td>
                          <td class="text-center" v-else>{{ index + 1 }}</td>

                          <td v-if="item.paket_pemeriksaan_lab_id" style="padding-left:30px;"><b>{{ data.nama_sub_pemeriksaan_lab }}</b></td>
                          <td v-else><b>{{ data.nama_pemeriksaan_lab }}</b></td>

                          <td>{{ data.hasil_pemeriksaan }} {{ data.satuan_pemeriksaan ? data.satuan_pemeriksaan : '' }}</td>
                          <td>{{ data.nilai_pemeriksaan }}</td>
                        </tr>
                      </template>
                    </tbody>
                </table>
            </div>

            <div class="w-100" style="margin-top: 2.5mm;">
                <h6>Keterangan : </h6>
            </div>

            <div class="w-100" style="margin-top: 10mm;">
                <table class="custom-table">
                    <tbody>
                        <tr>
                            <td style="width: 40%;">
                                <br/>
                                <h6 style="text-align: center;">Dokter/Tenaga Medis</h6>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <h6 v-if="nama_dokter" style="text-align: center;">{{ data_print.dokter }}</h6>
                                <h6 v-else style="text-align: center;">{{ 'nama_dokter' }}</h6>
                            </td>
                            <td style="width: 20%;">&nbsp;</td>
                            <td style="width: 40%;">
                                <h6 style="text-align: center;">{{ $store.state.puskesmas.nama_kota }}, {{ tglPrint }}</h6>
                                <h6 style="text-align: center;">Pemeriksa</h6>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <h6 v-if="nama_perawat" style="text-align: center;">{{ data_print.pemeriksa }}</h6>
                                <h6 v-else style="text-align: center;">{{ 'nama_pemeriksa' }}</h6>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
      </section>
    </div>
    <!-- PRINT BLANKO PEMERIKSAAN -->
    <div style="display: none" id="blanko_pemeriksaan">
      <section class="content-print A4">
        <div class="sheet">
            <div class="w-100">
                <table border="0" class="custom-table">
                <tbody>
                    <tr>
                        <td style="width: 15%;vertical-align: middle;padding-left: 1.5mm;padding-right: 1.5mm;"><img :src="logo" alt="" style="width: 100%;"></td>
                        <td style="width: 70%;vertical-align: middle;">
                            <h6 style="text-align: center;font-weight: bold;margin-bottom: 0;font-size: 16pt;">PEMERINTAH KABUPATEN/KOTA <span>NAMA KABKOT</span> </h6>
                            <h6 style="text-align: center;font-weight: bold;margin-bottom: 0;font-size: 16pt;"><span>DINAS KESEHATAN</span></h6>
                            <h6 style="text-align: center;font-weight: bold;margin-bottom: 0;font-size: 16pt;">PUSKESMAS <span>NAMA PUSKESMAS</span> </h6>
                            <h6 style="text-align: center;font-weight: normal;font-size: 10pt;"><span>Jl. Lorem ipsum dolor sit, amet consectetur adipisicing elit. Soluta, nulla</span></h6>
                            <h6 style="text-align: center;font-weight: normal;font-size: 10pt;"><span>Email : emailpuskesmas@gmil.com</span> <span>Telp. : 0987654321</span></h6>
                        </td>
                        <td style="width: 15%;vertical-align: middle;padding-left: 1.5mm;padding-right: 1.5mm;"><img src="../../../cetak/puskesmas-logo.png" alt="" style="width: 100%;"></td>
                    </tr>
                </tbody>
                </table>
            </div>

            <div class="w-100" style="margin-top: 2.5mm;">
                <div class="line"></div>
            </div>

            <div class="w-100" style="margin-top: 5mm;">
                <h6 style="font-size: 16pt;font-weight: bold;text-align: center;"><u>PERMINTAAN PEMERIKSAAN LABORATORIUM</u></h6>
            </div>

            <div class="w-100-flex" style="margin-top: 5mm;">
                <div style="width: 50%;">
                    <table class="custom-table">
                        <tbody>
                            <tr>
                                <td style="width: 30mm;">Nama</td>
                                <td style="width: 5mm;">:</td>
                                <td>-</td>
                            </tr>
    
                            <tr>
                                <td>No. Registrasi</td>
                                <td>:</td>
                                <td>-</td>
                            </tr>
    
                            <tr>
                                <td>Nama</td>
                                <td>:</td>
                                <td>-</td>
                            </tr>
    
                            <tr>
                                <td>Umur</td>
                                <td>:</td>
                                <td>&nbsp;/&nbsp;</td>
                            </tr>
    
                            <tr>
                                <td>NIK</td>
                                <td>:</td>
                                <td>&nbsp;/&nbsp;</td>
                            </tr>
    
                            <tr>
                                <td>Jenis Kelamin</td>
                                <td>:</td>
                                <td>&nbsp;/&nbsp;</td>
                            </tr>
    
                            <tr>
                                <td>Alamat</td>
                                <td>:</td>
                                <td>&nbsp;/&nbsp;</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div style="width: 50%;">
                    <table class="custom-table">
                        <tbody>
                            <tr>
                                <td colspan="3" style="font-weight: bold;">Pasien</td>
                            </tr>
                            <tr>
                                <td style="width: 30mm;">Gejala Penyakit</td>
                                <td style="width: 5mm;">:</td>
                                <td>-</td>
                            </tr>
    
                            <tr>
                                <td>Pengobatan</td>
                                <td>:</td>
                                <td>-</td>
                            </tr>
                        </tbody>
                    </table>

                    <table class="custom-table" style="margin-top: 5mm;">
                        <tbody>
                            <tr>
                                <td colspan="3" style="font-weight: bold;">Spimen Rujukan</td>
                            </tr>
                            <tr>
                                <td style="width: 30mm;">Jenis</td>
                                <td style="width: 5mm;">:</td>
                                <td>-</td>
                            </tr>
    
                            <tr>
                                <td>Asal Bahan</td>
                                <td>:</td>
                                <td>-</td>
                            </tr>

                            <tr>
                                <td>Tanggal/Jam Pengambilan SP</td>
                                <td>:</td>
                                <td>-</td>
                            </tr>

                            <tr>
                                <td>Petugas</td>
                                <td>:</td>
                                <td>-</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="w-100" v-if="list_tindakan.length" style="width: 100%;">
                <div>
                  <!-- <div style="width: 30%;float: left; margin: 1%">
                    <table class="custom-table" border="1">
                        <thead>
                            <tr>
                                <th>No.</th>
                                <th>Jenis Pemeriksaan</th>
                            </tr>
                        </thead>
                        <tbody>
                          <template v-for="(tindakan, index) in list_tindakan">
                            <tr>
                              <td></td>
                              <td>{{ tindakan.nama_tindakan_laboratorium }}</td>
                            </tr>
                            <tr v-for="(sub, ind) in tindakan.bulk_sub_tindakan_lab" :key="sub.sub_tindakan_lab_id">
                                <td>{{ ind + 1 }}</td>
                                <td>
                                  <div class="w-100-flex" style="height: 20px;justify-content: flex-start;align-items: center;">
                                      <div style="width: 10px;height: 10px;border:1px solid black;margin-right: 1.5mm;"></div>
                                      <h6>{{ sub.nama_sub_tindakan_lab }}</h6>
                                  </div>
                                </td>
                            </tr>
                          </template>
                        </tbody>
                    </table>
                  </div> -->

                  <div class="print-row">
                    <div class="print-col">
                      <div class="print-card-column">
                        <div class="print-card" v-for="(tindakan, index) in list_tindakan" :key="index + 'list_tindakan'">
                          <div class="print-card-body">
                            <!-- <b-form-checkbox
                              :id="tindakan.tindakan_laboratorium_id"
                              class="mt-1 title-checkbox"
                              v-model="tindakan.checked"
                              @change="toggleSelect(tindakan)"
                            >{{ tindakan.nama_tindakan_laboratorium }}</b-form-checkbox> -->
                            <!-- <div>{{ tindakan.nama_tindakan_laboratorium }}</div>

                            <div v-for="(sub) in tindakan.bulk_sub_tindakan_lab" :key="sub.sub_tindakan_lab_id"> -->
                              <!-- <b-form-checkbox
                                :id="sub.sub_tindakan_lab_id"
                                class="mt-1"
                                v-model="sub.checked"
                                ref="subTindakan"
                                :disabled="sub.disabled"
                                @change="setData()"
                              ><span class="font-checkbox">{{ sub.nama_sub_tindakan_lab }}</span></b-form-checkbox> -->
                              
                              <!-- <div class="w-100-flex" style="height: 20px;justify-content: flex-start;align-items: center;">
                                <div style="width: 10px;height: 10px;border:1px solid black;margin-right: 1.5mm;"></div>
                                <h6>{{ sub.nama_sub_tindakan_lab }}</h6>
                              </div>
                            </div> -->

                            <table class="custom-table" border="1">
                              <thead>
                                  <tr>
                                      <th>No.</th>
                                      <th>Jenis Pemeriksaan</th>
                                  </tr>
                              </thead>
                              <tbody>
                                <!-- <template v-for="(tindakan, index) in list_tindakan"> -->
                                  <tr>
                                    <td></td>
                                    <td>{{ tindakan.nama_tindakan_laboratorium }}</td>
                                  </tr>
                                  <tr v-for="(sub, ind) in tindakan.bulk_sub_tindakan_lab" :key="ind + 'sub_tindakan_lab_id'">
                                      <td>{{ ind + 1 }}</td>
                                      <td>
                                        <div class="w-100-flex" style="height: 20px;justify-content: flex-start;align-items: center;">
                                            <div style="width: 10px;height: 10px;border:1px solid black;margin-right: 1.5mm;"></div>
                                            <h6>{{ sub.nama_sub_tindakan_lab }}</h6>
                                        </div>
                                      </td>
                                  </tr>
                                <!-- </template> -->
                              </tbody>
                          </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- <div style="width: 32%;">
                    <table class="custom-table" border="1">
                        <thead>
                            <tr>
                                <th>No.</th>
                                <th>Jenis Pemeriksaan</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1.</td>
                                <td>-</td>
                            </tr>
                        </tbody>
                    </table>
                </div> -->
                <!-- <div style="width: 32%;">
                    <table class="custom-table" border="1">
                        <thead>
                            <tr>
                                <th>No.</th>
                                <th>Jenis Pemeriksaan</th>
                            </tr>
                        </thead>
                        <tbody> -->
                            <!-- judul -->
                            <!-- <tr>
                                <td>&nbsp;</td>
                                <td><strong>PEMERIKSAAN AIR FISIKA</strong></td>
                            </tr> -->

                            <!-- isi judul -->
                            <!-- <tr>
                                <td>1.</td>
                                <td>
                                    <div class="w-100-flex" style="height: 20px;justify-content: flex-start;align-items: center;">
                                        <div style="width: 10px;height: 10px;border:1px solid black;margin-right: 1.5mm;"></div>
                                        <h6>Bau</h6>
                                    </div>
                                </td>
                            </tr>

                            <tr>
                                <td>2.</td>
                                <td>
                                    <div class="w-100-flex" style="height: 20px;justify-content: flex-start;align-items: center;">
                                        <div style="width: 10px;height: 10px;border:1px solid black;margin-right: 1.5mm;"></div>
                                        <h6>Warna</h6>
                                    </div>
                                </td>
                            </tr> -->

                        <!-- </tbody>
                    </table>
                </div> -->
            </div>
            <div class="w-100" style="margin-top: 10mm;">
                <h6>Keterangan :</h6>
                <h6>-</h6>
            </div>
            <div class="w-100" style="margin-top: 10mm;">
                <table class="custom-table">
                    <tbody>
                        <tr>
                            <td style="width: 60%;">&nbsp;</td>
                            <td style="width: 40%;">
                                <h6 style="text-align: center;">.....................................</h6>
                                <h6 style="text-align: center;">Dokter/Pengirim</h6>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <h6 style="text-align: center;">(.......................................)</h6>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
      </section>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
moment.locale('id');

export default {
  components:{
  },
  props: [
  'dataAnamnesa',
  'test',
  'kunjungan',
  'listDokter',
  'listPerawat'
  ],
  data() {
    return {
      // value: null,
      idnya: "--- Otomatis ---",
      noAntrian: "-",
      tgl: this.$moment(new Date()).format("YYYY-MM-DD HH:mm"),
      tglPrint: this.$moment(new Date()).format("YYYY-MM-DD"),
      statusPemeriksaan: [
        {value: true, text: "CITO"},
        {value: false, text: "TIDAK CITO"}
      ],
      status_pemeriksaan: null,
      hasilPemeriksaan: [],
      rujukanPuskesmas: [],
      list_paket: [],
      list_tindakan: [],
      list_kunjungan: [],
      data_kunjungan: null,
      tampungan: [],
      rows: 100,
      currentPage: 1,
      perPage: 2,
      dokter: "",
      perawat: "",
      nama_dokter: "",
      nama_perawat: "",
      datanya: "",
      datanya2: "",
      busy:false,
      sudah: false,
      data_print: {},
      printReady: false
    }
  },
  computed: {
    logo() {
      return this.$store.state.logo;
    },
  },
  watch: {
    dataAnamnesa: {
      handler(data) {
        const vm = this
        vm.dokter = vm.$findKey(vm.listDokter, data.ms_dokter_id, true, 'tenaga_medis_id')
        vm.perawat = vm.$findKey(vm.listPerawat, data.ms_perawat_id, true, 'tenaga_medis_id')
        // console.log(vm.dokter, 'ini dokter');
        // console.log(vm.perawat, 'afguawgckdahsikcgfbilasbgcfilasildcasli');
      },
       deep: true
    },
    kunjungan(newVal, oldVal){
      if(newVal != oldVal){
        // console.log(newVal, oldVal, 'agduawgbgukcfagsukchbasikkhcdawwgbduka');
        this.getData()
      }
    },
  },
  // async mounted() {
  //   this.getData()
  // },
  methods: {
    async getData(){
      // console.log('abc');
      let vm = this
      vm.list_kunjungan = []
      let res1 = await vm.$axios.post("/paket_lab/list");
      vm.list_paket = res1.data.data
      let res2 = await vm.$axios.post("/tindakan_lab/list");
      vm.list_tindakan = res2.data.data
      let res3 = await vm.$axios.post("/kunjungan_lab/list", {
        kunjungan_id: vm.kunjungan.kunjungan_id,
        halaman: 0,
        jumlah: 1000,
      });
      // console.log(res3, 'ini list kunjungan lab');
      if(res3.data.data.length){
        vm.list_kunjungan = res3.data.data
        vm.sudah = true
      }
      for (let i = 0; i < vm.list_paket.length; i++) {
        let x = vm.list_paket[i];
        x.iconnya = "cil-plus"
        x.variantnya = "outline-dark"
        x.checked = false
      }
      for (let i = 0; i < vm.list_tindakan.length; i++) {
        let x = vm.list_tindakan[i];
        x.checked = false
        for (let j = 0; j < x.bulk_sub_tindakan_lab.length; j++) {
          let y = x.bulk_sub_tindakan_lab[j];
          y.checked = false
          y.disabled = false
        }
      }
      vm.getDetail()
      vm.nama_dokter = vm.dokter.nama_tenaga_medis
      vm.nama_perawat = vm.perawat.nama_tenaga_medis
      vm.$forceUpdate()
      // console.log(vm.list_paket, 'list paket');
      // console.log(vm.dataAnamnesa, 'list ini anamnesa');
      // console.log(vm.kunjungan, 'ini detail kunjungan');
      
    },
    getDetail(){
      let vm = this
      vm.$axios('/kunjungan/details_by_id/' + vm.kunjungan.kunjungan_id)
        .then((res) => {
            // console.log(res, 'ini ress data kunjungan');
            if(res.data.data.length){
              vm.data_kunjungan = res.data.data[0]
            }
        })
        .catch((err) => {
            console.log(err);
        })
    },
    getDetailKunjungan(x){
      let vm = this
      // console.log(x, 'ini x');
      vm.$axios("/pemeriksaan_lab/details_by_kunjungan_lab_id/"+ x.kunjungan_lab_id)
      .then((res) => {
        // console.log(res, 'ini detail kunjungan');
        if(res.data.data.length){
          vm.datanya = res.data.data[0]
          for (let i = 0; i < vm.datanya.sub_pemeriksaan_lab.length; i++) {
            let x = vm.datanya.sub_pemeriksaan_lab[i];
            if(x.nilai_pemeriksaan == "Tidak Normal"){
              x.textColor = 'abang'
            }
            
          }
        }
        else{
          vm.datanya = ""
        }
      })
      .catch((err) => {
        console.log(err);
      })

      vm.$axios("/kunjungan_lab/details_by_id/"+ x.kunjungan_lab_id)
        .then((res) => {
          // console.log(res, 'ini data2');
          if(res.data.data.length){
            vm.datanya2 = res.data.data[0]
            // console.log(vm.datanya2, 'data2');
          }
        })
        .catch((err) => {
          console.log(err);
        })

      
    },
    toggleSelect(x) {
      // console.log(x, 'ini x');
        let select = x.checked;
        x.bulk_sub_tindakan_lab.forEach(function (data) {
            data.checked = select;
        });
        this.setData()
        this.$forceUpdate()
    },
    assignData(x){
      let vm = this
      x.checked = !x.checked
      if(x.checked){
        for (let i = 0; i < x.bulk_paket_lab.length; i++) {
          let y = x.bulk_paket_lab[i];
          for (let j = 0; j < vm.list_tindakan.length; j++) {
            let z = vm.list_tindakan[j];
            for (let k = 0; k < z.bulk_sub_tindakan_lab.length; k++) {
              let p = z.bulk_sub_tindakan_lab[k];
              if(y.sub_tindakan_lab_id == p.sub_tindakan_lab_id){
                p.disabled = true
                p.checked = true
              }
            }
          }
        }
        this.setData()
        this.$forceUpdate()
      }else {
        for (let i = 0; i < x.bulk_paket_lab.length; i++) {
          let y = x.bulk_paket_lab[i];
          for (let j = 0; j < vm.list_tindakan.length; j++) {
            let z = vm.list_tindakan[j];
            for (let k = 0; k < z.bulk_sub_tindakan_lab.length; k++) {
              let p = z.bulk_sub_tindakan_lab[k];
              if(y.sub_tindakan_lab_id == p.sub_tindakan_lab_id){
                p.disabled = false
                p.checked = false
              }
            }
          }
        }
        this.setData()
        this.$forceUpdate()
      }
      
    },
    setData(){
      let vm = this
      vm.tampungan = []
      for (let i = 0; i < vm.list_paket.length; i++) {
        let x = vm.list_paket[i];
        if(x.checked == true){
          x.is_paket = true
          vm.tampungan.push(x)
          for (let j = 0; j < x.bulk_paket_lab.length; j++) {
            let y = x.bulk_paket_lab[j];
            for (let k = 0; k < vm.list_tindakan.length; k++) {
              let z = vm.list_tindakan[k];
              for (let l = 0; l < z.bulk_sub_tindakan_lab.length; l++) {
                let p = z.bulk_sub_tindakan_lab[l];
                if(y.sub_tindakan_lab_id == p.sub_tindakan_lab_id){
                  p.is_paket = false
                  p.harga_tindakan_lab = 0
                  vm.tampungan.push(p)
                }
              }
            }
          }
        }
      }
      for (let i = 0; i < vm.list_tindakan.length; i++) {
        let x = vm.list_tindakan[i];
        for (let j = 0; j < x.bulk_sub_tindakan_lab.length; j++) {
          let y = x.bulk_sub_tindakan_lab[j];
          if(y.checked == true){
            let sama = 0
            for (let k = 0; k < vm.tampungan.length; k++) {
              let z = vm.tampungan[k];
              if(y.sub_tindakan_lab_id == z.sub_tindakan_lab_id){
                sama +=1
              }
            }
            if(sama == 0){
              y.is_paket = false
              vm.tampungan.push(y)
            }
          }
        }
      }
      vm.$forceUpdate()
      // console.log(vm.tampungan, 'ini data akhir');
    },
    simpanFinalData(){
      let vm = this
      let bulk_paket = []
      let bulk_sub = []
      vm.busy = true
      for (let i = 0; i < vm.tampungan.length; i++) {
        let x = vm.tampungan[i];
        let tampung = {}
        if(x.is_paket == true){
          tampung.paket_lab_id = x.paket_lab_id
          tampung.harga_paket_kunjungan = x.harga_paket_lab
          bulk_paket.push(tampung)
        }else {
          tampung.sub_tindakan_lab_id = x.sub_tindakan_lab_id
          tampung.harga_sub_lab_kunjungan = x.harga_tindakan_lab
          bulk_sub.push(tampung)
        }
      }
      let total = 0
      for (let i = 0; i < bulk_paket.length; i++) {
        let x = bulk_paket[i];
        total += x.harga_paket_kunjungan
      }
      for (let i = 0; i < bulk_sub.length; i++) {
        let x = bulk_sub[i];
        total += x.harga_sub_lab_kunjungan
      }
      

      vm.$axios.post('/kunjungan_lab/register', {
        no_antrian: vm.data_kunjungan.nomor_antrian,
        tanggal_kunjungan_lab: vm.tgl,
        total_harga_kunjungan_lab: total,
        kunjungan_id: vm.data_kunjungan.kunjungan_id,
        ms_poli_id: vm.data_kunjungan.ms_poli_id,
        bulk_paket_kunjungan: bulk_paket,
        bulk_sub_lab_kunjungan: bulk_sub,
        initial: vm.data_kunjungan.initial,
        tipe_antrian: 'laboratorium',
        tanggal_antrian: vm.data_kunjungan.tanggal_antrian ? vm.$moment(vm.data_kunjungan.tanggal_antrian).format("YYYY-MM-DD HH:mm") : vm.$moment().format("YYYY-MM-DD HH:mm"),
        jadwal_id: vm.data_kunjungan.jadwal_id,
        rm_id: vm.data_kunjungan.rm_id,
        pendaftaran_id: vm.data_kunjungan.pendaftaran_id,
        urgency_lab: vm.status_pemeriksaan,
      })
      .then((res) => {
        // console.log(res);
        if(res.data.status == 200 && res.data.message == "sukses"){
          vm.$store.commit("set_alert", {variant: "success", msg: 'Berhasil Menyimpan Kunjungan Laboratorium', showing: true});
          vm.getData()
          vm.busy = false
          vm.$forceUpdate()
        }else {
          vm.$store.commit("set_alert", {variant: "danger", msg: res.data.message, showing: true});
          vm.busy = false
        }
      })
      .catch((err) => {
        vm.busy = false
        vm.$store.commit("set_alert", {variant: "danger", msg: err.message, showing: true});
      })
    },
    async printLab(val) {
      const vm = this
      let statusData = false
      if (val == 'hasil_pemeriksaan') {
        statusData = await vm.getDataPrint() 
      } else {
        statusData = true
      }
      const options_A4 = {
        name: '_blank',
        specs: [
          'fullscreen=yes',
          'titlebar=yes',
          'scrollbars=yes'
        ],
        styles: [
        '/style-paper-a4-lab.css',
        ],
        timeout: 1000, // default timeout before the print window appears
        autoClose: true, // if false, the window will not close after printing
        windowTitle: localStorage.getItem('nama_puskesmas'), // override the window title
      }

      if (statusData) {
        await vm.$htmlToPaper(val, options_A4);
      } else {
        vm.$store.commit("set_alert", { variant: "warning", msg: "TIDAK ADA DATA PEMERIKSAAN LABORATORIUM", showing: true });
      }
    },
    async getDataPrint() {
      const vm = this
      let x = {
        kunjungan_id: this.kunjungan.kunjungan_id
      }
      try {
        let res = await vm.$axios.post("/cetak/cetak_hasil_pemeriksaan_laboratorium", x)
        if (res.data.status == 200) {
          vm.data_print = res.data.data[0]
          vm.printReady = true
          return true
        }
      } catch (err) {
        console.log(err);
        vm.printReady = true
        return false
      }
    }
  },
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&display=swap');
  #page-laboratorium{
    font-family: 'Inter', sans-serif !important;
  }
  /* th{
    background-color: #D52F65;
    color:#fff;
    font-weight: 600;
  } */
  .abang {
    color:#D52F65;
  }
  th,td{
    border:1px solid #F1F1F1 !important
  }

  /* .custom-title-color-black{
    font-weight: 700;
    color:#D52F65;
    font-size: 16px;
  } */

  .btn-primary{
    background-color: #9C4098;
  }

  .btn-outline-dark{
    /* color: #7C7C7C; */
    border-color: #7C7C7C;
    font-weight: 600;
  }

  .card-columns .card-body{
    padding: 10px;
  }

  .title-checkbox{
    font-weight: 600;
    color: #202020;
  }

  .font-checbox{
    font-weight: 400;
  }

  #collapse-1 .card{
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }

  .custom-font-label{
    font-size:12px;
    font-weight:600;
    color:#7C7C7C
  }

  .custom-font-value{
    font-size:14px;
    font-weight:400;
    color:#202020
  }

  /* Print Style */
  .print-row {
    row-gap: 8px;
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    box-sizing: border-box;
    font-size: 12px;
    font-weight: 400;
  }
  .print-col {
    flex-basis: 100%;
    flex-grow: 0;
    flex-shrink: 0;
    max-width: 100%;
    position: relative;
    box-sizing: border-box;
  }
  .print-card-column {
    box-sizing: border-box;
    column-count: 3;
    column-gap: 17.5px;
  }
  .print-card {
    background-clip: border-box;
    background-color: rgb(255, 255, 255);
    border-bottom-color: rgb(255, 255, 255);
    border-bottom-left-radius: 3.5px;
    border-bottom-right-radius: 3.5px;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-image-outset: 0;
    border-image-repeat: stretch;
    border-image-slice: 100%;
    border-left-color: rgb(211, 211, 211);
    border-left-style: solid;
    border-left-width: 1px;
    border-right-color: rgb(211, 211, 211);
    border-right-style: solid;
    border-right-width: 1px;
    border-top-color: rgb(255, 255, 255);
    border-top-left-radius: 3.5px;
    border-top-right-radius: 3.5px;
    border-top-style: solid;
    border-top-width: 1px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 4px 4px 0px;
    box-sizing: border-box;
    color: rgb(60, 75, 100);
    display: inline-block;
    flex-direction: column;
    min-width: 0;
    margin-bottom: 10.5px;
  }
  .print-card-body {
    padding: 10px;
  }
</style>