<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-row class="" style="margin: 0px -6px;">
          <b-col cols="3"><strong class="">No Antrian</strong></b-col>
          <b-col cols="1" class="text-right">:</b-col>
          <b-col cols="8"><span class="">{{dataLab.no_antrian || '-'}}</span></b-col>
          <b-col cols="3"><strong class="">Dokter</strong></b-col>
          <b-col cols="1" class="text-right">:</b-col>
          <b-col cols="8"><span class="">{{dataLab.nama_dokter || '-'}}</span></b-col>
          <b-col cols="3"><strong class="">Perawat</strong></b-col>
          <b-col cols="1" class="text-right">:</b-col>
          <b-col cols="8"><span class="">{{dataLab.nama_perawat || '-'}}</span></b-col>
          <b-col cols="3"><strong class="">Tanggal</strong></b-col>
          <b-col cols="1" class="text-right">:</b-col>
          <b-col cols="8"><span class="">{{dataLab.tanggal_kunjungan_lab ? $moment(dataLab.tanggal_kunjungan_lab).format('LLLL') : '-'}}</span></b-col>
        </b-row>
      </b-col>
      <b-col cols="12" class="mt-3">
        <b-table
          v-if="dataLab.bulk_sub_lab_kunjungan"
          :items="dataLab.bulk_sub_lab_kunjungan"
          :fields="fieldsLab"
          responsive
          show-empty
          small
          bordered
          striped
          hover
          :busy="busy"
        >
          <template #cell(no)="item">{{item.index+1}}</template>
          <template #cell(harga_tindakan_lab)="item">{{$numberFormat(item.item.harga_tindakan_lab, 'idr')}}</template>
          <template #cell(actions)="item">
            <div class="d-flex nowrap justify-content-center">
              <b-button
                variant="info"
                size="sm"
                class="ml-1"
                @click="item.toggleDetails"
                v-c-tooltip.hover.click="'Detail Obat'"
              >
                <CIcon name="cil-info" />
              </b-button>
            </div>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <!-- <pre>dataLab {{dataLab}}</pre> -->
  </div>
</template>
<script>
export default {
  props: [
    'dataKunjungan',
  ],
  data() {
    return {
      busy: false,
      dataLab: {},
      fieldsLab: [
        {
          key: "no",
          label: "No",
          sortDirection: "desc",
          sortable: true,
          class: "table-number text-center",
        },
        {
          key: "nama_sub_tindakan_lab",
          label: "Tindakan",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "harga_tindakan_lab",
          label: "Harga",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        // {
        //   key: "nama_obat",
        //   label: "Obat",
        //   sortable: true,
        //   sortDirection: "desc",
        //   class: "text-left",
        // },
      ],
    }
  },
  computed: {
    
  },
  mounted() {
    const vm = this
    vm.getData()
  },
  methods: {
    async getData(){
      const vm = this
      vm.busy = true
      await new Promise(resolve => setTimeout(resolve, 100));
      try {
        let dataLab = await vm.$axios.post('/kunjungan_lab/list', {kunjungan_id: vm.dataKunjungan.kunjungan_id})
        // let dataLab = await vm.$axios.get('/kunjungan_lab/list/' + vm.dataKunjungan.kunjungan_id)
        // console.log('dataLab', dataLab)
        if(dataLab.data.status == 200 && dataLab.data.data.length > 0){
          vm.dataLab = dataLab.data.data[0]
        }else{
          // vm.triggerAlert({ variant: "warning", msg: 'Resep Tidak Ditemukan', showing: true })
        }
      } catch (error) {
        console.log(error)
        vm.triggerAlert({ variant: "warning", msg: 'Terjadi kesalahan sistem', showing: true });
      } finally {
        vm.busy = false
      }
    },
    triggerAlert(event) {
      let vm = this;
      vm.$store.commit("set_alert", event);
    },
  },
}
</script>