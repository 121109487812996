<template>
  <div>
    <b-modal
      id="modal-rujuk-internal"
      size="xl"
      centered
      title="Rujuk Internal"
      header-bg-variant="primary"
      header-text-variant="light"
      @show="showModal()"
    >
      <!-- scrollable  -->
      <b-row class="" style="margin: 0px -4px">
        <b-col cols="12">
          <b-row class="" style="margin: 0px -4px">
            <b-col cols="12" sm="12" md="12" lg="12">
              <h5 class="custom-title-card"><strong>DATA PASIEN</strong></h5>
            </b-col>
            <b-col cols="5" sm="5" md="5" lg="5">
              <label for="nama_lengkap">Nama Pasien</label>
              <b-form-input
                id="nama_lengkap"
                v-model="dataPasien.nama_lengkap"
                disabled
              ></b-form-input>
            </b-col>
            <b-col cols="3" sm="3" md="3" lg="3">
              <label for="jenis_kelamin">Jenis Kelamin</label>
              <b-form-input
                id="jenis_kelamin"
                v-model="dataPasien.nama_jenis_kelamin"
                disabled
              ></b-form-input>
            </b-col>
            <b-col cols="4" sm="4" md="4" lg="4">
              <label for="umur">Umur</label>
              <b-form-input
                id="umur"
                v-model="dataPasien.umur"
                disabled
              ></b-form-input>
            </b-col>
            <b-col cols="4" sm="4" md="4" lg="4">
              <label for="nik">NIK</label>
              <b-form-input
                id="nik"
                v-model="dataPasien.nik"
                disabled
              ></b-form-input>
            </b-col>
            <b-col cols="4" sm="4" md="4" lg="4">
              <label for="telepon">Telepon</label>
              <b-form-input
                id="telepon"
                v-model="dataPasien.no_telp"
                disabled
              ></b-form-input>
            </b-col>
            <b-col cols="4" sm="4" md="4" lg="4">
              <label for="no_bpjs">No BPJS</label>
              <b-form-input
                id="no_bpjs"
                v-model="dataPasien.no_bpjs"
                disabled
              ></b-form-input>
            </b-col>
            <b-col cols="4" sm="4" md="4" lg="4">
              <label for="alamat_sekarang">Alamat Sekarang</label>
              <b-form-input
                id="alamat_sekarang"
                v-model="dataPasien.alamat_sekarang"
                disabled
              ></b-form-input>
            </b-col>
            <b-col cols="4" sm="4" md="4" lg="4">
              <label for="pekerjaan">Pekerjaan</label>
              <b-form-input
                id="pekerjaan"
                v-model="dataPasien.pekerjaan"
                disabled
              ></b-form-input>
            </b-col>
            <b-col cols="4" sm="4" md="4" lg="4">
              <label for="nama_penjamin">Penanggung Jawab</label>
              <b-form-input
                id="nama_penjamin"
                v-model="dataPasien.nama_penjamin"
                disabled
              ></b-form-input>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="12">
          <b-row class="mt-1" style="margin: 0px -4px">
            <b-col cols="12" sm="12" md="12" lg="12">
              <h5 class="custom-title-card"><strong>INPUT DATA</strong></h5>
            </b-col>
            <b-col cols="6" sm="6" md="6" lg="6">
              <label for="rujukan_dari">Rujukan Dari <span class="text-danger">*</span></label>
              <Multiselect
                id="rujukan_dari"
                v-model="$v.dataForm.rujukan_dari.$model"
                :options="$store.state.data_static.rujukan_dari"
                :multiple="false"
                :searchable="true"
                :close-on-select="true"
                :show-labels="false"
                label="text"
                track-by="value"
                placeholder="-- Pilih Rujukan Dari --"
                size="sm"
              ></Multiselect>
            </b-col>
            <b-col cols="6" sm="6" md="6" lg="6">
              <label for="nama_perujuk">Nama Perujuk <span class="text-danger">*</span></label>
              <b-form-input
                id="nama_perujuk"
                type="text"
                :state="checkIfValid('dataForm', 'nama_perujuk')"
                v-model="$v.dataForm.nama_perujuk.$model"
              ></b-form-input>
            </b-col>
            <b-col cols="12"><hr></b-col>
            <b-col cols="4" sm="4" md="4" lg="4">
              <label for="tanggal_daftar">Tanggal Pendaftaran <span class="text-danger">*</span></label>
              <date-picker
                style="width: 100%;"
                id="tanggal_daftar"
                format="DD-MM-YYYY"
                :state="checkIfValid('dataForm', 'tanggal_daftar')"
                v-model="$v.dataForm.tanggal_daftar.$model"
              ></date-picker>
            </b-col>
            <b-col cols="4" sm="4" md="4" lg="4"></b-col>
            <!-- dataForm.kd_tkp {{dataForm.kd_tkp}}
            <b-col cols="4" sm="4" md="4" lg="4">
              <label for="kd_tkp">Jenis Layanan <span class="text-danger">*</span></label>
              <Multiselect
                id="kd_tkp"
                v-model="$v.dataForm.kd_tkp.$model"
                :options="$store.state.data_static.tkp"
                :multiple="false"
                :searchable="true"
                :close-on-select="true"
                :show-labels="false"
                label="text"
                track-by="kdTkp"
                placeholder="-- Pilih Jenis Layanan --"
                size="sm"
                :state="checkIfValid('dataForm', 'kd_tkp')"
              ></Multiselect>
            </b-col>  -->
            <b-col cols="4" sm="4" md="4" lg="4">
              <label for="no_antrian">No Antiran <span class="text-danger">*</span></label>
              <Multiselect
                id="no_antrian"
                v-model="$v.dataForm.no_antrian.$model"
                :options="listAntrian"
                :multiple="false"
                :searchable="true"
                :close-on-select="true"
                :show-labels="false"
                label="nama_no_antrian"
                track-by="antrian_id"
                placeholder=""
                :state="checkIfValid('dataForm', 'no_antrian')"
              >
                <template slot="singleLabel" slot-scope="props"><div style="" class="option__desc">{{ props.option.nama_no_antrian }}</div></template>
              </Multiselect>
            </b-col>
            <b-col cols="12" sm="12" md="12" lg="12">
              <label for="keluhan">Keluhan <span class="text-danger">*</span></label>
              <b-form-textarea
                id="keluhan"
                v-model="dataForm.keluhan"
                :state="checkIfValid('dataForm', 'keluhan')"
              ></b-form-textarea>
            </b-col>
            <!-- <b-col cols="12" sm="12" md="12" lg="12">
              <label for="ms_poli_id">Poliklinik <span class="text-danger">*</span></label>
              <multiselect
                id="ms_poli_id"
                v-model="dataForm.ms_poli_id"
                :options="listPoliklinik"
                :multiple="false"
                :searchable="true"
                :close-on-select="true"
                :show-labels="false"
                label="nama_poli"
                track-by="ms_poli_id"
                placeholder="-- Pilih Poliklinik --"
                size="sm"
                :state="checkIfValid('dataForm', 'ms_poli_id')"
              ></multiselect>
            </b-col> -->
            <b-col cols="12">
              <label for="keadaan_pasien">Keadaan / Kelainan Pasien</label>
              <b-form-group label="" v-slot="{ ariaDescribedby }">
                <b-form-checkbox-group
                  id="keadaan_pasien"
                  v-model="$v.dataForm.keadaan_pasien.$model"
                  :aria-describedby="ariaDescribedby"
                  name="flavour-2"
                >
                  <!-- stacked -->
                  <b-form-checkbox value="Handaya"><div style="margin-top: 2.5px;">Handaya</div></b-form-checkbox>
                  <b-form-checkbox value="Difabel"><div style="margin-top: 2.5px;">Difabel</div></b-form-checkbox>
                  <b-form-checkbox value="Lansia"><div style="margin-top: 2.5px;">Lansia</div></b-form-checkbox>
                  <b-form-checkbox :value="{text: 'Lain-lain', value: ''}"><div style="margin-top: 2.5px;">Tambah lainnya</div></b-form-checkbox>
                    <!-- :disabled="$findKey(dataForm.keadaan_pasien, 'Lain-lain', true, 'text') !== null" -->
                </b-form-checkbox-group>
              </b-form-group>
              <!-- <pre>{{$findKey(dataForm.keadaan_pasien, 'Lain-lain', true, 'text')}}</pre> -->
              <div class="ml-4" v-for="(x, key) of dataForm.keadaan_pasien" :key="key">
                <div class="d-flex mt-2" v-if="x.text === 'Lain-lain'">
                  <b-form-input
                    id="lain_lain"
                    class=""
                    type="text"
                    v-model="dataForm.keadaan_pasien[key].value"
                    placeholder=""
                  ></b-form-input>
                  <b-button
                    variant="danger"
                    size="sm"
                    class="ml-1"
                    v-c-tooltip.hover.click="'Hapus Data'"
                    @click="dataForm.keadaan_pasien.splice(key, 1)"
                    ><CIcon name="cil-trash" /></b-button
                  >
                </div>
              </div>
            </b-col>
            <b-col class="">
              <label for="ms_dokter_id">Status Sakit <span class="text-danger">*</span></label>
              <b-form-checkbox
                id="kunjungan_sakit"
                v-model="$v.dataForm.kunjungan_sakit.$model"
                name="kunjungan_sakit"
                :value="true"
                :unchecked-value="false"
                @change="dataForm.ms_dokter_id = null"
              >
                <div style="margin-top: 2.5px;">Sakit</div>
              </b-form-checkbox>
            </b-col>
            <b-col cols="10" >
              <label for="ms_dokter_id">Dokter <span class="text-danger" v-if="dataForm.kunjungan_sakit">*</span></label>
              <Multiselect
                id="ms_dokter_id"
                v-model="$v.dataForm.ms_dokter_id.$model"
                :options="listDokter"
                :multiple="false"
                :searchable="true"
                :close-on-select="true"
                :show-labels="false"
                label="nama_tenaga_medis"
                track-by="tenaga_medis_id"
                placeholder="-- Pilih Dokter --"
                size="sm"
                :disabled="!dataForm.kunjungan_sakit"
              ></Multiselect>
            </b-col>
            <b-col cols="12" sm="12" md="12" lg="12">
              <label for="ms_kelas_id">Kelas <span class="text-danger">*</span></label>
              <multiselect
                id="ms_kelas_id"
                v-model="dataForm.ms_kelas_id"
                :options="listKelas"
                :multiple="false"
                :searchable="true"
                :close-on-select="true"
                :show-labels="false"
                label="nama_kelas"
                track-by="ms_kelas_id"
                placeholder="-- Pilih Kelas --"
                size="sm"
                :state="checkIfValid('dataForm', 'ms_kelas_id')"
              ></multiselect>
            </b-col>
            <b-col cols="12" class="mt-2">
              <b-form-checkbox
                id="promotif_preventif"
                v-model="$v.dataForm.promotif_preventif.$model"
                name="promotif_preventif"
                :value="true"
                :unchecked-value="false"
              >
                <div style="margin-top: 2.5px;">Promotif Preppentif</div>
              </b-form-checkbox>
            </b-col>
            <b-col cols="12" sm="12" md="12" lg="12">
              <h5 class="custom-title-card mt-2"><strong>ASURANSI</strong></h5>
            </b-col>
            <b-col>
              <label for="asuransi">Asuransi <span class="text-danger">*</span></label>
              <Multiselect
                id="asuransi"
                v-model="dataForm.asuransi"
                :options="$store.state.data_static.asuransi"
                :multiple="false"
                :searchable="true"
                :close-on-select="true"
                :show-labels="false"
                label="text"
                track-by="value"
                placeholder="-- Pilih Asuransi --"
                size="sm"
                :state="checkIfValid('dataForm', 'asuransi')"
                @select="dataForm.no_kartu = null"
                @remove="dataForm.no_kartu = null"
              ></Multiselect>
            </b-col>
            <b-col cols="3" sm="3" md="3" lg="3" v-if="isAsuransiBPJS">
              <label for="type_no_asuransi">Type No</label>
              <multiselect
                id="type_no_asuransi"
                v-model="dataForm.type_no_asuransi"
                :options="listTypeNoAsuransi"
                :multiple="false"
                :searchable="true"
                :close-on-select="true"
                :show-labels="false"
                label="text"
                track-by="value"
                placeholder="Type"
                size="sm"
                :state="checkIfValid('dataForm', 'type_no_asuransi')"
                @input="dataForm.no_kartu = null"
              ></multiselect>
            </b-col>
            <b-col cols="6" sm="6" md="6" lg="6" v-if="isAsuransiBPJS">
              <label for="no_kartu">No BPJS/Asuransi</label>
              <b-input-group prepend="" class="">
                <b-form-input
                  id="no_kartu"
                  v-model="dataForm.no_kartu"
                  :state="checkIfValid('dataForm', 'no_kartu')"
                  :disabled="dataForm.asuransi == null || (isAsuransiBPJS && dataForm.type_no_asuransi == null) || isAsuransiUmum"
                  debounce="2000"
                  @keydown.enter="cekAsuransi()"
                ></b-form-input>
                <b-input-group-append>
                  <b-button 
                    variant="success" 
                    :disabled="dataForm.asuransi == null || (isAsuransiBPJS && dataForm.type_no_asuransi == null) || isAsuransiUmum || busy"
                    @click="cekAsuransi()"
                  >cek</b-button>
                </b-input-group-append>
              </b-input-group>
            </b-col>
            <b-col cols="12" sm="12" md="12" lg="12" v-if="isAsuransiBPJS">
              <label for="kdppk">Faskes Pertama</label>
              <Multiselect
                id="kdppk"
                v-model="dataForm.kdppk"
                :options="listProviderPeserta"
                :multiple="false"
                :searchable="true"
                :close-on-select="true"
                :show-labels="false"
                label="nmProvider"
                track-by="kdProvider"
                placeholder="-- Pilih Faskes Pertama --"
                size="sm"
                :state="checkIfValid('dataForm', 'kdppk')"
                disabled
              ></Multiselect>
            </b-col>
            <b-col cols="12" sm="12" md="12" lg="12">
              <h5 class="custom-title-card mt-2"><strong>PEMBAYARAN</strong></h5>
            </b-col>
            <b-col cols="12">
              <!-- <label for="tarif_pendaftaran">Tarif <span class="text-danger">*</span></label> -->
              <b-row style="margin: 0px -6px;">
                <b-col cols="10">
                  <label for="tarif_pendaftaran">Tarif <span class="text-danger">*</span></label>
                  <b-form-input
                    id="tarif_pendaftaran"
                    type="number"
                    :state="checkIfValid('dataForm', 'tarif_pendaftaran')"
                    v-model="$v.dataForm.tarif_pendaftaran.$model"
                  ></b-form-input>
                </b-col>
                <b-col cols="2">
                  <label for="status_bayar">Status Bayar <span class="text-danger">*</span></label>
                  <b-form-checkbox
                    id="status_bayar"
                    name="status_bayar"
                    unchecked-value="not_accepted"
                    v-model="$v.dataForm.status_bayar.$model"
                  ><div style="margin-top: 2.5px;">Bayar</div></b-form-checkbox>
                </b-col>
              </b-row>
            </b-col>
            <!-- <b-col cols="12">
              <label for="tagihan_pendaftaran_baru">Tagihan Baru <span class="text-danger">*</span></label>
              <b-form-input
                id="tagihan_pendaftaran_baru"
                type="number"
                :state="checkIfValid('dataForm', 'tagihan_pendaftaran_baru')"
                v-model="$v.dataForm.tagihan_pendaftaran_baru.$model"
              ></b-form-input>
            </b-col> -->
          </b-row>
        </b-col>
        <b-col cols="12"><hr></b-col>
        <b-col cols="6">
          <Tabel
            :listSkrining="listSkrining"
            :listPenyakitKhusus="listPenyakitKhusus"
            :listResikoKehamilan="listResikoKehamilan"
            :listPendaftaranHariIni="listPendaftaranHariIni" 
          />
        </b-col>
        <b-col cols="6">
        <!-- type_skrining <pre>{{dataForm.type_skrining}}</pre>  -->
          <b-row class="m-0 p-0">
            <b-col cols="12">
              <h6 class=""><strong>Skrining Visual </strong></h6>
              <b-card
                v-if="!dataForm.type_skrining || dataForm.type_skrining == 'danger'"
                border-variant="danger"
                header=""
                header-bg-variant="danger"
                header-text-variant="white"
                align="center"
              >
                <div class="" style="text-align: left !important;">
                  <b-form-group class="m-0" label="" v-slot="{ ariaDescribedby }">
                    <b-form-checkbox-group
                      id="skrining_visual"
                      v-model="$v.dataForm.skrining_visual.$model"
                      :aria-describedby="ariaDescribedby"
                      name="flavour-2"
                    >
                      <b-form-checkbox :value="{text: 'Tidak sadarkan diri atau pingsan', type:'danger'}"><div style="margin-top: 2.5px;">Tidak sadarkan diri atau pingsan</div></b-form-checkbox>
                      <b-form-checkbox :value="{text: 'Tidak bernafas atau kesulitan bernafas', type:'danger'}"><div style="margin-top: 2.5px;">Tidak bernafas atau kesulitan bernafas</div></b-form-checkbox>
                      <b-form-checkbox :value="{text: 'Nadi tidak teraba atau henti jantung', type:'danger'}"><div style="margin-top: 2.5px;">Nadi tidak teraba atau henti jantung</div></b-form-checkbox>
                      <b-form-checkbox :value="{text: 'Kejang berulang atau kejang lama', type:'danger'}"><div style="margin-top: 2.5px;">Kejang berulang atau kejang lama</div></b-form-checkbox>
                    </b-form-checkbox-group>
                  </b-form-group>
                </div>
              </b-card>
              <b-card
                v-if="!dataForm.type_skrining || dataForm.type_skrining == 'warning'"
                border-variant="warning"
                header=""
                header-bg-variant="warning"
                header-text-variant="white"
                align="center"
              >
                <div class="" style="text-align: left !important;">
                  <b-form-group class="m-0" label="" v-slot="{ ariaDescribedby }">
                    <b-form-checkbox-group
                      id="skrining_visual"
                      v-model="$v.dataForm.skrining_visual.$model"
                      :aria-describedby="ariaDescribedby"
                      name="flavour-2"
                    >
                      <b-form-checkbox :value="{text: 'Nyeri hebat', type:'warning'}"><div style="margin-top: 2.5px;">Nyeri hebat</div></b-form-checkbox>
                      <b-form-checkbox :value="{text: 'Nyeri dada', type:'warning'}"><div style="margin-top: 2.5px;">Nyeri dada</div></b-form-checkbox>
                    </b-form-checkbox-group>
                  </b-form-group>
                </div>
              </b-card>
              <b-card
                v-if="!dataForm.type_skrining || dataForm.type_skrining == 'success'"
                border-variant="success"
                header=""
                header-bg-variant="success"
                header-text-variant="white"
                align="center"
              >
                <div class="" style="text-align: left !important;">
                  <b-form-group class="m-0" label="" v-slot="{ ariaDescribedby }">
                    <b-form-checkbox-group
                      id="skrining_visual"
                      v-model="$v.dataForm.skrining_visual.$model"
                      :aria-describedby="ariaDescribedby"
                      name="flavour-2"
                    >
                      <b-form-checkbox :value="{text: 'Tampak pucat', type:'success'}"><div style="margin-top: 2.5px;">Tampak pucat</div></b-form-checkbox>
                      <b-form-checkbox :value="{text: 'Lemas', type:'success'}"><div style="margin-top: 2.5px;">Lemas</div></b-form-checkbox>
                      <b-form-checkbox :value="{text: 'Sempoyongan', type:'success'}"><div style="margin-top: 2.5px;">Sempoyongan</div></b-form-checkbox>
                    </b-form-checkbox-group>
                  </b-form-group>
                </div>
              </b-card>
              <b-card
                v-if="!dataForm.type_skrining || dataForm.type_skrining == 'info'"
                border-variant="info"
                header=""
                header-bg-variant="info"
                header-text-variant="white"
                align="center"
              >
                <div class="" style="text-align: left !important;">
                  <b-form-group class="m-0" label="" v-slot="{ ariaDescribedby }">
                    <b-form-checkbox-group
                      id="skrining_visual"
                      v-model="$v.dataForm.skrining_visual.$model"
                      :aria-describedby="ariaDescribedby"
                      name="flavour-2"
                    >
                      <b-form-checkbox :value="{text: 'Kondisi stabil', type:'info'}"><div style="margin-top: 2.5px;">Kondisi stabil</div></b-form-checkbox>
                    </b-form-checkbox-group>
                  </b-form-group>
                </div>
              </b-card>
            </b-col>
            <b-col cols="12">
              <h6 class=""><strong>Instalasi <span class="text-danger">*</span></strong></h6>
              <div class="">
                <b-button 
                  @click="
                    dataForm.kd_tkp = $findKey($store.state.data_static.tkp, x.kd_tkp, true, 'kdTkp'), 
                    dataForm.instalasi = x.value, 
                    dataForm.ms_poli_id = null, 
                    dataForm.ms_ruang_id = null, 
                    dataForm.ms_kamar_id = null, 
                    dataForm.ms_bed_id = null, 
                    listKamar = [],
                    listBed = [],
                    getListRuangan(x.value)
                  " 
                  :variant="dataForm.instalasi == x.value ? 'primary' : 'info'" 
                  class="mr-2 mb-2" 
                  v-for="(x, key) of listInstalasi" 
                  :key="key"
                >{{x.text}}</b-button>
              </div>
            </b-col>
            <b-col cols="12" v-if="dataForm.instalasi == 'rajal'">
              <h6 class=""><strong>Poli <span class="text-danger">*</span></strong></h6>
              <div class="">
                <b-button 
                  @click="dataForm.ms_poli_id = x.ms_poli_id, dataForm.initial = x.kode_poli_puskesmas" 
                  :variant="dataForm.ms_poli_id == x.ms_poli_id ? 'primary' : 'info'" 
                  class="mr-2 mb-2" 
                  v-for="(x, key) of listPoliklinik" 
                  :key="key"
                >{{x.nama_poli}}</b-button>
              </div>
            </b-col>
            <b-col cols="12" v-if="dataForm.instalasi == 'ranap'">
              <h6 class=""><strong>Ruangan <span class="text-danger">*</span></strong></h6>
              <div class="">
                <b-button 
                  @click="
                    dataForm.ms_ruang_id = x.ms_ruang_id, 
                    dataForm.ms_kamar_id = null, 
                    dataForm.ms_bed_id = null, 
                    listBed = [],
                    getListKamar(x.ms_ruang_id)
                  " 
                  :variant="dataForm.ms_ruang_id == x.ms_ruang_id ? 'primary' : 'info'" 
                  class="mr-2 mb-2" 
                  v-for="(x, key) of listRuang" 
                  :key="key"
                >{{x.nama_ruang}}</b-button>
              </div>
            </b-col>
            <b-col cols="12" v-if="dataForm.instalasi == 'ranap'">
              <h6 class=""><strong>Kamar <span class="text-danger">*</span></strong></h6>
              <div class="">
                <b-button 
                  @click="dataForm.ms_kamar_id = x.ms_kamar_id, getListBed(x.ms_kamar_id)" 
                  :variant="dataForm.ms_kamar_id == x.ms_kamar_id ? 'primary' : 'info'" 
                  class="mr-2 mb-2" 
                  v-for="(x, key) of listKamar" 
                  :key="key"
                >{{x.nama_kamar}}</b-button>
              </div>
            </b-col>
            <b-col cols="12" v-if="dataForm.instalasi == 'ranap'">
              <h6 class=""><strong>Bed <span class="text-danger">*</span></strong></h6>
              <div class="">
                <b-button 
                  @click="dataForm.ms_bed_id = x.ms_bed_id" 
                  :variant="dataForm.ms_bed_id == x.ms_bed_id ? 'primary' : 'info'" 
                  class="mr-2 mb-2" 
                  v-for="(x, key) of listBed" 
                  :key="key"
                >{{x.nama_bed}}</b-button>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
<!-- <hr><pre>tanggal_daftar <br>{{dataForm.tanggal_daftar}} <br> {{$v.dataForm.tanggal_daftar}}</pre>
<hr><pre>no_kartu <br>{{dataForm.no_kartu}} <br> {{$v.dataForm.no_kartu}}</pre>
<hr><pre>keluhan <br>{{dataForm.keluhan}} <br> {{$v.dataForm.keluhan}}</pre>
<hr><pre>kunjungan_sakit <br>{{dataForm.kunjungan_sakit}} <br> {{$v.dataForm.kunjungan_sakit}}</pre>
<hr><pre>kd_tkp <br>{{dataForm.kd_tkp}} <br> {{$v.dataForm.kd_tkp}}</pre>
<hr><pre>rm_id <br>{{dataForm.rm_id}} <br> {{$v.dataForm.rm_id}}</pre>
<hr><pre>ms_poli_id <br>{{dataForm.ms_poli_id}} <br> {{$v.dataForm.ms_poli_id}}</pre>
<hr><pre>jenis_rujukan <br>{{dataForm.jenis_rujukan}} <br> {{$v.dataForm.jenis_rujukan}}</pre>
<hr><pre>asal_rujukan <br>{{dataForm.asal_rujukan}} <br> {{$v.dataForm.asal_rujukan}}</pre>
<hr><pre>ms_ruang_id <br>{{dataForm.ms_ruang_id}} <br> {{$v.dataForm.ms_ruang_id}}</pre>
<hr><pre>ms_kelas_id <br>{{dataForm.ms_kelas_id}} <br> {{$v.dataForm.ms_kelas_id}}</pre>
<hr><pre>ms_kamar_id <br>{{dataForm.ms_kamar_id}} <br> {{$v.dataForm.ms_kamar_id}}</pre>
<hr><pre>ms_bed_id <br>{{dataForm.ms_bed_id}} <br> {{$v.dataForm.ms_bed_id}}</pre>
<hr><pre>kd_poli_rujuk_internal <br>{{dataForm.kd_poli_rujuk_internal}} <br> {{$v.dataForm.kd_poli_rujuk_internal}}</pre>
<hr><pre>jenis_rujuk_lanjut <br>{{dataForm.jenis_rujuk_lanjut}} <br> {{$v.dataForm.jenis_rujuk_lanjut}}</pre>
<hr><pre>tgl_est_rujuk <br>{{dataForm.tgl_est_rujuk}} <br> {{$v.dataForm.tgl_est_rujuk}}</pre>
<hr><pre>kdppk <br>{{dataForm.kdppk}} <br> {{$v.dataForm.kdppk}}</pre>
<hr><pre>sub_spesialis <br>{{dataForm.sub_spesialis}} <br> {{$v.dataForm.sub_spesialis}}</pre>
<hr><pre>kd_khusus <br>{{dataForm.kd_khusus}} <br> {{$v.dataForm.kd_khusus}}</pre>
<hr><pre>kd_sub_spesialis <br>{{dataForm.kd_sub_spesialis}} <br> {{$v.dataForm.kd_sub_spesialis}}</pre>
<hr><pre>catatan_kunjungan <br>{{dataForm.catatan_kunjungan}} <br> {{$v.dataForm.catatan_kunjungan}}</pre>
<hr><pre>kd_tacc <br>{{dataForm.kd_tacc}} <br> {{$v.dataForm.kd_tacc}}</pre>
<hr><pre>alasan_tacc <br>{{dataForm.alasan_tacc}} <br> {{$v.dataForm.alasan_tacc}}</pre>
<hr><pre>tagihan_pendaftaran_baru <br>{{dataForm.tagihan_pendaftaran_baru}} <br> {{$v.dataForm.tagihan_pendaftaran_baru}}</pre>
<hr><pre>no_antrian <br>{{dataForm.no_antrian}} <br> {{$v.dataForm.no_antrian}}</pre>
<hr><pre>initial <br>{{dataForm.initial}} <br> {{$v.dataForm.initial}}</pre>
<hr><pre>keadaan_pasien <br>{{dataForm.keadaan_pasien}} <br> {{$v.dataForm.keadaan_pasien}}</pre>
<hr><pre>promotif_preventif <br>{{dataForm.promotif_preventif}} <br> {{$v.dataForm.promotif_preventif}}</pre>
<hr><pre>asuransi <br>{{dataForm.asuransi}} <br> {{$v.dataForm.asuransi}}</pre>
<hr><pre>tarif_pendaftaran <br>{{dataForm.tarif_pendaftaran}} <br> {{$v.dataForm.tarif_pendaftaran}}</pre>
<hr><pre>status_bayar <br>{{dataForm.status_bayar}} <br> {{$v.dataForm.status_bayar}}</pre>
<hr><pre>rujukan_dari <br>{{dataForm.rujukan_dari}} <br> {{$v.dataForm.rujukan_dari}}</pre>
<hr><pre>nama_perujuk <br>{{dataForm.nama_perujuk}} <br> {{$v.dataForm.nama_perujuk}}</pre>
<hr><pre>skrining_visual <br>{{dataForm.skrining_visual}} <br> {{$v.dataForm.skrining_visual}}</pre>
<hr><pre>instalasi <br>{{dataForm.instalasi}} <br> {{$v.dataForm.instalasi}}</pre>
<hr><pre>ms_dokter_id <br>{{dataForm.ms_dokter_id}} <br> {{$v.dataForm.ms_dokter_id}}</pre>
<hr><pre>registrasi_id_lama <br>{{dataForm.registrasi_id_lama}} <br> {{$v.dataForm.registrasi_id_lama}}</pre>
<hr><pre>ms_status_pulang_id <br>{{dataForm.ms_status_pulang_id}} <br> {{$v.dataForm.ms_status_pulang_id}}</pre>
<hr><pre>jadwal_id <br>{{dataForm.jadwal_id}} <br> {{$v.dataForm.jadwal_id}}</pre>
<hr><pre>type_no_asuransi <br>{{dataForm.type_no_asuransi}} <br> {{$v.dataForm.type_no_asuransi}}</pre>
<hr><pre>kdppk <br>{{dataForm.kdppk}} <br> {{$v.dataForm.kdppk}}</pre> -->
      <!-- <pre>{{dataForm}}</pre> -->
      <template #modal-footer>
        <b-button variant="primary" @click="simpan()">
          Simpan
        </b-button>
        <b-button variant="secondary" @click="$bvModal.hide('modal-rujuk-internal')">
          Batal
        </b-button>
        <!-- <b-button variant="primary" @click="hapus()" :disabled="busy">
          {{ button }}
        </b-button> -->
      </template>
    </b-modal>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, numeric, minValue } from "vuelidate/lib/validators";
import Tabel from './tabel.vue'
export default {
  components: {
    Tabel,
  },
  props: [
    'dataKunjungan',
    'dataPasien',
    'dataAnamnesa',
    'listDokter',
    'listPoliklinik',
    'listKelas',
  ],
  data() {
    return {
      busy: false,
      listSkrining: [],
      listPenyakitKhusus: [],
      listResikoKehamilan: [],
      listPendaftaranHariIni: [],
      listTypeNoAsuransi: [
        {text: 'No BPJS', value: 'noka'}, 
        {text: 'NIK', value: 'nik'}, 
      ],
      listInstalasi: [
        {
          text: 'Rawat Jalan',
          value: 'rajal',
          kd_tkp: "10",
        },
        {
          text: 'Rawat Inap',
          value: 'ranap',
          kd_tkp: "20",
        },
        {
          text: 'Gawat Darurat',
          value: 'igd',
          kd_tkp: "10",
        },
      ],
      listProviderPeserta: [],
      listRuang: [],
      listKamar: [],
      listBed: [],
      listAntrian: [],
      dataForm: {
        tanggal_daftar: new Date(),
        no_kartu: null,
        keluhan: null,
        kunjungan_sakit: true,
        kd_tkp: this.$store.state.data_static.tkp[0],
        rm_id: null,
        ms_poli_id: null,
        jenis_rujukan: null,
        asal_rujukan: null,
        ms_ruang_id: null,
        ms_kelas_id: null,
        ms_kamar_id: null,
        ms_bed_id: null,
        kd_poli_rujuk_internal: null,
        jenis_rujuk_lanjut: null,
        tgl_est_rujuk: null,
        kdppk: null,
        sub_spesialis: null,
        kd_khusus: null,
        kd_sub_spesialis: null,
        catatan_kunjungan: null,
        kd_tacc: null,
        alasan_tacc: null,
        tagihan_pendaftaran_baru: 20000,
        no_antrian: null,
        initial: null,
        keadaan_pasien: [],
        promotif_preventif: false,
        asuransi: null,
        tarif_pendaftaran: 20000,
        status_bayar: false,
        rujukan_dari: null,
        nama_perujuk: null,
        skrining_visual: [],
        instalasi: null,
        ms_dokter_id: null,
        registrasi_id_lama: null,
        ms_status_pulang_id: null,
        jadwal_id: null,
        room_id: null,

        type_no_asuransi: null,
        status_sakit: true,
        type_skrining: null,
      },
    }
  },
  mixins: [validationMixin],
  validations: {
    dataForm: {
      tanggal_daftar: { required },
      no_kartu: {  },
      keluhan: { required },
      kunjungan_sakit: { required },
      kd_tkp: {  }, //otomatis ketika register
      rm_id: { required },
      ms_poli_id: {  },
      jenis_rujukan: {  },
      asal_rujukan: {  },
      ms_ruang_id: {  },
      ms_kelas_id: { required },
      ms_kamar_id: {  },
      ms_bed_id: {  },
      kd_poli_rujuk_internal: {  },
      jenis_rujuk_lanjut: {  },
      tgl_est_rujuk: {  },
      kdppk: {  },
      sub_spesialis: {  },
      kd_khusus: {  },
      kd_sub_spesialis: {  },
      catatan_kunjungan: {  },
      kd_tacc: {  },
      alasan_tacc: {  },
      tagihan_pendaftaran_baru: { required },
      no_antrian: { required },
      initial: {  },
      keadaan_pasien: {  },
      promotif_preventif: { required },
      asuransi: { required },
      tarif_pendaftaran: { required, numeric, minValue: minValue(0) },
      status_bayar: { required },
      rujukan_dari: { required },
      nama_perujuk: { required },
      skrining_visual: {  },
      instalasi: { required },
      ms_dokter_id: {  },
      registrasi_id_lama: { required },
      ms_status_pulang_id: { required },
      jadwal_id: {  },
      
      // asuransi: {  },
      type_no_asuransi: {  },
      // kdppk: {  },

//       kdppk
// asuransi
// type_no_asuransi
// no_kartu
    }
  },
  computed: {
    tanggal_sesi(){
      return this.$store.state.tanggal_sesi
    },
    isValid() {
      return !this.$v.dataForm.$invalid;
    },
    isDirty() {
      return this.$v.dataForm.$anyDirty;
    },
    isAsuransiBPJS(){
      return this.dataForm.asuransi && this.dataForm.asuransi.value === true
    },
    isAsuransiUmum(){
      return this.dataForm.asuransi && this.dataForm.asuransi.value === false
    }, 
  },
  watch: {
    'dataForm.skrining_visual': {
      handler(data) {
        // console.log('dataForm.skrining_visual', data)
        if(data && data.length){
          this.dataForm.type_skrining = data[0].type
        }else{
          this.dataForm.type_skrining = null
        }
      },
      deep: true
    },
    tanggal_sesi(newVal, oldVal){
      if(newVal != oldVal){
        if(newVal && this.$moment(oldVal).format('LL') != this.$moment(newVal).format('LL')) {
          if(oldVal != null){
            this.dataForm.no_antrian = null
          }
          this.dataForm.tanggal_daftar = new Date(this.tanggal_sesi)
          this.getListAntrian()
        }
      }
    },
    // 'dataForm.tanggal_daftar'(newVal, oldVal){
    //   if(newVal != oldVal){
    //     this.getListAntrian()
    //   }
    // },
  },
  created() {
    const vm = this
    vm.$socket.emit("joinRoom", localStorage.getItem('idp'));
    vm.$socket.off("refresh_rujuk_internal")
    vm.$socket.on("refresh_rujuk_internal", (data) => {
      console.log('refresh_rujuk_internal', data);
      // if(data.status == 200 && data.data.rm_id == vm.dataForm.rm_id){
      if(data.status == 200){
        vm.$emit("changeVar", {name: 'kunjunganSelesai', value: true})
        vm.$bvModal.hide('modal-rujuk-internal')
        vm.$store.commit("set_alert", { variant: "success", msg: "Berhasil Registrasi Rujuk Internal", showing: true });
        // vm.refresh()
      }else{
        vm.$store.commit("set_alert", { variant: "success", msg: data.message.toUpperCase(), showing: true });
      }
    });
  },
  mounted() {
    // const vm = this
  },
  methods: {
    async showModal(){
      const vm = this
      console.log('dataKunjungan rujuk internal', vm.dataKunjungan)
      await vm.getDetail()
      vm.getListAntrian()
      vm.getList()
      vm.reset()
      vm.dataForm.rm_id = vm.dataKunjungan.rm_id
      vm.dataForm.ms_kelas_id = vm.$findKey(vm.listKelas, vm.dataKunjungan.ms_kelas_id, true, 'ms_kelas_id'),
      // vm.dataForm.tanggal_daftar = vm.dataKunjungan.tanggal_daftar
      vm.dataForm.kd_tkp = vm.dataKunjungan.kd_tkp
      vm.dataForm.keluhan = vm.dataKunjungan.keluhan
      vm.dataForm.ms_status_pulang_id = vm.dataKunjungan.ms_status_pulang_id
      vm.dataForm.registrasi_id_lama = vm.dataKunjungan.pendaftaran_id
      if(vm.dataKunjungan.no_kartu) {
        vm.dataForm.asuransi = vm.$store.state.data_static.asuransi[1]
        vm.dataForm.type_no_asuransi = vm.listTypeNoAsuransi[0]
        vm.dataForm.no_kartu = vm.dataKunjungan.no_kartu
      }else{
        vm.dataForm.asuransi = vm.$store.state.data_static.asuransi[0]
      }
    },
    async getDetail(){
      const vm = this
      vm.busy = true
      try {
        'hello'
      } catch (err) {
        console.log(err)
        if (err.message) {
          vm.$store.commit("set_alert",{ variant: "danger", msg:err.message.toUpperCase(), showing: true });
        } else {
          vm.$store.commit("set_alert",{ variant: "danger", msg:err.toUpperCase(), showing: true });
        }
      } finally {
        vm.busy = false;
      }
    },
    async getList(){
      const vm = this
      vm.busy = true
      try {
        vm.listPendaftaranHariIni = []
        vm.listResikoKehamilan = []
        if(vm.dataKunjungan.rm_id){
          let listPendaftaranHariIni = await vm.$axios.post('/pendaftaran/list', {
            rm_id: vm.dataKunjungan.rm_id,
            tanggal_daftar: vm.$moment(),
          })
          vm.listPendaftaranHariIni = listPendaftaranHariIni.data.status == 200 ? listPendaftaranHariIni.data.data.map(x => {
            return {
              ...x,
              nama_tanggal_daftar: vm.$moment(x.tanggal_daftar).format('LLLL')
            }
          }) : []
          // console.log('listPendaftaranHariIni', listPendaftaranHariIni)
          let listResikoKehamilan = await vm.$axios.post('/kspr/list', {
            rm_id: vm.dataKunjungan.rm_id,
          })
          vm.listResikoKehamilan = listResikoKehamilan.data.status == 200 ? listResikoKehamilan.data.data.map(x => {
            return {
              ...x,
              nama_tanggal_kspr: vm.$moment(x.tanggal_kspr).format('LLLL')
            }
          }) : []
          console.log('listResikoKehamilan', listResikoKehamilan)
        }
      } catch (err) {
        console.log(err)
        if (err.message) {
          vm.$store.commit("set_alert",{ variant: "danger", msg:err.message.toUpperCase(), showing: true });
        } else {
          vm.$store.commit("set_alert",{ variant: "danger", msg:err.toUpperCase(), showing: true });
        }
      } finally {
        vm.busy = false;
      }
    },
    async getListAntrian(){
      const vm = this
      //Antrian
      let listAntrian = await vm.$axios.post("/antrian/list", {
        status_antrian: 2,
        tanggal_antrian: vm.$moment(vm.tanggal_sesi).format('YYYY-MM-DD'),
        // tanggal_antrian: vm.$moment(vm.dataForm.tanggal_daftar).format('YYYY-MM-DD'),
        // poli_layanan: '1', 
        // tgl_antrian: moment(vm.dataForm.tanggal_daftar).format('YYYY-MM-DD'),
        // status_antrian: '1',
      });
      // console.log('listAntrian', listAntrian)
      vm.listAntrian = listAntrian.data.status == 200 ? listAntrian.data.data.map(x => { return {
        ...x, 
        nama_no_antrian: x.initial + x.nomor_antrian,
      }}) : []
      vm.dataForm.no_antrian = null
    },
    async getListRuangan(instansi){
      const vm = this
      vm.busy = true
      try {
        vm.listRuang = []
        if(instansi){
          let listRuang = await vm.$axios.post('/ms_ruang/list', {instansi})
          vm.listRuang = listRuang.data.status == 200 ? listRuang.data.data.map(x => {
            return {
              ...x,
            }
          }) : []
          // console.log('listRuang', listRuang)
        }
      } catch (err) {
        console.log(err)
        if (err.message) {
          vm.$store.commit("set_alert",{ variant: "danger", msg:err.message.toUpperCase(), showing: true });
        } else {
          vm.$store.commit("set_alert",{ variant: "danger", msg:err.toUpperCase(), showing: true });
        }
      } finally {
        vm.busy = false;
      }
    },
    async getListKamar(ms_ruang_id){
      const vm = this
      vm.busy = true
      try {
        vm.listKamar = []
        if(ms_ruang_id){
          let listKamar = await vm.$axios.post('/ms_kamar/list', {ms_ruang_id})
          vm.listKamar = listKamar.data.status == 200 ? listKamar.data.data.map(x => {
            return {
              ...x,
            }
          }) : []
          // console.log('listKamar', listKamar)
        }
      } catch (err) {
        console.log(err)
        if (err.message) {
          vm.$store.commit("set_alert",{ variant: "danger", msg:err.message.toUpperCase(), showing: true });
        } else {
          vm.$store.commit("set_alert",{ variant: "danger", msg:err.toUpperCase(), showing: true });
        }
      } finally {
        vm.busy = false;
      }
    },
    async getListBed(ms_kamar_id){
      const vm = this
      vm.busy = true
      try {
        vm.listBed = []
        if(ms_kamar_id){
          let listBed = await vm.$axios.post('/ms_bed/list', {ms_kamar_id})
          vm.listBed = listBed.data.status == 200 ? listBed.data.data.map(x => {
            return {
              ...x,
            }
          }) : []
          // console.log('listBed', listBed)
        }
      } catch (err) {
        console.log(err)
        if (err.message) {
          vm.$store.commit("set_alert",{ variant: "danger", msg:err.message.toUpperCase(), showing: true });
        } else {
          vm.$store.commit("set_alert",{ variant: "danger", msg:err.toUpperCase(), showing: true });
        }
      } finally {
        vm.busy = false;
      }
    },
    async cekAsuransi(){
      const vm = this
      vm.busy = true
      vm.dataForm.status_no_asuransi = false
      if(this.dataForm.no_kartu){
        if(vm.isAsuransiBPJS){
          vm.dataForm.kdppk = null
          const res = await vm.$axios.post('/integrasi_pcare/get_peserta_by_jenis_kartu', {nomor_kartu: vm.dataForm.no_kartu, jenis_kartu: vm.dataForm.type_no_asuransi.value})
          // console.log('res', res)
          if(res.data.status == 200){
            // console.log('bpjs/getPesertaByNoKartu ', res.data.data[0].peserta.noKartu)
            vm.dataForm.no_kartu = res.data.data[0].noKartu
            vm.dataForm.type_no_asuransi = vm.$store.state.data_static.type_no_asuransi[0]
            if(res.data.data[0].ketAktif == "AKTIF"){
              vm.$store.commit("set_alert", { variant: "success", msg: 'NO BPJS ' + res.data.data[0].ketAktif, showing: true });
            }else{
              vm.$store.commit("set_alert", { variant: "warning", msg: 'NO BPJS ' + res.data.data[0].ketAktif, showing: true });
            }
            vm.dataForm.kdppk = res.data.data[0].kdProviderPst
            // vm.$store.commit("set_alert", { variant: "success", msg: 'No Asuransi Tersedia', showing: true });
            vm.dataForm.status_no_asuransi = true
          }else{
            vm.$store.commit("set_alert", { variant: "danger", msg: res.data.message, showing: true });
          }
        }else{
          vm.$store.commit("set_alert", { variant: "warning", msg: 'cek asuransi selain bpjs belum dibuat', showing: true });
          vm.dataForm.status_no_asuransi = true
        }
      }else{
        // vm.$store.commit("set_alert", { variant: "danger", msg: 'Data Tidak Valid', showing: true });
      }
      vm.$v.dataForm.no_kartu.$reset();
      vm.$v.dataForm.no_kartu.$touch();
      vm.busy = false
    },
    async simpan(){
      const vm = this
      vm.busy = true
      try {
        vm.dataForm.tagihan_pendaftaran_baru = vm.dataForm.tarif_pendaftaran
        await this.$v.dataForm.$touch();
        // if (vm.dataForm) {
        if (vm.isValid && vm.isDirty) {
          let x = vm.dataForm
          let data = {
            tanggal_daftar: x.tanggal_daftar,
            //// no_kartu: x.no_kartu,
            keluhan: x.keluhan,
            kunjungan_sakit: x.kunjungan_sakit,
            kd_tkp: x.kd_tkp,
            rm_id: x.rm_id,
            ms_poli_id: x.ms_poli_id,
            // jenis_rujukan: x.jenis_rujukan,
            // asal_rujukan: x.asal_rujukan,
            //// ms_kelas_id: x.ms_kelas_id,
            ms_ruang_id: x.ms_ruang_id,
            ms_kamar_id: x.ms_kamar_id,
            ms_bed_id: x.ms_bed_id,
            // kd_poli_rujuk_internal: x.kd_poli_rujuk_internal,
            // jenis_rujuk_lanjut: x.jenis_rujuk_lanjut,
            // tgl_est_rujuk: x.tgl_est_rujuk,
            //// kdppk: x.kdppk,
            // sub_spesialis: x.sub_spesialis,
            // kd_khusus: x.kd_khusus,
            // kd_sub_spesialis: x.kd_sub_spesialis,
            // catatan_kunjungan: x.catatan_kunjungan,
            // kd_tacc: x.kd_tacc,
            // alasan_tacc: x.alasan_tacc,
            tagihan_pendaftaran_baru: x.tagihan_pendaftaran_baru,
            //// no_antrian: x.no_antrian,
            //// initial: x.initial,
            keadaan_pasien: x.keadaan_pasien,
            promotif_preventif: x.promotif_preventif,
            asuransi: x.asuransi,
            tarif_pendaftaran: x.tarif_pendaftaran,
            status_bayar: x.status_bayar,
            rujukan_dari: x.rujukan_dari,
            nama_perujuk: x.nama_perujuk,
            skrining_visual: x.skrining_visual,
            instalasi: x.instalasi,
            ms_dokter_id: x.ms_dokter_id,
            registrasi_id_lama: x.registrasi_id_lama,
            //// ms_status_pulang_id: x.ms_status_pulang_id,
            // jadwal_id: x.jadwal_id,
            // room_id: x.room_id,
            puskesmas_id: vm.$store.state.puskesmas_id,
            room_id: localStorage.getItem('idp'),
          }
          if(x.ms_status_pulang_id) data.ms_status_pulang_id = x.ms_status_pulang_id.ms_status_pulang_id
          // if(x.ms_poli_id) data.ms_poli_id = x.ms_poli_id.ms_poli_id
          if(x.kdppk) data.kdppk = x.kdppk.kdProvider
          if(x.kd_tkp) {
            data.tipe_rawat = x.kd_tkp.tipe_rawat
            data.kd_tkp = x.kd_tkp.kdTkp
          }
          if(x.rujukan_dari) data.rujukan_dari = x.rujukan_dari.value
          if(x.asuransi) data.asuransi = x.asuransi.text
          if(x.ms_dokter_id) data.ms_dokter_id = x.ms_dokter_id.tenaga_medis_id
          if(x.ms_kelas_id) data.ms_kelas_id = x.ms_kelas_id.ms_kelas_id
          if(x.ms_bed_id) {
            data.no_antrian = x.no_antrian.nomor_antrian
            data.initial = x.no_antrian.initial
          }
          // if(x.ms_poli_id) {
          //   x.ms_poli_id = x.poliklinik.ms_poli_id
          //   x.jadwal_id = x.poliklinik.jadwal_id
          //   x.initial = x.poliklinik.kode_poli_puskesmas
          // }
          if(x.asuransi == 'BPJS') {
            data.no_kartu = x.no_kartu
            data.status_bayar = 3
          }else{
            data.status_bayar = data.status_bayar ? 2 : 1
          }
          // console.log('rujuk_internal', data)
          // console.log('rujuk_internal', x)
          if(data.tipe_rawat == 'RI'){
            console.log('rujuk_internal_ranap (rawat inap)', data)
            this.$socket.emit("rujuk_internal_ranap", {
              ...data,
            });
          }else{
            console.log('rujuk_internal (rawat jalan)', data)
            this.$socket.emit("rujuk_internal", {
              ...data,
            });
            
          }
          // vm.$store.commit("set_alert",{ variant: "success", msg: 'Data valid', showing: true });
        }else{
          vm.$store.commit("set_alert",{ variant: "warning", msg: 'Data tidak valid', showing: true });
        }
      } catch (err) {
        console.log(err)
        if (err.message) {
          vm.$store.commit("set_alert",{ variant: "danger", msg:err.message.toUpperCase(), showing: true });
        } else {
          vm.$store.commit("set_alert",{ variant: "danger", msg:err.toUpperCase(), showing: true });
        }
      } finally {
        vm.busy = false;
      }
    },
    checkIfValid(name, fieldName) {
      const field = this.$v[name][fieldName];
      // console.log(name, fieldName, field)
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    reset(){
      const vm = this
      // vm.dataForm = {
      //   tanggal_daftar: new Date(),
      //   no_kartu: null,
      //   keluhan: null,
      //   kunjungan_sakit: true,
      //   kd_tkp: this.$store.state.data_static.tkp[0],
      //   rm_id: null,
      //   ms_poli_id: null,
      //   jenis_rujukan: null,
      //   asal_rujukan: null,
      //   ms_ruang_id: null,
      //   ms_kelas_id: null,
      //   ms_kamar_id: null,
      //   ms_bed_id: null,
      //   kd_poli_rujuk_internal: null,
      //   jenis_rujuk_lanjut: null,
      //   tgl_est_rujuk: null,
      //   kdppk: null,
      //   sub_spesialis: null,
      //   kd_khusus: null,
      //   kd_sub_spesialis: null,
      //   catatan_kunjungan: null,
      //   kd_tacc: null,
      //   alasan_tacc: null,
      //   tagihan_pendaftaran_baru: 20000,
      //   no_antrian: null,
      //   initial: null,
      //   keadaan_pasien: [],
      //   promotif_preventif: false,
      //   asuransi: null,
      //   tarif_pendaftaran: 20000,
      //   status_bayar: null,
      //   rujukan_dari: null,
      //   nama_perujuk: null,
      //   skrining_visual: [],
      //   instalasi: null,
      //   ms_dokter_id: null,
      //   registrasi_id_lama: null,
      //   ms_status_pulang_id: null,
      //   jadwal_id: null,
      //   room_id: null,

      //   type_no_asuransi: null,
      //   kdppk: null,
      //   status_sakit: true,
      //   type_skrining: null,
      // }
      vm.$v.$reset()
    }
  },
}
</script>