<template>
  <b-modal
    :id="'modal-detail-kunjungan-' + id"
    size="xl"
    title="Detail Kunjungan"
    header-bg-variant="success"
    header-text-variant="light"
    @show="openModal()"
  >
    <b-container fluid class="m-0 p-0">
      <b-tabs card fill pills>
        <b-tab title="Pasien" active>
          <Pasien :dataKunjungan="dataKunjungan" />
        </b-tab>
        <b-tab title="Anamnesa">
          <Anamesa :dataKunjungan="dataKunjungan" />
        </b-tab>
        <b-tab title="Resep">
          <Resep :dataKunjungan="dataKunjungan" />
        </b-tab>
        <b-tab title="Laboratorium">
          <Labolatorium :dataKunjungan="dataKunjungan" />
        </b-tab>
      </b-tabs>
    </b-container>
    <template #modal-footer>
      <b-button variant="secondary" @click="$bvModal.hide('modal-detail-kunjungan-' + id)">
        close
      </b-button>
    </template>
  </b-modal>
</template>
<script>
import Pasien from './pasien.vue'
import Resep from './resep.vue'
import Anamesa from './anamnesa.vue'
import Labolatorium from './labolatorium.vue'
export default {
  components: {
    Pasien,
    Resep,
    Anamesa,
    Labolatorium,
  },
  props: [
    'id',
    'dataKunjungan',
  ],
  data() {
    return {
      busy: false,
      dataRm: {},
      listKunjungan: [],
      fieldsKunjungan: [
        {
          key: "no",
          label: "No",
          sortDirection: "desc",
          sortable: false,
          class: "table-number text-center",
        },
        {
          key: "nama_tanggal",
          label: "Tanggal",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "diagnosa",
          label: "Diagnosa",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "tindakan",
          label: "Tindakan",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "resep_obat",
          label: "Resetp Obat",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "nama_poli",
          label: "Poli / Ruangan",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "nama_kamar",
          label: "Kamar",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "rujuk_internal",
          label: "Rujuk Internal",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "rujuk_external",
          label: "Rujuk External",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "actions",
          label: "Actions",
          sortable: false,
          sortDirection: "desc",
          class: "text-center",
        },
      ],
    }
  },
  // mounted() {
  //   const vm = this
  //   vm.getData()
  // },
  methods: {
    openModal(){
      const vm = this
      vm.getData()
    },
    async getData(){
      const vm = this
      vm.busy = true
      try {
        // console.log('id', vm.$route.params.id)
        let dataRm = await vm.$axios.post('/rm/list', {rm_id: vm.$route.params.id})
        // console.log('dataRm', dataRm)
        if(dataRm.data.status == 200 && dataRm.data.data.length > 0){
          vm.dataRm = dataRm.data.data[0]
          let listKunjungan = await vm.$axios.post('/kunjungan/list', {no_rm: vm.dataRm.no_rm})
          // console.log('listKunjungan', listKunjungan)
          if(listKunjungan.data.status == 200 && listKunjungan.data.data.length > 0){
            vm.listKunjungan = listKunjungan.data.data.map(x => {
              return {
                ...x,
                nama_tanggal: vm.$moment(x.tanggal_daftar).format('LL'),
              }
            })
          }
        }else{
          // vm.triggerAlert({ variant: "warning", msg: 'Rekam Medis Tidak Ditemukan', showing: true })
        }
      } catch (error) {
        console.log(error)
        vm.triggerAlert({ variant: "warning", msg: 'Terjadi kesalahan sistem', showing: true });
      } finally {
        vm.busy = false
      }
    },
    triggerAlert(event) {
      let vm = this;
      vm.$store.commit("set_alert", event);
    },
  },
}
</script>