<template>
    <b-container>
        <b-row>
            <b-col cols="6" sm="6" md="6" lg="6">
                <b-card class="mt-3" bg-variant="light">
                    <b-card-title>
                        <h5 class="mb-0">Register</h5>
                    </b-card-title>
                    <b-form>
                        <b-form-group label-cols-md="3">
                            <template v-slot:label>
                               ID <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                                type="text"
                                v-model="id"
                                disabled
                            ></b-form-input>
                        </b-form-group>
                        <b-form-group label-cols-md="3">
                            <template v-slot:label>
                               Tanggal <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                                type="text"
                                v-model="ptm.tanggal_ptm"
                                disabled
                            ></b-form-input>
                        </b-form-group>
                        <b-form-group label-cols-md="3">
                            <template v-slot:label>
                               Dokter <span class="text-danger">*</span>
                            </template>
                            <Multiselect
                                id="ms_dokter_id"
                                v-model="ptm.ms_dokter_id"
                                :options="listDokter"
                                :multiple="false"
                                :searchable="true"
                                :close-on-select="true"
                                :show-labels="false"
                                label="nama_tenaga_medis"
                                track-by="tenaga_medis_id"
                                placeholder="-- Pilih Dokter --"
                                size="sm"
                            ></Multiselect>
                        </b-form-group>
                        <b-form-group label-cols-md="3">
                            <template v-slot:label>
                               Perawat <span class="text-danger">*</span>
                            </template>
                            <Multiselect
                                id="ms_perawat_id"
                                v-model="ptm.ms_perawat_id"
                                :options="listPerawat"
                                :multiple="false"
                                :searchable="true"
                                :close-on-select="true"
                                :show-labels="false"
                                label="nama_tenaga_medis"
                                track-by="tenaga_medis_id"
                                placeholder="-- Pilih Perawat --"
                                size="sm"
                            ></Multiselect>
                        </b-form-group>
                    </b-form>
                </b-card>
                <b-card bg-variant="light">
                    <b-card-title>
                        <h5 class="mb-0">Riwayat PTM pada Diri Sendiri</h5>
                    </b-card-title>
                    <b-form>
                        <b-form-group label-cols-md="5">
                            <template v-slot:label>
                               Penyakit Diabetes
                            </template>
                            <b-form-radio-group
                                id="diabetes_d"
                                v-model="riwayat_ptm_diri.diabetes_d"
                            >
                                <b-form-radio value="true">Ya</b-form-radio>
                                <b-form-radio value="false">Tidak</b-form-radio>
                            </b-form-radio-group>
                        </b-form-group>
                        <b-form-group label-cols-md="5">
                            <template v-slot:label>
                               Penyakit Hipertensi
                            </template>
                            <b-form-radio-group
                                id="hipertensi_d"
                                v-model="riwayat_ptm_diri.hipertensi_d"
                            >
                                <b-form-radio value="true">Ya</b-form-radio>
                                <b-form-radio value="false">Tidak</b-form-radio>
                            </b-form-radio-group>
                        </b-form-group>
                        <b-form-group label-cols-md="5">
                            <template v-slot:label>
                               Penyakit Jantung
                            </template>
                            <b-form-radio-group
                                id="jantung_d"
                                v-model="riwayat_ptm_diri.jantung_d"
                            >
                                <b-form-radio value="true">Ya</b-form-radio>
                                <b-form-radio value="false">Tidak</b-form-radio>
                            </b-form-radio-group>
                        </b-form-group>
                        <b-form-group label-cols-md="5">
                            <template v-slot:label>
                               Penyakit Stroke
                            </template>
                            <b-form-radio-group
                                id="struk_d"
                                v-model="riwayat_ptm_diri.struk_d"
                            >
                                <b-form-radio value="true">Ya</b-form-radio>
                                <b-form-radio value="false">Tidak</b-form-radio>
                            </b-form-radio-group>
                        </b-form-group>
                        <b-form-group label-cols-md="5">
                            <template v-slot:label>
                               Penyakit Asma
                            </template>
                            <b-form-radio-group
                                id="asma_d"
                                v-model="riwayat_ptm_diri.asma_d"
                            >
                                <b-form-radio value="true">Ya</b-form-radio>
                                <b-form-radio value="false">Tidak</b-form-radio>
                            </b-form-radio-group>
                        </b-form-group>
                        <b-form-group label-cols-md="5">
                            <template v-slot:label>
                               Penyakit Kanker
                            </template>
                            <b-form-radio-group
                                id="kanker_d"
                                v-model="riwayat_ptm_diri.kanker_d"
                            >
                                <b-form-radio value="true">Ya</b-form-radio>
                                <b-form-radio value="false">Tidak</b-form-radio>
                            </b-form-radio-group>
                        </b-form-group>
                        <b-form-group label-cols-md="5">
                            <template v-slot:label>
                               Penyakit Kolesterol
                            </template>
                            <b-form-radio-group
                                id="kolesterol_d"
                                v-model="riwayat_ptm_diri.kolesterol_d"
                            >
                                <b-form-radio value="true">Ya</b-form-radio>
                                <b-form-radio value="false">Tidak</b-form-radio>
                            </b-form-radio-group>
                        </b-form-group>
                    </b-form>
                </b-card>
                <b-card bg-variant="light">
                    <b-card-title>
                        <h5 class="mb-0">Tekanan Darah & IMT</h5>
                    </b-card-title>
                    <b-form>
                        <b-form-group label-cols-md="4">
                            <template v-slot:label>
                               Sistole
                            </template>
                            <b-input-group class="w-100">
                                <b-form-input
                                    id="sistole"
                                    v-model="tekanan_darah_ptm.sistole_ptm"
                                    type="number"
                                    placeholder="0"
                                ></b-form-input>
                                <b-input-group-append>
                                    <b-input-group-text>mm</b-input-group-text>
                                </b-input-group-append>
                            </b-input-group>
                        </b-form-group>
                        <b-form-group label-cols-md="4">
                            <template v-slot:label>
                               Diastole
                            </template>
                            <b-input-group class="w-100">
                                <b-form-input
                                    id="diastole"
                                    v-model="tekanan_darah_ptm.diastole_ptm"
                                    type="number"
                                    placeholder="0"
                                ></b-form-input>
                                <b-input-group-append>
                                    <b-input-group-text>Hg</b-input-group-text>
                                </b-input-group-append>
                            </b-input-group>
                        </b-form-group>
                        <b-form-group label-cols-md="4">
                            <template v-slot:label>
                               Tinggi Badan
                            </template>
                            <b-input-group class="w-100">
                                <b-form-input
                                    id="tinggi_badan"
                                    v-model="tekanan_darah_ptm.tinggi_badan_ptm"
                                    type="number"
                                    placeholder="0.00"
                                    @keyup="countImt()"
                                ></b-form-input>
                                <b-input-group-append>
                                    <b-input-group-text>cm</b-input-group-text>
                                </b-input-group-append>
                            </b-input-group>
                        </b-form-group>
                        <b-form-group label-cols-md="4">
                            <template v-slot:label>
                               Berat Badan
                            </template>
                            <b-input-group class="w-100">
                                <b-form-input
                                    id="berat_badan"
                                    v-model="tekanan_darah_ptm.berat_badan_ptm"
                                    type="number"
                                    placeholder="0.00"
                                    @keyup="countImt()"
                                ></b-form-input>
                                <b-input-group-append>
                                    <b-input-group-text>Kg</b-input-group-text>
                                </b-input-group-append>
                            </b-input-group>
                        </b-form-group>
                        <b-form-group label-cols-md="4">
                            <template v-slot:label>
                               IMT
                            </template>
                            <b-input-group class="w-100">
                                <b-form-input
                                    id="imt"
                                    type="text"
                                    v-model="tekanan_darah_ptm.imt_ptm"
                                    disabled
                                ></b-form-input>
                                <b-input-group-append>
                                    <b-input-group-text>Kg/m<sup>2</sup></b-input-group-text>
                                </b-input-group-append>                               
                            </b-input-group>
                            <small>berat badan (kg) / tinggi badan(m<sup>2</sup>)</small>
                        </b-form-group>
                        <b-form-group label-cols-md="4">
                            <template v-slot:label>
                               Hasil IMT
                            </template>
                            <b-input-group class="w-100">
                                <b-form-input
                                    id="hasil_imt"
                                    v-model="tekanan_darah_ptm.hasil_imt_ptm"
                                    type="text"
                                    disabled
                                ></b-form-input>
                            </b-input-group>
                        </b-form-group>
                    </b-form>
                </b-card>
                <b-card class="mt-3" bg-variant="light">
                    <b-card-title>
                        <h5 class="mb-0">Diagnosa</h5>
                    </b-card-title>
                    <b-form>
                        <b-form-group label-cols-md="3">
                            <template v-slot:label>
                               Diagnosa 1
                            </template>
                            <b-form-textarea
                                v-model="data_diagnosa.diagnosa_1"
                            ></b-form-textarea>
                        </b-form-group>
                        <b-form-group label-cols-md="3">
                            <template v-slot:label>
                               Diagnosa 2
                            </template>
                            <b-form-textarea
                                v-model="data_diagnosa.diagnosa_2"
                            ></b-form-textarea>
                        </b-form-group>
                        <b-form-group label-cols-md="3">
                            <template v-slot:label>
                               Diagnosa 3
                            </template>
                            <b-form-textarea
                                v-model="data_diagnosa.diagnosa_3"
                            ></b-form-textarea>
                        </b-form-group>
                    </b-form>
                </b-card>
                <b-card class="mt-3" bg-variant="light">
                    <b-card-title>
                        <h5 class="mb-0">Gangguan Penglihatan</h5>
                    </b-card-title>
                    <b-form>
                        <h6>Katarak</h6>
                        <b-form-group label-cols-md="5">
                            <template v-slot:label>
                               Mata Kanan
                            </template>
                            <b-form-radio-group
                                id="katarak_mata_kanan"
                                v-model="gangguan_penglihatan.katarak_mata_kanan"
                            >
                                <b-form-radio value="true">Ya</b-form-radio>
                                <b-form-radio value="false">Tidak</b-form-radio>
                            </b-form-radio-group>
                        </b-form-group>
                        <b-form-group label-cols-md="5">
                            <template v-slot:label>
                               Mata Kiri
                            </template>
                            <b-form-radio-group
                                id="katarak_mata_kiri"
                                v-model="gangguan_penglihatan.katarak_mata_kiri"
                            >
                                <b-form-radio value="true">Ya</b-form-radio>
                                <b-form-radio value="false">Tidak</b-form-radio>
                            </b-form-radio-group>
                        </b-form-group>
                        <b-form-group label-cols-md="5">
                            <template v-slot:label>
                               Rujuk RS
                            </template>
                            <b-form-radio-group
                                id="rujuk_rs_katarak"
                                v-model="gangguan_penglihatan.rujuk_rs_katarak"
                            >
                                <b-form-radio value="true">Ya</b-form-radio>
                                <b-form-radio value="false">Tidak</b-form-radio>
                            </b-form-radio-group>
                        </b-form-group>
                        <h6>Kelainan Refraksi</h6>
                        <b-form-group label-cols-md="5">
                            <template v-slot:label>
                               Mata Kanan
                            </template>
                            <b-form-radio-group
                                id="refraksi_mata_kanan"
                                v-model="gangguan_penglihatan.refraksi_mata_kanan"
                            >
                                <b-form-radio value="true">Ya</b-form-radio>
                                <b-form-radio value="false">Tidak</b-form-radio>
                            </b-form-radio-group>
                        </b-form-group>
                        <b-form-group label-cols-md="5">
                            <template v-slot:label>
                               Mata Kiri
                            </template>
                            <b-form-radio-group
                                id="refraksi_mata_kiri"
                                v-model="gangguan_penglihatan.refraksi_mata_kiri"
                            >
                                <b-form-radio value="true">Ya</b-form-radio>
                                <b-form-radio value="false">Tidak</b-form-radio>
                            </b-form-radio-group>
                        </b-form-group>
                        <b-form-group label-cols-md="5">
                            <template v-slot:label>
                               Rujuk RS
                            </template>
                            <b-form-radio-group
                                id="rujuk_rs_refraksi"
                                v-model="gangguan_penglihatan.rujuk_rs_refraksi"
                            >
                                <b-form-radio value="true">Ya</b-form-radio>
                                <b-form-radio value="false">Tidak</b-form-radio>
                            </b-form-radio-group>
                        </b-form-group>
                    </b-form>
                </b-card>
                <b-card class="mt-3" bg-variant="light">
                    <b-card-title>
                        <h5 class="mb-0">Pemeriksaan IVA dan Sadanis</h5>
                    </b-card-title>
                    <b-form>
                        <b-form-group label-cols-md="4">
                            <template v-slot:label>
                               Hasil IVA
                            </template>
                            <b-form-radio-group
                                id="hasil_iva"
                                v-model="tes_iva_sadanis.hasil_iva"
                            >
                                <b-form-radio value="positif">Positif</b-form-radio>
                                <b-form-radio value="negatif">Negatif</b-form-radio>
                                <b-form-radio value="curiga">Curiga Kanker</b-form-radio>
                            </b-form-radio-group>
                        </b-form-group>
                        <b-form-group label-cols-md="4">
                            <template v-slot:label>
                               Tindak Lanjut Iva Positif
                            </template>
                            <b-form-radio-group
                                id="tindak_lanjut_iva"
                                v-model="tes_iva_sadanis.tindak_lanjut_iva"
                            >
                                <b-form-radio value="krioterapi">Krioterapi</b-form-radio>
                                <b-form-radio value="rujuk">Rujuk</b-form-radio>
                            </b-form-radio-group>
                        </b-form-group>
                        <b-form-group label-cols-md="4">
                            <template v-slot:label>
                               Hasil Sadanis
                            </template>
                            <b-form-radio-group
                                id="hasil_sadanis"
                                v-model="tes_iva_sadanis.hasil_sadanis"
                                stacked
                            >
                                <b-form-radio value="benjolan">Benjolan</b-form-radio>
                                <b-form-radio value="tidak">Tidak Ada Benjolan</b-form-radio>
                                <b-form-radio value="curiga">Curiga Kanker</b-form-radio>
                            </b-form-radio-group>
                        </b-form-group>
                        <b-form-group label-cols-md="4">
                            <template v-slot:label>
                               Tindak Lanjut Sadanis
                            </template>
                            <b-form-radio-group
                                id="tindak_lanjut_sadanis"
                                v-model="tes_iva_sadanis.tindak_lanjut_sadanis"
                            >
                                <b-form-radio value="true">Rujuk</b-form-radio>
                            </b-form-radio-group>
                        </b-form-group>
                    </b-form>
                </b-card>
            </b-col>
            <b-col cols="6" sm="6" md="6" lg="6">
                <b-card class="mt-3" bg-variant="light">
                    <b-card-title>
                        <h5 class="mb-0">Riwayat PTM pada Keluarga</h5>
                    </b-card-title>
                    <b-form>
                        <b-form-group label-cols-md="5">
                            <template v-slot:label>
                               Penyakit Diabetes
                            </template>
                            <b-form-radio-group
                                id="diabetes_k"
                                v-model="riwayat_ptm_keluarga.diabetes_k"
                            >
                                <b-form-radio value="true">Ya</b-form-radio>
                                <b-form-radio value="false">Tidak</b-form-radio>
                            </b-form-radio-group>
                        </b-form-group>
                        <b-form-group label-cols-md="5">
                            <template v-slot:label>
                               Penyakit Hipertensi
                            </template>
                            <b-form-radio-group
                                id="hipertensi_k"
                                v-model="riwayat_ptm_keluarga.hipertensi_k"
                            >
                                <b-form-radio value="true">Ya</b-form-radio>
                                <b-form-radio value="false">Tidak</b-form-radio>
                            </b-form-radio-group>
                        </b-form-group>
                        <b-form-group label-cols-md="5">
                            <template v-slot:label>
                               Penyakit Jantung
                            </template>
                            <b-form-radio-group
                                id="jantung_k"
                                v-model="riwayat_ptm_keluarga.jantung_k"
                            >
                                <b-form-radio value="true">Ya</b-form-radio>
                                <b-form-radio value="false">Tidak</b-form-radio>
                            </b-form-radio-group>
                        </b-form-group>
                        <b-form-group label-cols-md="5">
                            <template v-slot:label>
                               Penyakit Stroke
                            </template>
                            <b-form-radio-group
                                id="struk_k"
                                v-model="riwayat_ptm_keluarga.struk_k"
                            >
                                <b-form-radio value="true">Ya</b-form-radio>
                                <b-form-radio value="false">Tidak</b-form-radio>
                            </b-form-radio-group>
                        </b-form-group>
                        <b-form-group label-cols-md="5">
                            <template v-slot:label>
                               Penyakit Asma
                            </template>
                            <b-form-radio-group
                                id="asma_k"
                                v-model="riwayat_ptm_keluarga.asma_k"
                            >
                                <b-form-radio value="true">Ya</b-form-radio>
                                <b-form-radio value="false">Tidak</b-form-radio>
                            </b-form-radio-group>
                        </b-form-group>
                        <b-form-group label-cols-md="5">
                            <template v-slot:label>
                               Penyakit Kanker
                            </template>
                            <b-form-radio-group
                                id="kanker_k"
                                v-model="riwayat_ptm_keluarga.kanker_k"
                            >
                                <b-form-radio value="true">Ya</b-form-radio>
                                <b-form-radio value="false">Tidak</b-form-radio>
                            </b-form-radio-group>
                        </b-form-group>
                        <b-form-group label-cols-md="5">
                            <template v-slot:label>
                               Penyakit Kolesterol
                            </template>
                            <b-form-radio-group
                                id="kolesterol_k"
                                v-model="riwayat_ptm_keluarga.kolesterol_k"
                            >
                                <b-form-radio value="true">Ya</b-form-radio>
                                <b-form-radio value="false">Tidak</b-form-radio>
                            </b-form-radio-group>
                        </b-form-group>
                    </b-form>
                </b-card>
                <b-card class="mt-3" bg-variant="light">
                    <b-card-title>
                        <h5 class="mb-0">Faktor Risiko</h5>
                    </b-card-title>
                    <b-form>
                        <b-form-group label-cols-md="4">
                            <template v-slot:label>
                               Merokok
                            </template>
                            <b-form-radio-group
                                id="merokok"
                                v-model="faktor_risiko.merokok"
                                stacked
                            >
                                <b-form-radio value="aktif">Ya (Aktif)</b-form-radio>
                                <b-form-radio value="pasif">Ya (Pasif)</b-form-radio>
                                <b-form-radio value="tidak">Tidak Merokok</b-form-radio>
                            </b-form-radio-group>
                        </b-form-group>
                        <b-form-group label-cols-md="4">
                            <template v-slot:label>
                               Kurang Aktivitas Fisik
                            </template>
                            <b-form-radio-group
                                id="kurang_aktivitas_fisik"
                                v-model="faktor_risiko.kurang_aktivitas_fisik"
                                stacked
                            >
                                <b-form-radio value="true">Ya (< 3 kali per minggu)</b-form-radio>
                                <b-form-radio value="false">Tidak (> 3 kali per minggu)</b-form-radio>
                            </b-form-radio-group>
                        </b-form-group>
                        <b-form-group label-cols-md="4">
                            <template v-slot:label>
                               Gula Berlebihan
                            </template>
                            <b-form-radio-group
                                id="gula_berlebihan"
                                v-model="faktor_risiko.gula_berlebihan"
                            >
                                <b-form-radio value="true">Ya</b-form-radio>
                                <b-form-radio value="false">Tidak</b-form-radio>
                            </b-form-radio-group>
                        </b-form-group>
                        <b-form-group label-cols-md="4">
                            <template v-slot:label>
                               Garam Berlebihan
                            </template>
                            <b-form-radio-group
                                id="garam_berlebihan"
                                v-model="faktor_risiko.garam_berlebihan"
                            >
                                <b-form-radio value="true">Ya</b-form-radio>
                                <b-form-radio value="false">Tidak</b-form-radio>
                            </b-form-radio-group>
                        </b-form-group>
                        <b-form-group label-cols-md="4">
                            <template v-slot:label>
                               Lemak Berlebihan
                            </template>
                            <b-form-radio-group
                                id="lemak_berlebihan"
                                v-model="faktor_risiko.lemak_berlebihan"
                            >
                                <b-form-radio value="true">Ya</b-form-radio>
                                <b-form-radio value="false">Tidak</b-form-radio>
                            </b-form-radio-group>
                        </b-form-group>
                        <b-form-group label-cols-md="4">
                            <template v-slot:label>
                               Kurang Buah dan Sayur
                            </template>
                            <b-form-radio-group
                                id="kurang_buah_sayur"
                                v-model="faktor_risiko.kurang_buah_sayur"
                            >
                                <b-form-radio value="true">Ya (< 5 porsi per hari)</b-form-radio>
                                <b-form-radio value="false">Tidak (> 5 porsi per hari)</b-form-radio>
                            </b-form-radio-group>
                        </b-form-group>
                        <b-form-group label-cols-md="4">
                            <template v-slot:label>
                               Konsumsi Alkohol
                            </template>
                            <b-form-radio-group
                                id="konsumsi_alkohol"
                                v-model="faktor_risiko.konsumsi_alkohol"
                            >
                                <b-form-radio value="true">Ya</b-form-radio>
                                <b-form-radio value="false">Tidak</b-form-radio>
                            </b-form-radio-group>
                        </b-form-group>
                    </b-form>
                </b-card>
                <b-card class="mt-3" bg-variant="light">
                    <b-card-title>
                        <h5 class="mb-0">Pemeriksaan</h5>
                    </b-card-title>
                    <b-form>
                        <b-form-group label-cols-md="4">
                            <template v-slot:label>
                               Lingkar Perut
                            </template>
                            <b-input-group class="w-100">
                                <b-form-input
                                    id="lingkar_perut"
                                    v-model="pemeriksaan_ptm.lingkar_perut"
                                    type="number"
                                    placeholder="0.0"
                                ></b-form-input>
                                <b-input-group-append>
                                    <b-input-group-text>cm</b-input-group-text>
                                </b-input-group-append>
                            </b-input-group>
                        </b-form-group>
                        <b-form-group label-cols-md="4">
                            <template v-slot:label>
                               Pemeriksaan Gula
                            </template>
                            <b-form-input
                                id="pemeriksaan_gula"
                                v-model="pemeriksaan_ptm.pemeriksaan_gula"
                                type="text"
                            ></b-form-input>
                        </b-form-group>
                        <b-form-group label-cols-md="4">
                            <template v-slot:label>
                               Pemeriksaan Gula Puasa
                            </template>
                            <b-form-input
                                id="pemeriksaan_gula_puasa"
                                v-model="pemeriksaan_ptm.pemeriksaan_gula_puasa"
                                type="text"
                            ></b-form-input>
                        </b-form-group>
                        <b-form-group label-cols-md="4">
                            <template v-slot:label>
                               Pemeriksaan Gula 2 Jam PP
                            </template>
                            <b-form-input
                                id="pemeriksaan_gula_2_jam_pp"
                                v-model="pemeriksaan_ptm.pemeriksaan_gula_2_jam_pp"
                                type="text"
                            ></b-form-input>
                        </b-form-group>
                        <b-form-group label-cols-md="4">
                            <template v-slot:label>
                               HbA1c
                            </template>
                            <b-input-group class="w-100">
                                <b-form-input
                                    id="hba1c"
                                    v-model="pemeriksaan_ptm.hba1c"
                                    type="number"
                                    placeholder="0.0"
                                ></b-form-input>
                                <b-input-group-append>
                                    <b-input-group-text>%</b-input-group-text>
                                </b-input-group-append>
                            </b-input-group>
                        </b-form-group>
                        <b-form-group label-cols-md="4">
                            <template v-slot:label>
                               Rujuk RS
                            </template>
                            <b-form-radio-group
                                id="rujuk_rs"
                                v-model="pemeriksaan_ptm.rujuk_rs"
                            >
                                <b-form-radio value="true">Ya</b-form-radio>
                                <b-form-radio value="false">Tidak</b-form-radio>
                            </b-form-radio-group>
                        </b-form-group>
                        <b-form-group label-cols-md="4">
                            <template v-slot:label>
                               Terapi Farmakologi
                            </template>
                            <b-form-radio-group
                                id="terapi_farmatologi"
                                v-model="pemeriksaan_ptm.terapi_farmatologi"
                            >
                                <b-form-radio value="true">Diberikan Obat</b-form-radio>
                                <b-form-radio value="false">Tidak Diberikan Obat</b-form-radio>
                            </b-form-radio-group>
                        </b-form-group>
                        <b-form-group label-cols-md="4">
                            <template v-slot:label>
                               Edukasi
                            </template>
                            <b-form-radio-group
                                id="edukasi"
                                v-model="pemeriksaan_ptm.edukasi"
                            >
                                <b-form-radio value="true">Ya</b-form-radio>
                                <b-form-radio value="false">Tidak</b-form-radio>
                            </b-form-radio-group>
                        </b-form-group>
                        <b-form-group label-cols-md="4">
                            <template v-slot:label>
                               Konseling
                            </template>
                            <b-form-radio-group
                                id="konseling"
                                v-model="pemeriksaan_ptm.konseling"
                            >
                                <b-form-radio value="aktifitas">Aktifitas Fisik</b-form-radio>
                                <b-form-radio value="gizi">Gizi</b-form-radio>
                                <b-form-radio value="tidak">Tidak</b-form-radio>
                            </b-form-radio-group>
                        </b-form-group>
                    </b-form>
                </b-card>
                <b-card class="mt-3" bg-variant="light">
                    <b-card-title>
                        <h5 class="mb-0">Gangguan Pendengaran</h5>
                    </b-card-title>
                    <b-form>
                        <h6>Curiga Tuli Kongenital</h6>
                        <b-form-group label-cols-md="5">
                            <template v-slot:label>
                               Telinga Kanan
                            </template>
                            <b-form-radio-group
                                id="ctk_kanan"
                                v-model="gangguan_pendengaran.ctk_kanan"
                            >
                                <b-form-radio value="true">Ya</b-form-radio>
                                <b-form-radio value="false">Tidak</b-form-radio>
                            </b-form-radio-group>
                        </b-form-group>
                        <b-form-group label-cols-md="5">
                            <template v-slot:label>
                               Telinga Kiri
                            </template>
                            <b-form-radio-group
                                id="ctk_kiri"
                                v-model="gangguan_pendengaran.ctk_kiri"
                            >
                                <b-form-radio value="true">Ya</b-form-radio>
                                <b-form-radio value="false">Tidak</b-form-radio>
                            </b-form-radio-group>
                        </b-form-group>
                        <b-form-group label-cols-md="5">
                            <template v-slot:label>
                               Rujuk RS
                            </template>
                            <b-form-radio-group
                                id="rujuk_rs_ctk"
                                v-model="gangguan_pendengaran.rujuk_rs_ctk"
                            >
                                <b-form-radio value="true">Ya</b-form-radio>
                                <b-form-radio value="false">Tidak</b-form-radio>
                            </b-form-radio-group>
                        </b-form-group>
                        <h6>OMSK/Congek</h6>
                        <b-form-group label-cols-md="5">
                            <template v-slot:label>
                               Telinga Kanan
                            </template>
                            <b-form-radio-group
                                id="omsk_kanan"
                                v-model="gangguan_pendengaran.omsk_kanan"
                            >
                                <b-form-radio value="true">Ya</b-form-radio>
                                <b-form-radio value="false">Tidak</b-form-radio>
                            </b-form-radio-group>
                        </b-form-group>
                        <b-form-group label-cols-md="5">
                            <template v-slot:label>
                               Telinga Kiri
                            </template>
                            <b-form-radio-group
                                id="omsk_kiri"
                                v-model="gangguan_pendengaran.omsk_kiri"
                            >
                                <b-form-radio value="true">Ya</b-form-radio>
                                <b-form-radio value="false">Tidak</b-form-radio>
                            </b-form-radio-group>
                        </b-form-group>
                        <b-form-group label-cols-md="5">
                            <template v-slot:label>
                               Rujuk RS
                            </template>
                            <b-form-radio-group
                                id="rujuk_rs_osmk"
                                v-model="gangguan_pendengaran.rujuk_rs_osmk"
                            >
                                <b-form-radio value="true">Ya</b-form-radio>
                                <b-form-radio value="false">Tidak</b-form-radio>
                            </b-form-radio-group>
                        </b-form-group>
                        <h6>Serumen</h6>
                        <b-form-group label-cols-md="5">
                            <template v-slot:label>
                               Telinga Kanan
                            </template>
                            <b-form-radio-group
                                id="serumen_kanan"
                                v-model="gangguan_pendengaran.serumen_kanan"
                            >
                                <b-form-radio value="true">Ya</b-form-radio>
                                <b-form-radio value="false">Tidak</b-form-radio>
                            </b-form-radio-group>
                        </b-form-group>
                        <b-form-group label-cols-md="5">
                            <template v-slot:label>
                               Telinga Kiri
                            </template>
                            <b-form-radio-group
                                id="serumen_kiri"
                                v-model="gangguan_pendengaran.serumen_kiri"
                            >
                                <b-form-radio value="true">Ya</b-form-radio>
                                <b-form-radio value="false">Tidak</b-form-radio>
                            </b-form-radio-group>
                        </b-form-group>
                        <b-form-group label-cols-md="5">
                            <template v-slot:label>
                               Rujuk RS
                            </template>
                            <b-form-radio-group
                                id="rujuk_rs_serumen"
                                v-model="gangguan_pendengaran.rujuk_rs_serumen"
                            >
                                <b-form-radio value="true">Ya</b-form-radio>
                                <b-form-radio value="false">Tidak</b-form-radio>
                            </b-form-radio-group>
                        </b-form-group>
                    </b-form>
                </b-card>
                <b-card class="mt-3" bg-variant="light">
                    <b-card-title>
                        <h5 class="mb-0">Form UBM</h5>
                    </b-card-title>
                    <b-form>
                        <b-form-group label-cols-md="3">
                            <template v-slot:label>
                               Konseling
                            </template>
                            <b-form-radio-group
                                id="konseling"
                                v-model="data_ubm.konseling"
                            >
                                <b-form-radio value="konseling1">Konseling 1</b-form-radio>
                                <b-form-radio value="konseling2">Konseling 2</b-form-radio>
                                <b-form-radio value="konseling3">Konseling 3</b-form-radio>
                                <b-form-radio value="konseling4">Konseling 4</b-form-radio>
                                <b-form-radio value="konseling5">Konseling 5</b-form-radio>
                                <b-form-radio value="konseling6">Konseling 6</b-form-radio>
                            </b-form-radio-group>
                        </b-form-group>
                        <b-form-group label-cols-md="3">
                            <template v-slot:label>
                               CAR
                            </template>
                            <b-form-radio-group
                                id="tindak_lanjut_iva"
                                v-model="data_ubm.tindak_lanjut_iva"
                            >
                                <b-form-radio value="car3">CAR 3</b-form-radio>
                                <b-form-radio value="car6">CAR 6</b-form-radio>
                                <b-form-radio value="car9">CAR 9</b-form-radio>
                            </b-form-radio-group>
                        </b-form-group>
                        <b-form-group label-cols-md="3">
                            <template v-slot:label>
                               Rujuk UBM
                            </template>
                            <b-form-radio-group
                                id="rujuk_rs_ubm_ptm"
                                v-model="data_ubm.rujuk_rs_ubm_ptm"
                            >
                                <b-form-radio value="true">Ya</b-form-radio>
                                <b-form-radio value="false">Tidak</b-form-radio>
                            </b-form-radio-group>
                        </b-form-group>
                        <b-form-group label-cols-md="3">
                            <template v-slot:label>
                               Kondisi
                            </template>
                            <b-form-radio-group
                                id="kondisi_ptm"
                                v-model="data_ubm.kondisi_ptm"
                            >
                                <b-form-radio value="do">DO</b-form-radio>
                                <b-form-radio value="kambuh">Kambuh</b-form-radio>
                                <b-form-radio value="sukses">Sukses</b-form-radio>
                            </b-form-radio-group>
                        </b-form-group>
                    </b-form>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="d-flex justify-content-end">
                <b-button v-if="!kirim" variant="success" @click="simpan()">
                    Simpan
                </b-button>
                <b-button v-else variant="warning" @click="simpan()">
                    Update
                </b-button>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
  import moment from 'moment';
  moment.locale('id');
  export default {
    props: ['kunjungan', 'listDokter', 'listPerawat'],
    emits: ['dataFromChild'],
    data() {
      return {
        id: "--- OTOMATIS ---",
        // listDokter: [],
        // listPerawat: [],
        ptm: {
            rm_id: null,
            tanggal_ptm: null,
            ms_dokter_id: null,
            ms_perawat_id: null,
        },
        riwayat_ptm_diri: {
            diabetes_d: false,
            hipertensi_d: false,
            jantung_d: false,
            struk_d: false,
            asma_d: false,
            kanker_d: false,
            kolesterol_d: false,
        },
        riwayat_ptm_keluarga: {
            diabetes_k: false,
            hipertensi_k: false,
            jantung_k: false,
            struk_k: false,
            asma_k: false,
            kanker_k: false,
            kolesterol_k: false,
        },
        data_diagnosa: {
            diagnosa_1: null,
            diagnosa_2: null,
            diagnosa_3: null,
        },
        tekanan_darah_ptm: {
            sistole_ptm: null,
            diastole_ptm: null,
            tinggi_badan_ptm: null,
            berat_badan_ptm: null,
            imt_ptm: null,
            hasil_imt_ptm: null,
        },
        faktor_risiko: {
            merokok: null,
            kurang_aktivitas_fisik: false,
            gula_berlebihan: false,
            garam_berlebihan: false,
            lemak_berlebihan: false,
            kurang_buah_sayur: false,
            konsumsi_alkohol: false,
        },
        gangguan_penglihatan: {
            katarak_mata_kanan: false,
            katarak_mata_kiri: false,
            rujuk_rs_katarak: false,
            refraksi_mata_kanan: false,
            refraksi_mata_kiri: false,
            rujuk_rs_refraksi: false,
        },
        pemeriksaan_ptm: {
            lingkar_perut: null,
            pemeriksaan_gula: null,
            pemeriksaan_gula_puasa: null,
            pemeriksaan_gula_2_jam_pp: null,
            hba1c: null,
            rujuk_rs: false,
            terapi_farmatologi: false,
            edukasi: false,
            konseling: null,
        },
        tes_iva_sadanis: {
            hasil_iva: null,
            tindak_lanjut_iva: null,
            hasil_sadanis: null,
            tindak_lanjut_sadanis: null,
        },
        gangguan_pendengaran: {
            ctk_kanan: false,
            ctk_kiri: false,
            rujuk_rs_ctk: false,
            omsk_kanan: false,
            omsk_kiri: false,
            rujuk_rs_osmk: false,
            serumen_kanan: false,
            serumen_kiri: false,
            rujuk_rs_serumen: false,
        },
        data_ubm: {
            konseling: null,
            car_ptm: null,
            rujuk_rs_ubm_ptm: false,
            kondisi_ptm: null,
            
        },
        items: [],
        kirim: false,
      }
    },
    async mounted() {
      const vm = this
      vm.getData()
    },
    methods: {
      onFiltered(filteredItems) {
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      },
      countImt(){
        const vm = this
        let imt = (vm.tekanan_darah_ptm.berat_badan_ptm / ((vm.tekanan_darah_ptm.tinggi_badan_ptm/100) * (vm.tekanan_darah_ptm.tinggi_badan_ptm/100))).toFixed(1)
        vm.tekanan_darah_ptm.imt_ptm = imt
        if (imt < 18.5) vm.tekanan_darah_ptm.hasil_imt_ptm = 'Berat badan kurang (Underweight)'
        else if (imt >= 18.5 && imt <= 22.9) vm.tekanan_darah_ptm.hasil_imt_ptm = 'Berat normal'
        else if (imt >= 23 && imt <= 24.9) vm.tekanan_darah_ptm.hasil_imt_ptm = 'Kelebihan berat badan (Overweight) dengan resiko'
        else if (imt >= 25 && imt <= 29.9) vm.tekanan_darah_ptm.hasil_imt_ptm = 'Obesitas'
        else vm.tekanan_darah_ptm.hasil_imt_ptm = 'Obesitas II'
      },
      async getData(){
        const vm = this
        // console.log(this.rm_id, 'ini data rmid');
        vm.ptm.tanggal_ptm = vm.$moment(new Date).format("YYYY-MM-DD HH:mm")
        try {
            // let listDokter = await vm.$axios.post('/tenaga_medis/list', {nama_jenis_tenaga_medis: 'dokter'})
            // // console.log('itikiwir', listDokter)
            // if(listDokter.data.status == 200){
            //     vm.listDokter = listDokter.data.data
            // }
            // let listPerawat = await vm.$axios.post('/tenaga_medis/list_tenaga_medis_non_dokter')
            // // console.log('itikiwir', listPerawat)
            // if(listPerawat.data.status == 200){
            //     vm.listPerawat = listPerawat.data.data
            // }
        } catch (error) {
            console.log(error)
        }
      },
      simpan(){
        let vm = this
        vm.ptm.rm_id = vm.kunjungan.rm_id
        let data_skrining = {}
        data_skrining.ptm = vm.ptm
        data_skrining.riwayat_ptm_diri = vm.riwayat_ptm_diri
        data_skrining.riwayat_ptm_keluarga = vm.riwayat_ptm_keluarga
        data_skrining.tekanan_darah_ptm = vm.tekanan_darah_ptm
        // data_skrining.data_diagnosa = vm.data_diagnosa
        // data_skrining.data_ubm = vm.data_ubm
        data_skrining.faktor_risiko = vm.faktor_risiko
        data_skrining.gangguan_pendengaran = vm.gangguan_pendengaran
        data_skrining.gangguan_penglihatan = vm.gangguan_penglihatan
        data_skrining.tes_iva_sadanis = vm.tes_iva_sadanis
        data_skrining.pemeriksaan_ptm = vm.pemeriksaan_ptm
        // console.log(data_skrining, 'ini datanya');
        vm.kirim = true
        console.log('simpan skrining', data_skrining)
        vm.$emit("dataFromChild", data_skrining)
      },
      triggerAlert(event) {
        let vm = this;
        vm.$store.commit("set_alert", event);
      },
    },
  }
  </script>