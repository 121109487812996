<template>
<!-- <div class=""> -->
  <b-modal
    id="modal-sync-kunjungan"
    size="lg"
    title="List Kunjungan"
    header-bg-variant="success"
    header-text-variant="light"
    @show="openModal()"
  >
    <b-container fluid>
      <b-row>
        <b-col cols="12" md="12" lg="12">
          <b-table
            :items="items"
            :fields="fields"
            :current-page="currentPage"
            :per-page="perPage"
            responsive
            show-empty
            small
            bordered
            striped
            hover
            :busy="tableBusy"
          >
            <template #cell(actions)="item">
              <div class="d-flex nowrap">
                <b-button
                  :variant="item.item.sync ? 'success' : 'warning'"
                  class="mr-1"
                  v-c-tooltip.hover.click="'Sync'"
                  @click="item.item.sync = !item.item.sync"
                  ><CIcon name="cil-check" /> {{ item.actions }}</b-button
                >
                <b-button
                  variant="success"
                  class="mr-1"
                  v-c-tooltip.hover.click="'Daftar Kunjungan'"
                  v-b-modal.modal-sync-kunjungan-kunjungan
                  @click="is_data = item.item"
                  ><CIcon name="cil-info" /> {{ item.actions }}</b-button
                >
              </div>
            </template>
          </b-table>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="5" offset-md="7">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="fill"
            size="sm"
          ></b-pagination>
        </b-col>
      </b-row>
    </b-container>
    <template #modal-footer>
      <b-button variant="secondary" @click="sync()">
        Sync
      </b-button>
      <b-button variant="secondary" @click="$bvModal.hide('modal-sync-kunjungan')">
        Tutup
      </b-button>
    </template>
  </b-modal>
<!-- </div> -->
</template>
<script>
export default {
  components: {
  },
  emits: [''],
  props: ['is_data'],
  data() {
    return {
      items: [],
      fields: [
        {
          key: "no",
          label: "No",
          sortDirection: "desc",
          class: "table-number text-center",
        },
        {
          key: "no",
          label: "No",
          sortDirection: "desc",
          class: "table-number text-center",
        },
        {
          key: "no_urut",
          label: "No. Urut",
          sortDirection: "desc",
          class: "text-center",
        },
        {
          key: "no_rm",
          label: "No. RM",
          sortDirection: "desc",
          class: "text-center",
        },
        {
          key: "no_bpjs",
          label: "No. BPJS",
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "nama_jenis_layanan",
          label: "Jenis Pelayanan",
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "nama_poli",
          label: "Nama Poli",
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "nama_lengkap",
          label: "Nama Pasien",
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "actions",
          label: "Actions",
          class: "table-option-2 text-center",
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      tableBusy: false,
    }
  },
  watch: {
  },
  methods: {
    async openModal(){
      const vm = this
      vm.getData()
    },
    async getData(){
      const vm = this
      vm.tableBusy = true
      let result = await vm.$axios.post('/pendaftaran/list', {
        is_bpjs: 'true',
        tanggal_daftar: vm.$moment(vm.is_data.date).format('YYYY-MM-DD'),
      })
      console.log('result', result)
      if (result.data.status == 200) {
        vm.items = result.data.data.map((x, idx) => {
          console.log(x.tanggal_daftar)
          return {
            ...x,
            no: idx + 1,
            nama_jenis_layanan: vm.$findKey(vm.$store.state.data_static.tkp, x.kd_tkp, false, 'kdTkp', 'text'),
            sync: true,
          }
        })
      }
      vm.totalRows = parseInt(result.data.data.length)
      vm.tableBusy = false
    },
    async sync(){
      const vm = this
      vm.tableBusy = true
      // let result = await vm.$axios.post('/pendaftaran/list', {
      //   is_bpjs: 'true',
      //   tanggal_daftar: vm.$moment(vm.is_data.date).format('YYYY-MM-DD'),
      // })
      vm.tableBusy = false
    },
  },
}
</script>