<template>
  <div>
    <b-row class="mt-3">
      <b-col cols="12" sm="12" md="12" lg="6" xl="6" >
        <b-form-group label-cols-md="4">
          <template v-slot:label>
            Kondisi Ibu KU
          </template>
          <b-form-input
            placeholder="Kondisi Ibu KU"
            type="text"
            v-model="kala_iv.kondisi_ibu"
          ></b-form-input>
        </b-form-group>

        <b-form-group label-cols-md="4">
          <template v-slot:label>
            Tekanan Darah 
          </template>
          <div style="width: 100%;display: flex;justify-content:space-between">
            <div style="width:49%">
              <b-form-input
                placeholder="mm"
                v-model="kala_iv.sistole"
                type="number"
                ></b-form-input>
            </div>
            <div style="width:49%">
              <b-form-input
              placeholder="Hg"
              v-model="kala_iv.diastole"
                type="number"
                ></b-form-input>
            </div>
          </div>
        </b-form-group>

        <b-form-group label-cols-md="4">
          <template v-slot:label>
            Nadi
          </template>
          <b-form-input
            placeholder="x/menit"
            v-model="kala_iv.nadi"
            type="number"
          ></b-form-input>
        </b-form-group>
        
      </b-col>

      <b-col cols="12" sm="12" md="12" lg="6" xl="6" >
        <b-form-group label-cols-md="4">
          <template v-slot:label>
            Pernapasan
          </template>
          <b-form-input
            placeholder="x/menit"
            v-model="kala_iv.pernapasan"
            type="number"
          ></b-form-input>
        </b-form-group>

        <b-form-group label-cols-md="4">
          <template v-slot:label>
            Masalah dan Penatalaksanaan
          </template>
          <b-form-input
            placeholder="x/menit"
            v-model="kala_iv.masalah_penatalaksanaan"
            type="text"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" sm="12" md="12" lg="12" xl="12">
        <hr>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12" sm="12" md="12" lg="12" xl="12">
        <b-button @click="simpan()" variant="primary">Simpan</b-button>
      </b-col>
    </b-row>
  </div>  
</template>

<script>


export default {
  name: 'App',
  components: {
  },
  data() {
    return {
      kala_iv: {
        kondisi_ibu: null,
        sistole: null,
        diastole: null,
        nadi: null,
        pernapasan: null,
        masalah_penatalaksanaan: null,
      }
    }
  },
  computed: {
    partograf_id(){
      return this.$store.state.partograf_id
    },
  },
  mounted(){
    this.getData()
  },
  methods: {
    getData(){
      let vm = this
      vm.$axios.post("/catatan_persalinan/list", {partograf_id:vm.partograf_id})
      .then((res) => {
        if(res.data.data.length){
          // console.log(res, 'ini catatan');
          if(res.data.data[0].kala_iv){
            vm.kala_iv = res.data.data[0].kala_iv
          }
        }
      })
      .catch((err) => {
        vm.$store.commit('set_alert', {variant: 'danger', msg: err.message, showing: true})
      })
    },
    simpan(){
      let vm = this
      vm.$axios.post("/catatan_persalinan/register", {
        partograf_id: vm.partograf_id,
        kala_iv: vm.kala_iv,
      })
      .then((res) => {
        // console.log(res, 'ini responya ui');
        if(res.data.status == 200 && res.data.message == "sukses"){
          vm.$store.commit('set_alert', {variant: 'success', msg: "BERHASIL MENYIMPAN DATA", showing: true})
        }
        else{
          vm.$store.commit('set_alert', {variant: 'danger', msg: res.data.message, showing: true})
        }
      })
      .catch((err) => {
        vm.$store.commit('set_alert', {variant: 'danger', msg: err.message, showing: true})
      })
    },
  }
}
</script>

