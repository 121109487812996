<template>
<div class="">
  <b-modal
    id="modal-sync"
    size="lg"
    title="List Sync"
    header-bg-variant="success"
    header-text-variant="light"
    @show="openModal()"
  >
    <b-container fluid>
      <b-row>
        <b-col cols="12" md="12" lg="12">
          <date-picker
            style="width: 100%"
            id="tanggal_daftar"
            format="MMMM YYYY"
            v-model="filter.tanggal"
            type='month'
          ></date-picker>
        </b-col>
        <b-col cols="12" md="12" lg="12">
          <b-table
            :items="items"
            :fields="fields"
            :current-page="currentPage"
            :per-page="perPage"
            responsive
            show-empty
            small
            bordered
            striped
            hover
            :busy="tableBusy"
          >
            <template #cell(actions)="item">
              <b-button
                variant="success"
                class="mr-1"
                v-c-tooltip.hover.click="'Daftar Kunjungan'"
                v-b-modal.modal-sync-kunjungan
                @click="is_data = item.item, $bvModal.hide('modal-sync')"
                ><CIcon name="cil-info" /> {{ item.actions }}</b-button
              >
            </template>
          </b-table>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="5" offset-md="7">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="fill"
            size="sm"
          ></b-pagination>
        </b-col>
      </b-row>
    </b-container>
    <template #modal-footer>
      <b-button variant="secondary" @click="$bvModal.hide('modal-sync')">
        Tutup
      </b-button>
    </template>
  </b-modal>
  <SyncKunjungan :is_data="is_data"/>
</div>
</template>
<script>
import SyncKunjungan from './modal_sync_kunjungan.vue'
export default {
  components: {
    SyncKunjungan
  },
  emits: [''],
  props: [''],
  data() {
    return {
      filter: {
        tanggal: new Date(),
      },
      is_data: {},
      items: [],
      fields: [
        {
          key: "no",
          label: "No",
          sortDirection: "desc",
          class: "table-number text-center",
        },
        {
          key: "sudah_sync",
          label: "Sudah Sync",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "belum_sync",
          label: "belum Sync",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "nama_tanggal",
          label: "Tanggal",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "actions",
          label: "Actions",
          class: "table-option-2 text-center",
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      tableBusy: false,
    }
  },
  watch: {
    'filter.tanggal'(newVal, oldVal){
      if(newVal != oldVal){
        console.log(newVal)
        this.getData()
      }
    }
  },
  methods: {
    async openModal(){
      const vm = this
      vm.getData()
    },
    async getData(){
      const vm = this
      vm.tableBusy = true
      let result = await vm.$axios.post('/pendaftaran/total_sync_bpjs_by_tanggal', {
        tanggal : vm.filter.tanggal ? vm.$moment(vm.filter.tanggal).format('YYYY-MM') : null
      })
      console.log('result', result)
      if (result.data.status == 200) {
        vm.items = result.data.data.map((x, idx) => {
          return {
            ...x,
            no: idx + 1,
            nama_tanggal: vm.$moment(x.date).format('LL')
          }
        })
      }
      vm.totalRows = parseInt(result.data.data.length)
      vm.tableBusy = false
    },
  },
}
</script>