<template>
  <div>
    <b-row>
      <b-col cols="6">
        <b-row class="" style="margin: 0px -6px;">
          <b-col cols="5"><strong class="">Keluhan Utama</strong></b-col>
          <b-col cols="1" class="text-right">:</b-col>
          <b-col cols="6"><span class="">{{dataAnamnesa.keluhan_utama || '-'}}</span></b-col>
          <b-col cols="5"><strong class="">Keluhan Tambahan</strong></b-col>
          <b-col cols="1" class="text-right">:</b-col>
          <b-col cols="6"><span class="">{{dataAnamnesa.keluhan_tambahan || '-'}}</span></b-col>
          <b-col cols="5"><strong class="">Lama Sakit</strong></b-col>
          <b-col cols="1" class="text-right">:</b-col>
          <b-col cols="6"><span class="">{{dataAnamnesa.lama_sakit_hari || '0'}} hari {{dataAnamnesa.lama_sakit_bulan || '0'}} bulan {{dataAnamnesa.lama_sakit_tahun || '0'}} tahun</span></b-col>
          <b-col cols="5"><strong class="">Dokter</strong></b-col>
          <b-col cols="1" class="text-right">:</b-col>
          <b-col cols="6"><span class="">{{dataAnamnesa.nama_dokter || '-'}}</span></b-col>
          <b-col cols="5"><strong class="">Perawat</strong></b-col>
          <b-col cols="1" class="text-right">:</b-col>
          <b-col cols="6"><span class="">{{dataAnamnesa.nama_perawat || '-'}}</span></b-col>
          <b-col cols="5"><strong class="">RPS</strong></b-col>
          <b-col cols="1" class="text-right">:</b-col>
          <b-col cols="6"><span class="">{{dataAnamnesa.rps || '-'}}</span></b-col>
          <b-col cols="5"><strong class="">RPD</strong></b-col>
          <b-col cols="1" class="text-right">:</b-col>
          <b-col cols="6"><span class="">{{dataAnamnesa.rpd || '-'}}</span></b-col>
          <b-col cols="5"><strong class="">RPK</strong></b-col>
          <b-col cols="1" class="text-right">:</b-col>
          <b-col cols="6"><span class="">{{dataAnamnesa.rpk || '-'}}</span></b-col>
          <b-col cols="5"><strong class="">Sistole</strong></b-col>
          <b-col cols="1" class="text-right">:</b-col>
          <b-col cols="6"><span class="">{{dataAnamnesa.sistole || '-'}}</span></b-col>
          <b-col cols="5"><strong class="">Diastole</strong></b-col>
          <b-col cols="1" class="text-right">:</b-col>
          <b-col cols="6"><span class="">{{dataAnamnesa.diastole || '-'}}</span></b-col>
          <b-col cols="5"><strong class="">Berat Badan</strong></b-col>
          <b-col cols="1" class="text-right">:</b-col>
          <b-col cols="6"><span class="">{{dataAnamnesa.berat_badan || '-'}}</span></b-col>
          <b-col cols="5"><strong class="">Tinggi Badan</strong></b-col>
          <b-col cols="1" class="text-right">:</b-col>
          <b-col cols="6"><span class="">{{dataAnamnesa.tinggi_badan || '-'}}</span></b-col>
          <b-col cols="5"><strong class="">Resp Rate</strong></b-col>
          <b-col cols="1" class="text-right">:</b-col>
          <b-col cols="6"><span class="">{{dataAnamnesa.resp_rate || '-'}}</span></b-col>
          <b-col cols="5"><strong class="">Heart Rate</strong></b-col>
          <b-col cols="1" class="text-right">:</b-col>
          <b-col cols="6"><span class="">{{dataAnamnesa.heart_rate || '-'}}</span></b-col>
          <b-col cols="5"><strong class="">Lingkar Perut</strong></b-col>
          <b-col cols="1" class="text-right">:</b-col>
          <b-col cols="6"><span class="">{{dataAnamnesa.lingkar_perut || '-'}}</span></b-col>
          <b-col cols="5"><strong class="">IMT</strong></b-col>
          <b-col cols="1" class="text-right">:</b-col>
          <b-col cols="6"><span class="">{{dataAnamnesa.imt || '-'}}</span></b-col>
          <b-col cols="5"><strong class="">Hasil IMT</strong></b-col>
          <b-col cols="1" class="text-right">:</b-col>
          <b-col cols="6"><span class="">{{dataAnamnesa.hasil_imt || '-'}}</span></b-col>
        </b-row>
      </b-col>
      <b-col>
        <b-row class="" style="margin: 0px -6px;">
          <b-col cols="5"><strong class="">Saturasi (Sp02)</strong></b-col>
          <b-col cols="1" class="text-right">:</b-col>
          <b-col cols="6"><span class="">{{dataAnamnesa.saturasi || '-'}}</span></b-col>
          <b-col cols="5"><strong class="">Suhu</strong></b-col>
          <b-col cols="1" class="text-right">:</b-col>
          <b-col cols="6"><span class="">{{dataAnamnesa.suhu || '-'}}</span></b-col>
          <b-col cols="5"><strong class="">Aktifitas fisik</strong></b-col>
          <b-col cols="1" class="text-right">:</b-col>
          <b-col cols="6"><span class="">{{dataAnamnesa.aktifitas_fisik || '-'}}</span></b-col>
          <b-col cols="5"><strong class="">Detak Jantung</strong></b-col>
          <b-col cols="1" class="text-right">:</b-col>
          <b-col cols="6"><span class="">{{dataAnamnesa.detak_jantung || '-'}}</span></b-col>
          <b-col cols="5"><strong class="">Triage</strong></b-col>
          <b-col cols="1" class="text-right">:</b-col>
          <b-col cols="6"><span class="">{{dataAnamnesa.triage || '-'}}</span></b-col>
          <b-col cols="5"><strong class="">Terapi</strong></b-col>
          <b-col cols="1" class="text-right">:</b-col>
          <b-col cols="6"><span class="">{{dataAnamnesa.terapi || '-'}}</span></b-col>
          <b-col cols="5"><strong class="">Rencana tindakan</strong></b-col>
          <b-col cols="1" class="text-right">:</b-col>
          <b-col cols="6"><span class="">{{dataAnamnesa.rencana_tindakan || '-'}}</span></b-col>
          <b-col cols="5"><strong class="">Tipe Askep</strong></b-col>
          <b-col cols="1" class="text-right">:</b-col>
          <b-col cols="6"><span class="">{{dataAnamnesa.tipe_askep || '-'}}</span></b-col>
          <b-col cols="5"><strong class="">Deskripsi Askep</strong></b-col>
          <b-col cols="1" class="text-right">:</b-col>
          <b-col cols="6"><span class="">{{dataAnamnesa.deskripsi_askep || '-'}}</span></b-col>
          <b-col cols="5"><strong class="">Edukasi</strong></b-col>
          <b-col cols="1" class="text-right">:</b-col>
          <b-col cols="6"><span class="">{{dataAnamnesa.edukasi || '-'}}</span></b-col>
          <b-col cols="5"><strong class="">Observasi</strong></b-col>
          <b-col cols="1" class="text-right">:</b-col>
          <b-col cols="6"><span class="">{{dataAnamnesa.observasi || '-'}}</span></b-col>
          <b-col cols="5"><strong class="">Keterangan</strong></b-col>
          <b-col cols="1" class="text-right">:</b-col>
          <b-col cols="6"><span class="">{{dataAnamnesa.keterangan_pool_anamnesa || '-'}}</span></b-col>
          <b-col cols="5"><strong class="">Biopsikososial</strong></b-col>
          <b-col cols="1" class="text-right">:</b-col>
          <b-col cols="6"><span class="">{{dataAnamnesa.biopsikososial || '-'}}</span></b-col>
          <b-col cols="5"><strong class="">Tindakan perawatan</strong></b-col>
          <b-col cols="1" class="text-right">:</b-col>
          <b-col cols="6"><span class="">{{dataAnamnesa.tindakan_keperawatan || '-'}}</span></b-col>
          <b-col cols="5"><strong class="">Skala nyeri</strong></b-col>
          <b-col cols="1" class="text-right">:</b-col>
          <b-col cols="6"><span class="">{{dataAnamnesa.skala_nyeri_angka}} - {{dataAnamnesa.skala_nyeri}}</span></b-col>
          <b-col cols="5"><strong class="">Merokok</strong></b-col>
          <b-col cols="1" class="text-right">:</b-col>
          <b-col cols="6"><span class="">{{dataAnamnesa.merokok ? 'Ya' : 'Tidak'}}</span></b-col>
          <b-col cols="5"><strong class="">Konsumsi alkohol</strong></b-col>
          <b-col cols="1" class="text-right">:</b-col>
          <b-col cols="6"><span class="">{{dataAnamnesa.konsumsi_alkohol ? 'Ya' : 'Tidak'}}</span></b-col>
          <b-col cols="5"><strong class="">Kurang sayur buah</strong></b-col>
          <b-col cols="1" class="text-right">:</b-col>
          <b-col cols="6"><span class="">{{dataAnamnesa.kurang_sayur_buah ? 'Ya' : 'Tidak'}}</span></b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- <pre>{{dataAnamnesa}}</pre> -->
  </div>
</template>
<script>
export default {
  props: [
    'dataKunjungan',
  ],
  data() {
    return {
      busy: false,
      dataAnamnesa: {},
    }
  },
  computed: {
    
  },
  mounted() {
    const vm = this
    vm.getData()
  },
  methods: {
    async getData(){
      const vm = this
      vm.busy = true
      try {
        await new Promise(resolve => setTimeout(resolve, 100));
        // console.log('id', vm.$route.params.id)
        let dataAnamnesa = await vm.$axios.post('/anamnesa/details_by_id', {kunjungan_id: vm.dataKunjungan.kunjungan_id})
        // console.log('dataAnamnesa', dataAnamnesa)
        if(dataAnamnesa.data.status == 200 && dataAnamnesa.data.data.length > 0){
          vm.dataAnamnesa = dataAnamnesa.data.data[0]
        }else{
          // vm.triggerAlert({ variant: "warning", msg: 'Anamnesa Tidak Ditemukan', showing: true })
        }
      } catch (error) {
        console.log(error)
        vm.triggerAlert({ variant: "warning", msg: 'Terjadi kesalahan sistem', showing: true });
      } finally {
        vm.busy = false
      }
    },
    triggerAlert(event) {
      let vm = this;
      vm.$store.commit("set_alert", event);
    },
  },
}
</script>