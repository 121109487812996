<template>
<!-- <div class=""> -->
  <b-modal
    id="modal-register-soap"
    size="lg"
    title="SOAP"
    header-bg-variant="primary"
    header-text-variant="light"
    @show="openModal()"
  >
    <b-container fluid>
      <b-row>
        <b-col cols="12">
          <label for="nama_assesment">Assessment <span class="text-danger">*</span></label>
          <b-form-input
            id="nama_assesment"
            type="text"
            v-model="dataForm.nama_assesment"
          ></b-form-input>
        </b-col>
        <b-col cols="12">
          <label for="objective">Objective</label>
          <Multiselect
            id="objective"
            v-model="dataForm.data_assesment.objective"
            :options="dataForm.list_objective"
            :multiple="true"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            :taggable="true" 
            @tag="addTagObjective"
            placeholder="-- Pilih objective --"
          ></Multiselect>
          <div class="">
            <ol type="1" class="mx-3 px-1">
              <li v-for="(item, key) of dataForm.data_assesment.objective" :key="key">{{item}}</li>
            </ol>
          </div>
        </b-col>
        <b-col cols="12">
          <label for="subjective">Subjective</label>
          <Multiselect
            id="subjective"
            v-model="dataForm.data_assesment.subjective"
            :options="dataForm.list_subjective"
            :multiple="true"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            :taggable="true" 
            @tag="addTagSubjective"
            placeholder="-- Pilih subjective --"
          ></Multiselect>
          <div class="">
            <ol type="1" class="mx-3 px-1">
              <li v-for="(item, key) of dataForm.data_assesment.subjective" :key="key">{{item}}</li>
            </ol>
          </div>
        </b-col>
        <b-col cols="12">
          <label for="planing">Planing</label>
          <Multiselect
            id="planing"
            v-model="dataForm.data_assesment.plan"
            :options="dataForm.list_plan"
            :multiple="true"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            :taggable="true" 
            @tag="addTagPlaning"
            placeholder="-- Pilih planing --"
          ></Multiselect>
          <div class="">
            <ol type="1" class="mx-3 px-1">
              <li v-for="(item, key) of dataForm.data_assesment.plan" :key="key">{{item}}</li>
            </ol>
          </div>
        </b-col>
        <b-col cols="12">
          <div class="d-flex justify-content-end">
            <b-button
              v-if="dataForm.assesment_id"
              variant="warning"
              :disabled="tableBusy"
              @click="update()"
              > Update
            </b-button>
            <b-button
              v-else
              variant="primary"
              :disabled="tableBusy"
              @click="simpan()"
              > Simpan
            </b-button>
          </div>
        </b-col>
        <b-col cols="12" md="12" lg="12">
          <b-table
            :items="items"
            :fields="fields"
            :current-page="currentPage"
            :per-page="perPage"
            responsive
            show-empty
            small
            bordered
            striped
            hover
            :busy="tableBusy"
          >
            <template #cell(actions)="item">
              <b-button
                variant="warning"
                class="mr-1"
                v-c-tooltip.hover.click="'Daftar Kunjungan'"
                @click="onEdit(item.item)"
                ><CIcon name="cil-pencil" /> {{ item.actions }}</b-button
              >
            </template>
          </b-table>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="5" offset-md="7">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="fill"
            size="sm"
          ></b-pagination>
        </b-col>
      </b-row>
    </b-container>
    <template #modal-footer>
      <b-button variant="secondary" @click="$bvModal.hide('modal-register-soap')">
        Tutup
      </b-button>
    </template>
  </b-modal>
<!-- </div> -->
</template>
<script>
export default {
  components: {
  },
  emits: [''],
  props: [''],
  data() {
    return {
      dataForm: {
        nama_assesment: null,
        data_assesment: {
          objective: [],
          subjective: [],
          plan: [],
        },
        list_objective: [],
        list_subjective: [],
        list_plan: [],
      },
      is_data: {},
      items: [],
      fields: [
        {
          key: "no",
          label: "No",
          sortDirection: "desc",
          class: "table-number text-center",
        },
        {
          key: "nama_assesment",
          label: "Nama",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "actions",
          label: "Actions",
          class: "table-option-2 text-center",
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      tableBusy: false,
    }
  },
  watch: {
  },
  methods: {
    async openModal(){
      const vm = this
      vm.getData()
    },
    addTagObjective(name){
      const vm = this
      for (var i = 0; i < vm.dataForm.data_assesment.objective.length; i++) {
        const x = vm.dataForm.data_assesment.objective[i];
        if(x == name) break
      }
      if(i == vm.dataForm.data_assesment.objective.length){
        vm.dataForm.data_assesment.objective.push(name)
        vm.dataForm.list_objective.push(name)
        // console.log(vm.dataForm.data_assesment)
      }
    },
    addTagSubjective(name){
      const vm = this
      vm.dataForm.data_assesment.subjective.push(name)
      vm.dataForm.list_subjective.push(name)
    },
    addTagPlaning(name){
      const vm = this
      vm.dataForm.data_assesment.plan.push(name)
      vm.dataForm.list_plan.push(name)
    },
    onEdit(data){
      const vm = this
      const x = JSON.parse(JSON.stringify(data))
      vm.dataForm = {
        assesment_id: x.assesment_id,
        id: x.assesment_id,
        nama_assesment: x.nama_assesment,
        data_assesment: x.data_assesment,
        list_objective: x.data_assesment.objective,
        list_subjective: x.data_assesment.subjective,
        list_plan: x.data_assesment.plan,
      }
    },
    async getData(){
      const vm = this
      vm.tableBusy = true
      let result = await vm.$axios.post('/assesment/list')
      // console.log('result', result)
      if (result.data.status == 200) {
        vm.items = result.data.data.map((x, idx) => {
          return {
            ...x,
            no: idx + 1,
          }
        })
      }
      vm.totalRows = parseInt(result.data.data.length)
      vm.tableBusy = false
    },
    async simpan(){
      const vm = this
      vm.tableBusy = true
      let result = await vm.$axios.post('/assesment/register', vm.dataForm)
      result
      vm.getData()
      vm.reset()
      // console.log('result', result)
      vm.tableBusy = false
    },
    async update(){
      const vm = this
      vm.tableBusy = true
      let result = await vm.$axios.post('/assesment/update', vm.dataForm)
      result
      vm.getData()
      vm.reset()
      // console.log('result', result)
      vm.tableBusy = false
    },
    reset(){
      this.dataForm = {
        nama_assesment: null,
        data_assesment: {
          objective: [],
          subjective: [],
          plan: [],
        },
        list_objective: [],
        list_subjective: [],
        list_plan: [],
      }
    },
  },
}
</script>