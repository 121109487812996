<template>
  <div>
    <serviks></serviks>
    <tabelServiks></tabelServiks>
    <detakjantung_janin></detakjantung_janin>
    <tabelDenyutJantung></tabelDenyutJantung>
    <nadi_tekanan></nadi_tekanan>
    <tabelNadi></tabelNadi>
  </div>

</template>

<script>
import serviks from './chart_serviks.vue'
import detakjantung_janin from './chart_denyut_jantung_janin.vue'
import nadi_tekanan from './chart_nadi_tekanan.vue'
import tabelServiks from './tabel_serviks.vue'
import tabelDenyutJantung from './tabel_denyut_jantung.vue'
import tabelNadi from './tabel_nadi.vue'

export default {
  name: 'patograf',
 
  components:{
    serviks,
    detakjantung_janin,
    nadi_tekanan,
    tabelServiks,
    tabelDenyutJantung,
    tabelNadi
  },
  data() {
    return {
    
    }
  }
}
</script>
<style scoped>
  td{
    border:1px solid red !important;
    padding:5px 10px !important;
  }
</style>