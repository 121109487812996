<template>
  <b-card style="margin-bottom: 15px;">
    <b-row>
      <b-col cols="12" md="12" lg="12" xl="12">
        <h5 class="custom-title-card">
          Galeri Foto
        </h5>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <div class="d-flex justify-content-end">
          <b-button 
            variant="success"
            v-c-tooltip.hover.click="'Tambah Galeri Foto'"
            v-b-modal.modal-input-galeria
          ><CIcon name="cil-plus" /></b-button>
        </div>
      </b-col>
      <b-col cols="12">
        <b-row>
          <b-col v-for="(item, key) of items" :key="key" cols="6">
            <div @click="is_data = item, $bvModal.show('modal-edit-galeria')">
              <b-img rounded :src="ip + '/' + item.judul_foto" fluid-grow  alt="Fluid image"></b-img>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <ModalRegister 
      :kunjungan="kunjungan"
      @alertFromChild="triggerAlert($event), getData()"  
    />
    <ModalEdit 
      :is_data="is_data"
      :kunjungan="kunjungan"
      @alertFromChild="triggerAlert($event), getData()"  
    />
  </b-card>
</template>
<script>
import ModalRegister from './modal_register.vue'
import ModalEdit from './modal_edit.vue'
export default {
  components: {
    ModalRegister,
    ModalEdit,
  },
  props: [
    'kunjungan',
  ],
  data() {
    return {
      is_data: {},
      items: [],
    }
  },
  computed: {
    ip(){
      return localStorage.getItem('url')
    }
  },
  watch: {
    "kunjungan.kunjungan_id"(newVal, oldVal){
      if(newVal != oldVal){
        this.getData()
      }
    }
  },
  methods: {
    async getData(){
      try {
        const vm = this
        const res = await vm.$axios.post('galeri_foto_kunjungan/list', {kunjungan_id: vm.kunjungan.kunjungan_id})
        if(res.data.status == 200){
          vm.items = res.data.data
        }
      } catch (error) {
        console.log('error', error)
      }
    },
    triggerAlert(event) {
      let vm = this;
      vm.$store.commit("set_alert", event);
    },
  },
}
</script>
<style scoped>
.custom-title-card{
  font-weight: 700;
  font-size: 16px;
  color: #D52F65;
}
</style>