<template>
  <div>
    <b-row class="m-0 p-0">
      <b-col cols="12" class="">
        <h5 class="custom-title-card"><strong>SKRINING</strong></h5>
      </b-col>
      <b-col cols="12">
        <b-table
          :items="listSkrining"
          :fields="fieldSkrining"
          responsive
          show-empty
          small
          bordered
          striped
          hover
        >
          <template #cell(no)="item">
            {{item.index+1}}
          </template>
        </b-table>
      </b-col>
      <b-col cols="12" class="">
        <h5 class="custom-title-card"><strong>PENYAKIT KHUSUS</strong></h5>
      </b-col>
      <b-col cols="12">
        <b-table
          :items="listPenyakitKhusus"
          :fields="fieldPenyakitKhusus"
          responsive
          show-empty
          small
          bordered
          striped
          hover
        >
          <template #cell(no)="item">
            {{item.index+1}}
          </template>
        </b-table>
      </b-col>
      <b-col cols="12" class="">
        <h5 class="custom-title-card"><strong>RESIKO KEHAMILAN</strong></h5>
      </b-col>
      <b-col cols="12">
        <b-table
          :items="listResikoKehamilan"
          :fields="fieldResikoKehamilan"
          responsive
          show-empty
          small
          bordered
          striped
          hover
        >
          <template #cell(no)="item">
            {{item.index+1}}
          </template>
        </b-table>
      </b-col>
      <b-col cols="12" class="">
        <h5 class="custom-title-card"><strong>PENDAFTARAN HARI INI</strong></h5>
      </b-col>
      <b-col cols="12">
        <b-table
          :items="listPendaftaranHariIni"
          :fields="fieldPendaftaranHariIni"
          responsive
          show-empty
          small
          bordered
          striped
          hover
        >
          <template #cell(no)="item">
            {{item.index+1}}
          </template>
        </b-table>
      </b-col>
    </b-row>
  </div>
</template>
<script>
export default {
  props: [
    'listSkrining',
    'listPenyakitKhusus',
    'listResikoKehamilan',
    'listPendaftaranHariIni',
  ],
  data() {
    return {
      fieldSkrining: [
        {
          key: "no",
          label: "No",
          sortDirection: "desc",
          sortable: true,
          class: "table-number text-center",
        },
        {
          key: "skrining",
          label: "Skrining",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "nama_tanggal_skrining",
          label: "Tanggal",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "keterangan",
          label: "Keterangan",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
      ],
      fieldPenyakitKhusus: [
        {
          key: "no",
          label: "No",
          sortDirection: "desc",
          sortable: true,
          class: "table-number text-center",
        },
        {
          key: "warna",
          label: "Warna",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "icdx",
          label: "ICDX",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "penyakit",
          label: "Penyakit",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
      ],
      fieldResikoKehamilan: [
        {
          key: "no",
          label: "No",
          sortDirection: "desc",
          sortable: true,
          class: "table-number text-center",
        },
        {
          key: "poin_risiko",
          label: "Skor Ibu (KSPR)",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "nama_tanggal_kspr",
          label: "Tanggal",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        // {
        //   key: "warna",
        //   label: "Warna",
        //   sortable: true,
        //   sortDirection: "desc",
        //   class: "text-left",
        // },
        // {
        //   key: "kspr",
        //   label: "Skor Ibu (KSPR)",
        //   sortable: true,
        //   sortDirection: "desc",
        //   class: "text-left",
        // },
        // {
        //   key: "status",
        //   label: "Status",
        //   sortable: true,
        //   sortDirection: "desc",
        //   class: "text-left",
        // },
      ],
      fieldPendaftaranHariIni: [
        {
          key: "no",
          label: "No",
          sortDirection: "desc",
          sortable: true,
          class: "table-number text-center",
        },
        {
          key: "nama_tanggal_daftar",
          label: "Tanggal",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "nama_poli",
          label: "Poli / Ruangan",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
      ],
    }
  },
}
</script>