<template>
  <b-card>
    <b-row>
      <b-col cols="12">
        <b-row class="mx-0">
          <b-col cols="6">
            <label for="ms_gudang_id">Gudang <span class="text-danger">*</span></label>
            <Multiselect
              id="ms_gudang_id"
              v-model="$v.dataForm.ms_gudang_id.$model"
              :options="listGudang"
              :multiple="false"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              label="nama_gudang"
              track-by="ms_gudang_id"
              placeholder="-- Pilih Gudang --"
              size="sm"
              @select="getBarang(), dataFormSub.ms_barang_id= null, dataFormSub.harga_satuan_alkes = null"
              @remove="getBarang(), dataFormSub.ms_barang_id= null, dataFormSub.harga_satuan_alkes = null"
            ></Multiselect>
          </b-col>
          <b-col cols="6">
            <label for="tanggal_alkes">Tanggal <span class="text-danger">*</span></label>
            <date-picker
              style="width: 100%"
              id="tanggal_alkes"
              format="DD-MM-YYYY"
              v-model="$v.dataForm.tanggal_alkes.$model"
            ></date-picker>
          </b-col>
          <b-col cols="6">
            <label for="ms_dokter_id">Dokter <span class="text-danger">*</span></label>
            <Multiselect
              id="ms_dokter_id"
              v-model="$v.dataForm.ms_dokter_id.$model"
              :options="listDokter"
              :multiple="false"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              label="nama_tenaga_medis"
              track-by="tenaga_medis_id"
              placeholder="-- Pilih Dokter --"
              size="sm"
            ></Multiselect>
          </b-col>
          <b-col cols="6">
            <label for="ms_perawat_id">Perawat <span class="text-danger">*</span></label>
            <Multiselect
              id="ms_perawat_id"
              v-model="$v.dataForm.ms_perawat_id.$model"
              :options="listPerawat"
              :multiple="false"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              label="nama_tenaga_medis"
              track-by="tenaga_medis_id"
              placeholder="-- Pilih Perawat --"
              size="sm"
            ></Multiselect>
          </b-col>
          <b-col cols="12">
            <div class="d-flex justify-content-end">
              <b-button 
                v-if="dataForm.id"
                :disabled="busy"
                @click="update()"
                variant="warning"
              >Update</b-button>
              <b-button
                v-else
                :disabled="busy || kunjunganSelesai"
                @click="simpan()"
                variant="primary"
              >Simpan</b-button>
            </div>
          </b-col>
        </b-row>
      </b-col>

      <b-col v-if="dataForm.id" cols="12"><hr></b-col>
      <b-col v-if="dataForm.id" cols="12">
        <h5 class="custom-title-card"><strong>ALAT KESEHATAN</strong></h5>
      </b-col>
      <b-col v-if="dataForm.id && !kunjunganSelesai" cols="12">
        <b-row class="m-0 mb-3">
          <b-col cols="6">
            <label for="ms_barang_id">Barang <span class="text-danger">*</span></label>
            <Multiselect
              id="ms_barang_id"
              v-model="$v.dataFormSub.ms_barang_id.$model"
              :options="listBarang"
              :multiple="false"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              label="nama"
              track-by="ms_barang_id"
              placeholder="-- Pilih Barang --"
              size="sm"
              @select="dataFormSub.harga_satuan_alkes = dataFormSub.ms_barang_id.harga_jual"
              @remove="dataFormSub.harga_satuan_alkes = null"
            ></Multiselect>
          </b-col>
          <b-col cols="6">
            <label for="qty_alkes">Jumlah <span class="text-danger">*</span></label>
            <b-form-input
              id="qty_alkes"
              type="number"
              v-model="dataFormSub.qty_alkes"
            ></b-form-input>
          </b-col>
          <b-col cols="6">
            <label for="harga_satuan_alkes">Harga Satuan <span class="text-danger">*</span></label>
            <b-form-input
              id="harga_satuan_alkes"
              type="number"
              v-model="dataFormSub.harga_satuan_alkes"
            ></b-form-input>
          </b-col>
          <b-col cols="6">
            <label for="total_harga_item_alkes">Total Harga <span class="text-danger">*</span></label>
            <b-form-input
              id="total_harga_item_alkes"
              type="number"
              v-model="dataFormSub.total_harga_item_alkes"
              disabled
            ></b-form-input>
          </b-col>
          <b-col cols="12">
            <label for="keterangan">Keterangan <span class="text-danger">*</span></label>
            <b-form-textarea
              id="keterangan"
              v-model="dataFormSub.keterangan"
            ></b-form-textarea>
          </b-col>
          <b-col cols="12">
            <div class="mt-2 d-flex justify-content-end">
              <!-- <b-button
                :disabled="busy"
                v-c-tooltip.hover.click="'List Batch'"
                v-b-modal.modal-batch-sub-obat
                @click="clickSelectorAll('#detail-obat')"
                variant="success"
              >List Batch</b-button> -->
              <b-button
                :disabled="busy"
                @click="simpanSub()"
                variant="primary"
              >Tambah</b-button>
            </div>
          </b-col>
        </b-row>
      </b-col>

      <b-col v-if="dataForm.id" cols="12" md="12" lg="12">
        <b-table
          :items="items"
          :fields="fields"
          responsive
          show-empty
          small
          bordered
          striped
          hover
          :busy="busy"
        >
          <template #cell(actions)="item">
            <div class="d-flex nowrap">
              <!-- <b-button
                variant="primary"
                size="sm"
                class="ml-1"
                v-c-tooltip.hover.click="'Detail Data'"
                v-b-modal.modal-detail-alkes
                @click="is_data = item.item"
                ><CIcon name="cil-info" /> {{ item.actions }}</b-button
              > -->
              <b-button
                id="detail-obat"
                variant="info"
                size="sm"
                class="ml-1"
                @click="item.toggleDetails"
                v-c-tooltip.hover.click="'Detail Alkes'"
              >
                <CIcon name="cil-info" />
              </b-button>
              <b-button
                variant="danger"
                size="sm"
                class="ml-1"
                v-c-tooltip.hover.click="'Hapus Data'"
                v-b-modal.modal-delete-alkes
                :disabled="kunjunganSelesai"
                @click="is_data = item.item"
                ><CIcon name="cil-trash" /> {{ item.actions }}</b-button
              >
            </div>
          </template>
          <template #row-details="row">
            <b-card class="m-0">
              <b-row class="m-0 p-0" style="row-gap: 1rem;">
                <b-col cols="6">
                  <div>
                    <h6><strong>Keterangan</strong></h6>
                    <p>{{row.item.keterangan || '-'}}</p>
                  </div>
                </b-col>
                <b-col cols="6">
                  <div cols="12" class="" v-for="(value, key) in row.item.data_item_alkes" :key="key">
                    <b-card class="m-0">
                      <b-row class="m-0 p-0">
                        <b-col cols="4"><h6 class="m-0 p-0">Kode Batch</h6></b-col>
                        <b-col cols="8"><h6 class="m-0 p-0">: {{value.kode_batch}}</h6></b-col>
                        <b-col cols="4"><h6 class="m-0 p-0">Jumlah</h6></b-col>
                        <b-col cols="8"><h6 class="m-0 p-0">: {{value.qty_item_alkes}}</h6></b-col>
                        <b-col cols="4"><h6 class="m-0 p-0">Kadaluarsa</h6></b-col>
                        <b-col cols="8"><h6 class="m-0 p-0">: {{$moment(value.tanggal_kadaluarsa).format("LL")}}</h6></b-col>
                      </b-row>
                    </b-card>
                  </div>
                </b-col>
              </b-row>
            </b-card>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <modal-delete
      :data_delete="is_data"
      @alertFromChild="triggerAlert($event), getDatas()"
    />
    <modal-detail
      :data_detail="is_data"
      @alertFromChild="triggerAlert($event), getDatas()"
    />
  </b-card>
</template>
<script>
import moment from 'moment';
moment.locale('id');
import ModalDelete from "./modalDelete.vue";
import ModalDetail from "./modalDetail.vue";
import { validationMixin } from "vuelidate";
import { required, numeric, minValue } from "vuelidate/lib/validators";
export default {
  components:{
    ModalDelete,
    ModalDetail,
  },
  props: [
    'kunjungan',
    'dataAnamnesa',
    'listDokter',
    'listPerawat',
    'kunjunganSelesai',
  ],
  data() {
    return {
      listGudang: [],
      listBarang: [],
      dataForm: {
        ms_gudang_id: null,
        tanggal_alkes: null,
        ms_dokter_id: null,
        ms_perawat_id: null,
      },
      dataFormSub: {
        qty_alkes: null,
        keterangan: null,
        harga_satuan_alkes: null,
        total_harga_item_alkes: null,
        ms_barang_id: null,
      },
      is_data: {},
      items: [],
      fields: [
        {
          key: "no",
          label: "No",
          sortDirection: "desc",
          sortable: true,
          class: "table-number text-center",
        },
        {
          key: "nama_barang",
          label: "Barang",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "qty_alkes",
          label: "Jumlah",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "nama_harga_satuan_alkes",
          label: "Harga Satuan",
          sortable: true,
          sortDirection: "desc",
          class: "text-right",
        },
        {
          key: "nama_total_harga_item_alkes",
          label: "Total Harga",
          sortable: true,
          sortDirection: "desc",
          class: "text-right",
        },
        {
          key: "actions",
          label: "Actions",
          class: "table-option-2 text-center",
        },
      ],
      busy: false,
    }
  },
  computed: {
    tanggal_sesi(){
      return this.$store.state.tanggal_sesi
    },
    isValid() {
      return !this.$v.dataForm.$invalid;
    },
    isDirty() {
      return this.$v.dataForm.$anyDirty;
    },
    isValidSub() {
      return !this.$v.dataFormSub.$invalid;
    },
    isDirtySub() {
      return this.$v.dataFormSub.$anyDirty;
    },
  },
  mixins: [validationMixin],
  validations: {
    dataForm: {
      ms_gudang_id: { required },
      tanggal_alkes: { required },
      ms_dokter_id: { required },
      ms_perawat_id: { required },
    },
    dataFormSub: {
      keterangan: { required },
      qty_alkes: { required, numeric, minValue: minValue(0) },
      harga_satuan_alkes: { required, numeric, minValue: minValue(0) },
      total_harga_item_alkes: { required, numeric, minValue: minValue(0) },
      ms_barang_id: { required },
    },
  },
  watch: {
    tanggal_sesi(newVal, oldVal){
      if(newVal != oldVal){
        if(!this.dataForm.id){
          this.dataForm.tanggal_alkes = new Date(this.tanggal_sesi)
        }
      }
    },
    "dataFormSub.qty_alkes"(newVal, oldVal){
      if(newVal != oldVal){
        this.dataFormSub.total_harga_item_alkes = Number.parseInt(newVal) * Number.parseInt(this.dataFormSub.harga_satuan_alkes)
      }
    },
    "dataFormSub.harga_satuan_alkes"(newVal, oldVal){
      if(newVal != oldVal){
        this.dataFormSub.total_harga_item_alkes = Number.parseInt(newVal) * Number.parseInt(this.dataFormSub.qty_alkes)
      }
    },
    'kunjungan': {
      handler() {
        this.onRefresh()
      },
      deep: true
    },
    'dataAnamnesa': {
      handler() {
        this.onRefresh()
      },
      deep: true
    },
  },
  async mounted() {
    const vm = this
    // vm.getList()
    await vm.reset()
  },
  methods: {
    async onRefresh(){
      const vm = this
      await vm.reset()
      await vm.getList()
      await vm.getDatas()
    },
    async clickSelectorAll(selector){
      let domAll = document.querySelectorAll(selector)
      // console.log('domAll', domAll)
      for (let i = 0; i < domAll.length; i++) {
        const x = domAll[i];
        x.click()
      }
    },
    async getDatas(){
      const vm = this
      vm.busy = true
      try {
        if(vm.kunjungan && vm.kunjungan.kunjungan_id){
          const alkes = await vm.$axios.post('/alkes_pasien/list', {kunjungan_id: vm.kunjungan.kunjungan_id})
          // console.log('alkes pasien', alkes)
          // console.log('vm.listDokter', vm.listDokter)
          if(alkes.data.status == 200 && alkes.data.data.length > 0){
            const x = alkes.data.data[0]
            vm.dataForm = {
              tanggal_alkes: new Date(x.tanggal_alkes),
              ms_gudang_id: vm.$findKey(vm.listGudang, x.ms_gudang_id, true, 'ms_gudang_id'),
              ms_dokter_id: vm.$findKey(vm.listDokter, x.ms_dokter_id, true, 'tenaga_medis_id'),
              ms_perawat_id: vm.$findKey(vm.listPerawat, x.ms_perawat_id, true, 'tenaga_medis_id'),
              id: x.alkes_pasien_id,
              alkes_pasien_id: x.alkes_pasien_id,
              kunjungan_id: x.kunjungan_id,
            }
            vm.getBarang()
            //list sib alkes pasien
            const items = await vm.$axios.post('/sub_alkes_pasien/list', {kunjungan_id: vm.kunjungan.kunjungan_id, alkes_pasien_id: x.alkes_pasien_id})
            // console.log('sub alkes pasien', items)
            vm.items = items.data.status == 200 ? items.data.data.map((x, i) => { return {
              ...x,
              no: i + 1,
              nama_harga_satuan_alkes: vm.$numberFormat(x.harga_satuan_alkes, 'idr'),
              nama_total_harga_item_alkes: vm.$numberFormat(x.total_harga_item_alkes, 'idr'),
            }}) : []
            vm.totalRows = vm.items.length
          }
        }
      } catch (err) {
        console.log(err)
        if (err.message) {
          vm.triggerAlert({ variant: "danger", msg:err.message.toUpperCase(), showing: true });
        } else {
          vm.triggerAlert({ variant: "danger", msg:err.toUpperCase(), showing: true });
        }
      } finally {
        vm.busy = false;
      }
    },
    async getList(){
      const vm = this
      vm.busy = true
      try {
        // console.log('vm.kunjungan', vm.kunjungan)
        if (vm.kunjungan.ms_poli_id) {
          //gudang
          let listGudang = await vm.$axios.post('/ms_gudang/list')
          vm.listGudang = []
          for (let i = 0; i < listGudang.data.data.length; i++) {
            let x = listGudang.data.data[i];
            if(x.ms_poli_id == vm.kunjungan.ms_poli_id.ms_poli_id){
              vm.listGudang.push(x)
            }else if(!x.ms_gudang_utama_id){
              vm.listGudang.push(x)
            }
          }
          
          // vm.listGudang.map(x => { return {
          //   ...x,
          //   nama: x.nama_gudang + ' - ' + x.tipe_gudang,
          // }})
          // console.log(vm.listGudang, 'ini list gudang');
          // if(!vm.dataForm.ms_gudang_id && vm.listGudang.length > 0) vm.dataForm.ms_gudang_id = vm.listGudang[0]
        }
        // this.getDatas()
      } catch (err) {
        if (err.message) {
          vm.triggerAlert({ variant: "danger", msg:err.message.toUpperCase(), showing: true });
        } else {
          vm.triggerAlert({ variant: "danger", msg:err.toUpperCase(), showing: true });
        }
      } finally {
        vm.busy = false;
      }
    },
    async getBarang(){
      const vm = this
      vm.busy = true
      try {
        if(vm.dataForm.ms_gudang_id){
          //barang
          let listBarang = await vm.$axios.post('/stock/list_stock_by_gudang', {
            ms_gudang_id: vm.dataForm.ms_gudang_id.ms_gudang_id,
            nama_jenis_barang: 'ALAT KESEHATAN',
          })
          vm.listBarang = listBarang.data.status == 200 ? listBarang.data.data.map(x => { return {
            ...x,
            nama: x.kode_produk + ' - ' + x.nama_barang + ' - ' + vm.$numberFormat(x.qty_stock)
          }}) : []
          // console.log('list_stock_by_gudang', listBarang)
        }else{
          vm.listBarang = []
        }
      } catch (err) {
        if (err.message) {
          vm.triggerAlert({ variant: "danger", msg:err.message.toUpperCase(), showing: true });
        } else {
          vm.triggerAlert({ variant: "danger", msg:err.toUpperCase(), showing: true });
        }
      } finally {
        vm.busy = false;
      }
    },
    async simpan(){
      const vm = this
      vm.busy = true
      try {
        await this.$v.dataForm.$touch();
        if (vm.isValid && vm.isDirty) {
          let res = await vm.$axios.post('/alkes_pasien/register', {
            ...vm.dataForm,
            ms_gudang_id: vm.dataForm.ms_gudang_id.ms_gudang_id,
            ms_dokter_id: vm.dataForm.ms_dokter_id.tenaga_medis_id,
            ms_perawat_id: vm.dataForm.ms_perawat_id.tenaga_medis_id,
            kunjungan_id: vm.kunjungan.kunjungan_id,
          })
          // console.log('register diagnosa', res)
          if(res.data.status == 200){
            vm.triggerAlert({ variant: "success", msg: 'Berhasil Membuat Alkes', showing: true });
            vm.reset()
          }else{
            vm.triggerAlert({ variant: "danger", msg: res.data.message, showing: true });
          }
        }else{
          vm.triggerAlert({ variant: "warning", msg: 'Data Tidak Valid', showing: true });
        }
      } catch (error) {
        console.log(error)
        if (error.message) {
          vm.triggerAlert({ variant: "danger", msg: error.message.toUpperCase(), showing: true });
        } else {
          vm.triggerAlert({ variant: "danger", msg: error.toUpperCase(), showing: true });
        }
      } finally {
        vm.busy = false
      }
    },
    async update(){
      const vm = this
      vm.busy = true
      try {
        await this.$v.dataForm.$touch();
        if (vm.isValid && vm.isDirty) {
          let res = await vm.$axios.post('/alkes_pasien/update', {
            ...vm.dataForm,
            ms_gudang_id: vm.dataForm.ms_gudang_id.ms_gudang_id,
            ms_dokter_id: vm.dataForm.ms_dokter_id.tenaga_medis_id,
            ms_perawat_id: vm.dataForm.ms_perawat_id.tenaga_medis_id,
            kunjungan_id: vm.kunjungan.kunjungan_id,
          })
          // console.log('update diagnosa', res)
          if(res.data.status == 200){
            vm.triggerAlert({ variant: "success", msg: 'Berhasil Mengubah Alkes', showing: true });
            vm.reset()
          }else{
            vm.triggerAlert({ variant: "danger", msg: res.data.message, showing: true });
          }
        }else{
          vm.triggerAlert({ variant: "warning", msg: 'Data Tidak Valid', showing: true });
        }
      } catch (error) {
        console.log(error)
        if (error.message) {
          vm.triggerAlert({ variant: "danger", msg: error.message.toUpperCase(), showing: true });
        } else {
          vm.triggerAlert({ variant: "danger", msg: error.toUpperCase(), showing: true });
        }
      } finally {
        vm.busy = false
      }
    },
    async simpanSub(){
      const vm = this
      vm.busy = true
      try {
        await this.$v.dataFormSub.$touch();
        // console.log('dataFormSub', vm.dataFormSub)
        if (vm.isValidSub && vm.isDirtySub) {
          let res = await vm.$axios.post('/sub_alkes_pasien/register', {
            ...vm.dataFormSub,
            alkes_pasien_id: vm.dataForm.alkes_pasien_id,
            ms_barang_id: vm.dataFormSub.ms_barang_id.ms_barang_id,
            kunjungan_id: vm.kunjungan.kunjungan_id,
          })
          // console.log('register diagnosa', res)
          if(res.data.status == 200){
            vm.triggerAlert({ variant: "success", msg: 'Berhasil Membuat Sub Alkes', showing: true });
            vm.reset()
          }else{
            vm.triggerAlert({ variant: "danger", msg: res.data.message, showing: true });
          }
        }else{
          vm.triggerAlert({ variant: "warning", msg: 'Data Tidak Valid', showing: true });
        }
      } catch (error) {
        console.log(error)
        if (error.message) {
          vm.triggerAlert({ variant: "danger", msg: error.message.toUpperCase(), showing: true });
        } else {
          vm.triggerAlert({ variant: "danger", msg: error.toUpperCase(), showing: true });
        }
      } finally {
        vm.busy = false
      }
    },
    reset(){
      const vm = this
      vm.dataForm = {
        ms_gudang_id: null,
        tanggal_alkes: new Date(vm.tanggal_sesi),
        ms_dokter_id: vm.$findKey(vm.listDokter, vm.dataAnamnesa.ms_dokter_id, true, 'tenaga_medis_id'),
        ms_perawat_id: vm.$findKey(vm.listPerawat, vm.dataAnamnesa.ms_perawat_id, true, 'tenaga_medis_id'),
      }
      vm.dataFormSub = {
        qty_alkes: null,
        keterangan: null,
        harga_satuan_alkes: null,
        total_harga_item_alkes: null,
        ms_barang_id: null,
      }
      vm.$v.$reset()
      vm.getDatas()
    },
    triggerAlert(event) {
      let vm = this;
      vm.$store.commit("set_alert", event);
    },
  },
}
</script>