<template>
  <div>
    <b-row class="mt-3">
      <b-col cols="12" sm="12" md="12" lg="6" xl="6" >
        <b-form-group label-cols-md="4">
          <template v-slot:label>
            Berat Badan
          </template>
          <b-form-input
            type="text"
            v-model="bayi_baru_lahir.berat_badan"
          ></b-form-input>
        </b-form-group>

        <b-form-group label-cols-md="4">
          <template v-slot:label>
            Panjang Badan
          </template>
          <b-form-input
            type="text"
            v-model="bayi_baru_lahir.panjang_badan"
          ></b-form-input>
        </b-form-group>

        <b-form-group label-cols-md="4">
          <template v-slot:label>
            Jenis Kelamin
          </template>
          <b-form-radio-group
            id=""
            v-model="bayi_baru_lahir.jenis_kelamin"
          >
            <b-form-radio value="Laki-laki" class="mt-1">
              <span >Laki-laki</span>
            </b-form-radio>
            <b-form-radio value="Perempuan">
              <span>Perempuan</span>
            </b-form-radio>
          </b-form-radio-group>
        </b-form-group>
        
        <b-form-group label-cols-md="4">
          <template v-slot:label>
            Penilaian Bayi Baru Lahir
          </template>
          <b-form-radio-group
            id=""
            v-model="bayi_baru_lahir.penilaian_bayi"
          >
            <b-form-radio value="Ya" class="mt-1">
              <span >Baik</span>
            </b-form-radio>
            <b-form-radio value="Tidak">
              <span>Ada Penyulit</span>
            </b-form-radio>
          </b-form-radio-group>
        </b-form-group>

        <b-form-group label-cols-md="4">
          <template v-slot:label>
            Bayi Lahir
          </template>
          <b-form-select v-model="bayi_baru_lahir.bayi_lahir" :options="bayi_lahir"></b-form-select>
        </b-form-group>

        <b-form-group label-cols-md="4">
          <template v-slot:label>
            Tindakan
          </template>
          <b-form-checkbox-group 
            id=""
            v-model="bayi_baru_lahir.tindakan"
            class="mt-1"
          >
            <b-form-checkbox value="Mengeringkan">Mengeringkan</b-form-checkbox>
            <b-form-checkbox value="Menghangatkan">Menghangatkan</b-form-checkbox>
            <b-form-checkbox value="Rangsang Taktil">Rangsang Taktil</b-form-checkbox>
            <b-form-checkbox value="Pakaian/Selimut Bayi">Pakaian/Selimut Bayi</b-form-checkbox>
            <b-form-checkbox value="Tindakan Pencegahan Infeksi Mata">Tindakan Pencegahan Infeksi Mata</b-form-checkbox>
          </b-form-checkbox-group>
          <!-- <b-form-radio-group
            id=""
            v-model="bayi_baru_lahir.tindakan"
          >
            <b-form-radio value="Mengeringkan" class="mt-1">
              <span >Mengeringkan</span>
            </b-form-radio>
            <b-form-radio value="Menghangatkan">
              <span>Menghangatkan</span>
            </b-form-radio>
            <b-form-radio value="Rangsang_Taktil">
              <span>Rangsang Taktil</span>
            </b-form-radio>
            <b-form-radio value="Pakaian">
              <span>Pakaian/Selimut Bayi</span>
            </b-form-radio>
            <b-form-radio value="tindakan_pencegahan">
              <span>Tindakan Pencegahan Infeksi Mata</span>
            </b-form-radio>
          </b-form-radio-group> -->
        </b-form-group>
      </b-col>

      <b-col cols="12" sm="12" md="12" lg="6" xl="6" >
        <b-form-group label-cols-md="4">
          <template v-slot:label>
            Pemberian ASI
          </template>
          <b-form-radio-group
            id=""
            v-model="bayi_baru_lahir.pemberian_asi"
          >
            <b-form-radio value="Baik" class="mt-1">
              <span >Baik</span>
            </b-form-radio>
            <b-form-radio value="Ada Penyulit">
              <span>Ada Penyulit</span>
            </b-form-radio>
          </b-form-radio-group>
        </b-form-group>

        <b-form-group label-cols-md="4">
          <template v-slot:label>
           Alasan
          </template>
          <b-form-input
            placeholder="Alasan Tidak Memberi ASI"
            v-model="bayi_baru_lahir.alasan"
            type="text"
          ></b-form-input>
        </b-form-group>

        <b-form-group label-cols-md="4">
          <template v-slot:label>
           Masalah Lain
          </template>
          <b-form-input
            v-model="bayi_baru_lahir.masalah_lain"
            type="text"
          ></b-form-input>
        </b-form-group>

        <b-form-group label-cols-md="4">
          <template v-slot:label>
           Hasil
          </template>
          <b-form-input
            placeholder="Hasil"
            v-model="bayi_baru_lahir.hasilnya"
            type="text"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" sm="12" md="12" lg="12" xl="12">
        <hr>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12" sm="12" md="12" lg="12" xl="12">
        <b-button @click="simpan()" variant="primary">Simpan</b-button>
      </b-col>
    </b-row>
  </div>  
</template>

<script>


export default {
  name: 'bayi_lahir',
  components: {
  },
  data() {
    return {
      bayi_lahir: [
        {value: "Normal", text: "Normal"},
        {value: "Asfiksia", text: "Asfiksia"},
        {value: "Hipotermia", text: "Hipotermia"},
        {value: "Cacat Bawaan", text: "Cacat Bawaan"},
      ],
      bayi_baru_lahir: {
        berat_badan: null,
        panjang_badan: null,
        jenis_kelamin: null,
        penilaian_bayi: null,
        bayi_lahir: null,
        tindakan: [],
        pemberian_asi: null,
        alasan: null,
        masalah_lain: null,
        hasilnya: null,
      }
    }
  },
  computed: {
    partograf_id(){
      return this.$store.state.partograf_id
    },
  },
  mounted(){
    this.getData()
  },
  methods: {
    getData(){
      let vm = this
      vm.$axios.post("/catatan_persalinan/list", {partograf_id:vm.partograf_id})
      .then((res) => {
        if(res.data.data.length){
          // console.log(res, 'ini catatan');
          // vm.catatan = res.data.data[0].catatan
          if(res.data.data[0].bayi_baru_lahir){
            vm.bayi_baru_lahir = res.data.data[0].bayi_baru_lahir
          }
        }
      })
      .catch((err) => {
        vm.$store.commit('set_alert', {variant: 'danger', msg: err.message, showing: true})
      })
    },
    simpan(){
      let vm = this
      vm.$axios.post("/catatan_persalinan/register", {
        partograf_id: vm.partograf_id,
        bayi_baru_lahir: vm.bayi_baru_lahir,
      })
      .then((res) => {
        // console.log(res, 'ini responya ui');
        if(res.data.status == 200 && res.data.message == "sukses"){
          vm.$store.commit('set_alert', {variant: 'success', msg: "BERHASIL MENYIMPAN DATA", showing: true})
        }
        else{
          vm.$store.commit('set_alert', {variant: 'danger', msg: res.data.message, showing: true})
        }
      })
      .catch((err) => {
        vm.$store.commit('set_alert', {variant: 'danger', msg: err.message, showing: true})
      })
    },
  }
}
</script>

