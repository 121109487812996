<template>
  <section class="content-print A4">
    <div class="sheet" v-if="dataPrint">
      <div class="w-100">
        <table border="0" class="custom-table">
          <tbody>
            <tr>
              <td
                style="
                  width: 15%;
                  vertical-align: middle;
                  padding-left: 1.5mm;
                  padding-right: 1.5mm;
                "
              >
                <img src="../../../cetak/puskesmas-logo.png" alt="" style="width: 100%" />
              </td>
              <td style="width: 70%; vertical-align: middle">
                <h6
                  style="
                    text-align: center;
                    font-weight: bold;
                    margin-bottom: 0;
                    font-size: 16pt;
                  "
                >
                  <!-- PEMERINTAH KABUPATEN/KOTA <span>NAMA KABKOT</span> -->
                  PEMERINTAH {{ $store.state.puskesmas.nama_kota }}
                </h6>
                <h6
                  style="
                    text-align: center;
                    font-weight: bold;
                    margin-bottom: 0;
                    font-size: 16pt;
                  "
                >
                  <span>DINAS KESEHATAN</span>
                </h6>
                <h6
                  style="
                    text-align: center;
                    font-weight: bold;
                    margin-bottom: 0;
                    font-size: 16pt;
                  "
                >
                  <!-- PUSKESMAS <span>NAMA PUSKESMAS</span> -->
                  {{ $store.state.puskesmas.nama_puskesmas }}
                </h6>
                <h6 style="text-align: center; font-weight: normal; font-size: 10pt">
                  <!-- <span>
                    Jl. Lorem ipsum dolor sit, amet consectetur adipisicing elit. Soluta,
                    nulla
                  </span> -->
                  {{ $store.state.puskesmas.alamat_puskesmas }}
                </h6>
                <h6 style="text-align: center; font-weight: normal; font-size: 10pt">
                  <span>Email : {{ $store.state.puskesmas.email_pengaduan }}</span>
                  <span>Telp. : {{ $store.state.puskesmas.no_telp_pengaduan }}</span>
                </h6>
              </td>
              <td
                style="
                  width: 15%;
                  vertical-align: middle;
                  padding-left: 1.5mm;
                  padding-right: 1.5mm;
                "
              >
                <img src="../../../cetak/puskesmas-logo.png" alt="" style="width: 100%" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="w-100" style="margin-top: 2.5mm">
        <div class="line"></div>
      </div>

      <div class="w-100" style="margin-top: 5mm">
        <h6 style="font-size: 16pt; font-weight: bold; text-align: center">
          <u>FORM PENGANTAR RUJUKAN INTERNAL</u>
        </h6>
      </div>

      <div class="w-100-flex" style="margin-top: 5mm" v-if="dataPrint">
        <div style="width: 50%">
          <table class="custom-table">
            <tbody>
              <tr>
                <td style="width: 30mm">NIK</td>
                <td style="width: 5mm">:</td>
                <td>{{ dataPrint.nik || "-" }}</td>
              </tr>

              <tr>
                <td>Nama Pasien</td>
                <td>:</td>
                <td>{{ dataPrint.nama_lengkap || "-" }}</td>
              </tr>

              <tr>
                <td>No. eRM</td>
                <td>:</td>
                <td>{{ dataPrint.no_rm || "-" }}</td>
              </tr>

              <tr>
                <td>No. eRM Lama</td>
                <td>:</td>
                <td>{{ dataPrint.no_rm_lama || "-" }}</td>
              </tr>

              <tr>
                <td>No. Dokumen RM</td>
                <td>:</td>
                <td>{{ dataPrint.no_dokumen_rm || "-" }}</td>
              </tr>

              <tr>
                <td>Jenis Kelamin</td>
                <td>:</td>
                <td>{{ (dataPrint.jenis_kelamin || '').toLowerCase() == "p" ? "Perempuan" : "Laki-laki" }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div style="width: 50%">
          <table class="custom-table">
            <tbody>
              <tr>
                <td style="width: 30mm">Tanggal Lahir</td>
                <td style="width: 5mm">:</td>
                <td>{{ dataPrint.tanggal_lahir ? $moment(dataPrint.tanggal_lahir).format("LL") : "-" }}</td>
              </tr>

              <tr>
                <td>Umur</td>
                <td>:</td>
                <td>
                  <span v-if="dataPrint.usia_tahun">{{ dataPrint.usia_tahun || "-" }} Tahun</span>
                  <span v-if="dataPrint.usia_bulan">{{ dataPrint.usia_bulan || "-" }} Bulan</span>
                  <span v-if="dataPrint.usia_hari">{{ dataPrint.usia_hari || "-" }} Hari</span>
                </td>
              </tr>

              <tr>
                <td>Kamar/Bed</td>
                <td>:</td>
                <td>{{ dataPrint.nama_kamar || "-" }} / {{ dataPrint.nama_bed || "-" }}</td>
                <!-- <td>&nbsp;/&nbsp;</td> -->
              </tr>

              <tr>
                <td>Tanggal Pendaftaran</td>
                <td>:</td>
                <td>{{ dataPrint.tanggal_daftar ? $moment(dataPrint.tanggal_daftar).format("LLL") : "-" }}</td>
              </tr>

              <tr>
                <td>Ruangan Tujuan</td>
                <td>:</td>
                <td>{{ dataPrint.ruangan_tujuan || "-" }}</td>
              </tr>

              <tr>
                <td>Ruangan Asal</td>
                <td>:</td>
                <td>{{ dataPrint.ruangan_asal || "-" }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="w-100" style="margin-top: 10mm">
        <table class="custom-table" border="1">
          <thead>
            <tr>
              <th colspan="16">Data Anamnesa</th>
            </tr>
            <tr>
              <th style="font-size: 8pt; padding: 0.5mm">No.</th>
              <th style="font-size: 8pt; padding: 0.5mm">Tanggal</th>
              <th style="font-size: 8pt; padding: 0.5mm">Dokter/<br />Tenaga Medis</th>
              <th style="font-size: 8pt; padding: 0.5mm">
                Perawat/Bidan/<br />Nutrisionist/Sanitarian
              </th>
              <th style="font-size: 8pt; padding: 0.5mm">Keluhan Utama</th>
              <th style="font-size: 8pt; padding: 0.5mm">Keluhan Tambahan</th>
              <th style="font-size: 8pt; padding: 0.5mm">Lama sakit</th>
              <th style="font-size: 8pt; padding: 0.5mm">Merokok</th>
              <th style="font-size: 8pt; padding: 0.5mm">Konsumsi Alkohol</th>
              <th style="font-size: 8pt; padding: 0.5mm">
                Kurang <br />
                Sayur/Buah
              </th>
              <th style="font-size: 8pt; padding: 0.5mm">Edukasi</th>
              <th style="font-size: 8pt; padding: 0.5mm">Terapi Sebelumnya</th>
              <th style="font-size: 8pt; padding: 0.5mm">Rencana Tindakan</th>
              <th style="font-size: 8pt; padding: 0.5mm">Observasi</th>
              <th style="font-size: 8pt; padding: 0.5mm">Biopsikososial</th>
              <th style="font-size: 8pt; padding: 0.5mm">Keterangan</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(objek, nomor) in dataPrint.anamnesa" :key="nomor">
              <td style="font-size: 8pt; padding: 0.5mm">{{ nomor + 1 }}.</td>
              <td style="font-size: 8pt; padding: 0.5mm">{{ $moment(objek.tanggal).format("LLL") }}</td>
              <td style="font-size: 8pt; padding: 0.5mm">{{ objek.nama_dokter || "-" }}</td>
              <td style="font-size: 8pt; padding: 0.5mm">{{ objek.nama_perawat || "-" }}</td>
              <td style="font-size: 8pt; padding: 0.5mm">{{ objek.keluhan_utama || "-" }}</td>
              <td style="font-size: 8pt; padding: 0.5mm">{{ objek.keluhan_tambahan || "-" }}</td>
              <td style="font-size: 8pt; padding: 0.5mm">{{ objek.lama_sakit_tahun }} Tahun</td>
              <td style="font-size: 8pt; padding: 0.5mm">{{ objek.merokok ? "Ya" : "Tidak" }}</td>
              <td style="font-size: 8pt; padding: 0.5mm">{{ objek.konsumsi_alkohol ? "Ya" : "Tidak" }}</td>
              <td style="font-size: 8pt; padding: 0.5mm">{{ objek.kurang_sayur_buah ? "Ya" : "Tidak" }}</td>
              <td style="font-size: 8pt; padding: 0.5mm">{{ objek.edukasi || "-" }}</td>
              <td style="font-size: 8pt; padding: 0.5mm">{{ objek.terapi || "-" }}</td>
              <td style="font-size: 8pt; padding: 0.5mm">{{ objek.rencana_tindakan || "-" }}</td>
              <td style="font-size: 8pt; padding: 0.5mm">{{ objek.observasi || "-" }}</td>
              <td style="font-size: 8pt; padding: 0.5mm">{{ objek.biopsikososial || "-" }}</td>
              <td style="font-size: 8pt; padding: 0.5mm">{{ objek.keterangan_pool_anamnesa || "-" }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="w-100-flex" style="margin-top: 10mm; justify-content: space-between">
        <div style="width: 45%">
          <table class="custom-table" border="1">
            <thead>
              <tr>
                <th colspan="2">Riwayat Pasien</th>
              </tr>

              <tr>
                <th style="font-size: 8pt; padding: 0.5mm">Jenis Riwayat</th>
                <th style="font-size: 8pt; padding: 0.5mm">Riwayat Pasien</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(riwayat, index) in dataPrint.riwayat_pasien" :key="index+'riwayat_pasien1'">
                <td style="font-size: 8pt; padding: 0.5mm">{{ (riwayat.jenis_riwayat || '').toUpperCase() }}</td>
                <td style="font-size: 8pt; padding: 0.5mm">{{ (riwayat.riwayat_pasien || '').toUpperCase() }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div style="width: 45%">
          <table class="custom-table" border="1">
            <thead>
              <tr>
                <th colspan="2">Alergi Pasien</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(a, b) in dataPrint.alergi_pasien" :key="b+'alergi_pasien1'">
                <td style="font-size: 8pt; padding: 0.5mm; width: 30mm">{{ a.nama_jenis_alergi }}</td>
                <td style="font-size: 8pt; padding: 0.5mm" v-if="a.data_alergi">
                  <span v-for="(c, d) in a.data_alergi" :key="d+'data_alergi1'">{{ c.nama_alergi }}</span>
                </td>
                <td style="font-size: 8pt; padding: 0.5mm" v-else>
                  -
                </td>
              </tr>

              <!-- <tr>
                <td style="font-size: 8pt; padding: 0.5mm">Makanan</td>
                <td style="font-size: 8pt; padding: 0.5mm">-</td>
              </tr> -->

              <!-- <tr>
                <td style="font-size: 8pt; padding: 0.5mm">Umum</td>
                <td style="font-size: 8pt; padding: 0.5mm">-</td>
              </tr> -->
            </tbody>
          </table>
        </div>
      </div>

      <div class="w-100" style="margin-top: 10mm" v-if="dataPrint.data_pemeriksaan_fisik">
        <table class="custom-table" border="1">
          <thead>
            <tr>
              <th colspan="20">Data Pemeriksaan Fisik</th>
            </tr>
            <tr>
              <th style="font-size: 8pt; padding: 0.5mm">No.</th>
              <th style="font-size: 8pt; padding: 0.5mm">Tanggal</th>
              <th style="font-size: 8pt; padding: 0.5mm">Dokter/<br />Tenaga Medis</th>
              <th style="font-size: 8pt; padding: 0.5mm">
                Perawat/Bidan/<br />Nutrisionist/Sanitarian
              </th>
              <th style="font-size: 8pt; padding: 0.5mm">Status Hamil</th>
              <th style="font-size: 8pt; padding: 0.5mm">Kesadaran</th>
              <th style="font-size: 8pt; padding: 0.5mm">Sistole</th>
              <th style="font-size: 8pt; padding: 0.5mm">Diastole</th>
              <th style="font-size: 8pt; padding: 0.5mm">Tinggi Badan</th>
              <th style="font-size: 8pt; padding: 0.5mm">Berat Badan</th>
              <th style="font-size: 8pt; padding: 0.5mm">Lingkar Perut</th>
              <th style="font-size: 8pt; padding: 0.5mm">IMT</th>
              <th style="font-size: 8pt; padding: 0.5mm">Hasil IMT</th>
              <th style="font-size: 8pt; padding: 0.5mm">Detak Nadi</th>
              <th style="font-size: 8pt; padding: 0.5mm">Nafas</th>
              <th style="font-size: 8pt; padding: 0.5mm">Suhu</th>
              <th style="font-size: 8pt; padding: 0.5mm">
                Aktfitas<br />Fisik dan <br />Assessement<br />Fungsional
              </th>
              <th style="font-size: 8pt; padding: 0.5mm">Detak Jatung</th>
              <th style="font-size: 8pt; padding: 0.5mm">Triage</th>
              <th style="font-size: 8pt; padding: 0.5mm">Skala Nyeri</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(objek, nomor) in dataPrint.data_pemeriksaan_fisik" :key="nomor+'data_pemeriksaan_fisik'">
              <td style="font-size: 8pt; padding: 0.5mm">{{ nomor + 1 }}.</td>
              <td style="font-size: 8pt; padding: 0.5mm">{{ objek.tanggal ? $moment(objek.tanggal).format("LLL") : "-" }}</td>
              <td style="font-size: 8pt; padding: 0.5mm">{{ objek.nama_dokter || "-" }}</td>
              <td style="font-size: 8pt; padding: 0.5mm">{{ objek.nama_perawat || "-" }}</td>
              <td style="font-size: 8pt; padding: 0.5mm">{{ objek.status_hamil || "-" }}</td>
              <td style="font-size: 8pt; padding: 0.5mm">{{ objek.nama_sadar || "-" }}</td>
              <td style="font-size: 8pt; padding: 0.5mm">{{ objek.sistole || "-" }}</td>
              <td style="font-size: 8pt; padding: 0.5mm">{{ objek.diastole || "-" }}</td>
              <td style="font-size: 8pt; padding: 0.5mm">{{ objek.tinggi_badan || "-" }}</td>
              <td style="font-size: 8pt; padding: 0.5mm">{{ objek.berat_badan || "-" }}</td>
              <td style="font-size: 8pt; padding: 0.5mm">{{ objek.lingkar_perut || "-" }}</td>
              <td style="font-size: 8pt; padding: 0.5mm">{{ objek.imt || "-" }}</td>
              <td style="font-size: 8pt; padding: 0.5mm">{{ objek.hasil_imt || "-" }}</td>
              <td style="font-size: 8pt; padding: 0.5mm">{{ objek.detak_nadi || "-" }}</td>
              <td style="font-size: 8pt; padding: 0.5mm">{{ objek.nafas || "-" }}</td>
              <td style="font-size: 8pt; padding: 0.5mm">{{ objek.suhu || "-" }}</td>
              <td style="font-size: 8pt; padding: 0.5mm">{{ objek.aktifitas_fisik || "-" }}</td>
              <td style="font-size: 8pt; padding: 0.5mm">{{ objek.detak_jantung || "-" }}</td>
              <td style="font-size: 8pt; padding: 0.5mm">{{ objek.triage || "-" }}</td>
              <td style="font-size: 8pt; padding: 0.5mm">{{ objek.skala_nyeri || "-" }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="w-100" style="margin-top: 10mm" v-if="dataPrint.pemeriksaan_dasar_gigi">
        <table class="custom-table" border="1">
          <thead>
            <tr>
              <th colspan="3">Pemeriksaan Dasar Gigi</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style="width: 33.33%; border: hidden; padding: 0">
                <table class="custom-table" border="1">
                  <tbody>
                    <tr>
                      <td style="font-size: 8pt; padding: 0.5mm; width: 30mm">
                        Bengkak Atas
                      </td>
                      <td
                        style="
                          font-size: 8pt;
                          padding: 0.5mm;
                          width: 2.5mm;
                          border-left: hidden;
                          border-right: hidden;
                        "
                      >
                        :
                      </td>
                      <td style="font-size: 8pt; padding: 0.5mm; border-right: hidden">
                        -
                      </td>
                    </tr>

                    <tr>
                      <td style="font-size: 8pt; padding: 0.5mm">Konsistensi</td>
                      <td
                        style="
                          font-size: 8pt;
                          padding: 0.5mm;
                          border-left: hidden;
                          border-right: hidden;
                        "
                      >
                        :
                      </td>
                      <td style="font-size: 8pt; padding: 0.5mm; border-right: hidden">
                        -
                      </td>
                    </tr>

                    <tr>
                      <td style="font-size: 8pt; padding: 0.5mm">Warna Kulit</td>
                      <td
                        style="
                          font-size: 8pt;
                          padding: 0.5mm;
                          border-left: hidden;
                          border-right: hidden;
                        "
                      >
                        :
                      </td>
                      <td style="font-size: 8pt; padding: 0.5mm; border-right: hidden">
                        -
                      </td>
                    </tr>

                    <tr>
                      <td style="font-size: 8pt; padding: 0.5mm">Suhu Kulit</td>
                      <td
                        style="
                          font-size: 8pt;
                          padding: 0.5mm;
                          border-left: hidden;
                          border-right: hidden;
                        "
                      >
                        :
                      </td>
                      <td style="font-size: 8pt; padding: 0.5mm; border-right: hidden">
                        -
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td style="width: 33.33%; border: hidden; padding: 0">
                <table class="custom-table" border="1">
                  <tbody>
                    <tr>
                      <td style="font-size: 8pt; padding: 0.5mm; width: 30mm">Goyang</td>
                      <td
                        style="
                          font-size: 8pt;
                          padding: 0.5mm;
                          width: 2.5mm;
                          border-left: hidden;
                          border-right: hidden;
                        "
                      >
                        :
                      </td>
                      <td style="font-size: 8pt; padding: 0.5mm; border-right: hidden">
                        -
                      </td>
                    </tr>

                    <tr>
                      <td style="font-size: 8pt; padding: 0.5mm">Warna Gusi</td>
                      <td
                        style="
                          font-size: 8pt;
                          padding: 0.5mm;
                          border-left: hidden;
                          border-right: hidden;
                        "
                      >
                        :
                      </td>
                      <td style="font-size: 8pt; padding: 0.5mm; border-right: hidden">
                        -
                      </td>
                    </tr>

                    <tr>
                      <td style="font-size: 8pt; padding: 0.5mm">Karies Gigi</td>
                      <td
                        style="
                          font-size: 8pt;
                          padding: 0.5mm;
                          border-left: hidden;
                          border-right: hidden;
                        "
                      >
                        :
                      </td>
                      <td style="font-size: 8pt; padding: 0.5mm; border-right: hidden">
                        -
                      </td>
                    </tr>

                    <tr>
                      <td style="font-size: 8pt; padding: 0.5mm">Pembengkakan</td>
                      <td
                        style="
                          font-size: 8pt;
                          padding: 0.5mm;
                          border-left: hidden;
                          border-right: hidden;
                        "
                      >
                        :
                      </td>
                      <td style="font-size: 8pt; padding: 0.5mm; border-right: hidden">
                        -
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td style="width: 33.33%; border: hidden; padding: 0">
                <table class="custom-table" border="1">
                  <tbody>
                    <tr>
                      <td style="font-size: 8pt; padding: 0.5mm; width: 30mm">Perkusi</td>
                      <td
                        style="
                          font-size: 8pt;
                          padding: 0.5mm;
                          width: 2.5mm;
                          border-left: hidden;
                          border-right: hidden;
                        "
                      >
                        :
                      </td>
                      <td style="font-size: 8pt; padding: 0.5mm">-</td>
                    </tr>

                    <tr>
                      <td style="font-size: 8pt; padding: 0.5mm">Druk</td>
                      <td
                        style="
                          font-size: 8pt;
                          padding: 0.5mm;
                          border-left: hidden;
                          border-right: hidden;
                        "
                      >
                        :
                      </td>
                      <td style="font-size: 8pt; padding: 0.5mm">-</td>
                    </tr>

                    <tr>
                      <td style="font-size: 8pt; padding: 0.5mm">Palpasi</td>
                      <td
                        style="
                          font-size: 8pt;
                          padding: 0.5mm;
                          border-left: hidden;
                          border-right: hidden;
                        "
                      >
                        :
                      </td>
                      <td style="font-size: 8pt; padding: 0.5mm">-</td>
                    </tr>

                    <tr>
                      <td style="font-size: 8pt; padding: 0.5mm">Status Karies Gigi</td>
                      <td
                        style="
                          font-size: 8pt;
                          padding: 0.5mm;
                          border-left: hidden;
                          border-right: hidden;
                        "
                      >
                        :
                      </td>
                      <td style="font-size: 8pt; padding: 0.5mm">-</td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="w-100" style="margin-top: 10mm" v-if="dataPrint.diagnosa">
        <table class="custom-table" border="1">
          <thead>
            <tr>
              <th colspan="6">Data Diagnosa</th>
            </tr>
            <tr>
              <th style="font-size: 8pt; padding: 0.5mm">No.</th>
              <th style="font-size: 8pt; padding: 0.5mm">Tanggal</th>
              <th style="font-size: 8pt; padding: 0.5mm">Dokter/Tenaga Medis</th>
              <th style="font-size: 8pt; padding: 0.5mm">
                Perawat/Bidan/Nutrisionist/Sanitarian
              </th>
              <th style="font-size: 8pt; padding: 0.5mm">ICD-X</th>
              <th style="font-size: 8pt; padding: 0.5mm">Diagnosa</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="(value, index) in dataPrint.diagnosa" :key="index">
              <td style="font-size: 8pt; padding: 0.5mm">{{ index + 1 }}.</td>
              <td style="font-size: 8pt; padding: 0.5mm">{{ value.tanggal ? $moment(value.tanggal).format("LL") : "-" }}</td>
              <td style="font-size: 8pt; padding: 0.5mm">{{ value.nama_dokter || "-" }}</td>
              <td style="font-size: 8pt; padding: 0.5mm">{{ value.nama_perawat || "-" }}</td>
              <td style="font-size: 8pt; padding: 0.5mm">{{ value.kd_diag || "-" }}</td>
              <td style="font-size: 8pt; padding: 0.5mm">{{ value.nm_diag || "-" }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="w-100" style="margin-top: 10mm" v-if="dataPrint">
        <table class="custom-table" border="1">
          <thead>
            <tr>
              <th colspan="6">Data Resep</th>
            </tr>
            <tr>
              <th style="font-size: 8pt; padding: 0.5mm">No.</th>
              <th style="font-size: 8pt; padding: 0.5mm">Tanggal</th>
              <th style="font-size: 8pt; padding: 0.5mm">Ruangan Tujuan</th>
              <th style="font-size: 8pt; padding: 0.5mm">Dokter/Tenaga Medis</th>
              <th style="font-size: 8pt; padding: 0.5mm">
                Perawat/Bidan/Nutrisionist/Sanitarian
              </th>
              <th style="font-size: 8pt; padding: 0.5mm">Alergi Pasien</th>
            </tr>
          </thead>
          <tbody v-if="dataPrint.resep">
            <tr v-for="(item, index) in dataPrint.resep" :key="index">
              <td style="font-size: 8pt; padding: 0.5mm">{{ index + 1 }}</td>
              <td style="font-size: 8pt; padding: 0.5mm">{{ item.tanggal ? $moment(item.tanggal).format("LLL") : "-" }}</td>
              <td style="font-size: 8pt; padding: 0.5mm">{{ item.ruangan_tujuan || "-" }}</td>
              <td style="font-size: 8pt; padding: 0.5mm">{{ item.nama_dokter || "-" }}</td>
              <td style="font-size: 8pt; padding: 0.5mm">{{ item.nama_perawat || "-" }}</td>
              <td style="font-size: 8pt; padding: 0.5mm">
                <span v-if="item.alergi_obat_saat_resep">
                  <ul>
                    <li v-for="(data, index) in item.alergi_obat_saat_resep" :key="index+'alergi_obat_saat_resep'">
                      {{ data.nama_jenis_alergi }} :
                      <span v-for="(value, index) in data.data_alergi" :key="index+'data_alergi2'">{{ value.nama_alergi + "," + " " }}</span>
                    </li>
                  </ul>
                </span>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan5 style="font-size: 8pt; padding: 0.5mm">
                <i>Data tidak ditemukan</i>
              </td>
              <!-- <td style="font-size: 8pt; padding: 0.5mm">-</td>
              <td style="font-size: 8pt; padding: 0.5mm">-</td>
              <td style="font-size: 8pt; padding: 0.5mm">-</td>
              <td style="font-size: 8pt; padding: 0.5mm">-</td> -->
            </tr>
          </tbody>
        </table>
      </div>

      <div class="w-100" style="margin-top: 10mm">
        <table class="custom-table" border="1">
          <thead>
            <tr>
              <th colspan="7">Resep Detail</th>
            </tr>
            <tr>
              <th style="font-size: 8pt; padding: 0.5mm">No.</th>
              <th style="font-size: 8pt; padding: 0.5mm">Nama Obat</th>
              <th style="font-size: 8pt; padding: 0.5mm">Jumlah</th>
              <th style="font-size: 8pt; padding: 0.5mm">Signa</th>
              <th style="font-size: 8pt; padding: 0.5mm">Racikan</th>
              <th style="font-size: 8pt; padding: 0.5mm">Jumlah Permintaan</th>
              <th style="font-size: 8pt; padding: 0.5mm">Keterangan</th>
            </tr>
          </thead>
          <tbody v-if="dataPrint.resep_detail">
            <tr v-for="(detail, index) in dataPrint.resep_detail" :key="index+'resep_detail'">
              <td style="font-size: 8pt; padding: 0.5mm">{{ index + 1 }}.</td>
              <td style="font-size: 8pt; padding: 0.5mm">{{ detail.nama_barang || "-" }}</td>
              <td style="font-size: 8pt; padding: 0.5mm">{{ detail.qty_barang || "-" }}</td>
              <td style="font-size: 8pt; padding: 0.5mm">{{ detail.signa_racikan || "-" }}</td>
              <td style="font-size: 8pt; padding: 0.5mm">{{ detail.qty_permintaan || "-" }}</td>
              <td style="font-size: 8pt; padding: 0.5mm">{{ detail.keterangan_racikan || "-" }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="w-100" style="margin-top: 10mm">
        <table class="custom-table">
          <tbody>
            <tr>
              <td style="width: 60%">&nbsp;</td>
              <td style="width: 40%">
                <h6 style="text-align: center">
                  <!-- <span>Kabupaten Bandung, 260122023</span> -->
                  {{ $store.state.puskesmas.nama_kota }}, {{ $moment(dataPrint.tanggal_daftar).format("LL") }}
                </h6>
                <h6 style="text-align: center">Dokter/Tenaga Medis</h6>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <h6 style="text-align: center">
                  <!-- (.......................................) -->
                  ( {{ dataPrint.nama_dokter }} )
                </h6>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </section>
  <!-- <span>
    <div style="display: none" :id="id_print">
    </div>
    <div
      class="w-100"
      style="border: 1px solid #333; display: none; position: relative"
      id="general_consent"
    >
      <button class="button" id="btn-print">Cetak</button>
    </div>
  </span> -->
</template>

<script>
export default {
  props: ["id_print", "dataPrint"],
  data() {
    return {
      busy: false,
    };
  },
  computed: {},
  watch: {},
  async mounted() {},
  methods: {
    async cetakDataPrint(val) {
      const options = {
        name: "_blank",
        specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
        styles: ["/paper-a4-landscape.css"],
        timeout: 1000, // default timeout before the print window appears
        autoClose: true, // if false, the window will not close after printing
        windowTitle: window.document.title, // override the window title
      };
      await this.$htmlToPaper(val, options);
    },
    triggerAlert(event) {
      let vm = this;
      vm.$store.commit("set_alert", event);
    },
  },
};
</script>
<style scoped></style>
