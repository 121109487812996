<template>
  <div>
    <b-modal
      id="modal-kunjungan-obat"
      size="lg"
      centered
      title="Input Obat"
      header-bg-variant="danger"
      header-text-variant="light"
      @show="showModal()"
    >
      <div class="mx-2">
        <div class="">
          <b-card no-body class="p-2">
            <h5>ALERGI PASIEN</h5>
            <b-form-group v-if="listAlergiPasien.length" v-slot="{ ariaDescribedby }">
                <b-form-checkbox 
                  v-for="(x, key) of listAlergiPasien" 
                  :key="key"
                  v-model="x.ms_alergi_id"
                  :value="x.ms_alergi_id"
                  :aria-describedby="ariaDescribedby"
                  disabled
                  inline
                ><div style="margin-top: 2.5px">{{ x.nama_alergi }}</div></b-form-checkbox>
            </b-form-group>
            <p v-else class="mb-0">
              <i>Pasien tidak memiliki alergi</i>
            </p>
          </b-card>
        </div>
        <div class="mb-3" v-if="dataFormHead.head_obat_jual_id !== true">
          <b-form-group>
            <b-form-checkbox
              v-model="is_racikan"
              :value="true"
              :unchecked-value="false"
              class="mr-n2"
              @change="resetHead()"
            ><div style="margin-top: 2.5px">Dengan Racikan</div></b-form-checkbox>
          </b-form-group>
        </div>
        <div v-if="is_racikan" class="">
          <h5 class="custom-title-card"><strong>HEAD OBAT</strong></h5>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Racikan <span class="text-danger">*</span>
            </template>
            <b-form-input
              id="no_racikan"
              type="text"
              v-model="$v.dataFormHead.no_racikan.$model"
              :state="checkIfValid('dataFormHead', 'no_racikan')"
            ></b-form-input>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Jumlah <span class="text-danger">*</span>
            </template>
            <b-form-input
              id="jumlah_bungkus"
              type="text"
              v-model="$v.dataFormHead.jumlah_bungkus.$model"
              :state="checkIfValid('dataFormHead', 'jumlah_bungkus')"
            ></b-form-input>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Indikasi Head 
            </template>
            <b-form-textarea
              id="indikasi_head_obat_jual"
              v-model="dataFormHead.indikasi_head_obat_jual"
              :state="checkIfValid('dataFormHead', 'indikasi_head_obat_jual')"
            ></b-form-textarea>
          </b-form-group>
          <div class="d-flex justify-content-end" v-if="isUpdate">
            <b-button variant="warning" @click="update()" :disabled="busy || !isValidHead">
              Update
            </b-button>
          </div>
          <hr>
        </div>
        <div class="">
          <h5 class="custom-title-card"><strong>SUB OBAT</strong></h5>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Obat <span class="text-danger">*</span>
            </template>
            <Multiselect
              id="ms_barang_id"
              v-model="dataFormSub.ms_barang_id"
              :options="listBarang"
              :multiple="false"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              label="nama"
              track-by="ms_barang_id"
              placeholder="-- Pilih Obat --"
              :state="checkIfValid('dataFormSub', 'ms_barang_id')"
              @select="dataFormSub.harga_sub_obat_jual = dataFormSub.ms_barang_id.harga_jual"
              @remove="dataFormSub.harga_sub_obat_jual = null"
            ></Multiselect>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Harga Obat <span class="text-danger">*</span>
            </template>
            <b-form-input
              id="harga_sub_obat_jual"
              type="number"
              v-model="dataFormSub.harga_sub_obat_jual"
              :state="checkIfValid('dataFormSub', 'harga_sub_obat_jual')"
            ></b-form-input>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Aturan Pakai <span class="text-danger">*</span>
            </template>
            <Multiselect
              id="aturan_pakai_obat"
              v-model="dataFormSub.aturan_pakai_obat"
              :options="$store.state.data_static.aturan_pakai"
              :multiple="false"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              placeholder="-- Pilih Aturan Pakai --"
              :state="checkIfValid('dataFormSub', 'aturan_pakai_obat')"
            ></Multiselect>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Signa <span class="text-danger">*</span>
            </template>
            <Multiselect
              id="signa_obat"
              v-model="dataFormSub.signa_obat"
              :options="$store.state.data_static.signa"
              :multiple="false"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              placeholder="-- Pilih Signa --"
              :state="checkIfValid('dataFormSub', 'signa_obat')"
            ></Multiselect>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Jumlah Permintaan <span class="text-danger">*</span>
            </template>
            <b-form-input
              id="qty_permintaan"
              type="number"
              v-model="dataFormSub.qty_permintaan"
              :state="checkIfValid('dataFormSub', 'qty_permintaan')"
              @input="dataFormSub.qty_obat = dataFormSub.qty_permintaan"
            ></b-form-input>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Jumlah Obat yang diberikan <span class="text-danger">*</span>
            </template>
            <b-form-input
              id="qty_obat"
              type="number"
              v-model="dataFormSub.qty_obat"
              :state="checkIfValid('dataFormSub', 'qty_obat')"
            ></b-form-input>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Indikasi Sub 
            </template>
            <b-form-textarea
              id="indikasi_obat"
              v-model="dataFormSub.indikasi_obat"
              :state="checkIfValid('dataFormSub', 'indikasi_obat')"
            ></b-form-textarea>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Keterangan 
            </template>
            <b-form-textarea
              id="keterangan_obat"
              v-model="dataFormSub.keterangan_obat"
              :state="checkIfValid('dataFormSub', 'keterangan_obat')"
            ></b-form-textarea>
          </b-form-group>
          <div class="mt-2 d-flex justify-content-end">
            <b-button
              :disabled="busy || !isValidSub"
              @click="tambahSub()"
              variant="primary"
            >Tambah</b-button>
          </div>
        </div>
        <!-- <pre>{{dataFormSub.ms_barang_id}}</pre> -->
        <div class="mt-3">
          <b-table
            :items="listSubObat"
            :fields="fieldSubObat"
            responsive
            show-empty
            small
            bordered
            striped
            hover
            :busy="busy"
          >
            <template #cell(no)="item">{{item.index+1}}</template>
            <template #cell(actions)="item">
              <div class="d-flex nowrap">
                <b-button
                  id="detail-obat"
                  variant="info"
                  size="sm"
                  class="ml-1"
                  @click="item.toggleDetails"
                  v-c-tooltip.hover.click="'Detail Sub Obat'"
                >
                  <CIcon name="cil-info" />
                </b-button>
                <b-button
                  id="delete-obat"
                  variant="danger"
                  size="sm"
                  class="ml-1"
                  @click="deleteSub(item.index)"
                  v-c-tooltip.hover.click="'Delete Sub Obat'"
                >
                  <CIcon name="cil-trash" />
                </b-button>
              </div>
            </template>
            <template #row-details="row">
              <b-card class="m-0">
                <b-row class="m-0 p-0" style="row-gap: 1rem;">
                  <b-col cols="6">
                    <div>
                      <h6><strong>Indikasi</strong></h6>
                      <p>{{row.item.indikasi_obat || '-'}}</p>
                    </div>
                    <div>
                      <h6><strong>Keterangan</strong></h6>
                      <p>{{row.item.keterangan_obat || '-'}}</p>
                    </div>
                  </b-col>
                  <b-col cols="6">
                    <div class="" v-for="(value, key) in row.item.item_obat_jual" :key="key">
                      <b-card class="m-0">
                        <b-row class="m-0 p-0">
                          <b-col cols="4"><h6 class="m-0 p-0">Kode Batch</h6></b-col>
                          <b-col cols="8"><h6 class="m-0 p-0">: {{value.kode_batch}}</h6></b-col>
                          <b-col cols="4"><h6 class="m-0 p-0">Jumlah</h6></b-col>
                          <b-col cols="8"><h6 class="m-0 p-0">: {{value.qty_item_obat_jual}}</h6></b-col>
                          <b-col cols="4"><h6 class="m-0 p-0">Kadaluarsa</h6></b-col>
                          <b-col cols="8"><h6 class="m-0 p-0">: {{$moment(value.tanggal_kadaluarsa).format("LL")}}</h6></b-col>
                        </b-row>
                      </b-card>
                    </div>
                  </b-col>
                </b-row>
              </b-card>
            </template>
          </b-table>
        </div>
      </div>
      <!-- <pre>{{listSubObat}}</pre> -->
      <!-- <p>Apakah anda yakin akan menghapus data ini ?</p> -->
      <!-- <pre>{{dataObat}}</pre> -->
      <template #modal-footer>
        <b-button variant="danger" @click="resetSub(), resetHead(), listSubObat = [], is_data_detail = {}">
          reset
        </b-button>
        <b-button v-if="!isUpdate" variant="primary" @click="simpan()" :disabled="busy">
          {{ button }}
        </b-button>
        <b-button
          v-if="isUpdate"
          variant="danger"
          class="mr-1"
          v-c-tooltip.hover.click="'Delete Obat'"
          @click="$bvModal.show('modal-delete-head')"
        >Delete Obat</b-button>
        <b-button variant="secondary" @click="$bvModal.hide('modal-kunjungan-obat')">
          close
        </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import { validationMixin } from "vuelidate";
import { required, numeric, minValue } from "vuelidate/lib/validators";
export default {
  name: "modalDelete",
  props: [
    "dataObat", 
    "dataSubObat", 
    'listBarang', 
    'listAlergiPasien',
    'is_data_detail', 
  ],
  data() {
    return {
      busy: false,
      button: "Simpan",
      listSubObat: [],
      fieldSubObat: [
        {
          key: "no",
          label: "No",
          sortDirection: "desc",
          sortable: true,
          class: "table-number text-center",
        },
        {
          key: "nama_barang",
          label: "Obat",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "qty_obat",
          label: "Diberikan",
          sortable: true,
          sortDirection: "desc",
          class: "text-right",
        },
        {
          key: "qty_permintaan",
          label: "Permintaan",
          sortable: true,
          sortDirection: "desc",
          class: "text-right",
        },
        {
          key: "signa_obat",
          label: "Signa",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "aturan_pakai_obat",
          label: "Aturan Pakai",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        // {
        //   key: "indikasi_obat",
        //   label: "Indikasi",
        //   sortable: true,
        //   sortDirection: "desc",
        //   class: "text-left",
        // },
        // {
        //   key: "keterangan_obat",
        //   label: "Keterangan",
        //   sortable: true,
        //   sortDirection: "desc",
        //   class: "text-left",
        // },
        {
          key: "harga_sub_obat_jual",
          label: "Harga",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        // {
        //   key: "nama_tanggal_kadaluarsa_obat",
        //   label: "Kadaluarsa",
        //   sortable: true,
        //   sortDirection: "desc",
        //   class: "text-left",
        // },
        {
          key: "actions",
          label: "Actions",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
      ],
      is_racikan: false,
      dataFormHead: {
        no_racikan: null,
        indikasi_head_obat_jual: null,
        jumlah_bungkus: null,
        obat_jual_id: null,
      },
      dataFormSub: {
        qty_obat: null,
        signa_obat: null,
        aturan_pakai_obat: null,
        qty_permintaan: null,
        indikasi_obat: null,
        keterangan_obat: null,
        tanggal_kadaluarsa_obat: null,
        harga_sub_obat_jual: null,
        obat_jual_id: null,
        ms_barang_id: null,
        head_obat_jual_id: null,
      },
    };
  },
  computed: {
    isValidHead() {
      return !this.$v.dataFormHead.$invalid;
    },
    isDirtyHead() {
      return this.$v.dataFormHead.$anyDirty;
    },
    isValidSub() {
      return !this.$v.dataFormSub.$invalid;
    },
    isDirtySub() {
      return this.$v.dataFormSub.$anyDirty;
    },
    isUpdate() {
      return this.is_data_detail.head_obat_jual_id ? true : false;
    },
  },
  mixins: [validationMixin],
  validations: {
    dataFormHead: {
      no_racikan: { required },
      indikasi_head_obat_jual: {  },
      jumlah_bungkus: { required, numeric, minValue: minValue(0) },
      // obat_jual_id: { required },
    },
    dataFormSub: {
      qty_obat: { required, numeric, minValue: minValue(0) },
      signa_obat: { required },
      aturan_pakai_obat: { required },
      qty_permintaan: { required, numeric, minValue: minValue(0) },
      indikasi_obat: {  },
      keterangan_obat: {  },
      tanggal_kadaluarsa_obat: {  },
      harga_sub_obat_jual: { required, numeric, minValue: minValue(0) },
      // obat_jual_id: { required },
      ms_barang_id: { required },
      // head_obat_jual_id: { required },
    },
  },
  methods: {
    onRacikan(){
      const vm = this
      console.log(vm.is_racikan)
      vm.resetHead()
    },
    async showModal(){
      const vm = this
      vm.resetSub()
      vm.resetHead()
      vm.listSubObat = []
      vm.is_racikan = true
      console.log('dataObat', vm.dataObat)
      console.log('dataSubObat', vm.dataSubObat)
      console.log('is_data_detail', vm.is_data_detail)
      vm.dataFormHead.obat_jual_id = vm.dataObat.obat_jual_id
      vm.dataFormSub.obat_jual_id = vm.dataObat.obat_jual_id
      await new Promise(resolve => setTimeout(resolve, 100));
      if(vm.is_data_detail.head_obat_jual_id){
        const x = vm.is_data_detail
        vm.listSubObat = x.data_sub
        vm.dataFormHead = {...x, data_sub: undefined}
        vm.is_racikan = vm.dataFormHead.no_racikan ? true : false
      }else {
        vm.is_racikan = false
      }
    },
    async tambahSub(){
      const vm = this
      console.log(vm.dataFormSub)
      vm.busy = true
      try {
        await this.$v.dataFormSub.$touch()
        if (vm.isValidSub && vm.isDirtySub) {
          let i = 0
          //untuk mengecek terdapat barang yang sudah ada di sub obat
          for (i = 0; i < vm.listSubObat.length; i++) {
            const x = vm.listSubObat[i];
            if(x.ms_barang_id == vm.dataFormSub.ms_barang_id.ms_barang_id) break
          }
          if(i != vm.listSubObat.length){
            vm.$emit("alertFromChild", { variant: "danger", msg: 'Barang Sudah Ada Di Sub Obat', showing: true });
          }else{
            if(vm.dataFormHead.head_obat_jual_id || vm.dataFormHead.head_obat_jual_id === true){ // jika sudah register ke database
              if(vm.dataFormHead.head_obat_jual_id !== true) vm.dataFormSub.head_obat_jual_id = vm.dataFormHead.head_obat_jual_id
              let res = await vm.$axios.post('/sub_obat_jual/register', {
                ...vm.dataFormSub, 
                ms_barang_id: vm.dataFormSub.ms_barang_id.ms_barang_id,
                obat_jual_id: vm.dataObat.obat_jual_id,
              })
              console.log('sub_obat_jual', res)
              if(res.data.status == 200){
                vm.$emit("alertFromChild", { variant: "success", msg: 'Berhasil Menambah Sub Obat', showing: true });
                vm.listSubObat.push({
                  ...vm.dataFormSub,
                  ms_barang_id: vm.dataFormSub.ms_barang_id.ms_barang_id,
                  nama_barang: vm.dataFormSub.ms_barang_id.nama_barang,
                  nama_tanggal_kadaluarsa_obat: vm.$moment(vm.dataFormSub.tanggal_kadaluarsa_obat).format('LL'),
                  obat_jual_id: vm.dataObat.obat_jual_id,
                })
              }else{
                vm.$emit("alertFromChild", { variant: "danger", msg: 'Gagal Menambah Sub Obat', showing: true });
              }
            }else{ // jika belum register ke database
              vm.listSubObat.push({
                ...vm.dataFormSub,
                ms_barang_id: vm.dataFormSub.ms_barang_id.ms_barang_id,
                nama_barang: vm.dataFormSub.ms_barang_id.nama_barang,
                nama_tanggal_kadaluarsa_obat: vm.$moment(vm.dataFormSub.tanggal_kadaluarsa_obat).format('LL'),
                obat_jual_id: vm.dataObat.obat_jual_id,
              })
            }
          }
          vm.resetSub()
        }else{
          vm.$emit("alertFromChild", { variant: "warning", msg: 'Data Tidak Valid', showing: true });
        }
      } catch (error) {
        console.log(error)
        if (error.message) {
          vm.$emit("alertFromChild", { variant: "danger", msg: error.message.toUpperCase(), showing: true });
        } else {
          vm.$emit("alertFromChild", { variant: "danger", msg: error.toUpperCase(), showing: true });
        }
      } finally {
        vm.busy = false
      }
    },
    async deleteSub(idx){
      const vm = this
      console.log(vm.dataFormSub)
      vm.busy = true
      try {
        if(vm.dataFormHead.head_obat_jual_id){ // jika sudah register ke database
          let res = await vm.$axios.post('/sub_obat_jual/delete', {
            id: vm.listSubObat[idx].sub_obat_jual_id
          })
          console.log('sub_obat_jual', res)
          if(res.data.status == 200){
            vm.$emit("alertFromChild", { variant: "success", msg: 'Berhasil Menghapus Sub Obat', showing: true });
            vm.listSubObat.splice(idx, 1)
          }else{
            vm.$emit("alertFromChild", { variant: "danger", msg: 'Gagal Menghapus Sub Obat', showing: true });
          }
        }else{ // jika belum register ke database
          vm.listSubObat.splice(idx, 1)
        }
      } catch (error) {
        console.log(error)
        if (error.message) {
          vm.$emit("alertFromChild", { variant: "danger", msg: error.message.toUpperCase(), showing: true });
        } else {
          vm.$emit("alertFromChild", { variant: "danger", msg: error.toUpperCase(), showing: true });
        }
      } finally {
        vm.busy = false
      }
    },
    async simpan(){
      const vm = this
      vm.busy = true
      try {
        await this.$v.dataFormHead.$touch();
        // console.log('dataFormHead', vm.dataFormHead)
        if (!vm.is_racikan || (vm.is_racikan && vm.isValidHead && vm.isDirtyHead)) {
          if(vm.listSubObat.length >= 1){
            let res = await vm.$axios.post('/sub_obat_jual/register_bulk', {...vm.dataFormHead, bulk_sub_obat_jual: vm.listSubObat})
            console.log('sub_obat_jual', res)
            if(res.data.status == 200){
              vm.$emit("alertFromChild", { variant: "success", msg: 'Berhasil Membuat Obat', showing: true });
              vm.resetSub()
              vm.resetHead()
              vm.$bvModal.hide('modal-kunjungan-obat')
            }else{
              vm.$emit("alertFromChild", { variant: "danger", msg: 'Gagal Membuat Obat', showing: true });
            }
          }else{
            vm.$emit("alertFromChild", { variant: "warning", msg: 'Harus Menginputkan Obat', showing: true });
          }
        }else{
          vm.$emit("alertFromChild", { variant: "warning", msg: 'Data Tidak Valid', showing: true });
        }
      } catch (error) {
        console.log(error)
        if (error.message) {
          vm.$emit("alertFromChild", { variant: "danger", msg: error.message.toUpperCase(), showing: true });
        } else {
          vm.$emit("alertFromChild", { variant: "danger", msg: error.toUpperCase(), showing: true });
        }
      } finally {
        vm.busy = false
      }
    },
    async update(){
      const vm = this
      vm.busy = true
      try {
        await this.$v.dataFormHead.$touch();
        if (vm.isValidHead && vm.isDirtyHead) {
          let res = await vm.$axios.post('/head_obat_jual/update', {...vm.dataFormHead, id: vm.dataFormHead.head_obat_jual_id})
          console.log('head_obat_jual', res)
          if(res.data.status == 200){
            vm.$emit("alertFromChild", { variant: "success", msg: 'Berhasil Update Head Obat', showing: true });
          }else{
            vm.$emit("alertFromChild", { variant: "danger", msg: 'Gagal Update Head Obat', showing: true });
          }
        }else{
          vm.$emit("alertFromChild", { variant: "warning", msg: 'Data Tidak Valid', showing: true });
        }
      } catch (error) {
        console.log(error)
        if (error.message) {
          vm.$emit("alertFromChild", { variant: "danger", msg: error.message.toUpperCase(), showing: true });
        } else {
          vm.$emit("alertFromChild", { variant: "danger", msg: error.toUpperCase(), showing: true });
        }
      } finally {
        vm.busy = false
      }
    },
    resetSub(){
      const vm = this
      vm.dataFormSub = {
        qty_obat: null,
        signa_obat: null,
        aturan_pakai_obat: null,
        qty_permintaan: null,
        indikasi_obat: null,
        keterangan_obat: null,
        tanggal_kadaluarsa_obat: null,
        harga_sub_obat_jual: null,
        obat_jual_id: null,
        ms_barang_id: null,
        head_obat_jual_id: null,
      }
      vm.$v.dataFormSub.$reset()
    },
    resetHead(){
      const vm = this
      vm.dataFormHead = {
        no_racikan: null,
        indikasi_head_obat_jual: null,
        jumlah_bungkus: null,
        obat_jual_id: null,
      }
      vm.$v.dataFormHead.$reset()
    },
    checkIfValid(name, fieldName) {
      const field = this.$v[name][fieldName];
      // console.log(name, fieldName, field)
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
  },
};
</script>
