<template>
  <b-row>
    <b-col cols="12" md="12" lg="12">
      <CTabs variant="tabs" :fill="true" :active-tab="0">
        <CTab title="SKRINING PTM">
          <SkriningPTM 
            :kunjungan="kunjungan"
            :listDokter="listDokter"
            :listPerawat="listPerawat"
            @dataFromChild="addSkrining($event)" 
          />
        </CTab>
        <CTab title="CARTA DAN ORGAN">
          <CartaOrgan 
            :listDokter="listDokter"
            :listPerawat="listPerawat"
            @dataFromChild="addCO($event)"
          />
        </CTab>
      </CTabs>
    </b-col>
  </b-row>
</template>
<script>
  import SkriningPTM from './skrining_ptm'
  import CartaOrgan from './carta_organ.vue'
  import moment from 'moment';
  moment.locale('id');
  export default {
    props: ['kunjungan', 'listDokter', 'listPerawat'],
    emits: ['cekPTM'],
    components: {
        SkriningPTM,
        CartaOrgan,
    },
    data() {
      return {
        is_data: [],
        data_skrining: null,
        data_co: null,
        rm_id: null,
        items: [],
        fields: [
          {
            key: "no",
            label: "No",
            sortDirection: "desc",
            sortable: true,
            class: "table-number text-center",
          },
          {
            key: "nama_tanggal",
            label: "Tanggal",
            sortable: true,
            sortDirection: "desc",
            class: "text-left",
          },
          {
            key: "anamnesa",
            label: "Anamnesa",
            sortable: true,
            sortDirection: "desc",
            class: "text-left",
          },
          {
            key: "berat_badan",
            label: "Berat Badan",
            sortable: true,
            sortDirection: "desc",
            class: "text-left",
          },
          {
            key: "tinggi_badan",
            label: "Tinggi Badan",
            sortable: true,
            sortDirection: "desc",
            class: "text-left",
          },
          {
            key: "sitole",
            label: "Sitole / Diastole",
            sortable: true,
            sortDirection: "desc",
            class: "text-left",
          },
          {
            key: "user",
            label: "User",
            sortable: true,
            sortDirection: "desc",
            class: "text-left",
          },
          {
            key: "actions",
            label: "Actions",
            class: "table-option-2 text-center",
          },
        ],
        totalRows: 1,
        currentPage: 1,
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        filter: null,
        filterOn: [],
        tableBusy: false,
      }
    },
    async mounted() {
      // const vm = this
      // vm.getData()
    },
    methods: {
      onFiltered(filteredItems) {
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      },
      // async getData(){
      //   const vm = this
      //   try {
      //       let listDokter = await vm.$axios.post('/tenaga_medis/list', {nama_jenis_tenaga_medis: 'dokter'})
      //       // console.log('itikiwir', listDokter)
      //       if(listDokter.data.status == 200){
      //           vm.listDokter = listDokter.data.data
      //       }
      //       let listPerawat = await vm.$axios.post('/tenaga_medis/list_tenaga_medis_non_dokter')
      //       // console.log('itikiwir', listPerawat)
      //       if(listPerawat.data.status == 200){
      //           vm.listPerawat = listPerawat.data.data
      //       }
      //   } catch (error) {
      //       console.log(error)
      //   }
      // },
      addSkrining(event){
        let vm = this
        // console.log('addSkrining', event)
        vm.data_skrining = event
        if(vm.data_skrining && vm.data_co){
          vm.register()
        }
      },
      addCO(event){
        let vm = this
        vm.data_co = event
        if(vm.data_skrining && vm.data_co){
          vm.register()
        }
      },
      register(){
        let vm = this
        // console.log('cekPTM', vm.data_skrining)
        if(vm.data_skrining.ptm.ms_dokter_id){
          vm.data_skrining.ptm.ms_dokter_id = vm.data_skrining.ptm.ms_dokter_id.tenaga_medis_id
        }
        if(vm.data_skrining.ptm.ms_perawat_id){
          vm.data_skrining.ptm.ms_perawat_id = vm.data_skrining.ptm.ms_perawat_id.tenaga_medis_id
        }
        vm.data_skrining.ptm.puskesmas_id = vm.$store.state.puskesmas_id
        let datanya = {}
        datanya.ptm = vm.data_skrining.ptm
        datanya.riwayat_ptm_diri = vm.data_skrining.riwayat_ptm_diri
        datanya.riwayat_ptm_keluarga = vm.data_skrining.riwayat_ptm_keluarga
        datanya.gangguan_pendengaran = vm.data_skrining.gangguan_pendengaran
        datanya.gangguan_penglihatan = vm.data_skrining.gangguan_penglihatan
        datanya.faktor_risiko = vm.data_skrining.faktor_risiko
        datanya.tes_iva_sadanis = vm.data_skrining.tes_iva_sadanis
        datanya.tekanan_darah_ptm = vm.data_skrining.tekanan_darah_ptm
        datanya.pemeriksaan_ptm = vm.data_skrining.pemeriksaan_ptm
        datanya.co_ginjal = vm.data_co.co_ginjal
        datanya.co_hati = vm.data_co.co_hati
        datanya.co_kardiovaskular = vm.data_co.co_kardiovaskular
        datanya.co_mata = vm.data_co.co_mata
        datanya.co_profil_lipid = vm.data_co.co_profil_lipid
        datanya.co_saraf_otot = vm.data_co.co_saraf_otot
        datanya.skrining = vm.data_co.skrining
        vm.$axios.post("/ptm/register_all_ptm", datanya)
        .then((res) => {
          // console.log(res, 'ini respon');
          if(res.data.status == 200 && res.data.message == "sukses"){
            vm.$store.commit("set_alert", {msg: "SUKSES", variant: "success", showing: true});
            vm.$emit("cekPTM")
          }else {
            vm.$store.commit("set_alert", {msg: res.data.message.toUpperCase(), variant: "danger", showing: true});
            // vm.$store.commit("set_alert", {msg: "GAGAL MOHON CEK KEMBALI", variant: "danger", showing: true});
          }
        })
        .catch((err) => {
          console.log(err);
          vm.$store.commit("set_alert", {msg: "TERJADI KESALAHAN PADA SERVER", variant: "danger", showing: true});
        });
      },
      triggerAlert(event) {
        let vm = this;
        vm.$store.commit("set_alert", event);
      },
    },
  }
  </script>