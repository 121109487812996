<template>
    <b-card>
      <b-row>
        <b-col cols="12" md="12" lg="12">
            <CTabs variant="tabs" :fill="true" :active-tab="0">
                <CTab title="SKRINING PTM">
                    <SkriningPTM :tembak="tembak"/>
                </CTab>
                <CTab title="CARTA DAN ORGAN">
                    <CartaOrgan :tembak="tembak"/>
                </CTab>
            </CTabs>
        </b-col>
      </b-row>
      
    </b-card>
</template>
<script>
  import SkriningPTM from './skrining_ptm'
  import CartaOrgan from './carta_organ.vue'
  import moment from 'moment';
  moment.locale('id');
  export default {
    props: ['fetching'],
    components: {
        SkriningPTM,
        CartaOrgan,
    },
    data() {
      return {
        is_data: [],
        data_skrining: null,
        data_co: null,
        rm_id: null,
        tembak: false,
        ptm: {},
        riwayat_ptm_diri: {},
        riwayat_ptm_keluarga: {},
        tekanan_darah_ptm: {},
        faktor_risiko: {},
        gangguan_penglihatan: {},
        pemeriksaan_ptm: {},
        tes_iva_sadanis: {},
        gangguan_pendengaran: {},

        co_mata: {},
        co_kardiovaskular: {},
        co_hati: {},
        co_saraf_otot: {},
        co_profil_lipid: {},
        co_ginjal: {},
        skrining: {},
      }
    },
    async mounted() {
      // const vm = this
      // vm.getData()
      // vm.getDetail()
    },
    watch: {
        fetching(newVar, oldVar){
            if(newVar != oldVar && newVar){
                this.tembak = !this.tembak
                console.log(this.tembak);
            }
        },
    },
    methods: {
      onFiltered(filteredItems) {
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      },
      // async getDetail(){
      //   let vm = this
      //   // console.log(vm.kunjungan, 'ini kunjungannnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnn');
      //   let id = vm.$store.state.ptm_id
      //   let detail = await vm.$axios.get("/ptm/details_by_id/" +id )
      //   // console.log(detail, 'ini detainyaa');
      //   if(detail.data.status == 200 && detail.data.message == "sukses"){
      //     let data = detail.data.data[0]
      //     vm.ptm.tanggal_ptm = vm.$moment(data.tanggal_ptm).format("YYYY-MM-DD")
      //     vm.ptm.nama_dokter = data.dokter[0]
      //     vm.ptm.nama_perawat = data.perawat[0]
      //     vm.riwayat_ptm_diri = data.riwayat_ptm_diri[0]
      //     vm.riwayat_ptm_keluarga = data.riwayat_ptm_keluarga[0]
      //     vm.tekanan_darah_ptm = data.tekanan_darah_ptm[0]
      //     vm.faktor_risiko = data.faktor_risiko[0]
      //     vm.gangguan_penglihatan = data.gangguan_penglihatan[0]
      //     vm.gangguan_pendengaran = data.gangguan_pendengaran[0]
      //     vm.pemeriksaan_ptm = data.pemeriksaan_ptm[0]
      //     vm.tes_iva_sadanis = data.tes_iva_sadanis[0]
      //     vm.co_ginjal = data.co_ginjal[0]
      //     vm.co_hati = data.co_hati[0]
      //     vm.co_kardiovaskular = data.co_kardiovaskular[0]
      //     vm.co_mata = data.co_mata[0]
      //     vm.co_profil_lipid = data.co_profil_lipid[0]
      //     vm.co_saraf_otot = data.co_saraf_otot[0]
      //     vm.skrining = data.skrining[0]
      //   }
      // },
      triggerAlert(event) {
        let vm = this;
        vm.$store.commit("set_alert", event);
      },
    },
  }
  </script>