<template>
  <section class="content-print paper-58">
    <div class="sheet custom-border-paper">
      <table border="0" class="custom-table">
        <tbody>
          <tr>
            <td style="width: 17mm; padding: 0.5mm; font-size: 8pt">Nama</td>
            <td style="width: 2.5mm; text-align: center; padding: 0.5mm; font-size: 8pt">
              :
            </td>
            <td style="padding: 0.5mm; font-size: 8pt">{{ dataPasien.nama_lengkap }}</td>
          </tr>

          <tr>
            <td style="padding: 0.5mm; font-size: 8pt">No. RM</td>
            <td style="text-align: center; padding: 0.5mm; font-size: 8pt">:</td>
            <td style="padding: 0.5mm; font-size: 8pt">{{ dataPasien.no_rm }}</td>
          </tr>

          <tr>
            <td style="padding: 0.5mm; font-size: 8pt">Tgl. Lahir</td>
            <td style="text-align: center; padding: 0.5mm; font-size: 8pt">:</td>
            <td style="padding: 0.5mm; font-size: 8pt">
              {{ $moment(dataPasien.tanggal_lahir).format("DD-MM-YYYY") || "-" }}
            </td>
          </tr>

          <tr>
            <td style="padding: 0.5mm; font-size: 8pt">Jns. Kelamin</td>
            <td style="text-align: center; padding: 0.5mm; font-size: 8pt">:</td>
            <td style="padding: 0.5mm; font-size: 8pt">
              {{ dataPasien.nama_jenis_kelamin }}
            </td>
          </tr>

          <tr>
            <td style="padding: 0.5mm; font-size: 8pt">Alamat</td>
            <td style="text-align: center; padding: 0.5mm; font-size: 8pt">:</td>
            <td style="padding: 0.5mm; font-size: 8pt">
              {{ dataPasien.alamat_sekarang }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </section>
</template>

<script>
export default {
  props: ["id_print", "dataPasien"],
  data() {
    return {
      busy: false,
    };
  },
  computed: {},
  watch: {},
  async mounted() {},
  methods: {
    async cetakDataPrint(val) {
      const options = {
        name: "_blank",
        specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
        styles: ["/paper-a4-landscape.css"],
        timeout: 1000, // default timeout before the print window appears
        autoClose: true, // if false, the window will not close after printing
        windowTitle: window.document.title, // override the window title
      };
      await this.$htmlToPaper(val, options);
    },
    triggerAlert(event) {
      let vm = this;
      vm.$store.commit("set_alert", event);
    },
  },
};
</script>
<style scoped></style>
