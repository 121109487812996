import { render, staticRenderFns } from "./carta_organ.vue?vue&type=template&id=095d4492&scoped=true"
import script from "./carta_organ.vue?vue&type=script&lang=js"
export * from "./carta_organ.vue?vue&type=script&lang=js"
import style0 from "./carta_organ.vue?vue&type=style&index=0&id=095d4492&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "095d4492",
  null
  
)

export default component.exports