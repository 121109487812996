<template>
  <div>
    <b-modal
      id="modal-edit-galeria"
      size="lg"
      centered
      :title="'Edit Galeria Kunjungan'"
      header-bg-variant="warning"
      header-text-variant="light"
      @show="showModal()"
      @hidden="resetModal"
    >
    <b-row class="mx-0">
      <b-col cols="4" style="height: 200px;">
        <b-img rounded class="h-100" :src="ip + '/' + is_data.judul_foto" fluid alt="image"></b-img>
      </b-col>
      <b-col cols="8">
        <b-form>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Upload File <span class="text-danger">*</span>
            </template>
            <b-form-file
              v-model="dataForm.file1"
              :state="checkIfValid('file1')"
              placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here..."
            ></b-form-file>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Deskripsi <span class="text-danger">*</span>
            </template>
            <b-form-textarea
              id="deskripsi"
              v-model="dataForm.deskripsi"
              :state="checkIfValid('deskripsi')"
            ></b-form-textarea>
          </b-form-group>
        </b-form>
      </b-col>
    </b-row>
      <template #modal-footer>
        <b-button variant="secondary" @click="$bvModal.hide('modal-edit-galeria')">
          Batal
        </b-button>
        <b-button
          variant="danger"
          :disabled="busy || !isValid"
          @click="hapus()"
          >Hapus</b-button
        >
        <b-button
          variant="warning"
          :disabled="busy || !isValid"
          @click="simpan()"
          >{{ button }}</b-button
        >
      </template>
    </b-modal>
  </div>
</template>
  
<script>
  import _ from "lodash"
  import { validationMixin } from "vuelidate";
  import { required } from "vuelidate/lib/validators";
  
  export default {
    name: "modalInput",
    props: [
      'kunjungan',
      'is_data',
    ],
    data() {
      return {
        dataForm: {
          file1: null,
          deskripsi: null,
        },
        busy: false,
        button: "Simpan",
      };
    },
    computed: {
      ip(){
        return localStorage.getItem('url')
      },
      formString() {
        return JSON.stringify(this.dataForm, null, 4);
      },
      isValid() {
        return !this.$v.dataForm.$invalid;
      },
      isDirty() {
        return this.$v.dataForm.$anyDirty;
      },
    },
    mixins: [validationMixin],
    validations: {
      dataForm: {
        file1: {  },
        deskripsi: { required },
      },
    },
    methods: {
      checkIfValid(fieldName) {
        const field = this.$v.dataForm[fieldName];
        if (!field.$dirty) {
          return null;
        }
        return !(field.$invalid || field.$model === "");
      },
      async showModal(){
        const vm = this
        await new Promise(resolve => setTimeout(resolve, 100));
        console.log('is_data', vm.is_data)
        vm.dataForm = {
          file1: null,
          ...vm.is_data,
        }
      },
      simpan() {
        let vm = this;
        vm.busy = true;
        vm.button = "Mohon Tunggu";
        const formData = new FormData()
        if(vm.dataForm.file1) formData.append('file1', vm.dataForm.file1)
        formData.append('deskripsi', vm.dataForm.deskripsi)
        formData.append('kunjungan_id', vm.kunjungan.kunjungan_id)
        formData.append('id', vm.dataForm.galeri_foto_kunjungan_id)
        vm.$axios
          .post(`/galeri_foto_kunjungan/update`, formData)
          .then((res) => {
            if (res.data.message == "sukses") {
              vm.button = "Simpan";
              vm.busy = false;
              vm.$v.$reset();
              vm.$emit("alertFromChild", {
                variant: "success",
                msg: "BERHASIL MENGUBAH GALERIA KUNJUNGAN",
                showing: true,
              });
              this.$bvModal.hide("modal-edit-galeria");
              this.resetModal();
            } else {
              vm.button = "Simpan";
              vm.busy = false;
              vm.$emit("alertFromChild", {
                variant: "danger",
                msg: _.toUpper(res.data.message),
                showing: true,
              });
            }
          })
          .catch((err) => {
            console.log(err);
            vm.button = "Simpan";
            vm.busy = false;
            vm.$emit("alertFromChild", {
              variant: "danger",
              msg: "TERJADI KESALAHAN PADA SERVER",
              showing: true,
            });
          });
      },
      async hapus(){
        const vm = this
        try {
          const res = await vm.$axios.post('/galeri_foto_kunjungan/delete', {id: vm.dataForm.galeri_foto_kunjungan_id})
          if(res.data.status == 200){
            vm.$emit("alertFromChild", {
              variant: "success",
              msg: "BERHASIL MENGUBAH GALERIA KUNJUNGAN",
              showing: true,
            });
            this.$bvModal.hide("modal-edit-galeria");
            this.resetModal();
          }
        } catch (error) {
          console.log('error', error)
        }
      },
      resetModal() {
        this.$v.$reset();
        this.dataForm = {
          file1: null,
          deskripsi: null,
        };
      },
    },
  };
</script>
  