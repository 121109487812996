<template>
  <div>
    <b-modal
      id="modal-kunjungan-obat-resep"
      size="lg"
      centered
      title="Input Resep"
      header-bg-variant="danger"
      header-text-variant="light"
      @show="showModal()"
    >
      <div class="mx-2">
        <div class="">
          <b-card no-body class="p-2">
            <h5>ALERGI PASIEN</h5>
            <b-form-group v-if="listAlergiPasien.length" v-slot="{ ariaDescribedby }">
                <b-form-checkbox 
                  v-for="(x, key) of listAlergiPasien" 
                  :key="key"
                  v-model="x.ms_alergi_id"
                  :value="x.ms_alergi_id"
                  :aria-describedby="ariaDescribedby"
                  disabled
                  inline
                ><div style="margin-top: 2.5px">{{ x.nama_alergi }}</div></b-form-checkbox>
            </b-form-group>
            <p v-else class="mb-0">
              <i>Pasien tidak memiliki alergi</i>
            </p>
          </b-card>
        </div>
        <div class="mb-3" v-if="dataFormHead.head_racikan_id !== true">
          <b-form-group>
            <b-form-checkbox
              v-model="is_racikan"
              :value="true"
              :unchecked-value="false"
              class="mr-n2"
              @change="resetHead()"
            ><div style="margin-top: 2.5px">Dengan Racikan</div></b-form-checkbox>
          </b-form-group>
        </div>
        <div v-if="is_racikan" class="">
          <h5 class="custom-title-card"><strong>HEAD RESEP</strong></h5>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Racikan <span class="text-danger">*</span>
            </template>
            <b-form-input
              id="no_racikan"
              type="text"
              v-model="$v.dataFormHead.no_racikan.$model"
              :state="checkIfValid('dataFormHead', 'no_racikan')"
            ></b-form-input>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Jumlah <span class="text-danger">*</span>
            </template>
            <b-form-input
              id="jumlah_bungkus"
              type="text"
              v-model="$v.dataFormHead.jumlah_bungkus.$model"
              :state="checkIfValid('dataFormHead', 'jumlah_bungkus')"
            ></b-form-input>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Indikasi Head 
            </template>
            <b-form-textarea
              id="indikasi_head_racikan"
              v-model="dataFormHead.indikasi_head_racikan"
              :state="checkIfValid('dataFormHead', 'indikasi_head_racikan')"
            ></b-form-textarea>
          </b-form-group>
          <div class="d-flex justify-content-end" v-if="isUpdate">
            <b-button variant="warning" @click="update()" :disabled="busy || !isValidHead">
              Update
            </b-button>
          </div>
          <hr>
        </div>
        <div class="">
          <h5 class="custom-title-card"><strong>SUB RESEP</strong></h5>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Komposisi
            </template>
            <Multiselect
              id="komposisi"
              v-model="dataFormSub.komposisi"
              :options="listKomposisi"
              :multiple="false"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              label="nama_komposisi"
              placeholder="-- Pilih Komposisi --"
            ></Multiselect>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Obat <span class="text-danger">*</span>
            </template>
            <Multiselect
              id="ms_barang_id"
              v-model="dataFormSub.ms_barang_id"
              :options="filteredOptions(dataFormSub.komposisi)"
              :multiple="false"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              label="nama"
              track-by="ms_barang_id"
              placeholder="-- Pilih Obat --"
              :state="checkIfValid('dataFormSub', 'ms_barang_id')"
              @select="dataFormSub.harga_satuan_racikan = dataFormSub.ms_barang_id.harga_jual"
              @remove="dataFormSub.harga_satuan_racikan = null"
            ></Multiselect>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Harga Obat <span class="text-danger">*</span>
            </template>
            <b-form-input
              id="harga_satuan_racikan"
              type="number"
              v-model="dataFormSub.harga_satuan_racikan"
              :state="checkIfValid('dataFormSub', 'harga_satuan_racikan')"
              disabled
            ></b-form-input>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Aturan Pakai <span class="text-danger">*</span>
            </template>
            <Multiselect
              id="aturan_pakai_racikan"
              v-model="dataFormSub.aturan_pakai_racikan"
              :options="$store.state.data_static.aturan_pakai"
              :multiple="false"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              placeholder="-- Pilih Aturan Pakai --"
              :state="checkIfValid('dataFormSub', 'aturan_pakai_racikan')"
            ></Multiselect>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Signa <span class="text-danger">*</span>
            </template>
            <Multiselect
              id="signa_racikan"
              v-model="dataFormSub.signa_racikan"
              :options="$store.state.data_static.signa"
              :multiple="false"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              placeholder="-- Pilih Signa --"
              :state="checkIfValid('dataFormSub', 'signa_racikan')"
            ></Multiselect>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Jumlah Permintaan <span class="text-danger">*</span>
            </template>
            <b-form-input
              id="qty_permintaan"
              type="number"
              v-model="dataFormSub.qty_permintaan"
              :state="checkIfValid('dataFormSub', 'qty_permintaan')"
              @input="dataFormSub.qty_barang = dataFormSub.qty_permintaan"
            ></b-form-input>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Jumlah Obat yang diberikan <span class="text-danger">*</span>
            </template>
            <b-form-input
              id="qty_barang"
              type="number"
              v-model="dataFormSub.qty_barang"
              :state="checkIfValid('dataFormSub', 'qty_barang')"
              disabled
            ></b-form-input>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Indikasi Sub 
            </template>
            <b-form-textarea
              id="indikasi"
              v-model="dataFormSub.indikasi"
              :state="checkIfValid('dataFormSub', 'indikasi')"
            ></b-form-textarea>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Keterangan 
            </template>
            <b-form-textarea
              id="keterangan_racikan"
              v-model="dataFormSub.keterangan_racikan"
              :state="checkIfValid('dataFormSub', 'keterangan_racikan')"
            ></b-form-textarea>
          </b-form-group>
          <div class="mt-2 d-flex justify-content-end">
            <b-button
              :disabled="busy || !isValidSub"
              @click="tambahSub()"
              variant="primary"
            >Tambah</b-button>
          </div>
        </div>
        <!-- <pre>{{dataFormSub.ms_barang_id}}</pre> -->
        <div class="mt-3">
          <b-table
            :items="listSubResep"
            :fields="fieldSubObat"
            responsive
            show-empty
            small
            bordered
            striped
            hover
            :busy="busy"
          >
            <template #cell(no)="item">{{item.index+1}}</template>
            <template #cell(actions)="item">
              <div class="d-flex nowrap">
                <b-button
                  id="detail-obat"
                  variant="info"
                  size="sm"
                  class="ml-1"
                  @click="item.toggleDetails"
                  v-c-tooltip.hover.click="'Detail Sub Obat'"
                >
                  <CIcon name="cil-info" />
                </b-button>
                <b-button
                  id="delete-obat"
                  variant="danger"
                  size="sm"
                  class="ml-1"
                  @click="deleteSub(item.index)"
                  v-c-tooltip.hover.click="'Delete Sub Obat'"
                >
                  <CIcon name="cil-trash" />
                </b-button>
              </div>
            </template>
            <template #row-details="row">
              <b-card class="m-0">
                <b-row class="m-0 p-0" style="row-gap: 1rem;">
                  <b-col cols="6">
                    <div>
                      <h6><strong>Indikasi</strong></h6>
                      <p>{{row.item.indikasi || '-'}}</p>
                    </div>
                    <div>
                      <h6><strong>Keterangan</strong></h6>
                      <p>{{row.item.keterangan_racikan || '-'}}</p>
                    </div>
                  </b-col>
                  <b-col cols="6">
                    <div class="" v-for="(value, key) in row.item.item_obat_jual" :key="key">
                      <b-card class="m-0">
                        <b-row class="m-0 p-0">
                          <b-col cols="4"><h6 class="m-0 p-0">Kode Batch</h6></b-col>
                          <b-col cols="8"><h6 class="m-0 p-0">: {{value.kode_batch}}</h6></b-col>
                          <b-col cols="4"><h6 class="m-0 p-0">Jumlah</h6></b-col>
                          <b-col cols="8"><h6 class="m-0 p-0">: {{value.qty_item_obat_jual}}</h6></b-col>
                          <b-col cols="4"><h6 class="m-0 p-0">Kadaluarsa</h6></b-col>
                          <b-col cols="8"><h6 class="m-0 p-0">: {{$moment(value.tanggal_kadaluarsa).format("LL")}}</h6></b-col>
                        </b-row>
                      </b-card>
                    </div>
                  </b-col>
                </b-row>
              </b-card>
            </template>
          </b-table>
        </div>
      </div>
      <!-- <pre>{{listSubResep}}</pre> -->
      <!-- <p>Apakah anda yakin akan menghapus data ini ?</p> -->
      <!-- <pre>{{dataResep}}</pre> -->
      <template #modal-footer>
        <b-button variant="danger" @click="resetSub(), resetHead(), listSubResep = [], is_data_detail = {}">
          reset
        </b-button>
        <b-button v-if="!isUpdate" variant="primary" @click="simpan()" :disabled="busy">
          {{ button }}
        </b-button>
        <b-button
          v-if="isUpdate"
          variant="danger"
          class="mr-1"
          v-c-tooltip.hover.click="'Delete Resep'"
          @click="$bvModal.show('modal-delete-head-resep')"
        >Delete Resep</b-button>
        <b-button variant="secondary" @click="$bvModal.hide('modal-kunjungan-obat-resep')">
          close
        </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import { validationMixin } from "vuelidate";
import { required, numeric, minValue } from "vuelidate/lib/validators";
export default {
  name: "modalDelete",
  props: [
    'kunjungan',
    "dataResep", 
    "dataSubObat", 
    'listBarang', 
    'listKomposisi', 
    'listAlergiPasien',
    'itemsObat',
    'itemsRekonsiliasi',
    'is_data_detail', 
  ],
  data() {
    return {
      busy: false,
      button: "Simpan",
      listSubResep: [],
      fieldSubObat: [
        {
          key: "no",
          label: "No",
          sortDirection: "desc",
          sortable: true,
          class: "table-number text-center",
        },
        {
          key: "nama_barang",
          label: "Obat",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "qty_barang",
          label: "Diberikan",
          sortable: true,
          sortDirection: "desc",
          class: "text-right",
        },
        {
          key: "qty_permintaan",
          label: "Permintaan",
          sortable: true,
          sortDirection: "desc",
          class: "text-right",
        },
        {
          key: "signa_racikan",
          label: "Signa",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "aturan_pakai_racikan",
          label: "Aturan Pakai",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "harga_satuan_racikan",
          label: "Harga",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "actions",
          label: "Actions",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
      ],
      is_racikan: false,
      dataFormHead: {
        no_racikan: null,
        indikasi_head_racikan: null,
        jumlah_bungkus: null,
        resep_id: null,
      },
      dataFormSub: {
        qty_barang: null,
        signa_racikan: null,
        aturan_pakai_racikan: null,
        qty_permintaan: null,
        indikasi: null,
        keterangan_racikan: null,
        tanggal_kadaluarsa_racikan: null,
        harga_satuan_racikan: null,
        resep_id: null,
        ms_barang_id: null,
        head_racikan_id: null,
        komposisi: null,
      },
    };
  },
  computed: {
    isValidHead() {
      return !this.$v.dataFormHead.$invalid;
    },
    isDirtyHead() {
      return this.$v.dataFormHead.$anyDirty;
    },
    isValidSub() {
      return !this.$v.dataFormSub.$invalid;
    },
    isDirtySub() {
      return this.$v.dataFormSub.$anyDirty;
    },
    isUpdate() {
      return this.is_data_detail.head_racikan_id ? true : false;
    },
  },
  mixins: [validationMixin],
  validations: {
    dataFormHead: {
      no_racikan: { required },
      indikasi_head_racikan: {  },
      jumlah_bungkus: { required, numeric, minValue: minValue(0) },
      // resep_id: { required },
    },
    dataFormSub: {
      qty_barang: { required, numeric, minValue: minValue(0) },
      signa_racikan: { required },
      aturan_pakai_racikan: { required },
      qty_permintaan: { required, numeric, minValue: minValue(0) },
      indikasi: {  },
      keterangan_racikan: {  },
      tanggal_kadaluarsa_racikan: {  },
      harga_satuan_racikan: { required, numeric, minValue: minValue(0) },
      // resep_id: { required },
      ms_barang_id: { required },
      // head_racikan_id: { required },
    },
  },
  methods: {
    onRacikan(){
      const vm = this
      // console.log(vm.is_racikan)
      vm.resetHead()
    },
    async showModal(){
      const vm = this
      vm.resetSub()
      vm.resetHead()
      vm.listSubResep = []
      vm.is_racikan = true
      // console.log('dataResep', vm.dataResep)
      // console.log('dataSubObat', vm.dataSubObat)
      // console.log('is_data_detail', vm.is_data_detail)
      vm.dataFormHead.resep_id = vm.dataResep.resep_id
      vm.dataFormSub.resep_id = vm.dataResep.resep_id
      await new Promise(resolve => setTimeout(resolve, 100));
      if(vm.is_data_detail.head_racikan_id){
        const x = vm.is_data_detail
        vm.listSubResep = x.racikan
        vm.dataFormHead = {...x, racikan: undefined}
        vm.is_racikan = vm.dataFormHead.no_racikan ? true : false
      }else {
        vm.is_racikan = false
      }
    },
    filteredOptions(komposisi) {
      const vm = this
      // console.log('vm.listBarang', vm.listBarang)
      let x = vm.listBarang.filter(o => o.nama_jenis_barang == 'OBAT')
      if (komposisi) {
        return x.filter(
          (opt) => opt.pool_komposisi.includes(komposisi.nama_komposisi)
        );
      } else {
        return x;
      }
    },
    // async getBarang(){
    //   const vm = this
    //   vm.busy = true
    //   try {
    //     let listBarang = await vm.$axios.post("/stock/list_stock_by_komposisi", {
    //       ms_gudang_id: vm.dataResep.ms_gudang_id.ms_gudang_id,
    //       komposisi: vm.dataFormSub.komposisi ? vm.dataFormSub.komposisi.komposisi : null,
    //       nama_jenis_barang: 'OBAT',
    //     })
    //     vm.listBarang = listBarang.data.status == 200 ? listBarang.data.data.map(x => { return {
    //       ...x,
    //       nama: x.kode_produk + ' - ' + x.nama_barang + ' - ' + vm.$numberFormat(x.qty_stock)
    //     }}) : []
    //   } catch (err) {
    //     if (err.message) {
    //       vm.$emit("alertFromChild", { variant: "danger", msg: err.message.toUpperCase(), showing: true });
    //     } else {
    //       vm.$emit("alertFromChild", { variant: "danger", msg: err.toUpperCase(), showing: true });
    //     }
    //   } finally {
    //     vm.busy = false;
    //   }
    // },
    async tambahSub(){
      const vm = this
      // console.log(vm.dataFormSub)
      vm.busy = true
      try {
        await this.$v.dataFormSub.$touch()
        if (vm.isValidSub && vm.isDirtySub) {
          let i = 0
          for (i = 0; i < vm.listSubResep.length; i++) {
            const x = vm.listSubResep[i];
            if(x.ms_barang_id == vm.dataFormSub.ms_barang_id.ms_barang_id) break
          }
          if(i != vm.listSubResep.length){
            vm.$emit("alertFromChild", { variant: "danger", msg: 'Barang Sudah Ada Di Sub Resep', showing: true });
          }else{
            if(vm.dataFormHead.head_racikan_id || vm.dataFormHead.head_racikan_id === true){ // jika sudah register ke database
                if(vm.dataFormHead.head_racikan_id !== true) vm.dataFormSub.head_racikan_id = vm.dataFormHead.head_racikan_id
              let res = await vm.$axios.post('/racikan/register_racikan', {
                ...vm.dataFormSub, 
                ms_barang_id: vm.dataFormSub.ms_barang_id.ms_barang_id,
                resep_id: vm.dataResep.id,
              })
              // console.log('racikan', res)
              if(res.data.status == 200){
                vm.$emit("alertFromChild", { variant: "success", msg: 'Berhasil Menambah Sub Resep', showing: true });
                vm.listSubResep.push({
                  ...vm.dataFormSub,
                  ms_barang_id: vm.dataFormSub.ms_barang_id.ms_barang_id,
                  nama_barang: vm.dataFormSub.ms_barang_id.nama_barang,
                  nama_tanggal_kadaluarsa_racikan: vm.$moment(vm.dataFormSub.tanggal_kadaluarsa_racikan).format('LL'),
                  resep_id: vm.dataResep.id,
                })
              }else{
                vm.$emit("alertFromChild", { variant: "danger", msg: 'Gagal Menambah Sub Resep', showing: true });
              }
            }else{ // jika belum register ke database
              vm.listSubResep.push({
                ...vm.dataFormSub,
                ms_barang_id: vm.dataFormSub.ms_barang_id.ms_barang_id,
                nama_barang: vm.dataFormSub.ms_barang_id.nama_barang,
                nama_tanggal_kadaluarsa_racikan: vm.$moment(vm.dataFormSub.tanggal_kadaluarsa_racikan).format('LL'),
                resep_id: vm.dataResep.id,
              })
            }
          }
          vm.resetSub()
        }else{
          vm.$emit("alertFromChild", { variant: "warning", msg: 'Data Tidak Valid', showing: true });
        }
      } catch (error) {
        console.log(error)
        if (error.message) {
          vm.$emit("alertFromChild", { variant: "danger", msg: error.message.toUpperCase(), showing: true });
        } else {
          vm.$emit("alertFromChild", { variant: "danger", msg: error.toUpperCase(), showing: true });
        }
      } finally {
        vm.busy = false
      }
    },
    async deleteSub(idx){
      const vm = this
      // console.log(vm.dataFormSub)
      vm.busy = true
      try {
        if(vm.dataFormHead.head_racikan_id){ // jika sudah register ke database
          let res = await vm.$axios.post('/racikan/delete', {
            id: vm.listSubResep[idx].racikan_id
          })
          // console.log('racikan', res)
          if(res.data.status == 200){
            vm.$emit("alertFromChild", { variant: "success", msg: 'Berhasil Menghapus Sub Resep', showing: true });
            vm.listSubResep.splice(idx, 1)
          }else{
            vm.$emit("alertFromChild", { variant: "danger", msg: 'Gagal Menghapus Sub Resep', showing: true });
          }
        }else{ // jika belum register ke database
          vm.listSubResep.splice(idx, 1)
        }
      } catch (error) {
        console.log(error)
        if (error.message) {
          vm.$emit("alertFromChild", { variant: "danger", msg: error.message.toUpperCase(), showing: true });
        } else {
          vm.$emit("alertFromChild", { variant: "danger", msg: error.toUpperCase(), showing: true });
        }
      } finally {
        vm.busy = false
      }
    },
    async simpan(){
      const vm = this
      vm.busy = true
      try {
        await this.$v.dataFormHead.$touch();
        // console.log('dataFormHead', vm.dataFormHead)
        if (!vm.is_racikan || (vm.is_racikan && vm.isValidHead && vm.isDirtyHead)) {
          if(vm.listSubResep.length >= 1){
            const data = { resep_id: vm.dataResep.id }
            if(vm.is_racikan){ //jika menggunakan racikan
              data.bulk_racikan = [
                {
                  ...vm.dataFormHead,
                  data_racikan: vm.listSubResep
                }
              ]
            }else{ //tanpa racikan
              data.bulk_racikan = vm.listSubResep
            }
            let res = await vm.$axios.post('/racikan/register_bulk', data)
            // console.log('racikan', res)
            if(res.data.status == 200){
              vm.$emit("alertFromChild", { variant: "success", msg: 'Berhasil Membuat Resep', showing: true });
              vm.resetSub()
              vm.resetHead()
              vm.$bvModal.hide('modal-kunjungan-obat-resep')
            }else{
              vm.$emit("alertFromChild", { variant: "danger", msg: 'Gagal Membuat Resep', showing: true });
            }
          }else{
            vm.$emit("alertFromChild", { variant: "warning", msg: 'Harus Menginputkan Resep', showing: true });
          }
        }else{
          vm.$emit("alertFromChild", { variant: "warning", msg: 'Data Tidak Valid', showing: true });
        }
      } catch (error) {
        console.log(error)
        if (error.message) {
          vm.$emit("alertFromChild", { variant: "danger", msg: error.message.toUpperCase(), showing: true });
        } else {
          vm.$emit("alertFromChild", { variant: "danger", msg: error.toUpperCase(), showing: true });
        }
      } finally {
        vm.busy = false
      }
    },
    async update(){
      const vm = this
      vm.busy = true
      try {
        await this.$v.dataFormHead.$touch();
        if (vm.isValidHead && vm.isDirtyHead) {
          let res = await vm.$axios.post('/head_racikan/update', {...vm.dataFormHead, id: vm.dataFormHead.head_racikan_id})
          // console.log('head_racikan', res)
          if(res.data.status == 200){
            vm.$emit("alertFromChild", { variant: "success", msg: 'Berhasil Update Head Obat', showing: true });
          }else{
            vm.$emit("alertFromChild", { variant: "danger", msg: 'Gagal Update Head Obat', showing: true });
          }
        }else{
          vm.$emit("alertFromChild", { variant: "warning", msg: 'Data Tidak Valid', showing: true });
        }
      } catch (error) {
        console.log(error)
        if (error.message) {
          vm.$emit("alertFromChild", { variant: "danger", msg: error.message.toUpperCase(), showing: true });
        } else {
          vm.$emit("alertFromChild", { variant: "danger", msg: error.toUpperCase(), showing: true });
        }
      } finally {
        vm.busy = false
      }
    },
    resetSub(){
      const vm = this
      vm.dataFormSub = {
        qty_barang: null,
        signa_racikan: null,
        aturan_pakai_racikan: null,
        qty_permintaan: null,
        indikasi: null,
        keterangan_racikan: null,
        tanggal_kadaluarsa_racikan: null,
        harga_satuan_racikan: null,
        resep_id: null,
        ms_barang_id: null,
        head_racikan_id: null,
        komposisi: null,
      }
      vm.$v.dataFormSub.$reset()
    },
    resetHead(){
      const vm = this
      vm.dataFormHead = {
        no_racikan: null,
        indikasi_head_racikan: null,
        jumlah_bungkus: null,
        resep_id: null,
      }
      vm.$v.dataFormHead.$reset()
    },
    checkIfValid(name, fieldName) {
      const field = this.$v[name][fieldName];
      // console.log(name, fieldName, field)
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
  },
};
</script>
