<template>
  <div>
    <b-modal
      id="modal-input-galeria"
      size="lg"
      centered
      :title="'Tambah Galeria Kunjungan'"
      header-bg-variant="primary"
      header-text-variant="light"
      @hidden="resetModal"
    >
      <b-form>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Upload File <span class="text-danger">*</span>
          </template>
          <b-form-file
            v-model="dataForm.file1"
            :state="checkIfValid('file1')"
            placeholder="Choose a file or drop it here..."
            drop-placeholder="Drop file here..."
          ></b-form-file>
        </b-form-group>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Deskripsi <span class="text-danger">*</span>
          </template>
          <b-form-textarea
            id="deskripsi"
            v-model="dataForm.deskripsi"
            :state="checkIfValid('deskripsi')"
          ></b-form-textarea>
        </b-form-group>
      </b-form>
      <template #modal-footer>
        <b-button variant="secondary" @click="$bvModal.hide('modal-input-galeria')">
          Batal
        </b-button>
        <b-button
          variant="primary"
          :disabled="busy || !isValid"
          @click="simpan()"
          >{{ button }}</b-button
        >
      </template>
    </b-modal>
  </div>
</template>
  
<script>
  import _ from "lodash"
  import { validationMixin } from "vuelidate";
  import { required } from "vuelidate/lib/validators";
  
  export default {
    name: "modalInput",
    props: [
      'kunjungan',
    ],
    data() {
      return {
        dataForm: {
          file1: null,
          deskripsi: null,
        },
        busy: false,
        button: "Simpan",
      };
    },
    computed: {
      formString() {
        return JSON.stringify(this.dataForm, null, 4);
      },
      isValid() {
        return !this.$v.dataForm.$invalid;
      },
      isDirty() {
        return this.$v.dataForm.$anyDirty;
      },
    },
    mixins: [validationMixin],
    validations: {
      dataForm: {
        file1: { required },
        deskripsi: { required },
      },
    },
    methods: {
      checkIfValid(fieldName) {
        const field = this.$v.dataForm[fieldName];
        if (!field.$dirty) {
          return null;
        }
        return !(field.$invalid || field.$model === "");
      },
      simpan() {
        let vm = this;
        vm.busy = true;
        vm.button = "Mohon Tunggu";
        const formData = new FormData()
        formData.append('file1', vm.dataForm.file1)
        formData.append('deskripsi', vm.dataForm.deskripsi)
        formData.append('kunjungan_id', vm.kunjungan.kunjungan_id)
        vm.$axios
          .post(`/galeri_foto_kunjungan/register`, formData)
          .then((res) => {
            if (res.data.message == "sukses") {
              vm.button = "Simpan";
              vm.busy = false;
              vm.$v.$reset();
              vm.$emit("alertFromChild", {
                variant: "success",
                msg: "BERHASIL MENDAFTARKAN GALERIA KUNJUNGAN",
                showing: true,
              });
              this.$bvModal.hide("modal-input-galeria");
              this.resetModal();
            } else {
              vm.button = "Simpan";
              vm.busy = false;
              vm.$emit("alertFromChild", {
                variant: "danger",
                msg: _.toUpper(res.data.message),
                showing: true,
              });
            }
          })
          .catch((err) => {
            console.log(err);
            vm.button = "Simpan";
            vm.busy = false;
            vm.$emit("alertFromChild", {
              variant: "danger",
              msg: "TERJADI KESALAHAN PADA SERVER",
              showing: true,
            });
          });
      },
      resetModal() {
        this.$v.$reset();
        this.dataForm = {
          file1: null,
          deskripsi: null,
        };
      },
    },
  };
</script>
  