<template>
  <div id="page-antenatal">
    <b-row style="margin-top: 15px;">
      <b-col cols="12" sm="12" md="12" lg="12" xl="12">
        <div style="width:100%;display: flex;justify-content: flex-end;">
          <!-- <b-button variant="success" class="mr-2">Buat Baru Kehamilan</b-button> -->
          <b-button variant="success">Cetak Kartu Ibu</b-button>
        </div>
      </b-col>
      <b-col cols="12" sm="12" md="12" lg="12" xl="12">
        <CTabs variant="tabs" :fill="true" :active-tab="0">
          <CTab title="ANTENATAL">
            <Antenatal :kunjungan="kunjungan" :dataAnamnesa="dataAnamnesa" @disabled="set_disable($event)" @register_antenatal="set_register($event)"/>
          </CTab>
          <CTab title="KSPR" :disabled="disable_kspr">
            <kspr :kunjungan="kunjungan" :dataAnamnesa="dataAnamnesa" :register_antenatal="register_antenatal"/>
          </CTab>
        </CTabs>
      </b-col>
    </b-row>
    
  </div>
</template>
<script>
import Antenatal from "../kesehatan_ibu_anak/antenatal/antenatal.vue";
import Kspr from "../kesehatan_ibu_anak/antenatal/kspr.vue";
export default {
  components:{
   Antenatal,
   Kspr
  },
  props: [
  'dataAnamnesa',
  'kunjungan',
  ],
  data() {
    return {
      disable_kspr: true,
      register_antenatal: null,
    }
  },
 methods: {
  set_disable(event){
    let vm = this
    vm.disable_kspr = event
  },
  set_register(event){
    let vm = this
    vm.register_antenatal = event
  },
 },
}
</script>

<style scoped>
.random-card .card{
  background-color: #FAFAFA;
  border:1px solid #D3D3D3
}


</style>