<template>
  <b-card class="mt-2">
    <b-row>
      <b-col cols="12">
        <b-row class="mx-0 mb-3">
          <b-col cols="12">
            <label for="ms_diagnosa_id">Diagnosa <span class="text-danger">*</span></label>
            <!-- <Multiselect
              id="ms_diagnosa_id"
              v-model="$v.dataForm.ms_diagnosa_id.$model"
              :options="listDiagnosa "
              :multiple="false"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              label="nama_diagnosa"
              track-by="ms_diagnosa_id"
              placeholder="-- Pilih Diagnosa --"
              size="sm"
            ></Multiselect> -->
            <Multiselect
              id="ms_diagnosa_id"
              v-model="$v.dataForm.ms_diagnosa_id.$model"
              @search-change="getDiagnosa"
              :options="listDiagnosa"
              :multiple="false"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              label="nama_diagnosa"
              track-by="ms_diagnosa_id"
              placeholder="-- Pilih Diagnosa --"
              size="sm"
            ></Multiselect>
            <!-- <div class="text-right"> -->
              <small class="text-danger">ketikkan kode atau nama diagnosa untuk mencari</small>
            <!-- </div> -->
          </b-col>
          <b-col cols="6">
            <label for="ms_dokter_id">Dokter <span class="text-danger">*</span></label>
            <Multiselect
              id="ms_dokter_id"
              v-model="$v.dataForm.ms_dokter_id.$model"
              :options="listDokter"
              :multiple="false"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              label="nama_tenaga_medis"
              track-by="tenaga_medis_id"
              placeholder="-- Pilih Dokter --"
              size="sm"
            ></Multiselect>
          </b-col>
          <b-col cols="6">
            <label for="ms_perawat_id">Perawat <span class="text-danger">*</span></label>
            <Multiselect
              id="ms_perawat_id"
              v-model="$v.dataForm.ms_perawat_id.$model"
              :options="listPerawat"
              :multiple="false"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              label="nama_tenaga_medis"
              track-by="tenaga_medis_id"
              placeholder="-- Pilih Perawat --"
              size="sm"
            ></Multiselect>
          </b-col>
          <b-col cols="6">
            <label for="kasus_diagnosa">Kasus <span class="text-danger">*</span></label>
            <Multiselect
              id="kasus_diagnosa"
              v-model="$v.dataForm.kasus_diagnosa.$model"
              :options="$store.state.data_static.kasus_diagnosa"
              :multiple="false"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              label="text"
              track-by="value"
              placeholder="-- Pilih Kasus --"
              size="sm"
            ></Multiselect>
          </b-col>
          <b-col cols="6">
            <label for="jenis_diagnosa">Jenis <span class="text-danger">*</span></label>
            <Multiselect
              id="jenis_diagnosa"
              v-model="$v.dataForm.jenis_diagnosa.$model"
              :options="$store.state.data_static.jenis_diagnosa"
              :multiple="false"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              label="text"
              track-by="value"
              placeholder="-- Pilih Jenis --"
              size="sm"
            ></Multiselect>
          </b-col>
          <b-col cols="12">
            <div class="d-flex justify-content-end">
              <b-button 
                class="mr-2"
                :disabled="busy"
                @click="reset()"
                variant="danger"
              >Reset</b-button>
              <b-button 
                v-if="dataForm.id"
                :disabled="busy"
                @click="update()"
                variant="warning"
              >Update</b-button>
              <b-button
                v-else
                :disabled="busy || kunjunganSelesai"
                @click="simpan()"
                variant="primary"
              >Simpan</b-button>
            </div>
          </b-col>
        </b-row>
        <hr>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" md="12" lg="12">
        <b-table
          :items="items"
          :fields="fields"
          responsive
          show-empty
          small
          bordered
          striped
          hover
          :busy="busy"
        >
          <template #cell(actions)="item">
            <div class="d-flex nowrap">
              <b-button
                variant="warning"
                size="sm"
                v-c-tooltip.hover.click="'Update Data'"
                @click="onUpdate(item.item)"
                ><CIcon name="cil-pencil" /> {{ item.actions }}</b-button
              >
              <b-button
                variant="danger"
                size="sm"
                class="ml-1"
                v-c-tooltip.hover.click="'Hapus Data'"
                v-b-modal.modal-delete-diagnosa
                :disabled="kunjunganSelesai"
                @click="is_data = item.item"
                ><CIcon name="cil-trash" /> {{ item.actions }}</b-button
              >
            </div>
          </template>
        </b-table>
      </b-col>
      <b-col cols="12">
        <hr class="mt-0">
        <div class="d-flex justify-content-end" v-if="items.length">
          <!-- kunjungan.is_diagnosa {{kunjungan.is_diagnosa}} -->
          <b-button
            v-if="!kunjungan.is_diagnosa"
            class="ml-3"
            :disabled="busy"
            @click="createSyncBpjs()"
            variant="success"
          ><img src="/img/logo-bpjs-1.svg" alt="" srcset="" style="height: 1.5rem;margin-right:5px;">Sync</b-button>
          <b-button
            v-else
            class="ml-3"
            :disabled="busy"
            @click="updateSyncBpjs()"
            variant="warning"
          ><img src="/img/logo-bpjs-1.svg" alt="" srcset="" style="height: 1.5rem;margin-right:5px;">Update Sync</b-button>
        </div>
      </b-col>
    </b-row>
    <!-- <pre>{{items}}</pre> -->
    <modal-delete
      :data_delete="is_data"
      @alertFromChild="triggerAlert($event), getDatas()"
    />
  </b-card>
</template>
<script>
import moment from 'moment';
moment.locale('id');
import ModalDelete from "./modalDelete.vue";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
export default {
  components:{
    ModalDelete,
  },
  props: [
    'kunjungan',
    'dataAnamnesa',
    // 'listDiagnosa',
    'listDokter',
    'listPerawat',
    'kunjunganSelesai',
  ],
  data() {
    return {
      dataForm: {
        kasus_diagnosa: null,
        jenis_diagnosa: null,
        ms_diagnosa_id: null,
        ms_dokter_id: null,
        ms_perawat_id: null,
      },
      is_data: {},
      items: [],
      listDiagnosa: [],
      fields: [
        {
          key: "no",
          label: "No",
          sortDirection: "desc",
          sortable: true,
          class: "table-number text-center",
        },
        {
          key: "nama_diagnosa",
          label: "Diagnosa",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "nama_dokter",
          label: "Dokter",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "nama_perawat",
          label: "Perawat",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "kasus_diagnosa",
          label: "Kasus",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "jenis_diagnosa",
          label: "Jenis",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "actions",
          label: "Actions",
          class: "table-option-2 text-center",
        },
      ],
      busy: false,
    }
  },
  async mounted() {
    const vm = this
    vm.getList()
    vm.getDatas()
  },
  computed: {
    isValid() {
      return !this.$v.dataForm.$invalid;
    },
    isDirty() {
      return this.$v.dataForm.$anyDirty;
    },
  },
  mixins: [validationMixin],
  validations: {
    dataForm: {
      kasus_diagnosa: { required },
      jenis_diagnosa: { required },
      ms_diagnosa_id: { required },
      ms_dokter_id: { required },
      ms_perawat_id: { required },
    }
  },
  watch: {
    "kunjungan.kunjungan_id"(newVal, oldVal){
      if(newVal != oldVal){
        this.getDatas()
      }
    },
    'dataAnamnesa': {
      handler(data) {
        // console.log('ubah dataAnamnesa', data)
        const vm = this
        vm.dataForm.ms_dokter_id = vm.$findKey(vm.listDokter, data.ms_dokter_id, true, 'tenaga_medis_id')
        vm.dataForm.ms_perawat_id = vm.$findKey(vm.listPerawat, data.ms_perawat_id, true, 'tenaga_medis_id')
        vm.getDatas()
      },
      deep: true
    },
  },
  methods: {
    async getDiagnosa(nama_diagnosa){
      const vm = this
      vm.busy = false
      try {
        // console.log('get data')
        //diagnosa
        console.log('listDiagnosa')
        const listDiagnosa = await vm.$axios.post('/ms_diagnosa/list', {
          nama_diagnosa,
          halaman: 0 + '', 
          jumlah : 100,
        })
        vm.listDiagnosa = listDiagnosa.data.status == 200 ? listDiagnosa.data.data.map(x => { 
          return {
            ...x,
            nama_diagnosa: `${x.kd_diag} - ${x.nm_diag}`,
          }
        }) : []
      } catch (error) {
        vm.triggerAlert({ variant: "danger", msg: 'Terjadi kesalahan system', showing: true });
        console.log(error)
      } finally {
        vm.busy = false
      }
    },
    async getDatas(){
      const vm = this
      vm.busy = true
      try {
        if(vm.kunjungan && vm.kunjungan.kunjungan_id){
          const items = await vm.$axios.post('/pool_diagnosa/list', {kunjungan_id: vm.kunjungan.kunjungan_id})
          // console.log('pool_diagnosa', items)
          vm.items = items.data.status == 200 ? items.data.data.map((x, i) => { return {
            ...x,
            no: i + 1,
          }}) : []
          vm.totalRows = vm.items.length
        }
      } catch (err) {
        if (err.message) {
          vm.triggerAlert({ variant: "danger", msg:err.message.toUpperCase(), showing: true });
        } else {
          vm.triggerAlert({ variant: "danger", msg:err.toUpperCase(), showing: true });
        }
      } finally {
        vm.busy = false;
      }
    },
    async getList(){
      const vm = this
      vm.busy = true
      try {
        // //dokter
        // let listDokter = await vm.$axios.post('/tenaga_medis/list', {nama_jenis_tenaga_medis: 'dokter'})
        // vm.listDokter = listDokter.data.status == 200 ? listDokter.data.data : []
      } catch (err) {
        if (err.message) {
          vm.triggerAlert({ variant: "danger", msg:err.message.toUpperCase(), showing: true });
        } else {
          vm.triggerAlert({ variant: "danger", msg:err.toUpperCase(), showing: true });
        }
      } finally {
        vm.busy = false;
      }
    },
    async createSyncBpjs(){
      const vm = this
      vm.busy = true
      try {
        let res = await vm.$axios.post('/kunjungan/sync_kunjungan', {
          kunjungan_id: vm.kunjungan.kunjungan_id,
          pendaftaran_id: vm.kunjungan.pendaftaran_id,
        })
        // console.log('register diagnosa', res)
        // vm.$emit("createSyncBpjs", true)
        if(res.data.status == 200){
          vm.triggerAlert({ variant: "success", msg: 'Berhasil Sync BPJS', showing: true });
          vm.$emit("createSyncBpjs", true)
        }else{
          let msg = ""
          for (let i = 0; i < res.data.data.response.length; i++) {
            let x = res.data.data.response[i];
            if(!msg.length){
              msg +=`${x.field} ${x.message}`
            }else {
              msg += `, ${x.field} ${x.message}`
            }
            
          }
          vm.triggerAlert({ variant: "danger", msg: msg, showing: true });
        }
      } catch (error) {
        console.log(error)
        if (error.message) {
          vm.triggerAlert({ variant: "danger", msg: error.message.toUpperCase(), showing: true });
        } else {
          vm.triggerAlert({ variant: "danger", msg: error.toUpperCase(), showing: true });
        }
      } finally {
        vm.busy = false
      }
    },
    async updateSyncBpjs(){
      const vm = this
      // vm.$emit("createSyncBpjs", true)
      vm.busy = true
      try {
        let res = await vm.$axios.post('/pool_diagnosa/update_sync_diagnosa', {
          kunjungan_id: vm.kunjungan.kunjungan_id,
          pendaftaran_id: vm.kunjungan.pendaftaran_id,
        })
        // console.log('register diagnosa', res)
        if(res.data.status == 200){
          vm.triggerAlert({ variant: "success", msg: 'Berhasil Update Sync Diagnosa BPJS', showing: true });
        }else{
          vm.triggerAlert({ variant: "danger", msg: 'Gagal Membuat Update Sync Diagnosa BPJS', showing: true });
        }
      } catch (error) {
        console.log(error)
        if (error.message) {
          vm.triggerAlert({ variant: "danger", msg: error.message.toUpperCase(), showing: true });
        } else {
          vm.triggerAlert({ variant: "danger", msg: error.toUpperCase(), showing: true });
        }
      } finally {
        vm.busy = false
      }
    },
    async simpan(){
      const vm = this
      vm.busy = true
      try {
        await this.$v.dataForm.$touch();
        if (vm.isValid && vm.isDirty) {
          let res = await vm.$axios.post('/pool_diagnosa/register', {
            kasus_diagnosa: vm.dataForm.kasus_diagnosa.value,
            jenis_diagnosa: vm.dataForm.jenis_diagnosa.value,
            ms_diagnosa_id: vm.dataForm.ms_diagnosa_id.ms_diagnosa_id,
            ms_dokter_id: vm.dataForm.ms_dokter_id.tenaga_medis_id,
            ms_perawat_id: vm.dataForm.ms_perawat_id.tenaga_medis_id,
            kunjungan_id: vm.kunjungan.kunjungan_id,
          })
          // console.log('register diagnosa', res)
          if(res.data.status == 200){
            vm.triggerAlert({ variant: "success", msg: 'Berhasil Membuat Diagnosa', showing: true });
            vm.reset()
            // vm.$emit("changeVar", {name: 'active_tab', value: 4})
          }else if(res.data.status == 204){
            vm.triggerAlert({
              variant: "danger",
              msg: `${res.data.data[0].field} ${res.data.data[0].message}`,
              showing: true,
            });
          }else{
            vm.triggerAlert({ variant: "danger", msg: 'Gagal Membuat Diagnosa', showing: true });
          }
        }else{
          vm.triggerAlert({ variant: "warning", msg: 'Data Tidak Valid', showing: true });
        }
      } catch (error) {
        console.log(error)
        if (error.message) {
          vm.triggerAlert({ variant: "danger", msg: error.message.toUpperCase(), showing: true });
        } else {
          vm.triggerAlert({ variant: "danger", msg: error.toUpperCase(), showing: true });
        }
      } finally {
        vm.busy = false
      }
    },
    onUpdate(data){
      const vm = this
      // console.log('data', data)
      vm.dataForm = {
        id: data.pool_diagnosa_id,
        kasus_diagnosa: vm.$findKey(vm.$store.state.data_static.kasus_diagnosa, data.kasus_diagnosa, true, 'value'),
        jenis_diagnosa: vm.$findKey(vm.$store.state.data_static.jenis_diagnosa, data.jenis_diagnosa, true, 'value'),
        ms_diagnosa_id: {nama_diagnosa: `${data.kd_diag} - ${data.nm_diag}`, ms_diagnosa_id: data.ms_diagnosa_id},
        // ms_diagnosa_id: vm.$findKey(vm.listDiagnosa, data.ms_diagnosa_id, true, 'ms_diagnosa_id'),
        ms_dokter_id: vm.$findKey(vm.listDokter, data.ms_dokter_id, true, 'tenaga_medis_id'),
        ms_perawat_id: vm.$findKey(vm.listPerawat, data.ms_perawat_id, true, 'tenaga_medis_id'),
      }
    },
    async update(){
      const vm = this
      vm.busy = true
      try {
        await this.$v.dataForm.$touch();
        if (vm.isValid && vm.isDirty) {
          let res = await vm.$axios.post('/pool_diagnosa/update', {
            ...vm.dataForm,
            kasus_diagnosa: vm.dataForm.kasus_diagnosa.value,
            jenis_diagnosa: vm.dataForm.jenis_diagnosa.value,
            ms_diagnosa_id: vm.dataForm.ms_diagnosa_id.ms_diagnosa_id,
            ms_dokter_id: vm.dataForm.ms_dokter_id.tenaga_medis_id,
            ms_perawat_id: vm.dataForm.ms_perawat_id.tenaga_medis_id,
            kunjungan_id: vm.kunjungan.kunjungan_id,
          })
          // console.log('update diagnosa', res)
          if(res.data.status == 200){
            vm.triggerAlert({ variant: "success", msg: 'Berhasil Mengubah Diagnosa', showing: true });
            vm.reset()
          }else{
            vm.triggerAlert({ variant: "danger", msg: 'Gagal Mengubah Diagnosa', showing: true });
          }
        }else{
          vm.triggerAlert({ variant: "warning", msg: 'Data Tidak Valid', showing: true });
        }
      } catch (error) {
        console.log(error)
        if (error.message) {
          vm.triggerAlert({ variant: "danger", msg: error.message.toUpperCase(), showing: true });
        } else {
          vm.triggerAlert({ variant: "danger", msg: error.toUpperCase(), showing: true });
        }
      } finally {
        vm.busy = false
      }
    },
    reset(){
      const vm = this
      vm.dataForm = {
        kasus_diagnosa: null,
        jenis_diagnosa: null,
        ms_diagnosa_id: null,
        ms_dokter_id: null,
        ms_perawat_id: null,
      }
      vm.dataForm.ms_dokter_id = vm.$findKey(vm.listDokter, vm.dataAnamnesa.ms_dokter_id, true, 'tenaga_medis_id')
      vm.dataForm.ms_perawat_id = vm.$findKey(vm.listPerawat, vm.dataAnamnesa.ms_perawat_id, true, 'tenaga_medis_id')
      vm.$v.$reset()
      vm.getDatas()
    },
    triggerAlert(event) {
      let vm = this;
      vm.$store.commit("set_alert", event);
    },
  },
}
</script>