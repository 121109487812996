<template>
  <div>
    <b-modal
      id="modal-detail-alkes"
      size="lg"
      centered
      title="Detail Sub Alkes"
      header-bg-variant="info"
      header-text-variant="light"
      @show="showModal()"
    >
      <div class="">
        <b-row class="m-0 p-0">
          <b-col cols="4"><h6 class="m-0 p-0">Barang</h6></b-col>
          <b-col cols="8"><h6 class="m-0 p-0">: {{data.nama_barang}}</h6></b-col>
          <b-col cols="4"><h6 class="m-0 p-0">Jumlah</h6></b-col>
          <b-col cols="8"><h6 class="m-0 p-0">: {{data.qty_alkes}}</h6></b-col>
          <b-col cols="4"><h6 class="m-0 p-0">Harga Satuan</h6></b-col>
          <b-col cols="8"><h6 class="m-0 p-0">: {{data.harga_satuan_alkes}}</h6></b-col>
          <b-col cols="4"><h6 class="m-0 p-0">Total Harga</h6></b-col>
          <b-col cols="8"><h6 class="m-0 p-0">: {{data.total_harga_item_alkes}}</h6></b-col>
          <b-col cols="4"><h6 class="m-0 p-0">Keterangan</h6></b-col>
          <b-col cols="8"><h6 class="m-0 p-0">: {{data.keterangan}}</h6></b-col>
        </b-row>
        <hr>
        <div class="mb-3">
          <b-table
            :items="data.batch"
            :fields="fields"
            responsive
            show-empty
            small
            bordered
            striped
            hover
            :busy="busy"
          >
            <template #cell(no)="item">
              {{item.index + 1}}
            </template>
          </b-table>
        </div>
      </div>
      <template #modal-footer>
        <b-button variant="secondary" @click="$bvModal.hide('modal-detail-alkes')">
          Kembali
        </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
export default {
  name: "modalDelete",
  props: ["data_detail"],
  data() {
    return {
      busy: false,
      button: "Hapus",
      data: {},
      fields: [
        {
          key: "no",
          label: "No",
          sortDirection: "desc",
          sortable: true,
          class: "table-number text-center",
        },
        {
          key: "kode_batch",
          label: "Kode Batch",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "kadarluasa",
          label: "Kadarluasa",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
      ],
    };
  },
  methods: {
    async showModal(){
      const vm = this
      // console.log('data_detail', vm.data_detail)
      const res = await vm.$axios.get('/sub_alkes_pasien/details_by_id/' + vm.data_detail.sub_alkes_pasien_id)
      if(res.data.status == 200 && res.data.data.length > 0){
        vm.data = res.data.data[0]
        vm.data.batch = [
          {
            kode_batch: '1',
            kadarluasa: vm.$moment().add('25', 'days').format('LL'),
          },
          {
            kode_batch: '2',
            kadarluasa: vm.$moment().add('75', 'days').format('LL'),
          },
          {
            kode_batch: '3',
            kadarluasa: vm.$moment().add('175', 'days').format('LL'),
          },
          {
            kode_batch: '4',
            kadarluasa: vm.$moment().add('275', 'days').format('LL'),
          },
        ]
      }
      // console.log('res', res)
    },
  },
};
</script>
