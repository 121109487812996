<template>
  <div>
    <b-card style="margin-bottom: 15px">
      <b-row>
        <b-col cols="12" md="12" lg="12" xl="12">
          <b-button-group>
            <b-button variant="primary">Data Riwayat</b-button>
          </b-button-group>
        </b-col>
      </b-row>

      <b-row class="mt-2">
        <b-col cols="12" md="12" lg="12" xl="12">
          <!-- <h6 style="color:#D52F65;font-size:12px;margin-top:15px;">Kunjungan 25 tahun terakhir</h6> -->
          <b-card 
            v-for="(item, key) of listKunjungan" 
            :key="key"
            class="p-0 mb-2"
            @click="pilihKunjungan(item)" 
          >
            <div style="width:100%;display: flex;">
              <div style="width:19%;background-color: ;">
                <CIcon name="cil-clipboard" style="width:35px;height:35px;"/>
              </div>
              <div style="width:75%">
                <h6 style="font-size:12px;font-weight:600;color:#202020;margin:2.5px 0 0 0 !important">{{item.nama_puskesmas}}</h6>
                <h6 style="font-size:10px;font-weight:600;color:#7C7C7C;margin:0 !important">{{item.nama_tanggal_daftar}} / {{item.nama_poli}}</h6>
              </div>
            </div>
          </b-card>

        </b-col>
        <b-col cols="12">
          <b-pagination class="w-100" v-model="currentPage" pills :total-rows="totalRows" :per-page="perPage"></b-pagination>
        </b-col>
      </b-row>
    </b-card>
    <ModalDetailKunjungan
      :id="'kunjungan-riwayat-kunjungan'" 
      :dataKunjungan="pilihDataKunjungan"
    />
  </div>
</template>

<script>
import ModalDetailKunjungan from '../../../pasien/rekam_medis/modal_detail_kunjungan/modal_detail_kunjungan.vue'
export default {
  components:{
    ModalDetailKunjungan
  },
  props: [
    'dataKunjungan',
    'dataPasien',
  ],
  data() {
    return {
      busy: false,
      pilihDataKunjungan: {},
      listKunjungan: [],
      totalRows: 0,
      currentPage: 1,
      perPage: 5,
    }
  },
  computed: {

  },
  watch: {
    'dataKunjungan': {
      handler(data) {
        // console.log('ubah dataKunjungan', data)
        data
        const vm = this
        vm.getData()
      },
      deep: true
    },
    'currentPage'(newVar, oldVar){
      if(newVar != oldVar){
        this.getData()
      }
    },
    'perPage'(newVar, oldVar){
      if(newVar != oldVar){
        this.getData()
      }
    },
  },
  async mounted() {
    const vm = this
    await vm.getData()
  },
  methods: {
    async pilihKunjungan(data){
      const vm = this
      vm.pilihDataKunjungan = data
      vm.$bvModal.show('modal-detail-kunjungan-kunjungan-riwayat-kunjungan')
    },
    async getData(){
      const vm = this
      vm.busy = false
      try {
        vm.listKunjungan = []
        if(vm.dataKunjungan.no_rm){
          let listKunjungan = await vm.$axios.post('/kunjungan/list', {
            no_rm: vm.dataKunjungan.no_rm,
            halaman: vm.currentPage - 1 + '', 
            jumlah : vm.perPage
          })
          // console.log('kunjungan', listKunjungan)
          if (listKunjungan.data.status == 200) {
            vm.listKunjungan = listKunjungan.data.data.map((x, idx) => {
              return {
                ...x,
                no: idx + 1 + (this.currentPage - 1) * this.perPage,
                nama_waktu_mulai: vm.$moment(x.waktu_mulai).format('LL'),
                nama_jenis_layanan: vm.$findKey(vm.$store.state.data_static.tkp, x.kd_tkp, false, 'kdTkp', 'text'),
                nama_tanggal_daftar: vm.$moment(x.tanggal_daftar).format('LLLL'),
                nama_asuransi: x.no_bpjs ? 'BPJS' : 'UMUM',
              }
            })
            vm.totalRows = listKunjungan.data.count
          }
        }
      } catch (error) {
        vm.triggerAlert({ variant: "danger", msg: 'Terjadi kesalahan system', showing: true });
        console.log(error)
      } finally {
        vm.busy = false
      }
    },
    triggerAlert(event) {
      let vm = this;
      vm.$store.commit("set_alert", event);
    },
  },

}
</script>