<template>
  <b-card class="mt-2">
    <CTabs variant="pills" :fill="true" :activeTab=activeTab>
      <CTab title="PENGAMATAN KEHAMILAN">
        <PengamatanKehamilan 
          :kunjungan="kunjungan" 
          :dataAnamnesa="dataAnamnesa" 
          :listDokter="listDokter" 
          :listPerawat="listPerawat" 
          :kunjunganSelesai="kunjunganSelesai"
          @disabled="set_disabled($event)"
        />
      </CTab>
      <CTab title="PEMERIKSAAN ANTENATAL" :disabled="disabled">
        <PemeriksaanAntenatal :kunjungan="kunjungan" :dataAnamnesa="dataAnamnesa" :kunjunganSelesai="kunjunganSelesai"/>
      </CTab>
      <CTab title="PENGAMATAN PERSALINAN" :disabled="disabled">
        <PengamatanPersalinan :kunjungan="kunjungan" :dataAnamnesa="dataAnamnesa" :listDokter="listDokter" :kunjunganSelesai="kunjunganSelesai"/>
      </CTab>

      <CTab title="PENGAMATAN NIFAS" :disabled="disabled">
        <PengamatanNifas :kunjungan="kunjungan" :dataAnamnesa="dataAnamnesa" :kunjunganSelesai="kunjunganSelesai"/>
      </CTab>
      <CTab title="PATOGRAF" :disabled="disabled">
        <Partograf :kunjungan="kunjungan" :dataAnamnesa="dataAnamnesa" :kunjunganSelesai="kunjunganSelesai"/>
      </CTab>
      <CTab title="SURAT KETERANGAN LAHIR" :disabled="disabled">
        <SuratKetLahir :kunjungan="kunjungan" :dataAnamnesa="dataAnamnesa" :kunjunganSelesai="kunjunganSelesai"/>
      </CTab>
    </CTabs>

    
  </b-card>
</template>
<script>

import PengamatanKehamilan from "../kesehatan_ibu_anak/pengamatan_kehamilan.vue";
import PemeriksaanAntenatal from "../kesehatan_ibu_anak/pemeriksaan_antenatal.vue";
import PengamatanPersalinan from "../kesehatan_ibu_anak/pengamatan_persalian.vue";
import PengamatanNifas from "../kesehatan_ibu_anak/pengamatan_nifas.vue";
import Partograf from "../kesehatan_ibu_anak/partograf.vue";
import SuratKetLahir from "../kesehatan_ibu_anak/surat_ket_lahir.vue";
export default {
  components:{
    PengamatanKehamilan,
    PemeriksaanAntenatal,
    PengamatanPersalinan,
    PengamatanNifas,
    Partograf,
    SuratKetLahir,
  },
  props: [
  'dataAnamnesa',
  'kunjungan',
  'listDokter',
  'listPerawat',
  'kunjunganSelesai',
  ],
  data() {
    return {
      data_kia: "",
      disabled: true,
      activeTab: 0,
    }
  },
  async mounted(){
    
  },
  computed: {
    reset(){
        return this.$store.state.reset
      },
  },
  watch: {
    reset(newVal, oldVal){
      if(newVal != oldVal){
        if(newVal == true){
          // this.disabled = true
          this.$store.commit("set_kia", null)
          this.$store.commit("set_usia_kehamilan", null)
          this.$store.commit("set_antenatal", null)
          this.$store.commit("set_partograf", null)
          this.activeTab = "0"
          // console.log(this.activeTab, 'activeeeeeeeeeeeee');
        }
      }
    },
  },
  methods: {
    set_disabled(event){
      let vm = this
      vm.disabled = event
    }
  },
}
</script>

<style scoped>

</style>