<template>
  <b-card>
    <b-row>
      <b-col cols="12">
        <b-row class="mx-0">
          <b-col cols="12" v-if="dataForm.no_resep_obat">
            <label for="no_resep_obat">No Resep <span class="text-danger">*</span></label>
            <b-form-input
              id="no_resep_obat"
              type="text"
              v-model="dataForm.no_resep_obat"
              disabled
            ></b-form-input>
          </b-col>
          <b-col cols="6">
            <label for="ms_gudang_id">Gudang <span class="text-danger">*</span></label>
            <Multiselect
              id="ms_gudang_id"
              v-model="$v.dataForm.ms_gudang_id.$model"
              :options="listGudang"
              :multiple="false"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              label="nama_gudang"
              placeholder="-- Pilih Gudang --"
              size="sm"
              @select="getBarang()"
              @remove="getBarang()"
            ></Multiselect>
          </b-col>
          <b-col cols="6">
            <label for="status_prioritas">Prioritas <span class="text-danger">*</span></label>
            <Multiselect
              id="status_prioritas"
              v-model="$v.dataForm.status_prioritas.$model"
              :options="listPrioritas"
              :multiple="false"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              label="text"
              placeholder="-- Pilih Prioritas --"
              size="sm"
            ></Multiselect>
          </b-col>
          <b-col cols="6">
            <label for="dokter_id">Dokter <span class="text-danger">*</span></label>
            <Multiselect
              id="dokter_id"
              v-model="$v.dataForm.dokter_id.$model"
              :options="listDokter"
              :multiple="false"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              label="nama_tenaga_medis"
              placeholder="-- Pilih Dokter --"
              size="sm"
            ></Multiselect>
          </b-col>
          <b-col cols="6">
            <label for="perawat_id">Perawat <span class="text-danger">*</span></label>
            <Multiselect
              id="perawat_id"
              v-model="$v.dataForm.perawat_id.$model"
              :options="listPerawat"
              :multiple="false"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              label="nama_tenaga_medis"
              placeholder="-- Pilih Perawat --"
              size="sm"
            ></Multiselect>
          </b-col>
          <b-col cols="12">
            <div class="d-flex justify-content-end">
              <b-button 
                v-if="dataForm.id"
                :disabled="busy"
                @click="update()"
                variant="warning"
              >Update</b-button>
              <b-button
                v-else
                :disabled="busy || kunjunganSelesai"
                @click="simpan()"
                variant="primary"
              >Simpan</b-button>
            </div>
          </b-col>
        </b-row>
      </b-col>      

      <b-col cols="12" v-show="dataForm.id">
        <hr>
        <b-button v-b-toggle.collapse-1 variant="success"><h5 class="m-0 p-0"><strong>REKONSILIASI</strong></h5></b-button>
        <b-collapse id="collapse-1" class="mt-2">
          <!-- <h5><strong>REKONSILIASI</strong></h5> -->
          <b-row>
            <b-col cols="12">
              <b-row class="m-0 mb-3 mt-3">
                <b-col cols="6">
                  <label for="nama_obat">Nama Obat <span class="text-danger">*</span></label>
                  <b-form-input
                    id="nama_obat"
                    type="text"
                    v-model="dataFormRekonsiliasi.nama_obat"
                  ></b-form-input>
                </b-col>
                <b-col cols="6">
                  <label for="signa_rekonsiliasi">Signa </label>
                  <Multiselect
                    id="signa_rekonsiliasi"
                    v-model="$v.dataFormRekonsiliasi.signa_rekonsiliasi.$model"
                    :options="$store.state.data_static.signa"
                    :multiple="false"
                    :searchable="true"
                    :close-on-select="true"
                    :show-labels="false"
                    placeholder="-- Pilih Signa --"
                    size="sm"
                  ></Multiselect>
                </b-col>
                <b-col cols="6">
                  <label for="frekuensi">Frekuensi </label>
                  <b-input-group>
                    <b-form-input
                      v-model="$v.dataFormRekonsiliasi.frekuensi.$model"
                      type="number"
                    ></b-form-input>
                    <b-input-group-append>
                      <b-button disabled>Jam</b-button>
                    </b-input-group-append>
                  </b-input-group>
                  <!-- <Multiselect
                    id="frekuensi"
                    v-model="$v.dataFormRekonsiliasi.frekuensi.$model"
                    :options="$store.state.data_static.frekuensi"
                    :multiple="false"
                    :searchable="true"
                    :close-on-select="true"
                    :show-labels="false"
                    placeholder="-- Pilih Frekuensi --"
                    size="sm"
                  ></Multiselect> -->
                </b-col>
                <b-col cols="6">
                  <label for="cara_pemberian">Cara Pemberian </label>
                  <b-form-input
                    id="cara_pemberian"
                    type="text"
                    v-model="dataFormRekonsiliasi.cara_pemberian"
                  ></b-form-input>
                </b-col>
                <b-col :cols="dataFormRekonsiliasi.status_pemberian_obat == 'Lanjut aturan pakai berubah' ? '6' : '12'">
                  <label for="status_pemberian_obat">Status Pemberian Obat <span class="text-danger">*</span></label>
                  <Multiselect
                    id="status_pemberian_obat"
                    v-model="$v.dataFormRekonsiliasi.status_pemberian_obat.$model"
                    :options="$store.state.data_static.status_pemberian_obat"
                    :multiple="false"
                    :searchable="true"
                    :close-on-select="true"
                    :show-labels="false"
                    placeholder="-- Pilih Status Pemberian Obat --"
                    size="sm"
                    @select="dataFormRekonsiliasi.perubahan_aturan_pakai = null"
                    @remove="dataFormRekonsiliasi.perubahan_aturan_pakai = null"
                  ></Multiselect>
                </b-col>
                <b-col cols="6" v-if="dataFormRekonsiliasi.status_pemberian_obat == 'Lanjut aturan pakai berubah'">
                  <label for="perubahan_aturan_pakai">Perubahan Aturan Pakai</label>
                  <b-form-input
                    id="perubahan_aturan_pakai"
                    type="text"
                    v-model="dataFormRekonsiliasi.perubahan_aturan_pakai"
                  ></b-form-input>
                </b-col>
                <b-col cols="12">
                  <label for="keterangan">Keterangan</label>
                  <b-form-textarea
                    id="keterangan"
                    v-model="dataFormRekonsiliasi.keterangan"
                  ></b-form-textarea>
                </b-col>
                <b-col cols="12">
                  <div class="d-flex justify-content-end">
                    <b-button
                      :disabled="busy || kunjunganSelesai"
                      @click="simpanRekonsiliasi()"
                      variant="primary"
                    >Tambah</b-button>
                  </div>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="12">
              <b-table
                :items="itemsRekonsiliasi"
                :fields="fieldsRekonsiliasi"
                responsive
                show-empty
                small
                bordered
                striped
                hover
                :busy="busy"
              >
                <template #cell(actions)="item">
                  <div class="d-flex nowrap">
                    <b-button
                      variant="info"
                      size="sm"
                      class="ml-1"
                      @click="item.toggleDetails"
                      v-c-tooltip.hover.click="'Detail Obat'"
                    >
                      <CIcon name="cil-info" />
                    </b-button>
                    <b-button
                      variant="danger"
                      size="sm"
                      class="ml-1"
                      v-c-tooltip.hover.click="'Hapus Data'"
                      v-b-modal.modal-delete-rekonsilisasi-resep
                      :disabled="kunjunganSelesai"
                      @click="is_data_rekonsilisasi = item.item"
                      ><CIcon name="cil-trash" /> {{ item.actions }}</b-button
                    >
                  </div>
                </template>
                <template #row-details="row">
                  <b-card class="m-0">
                    <b-row class="m-0 p-0">
                      <b-col cols="4"><h6 class="m-0 p-0">Obat</h6></b-col>
                      <b-col cols="8"><h6 class="m-0 p-0">: {{row.item.nama_obat}}</h6></b-col>
                      <b-col cols="4"><h6 class="m-0 p-0">Signa</h6></b-col>
                      <b-col cols="8"><h6 class="m-0 p-0">: {{row.item.signa_rekonsiliasi}}</h6></b-col>
                      <b-col cols="4"><h6 class="m-0 p-0">Frekuensi</h6></b-col>
                      <b-col cols="8"><h6 class="m-0 p-0">: {{row.item.frekuensi}}</h6></b-col>
                      <b-col cols="4"><h6 class="m-0 p-0">Cara Pemberian</h6></b-col>
                      <b-col cols="8"><h6 class="m-0 p-0">: {{row.item.cara_pemberian}}</h6></b-col>
                      <b-col cols="4"><h6 class="m-0 p-0">Status Pemberian Obat</h6></b-col>
                      <b-col cols="8"><h6 class="m-0 p-0">: {{row.item.status_pemberian_obat}}</h6></b-col>
                      <b-col v-if="row.item.perubahan_aturan_pakai" cols="4"><h6 class="m-0 p-0">Perubahan Aturan Pakai</h6></b-col>
                      <b-col v-if="row.item.perubahan_aturan_pakai" cols="8"><h6 class="m-0 p-0">: {{row.item.perubahan_aturan_pakai}}</h6></b-col>
                      <b-col cols="4"><h6 class="m-0 p-0">Keterangan</h6></b-col>
                      <b-col cols="8"><h6 class="m-0 p-0">: {{row.item.keterangan}}</h6></b-col>
                    </b-row>
                  </b-card>
                </template>
              </b-table>
            </b-col>
          </b-row>
        </b-collapse>
      </b-col>

      <b-col v-if="dataForm.id" cols="12"><hr></b-col>
      <b-col v-if="dataForm.id" cols="12">
        <h5 class="custom-title-card"><strong>RESEP</strong></h5>
      </b-col>
      <b-col v-if="dataForm.id && !kunjunganSelesai" cols="12">
        <div class="mt-2 d-flex justify-content-end">
          <b-button
            :disabled="busy"
            @click="is_data_detail = {}, $bvModal.show('modal-kunjungan-obat-resep')"
            variant="primary"
          >Tambah</b-button>
        </div>
      </b-col>

      <b-col v-if="dataForm.id" cols="12" md="12" lg="12">
        <b-table-simple hover small responsive bordered>
          <b-thead class="table-color">
            <b-tr>
              <b-th v-for="(field, key) in fieldsObat" :key="key" :class="field.class">{{ field.label }}</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <tr v-for="(item, index) in itemsResep" :key="index">
              <th v-if="item.type == 'head'" scope="row" class="text-center">{{ item.no }}.</th>
              <td v-if="item.type == 'head' && item.no_racikan" colspan="6">{{ `${item.no_racikan} - ${item.jumlah_bungkus} bungkus ${item.indikasi_head_racikan ? ` - ${item.indikasi_head_racikan}` : ''}` }}</td>
              <td v-if="item.type == 'head' && !item.no_racikan" colspan="6">Tanpa Racikan</td>
             
              <td v-if="item.type == 'sub'"></td>
              <td v-if="item.type == 'sub'">{{ item.nama_barang }}</td>
              <td v-if="item.type == 'sub'">{{ item.nama_qty_obat }}</td>
              <td v-if="item.type == 'sub'">{{ item.nama_qty_permintaan }}</td>
              <td v-if="item.type == 'sub'">{{ item.aturan_pakai_racikan }}</td>
              <td v-if="item.type == 'sub'">{{ item.signa_racikan }}</td>
              <td v-if="item.type == 'sub'">{{ item.nama_harga_sub_obat_jual }}</td>
              
              <td v-if="item.type == 'detail'"></td>
              <td v-if="item.type == 'detail'" colspan="8">
                <b-card class="m-0">
                  <b-row class="m-0 p-0" style="row-gap: 1rem;">
                    <b-col cols="6">
                      <div>
                        <h6><strong>Indikasi</strong></h6>
                        <p>{{item.indikasi_obat || '-'}}</p>
                      </div>
                      <div>
                        <h6><strong>Keterangan</strong></h6>
                        <p>{{item.keterangan_obat || '-'}}</p>
                      </div>
                    </b-col>
                    <b-col cols="6">
                      <div class="" v-for="(value, key) in item.item_obat_jual" :key="key">
                        <b-card class="m-0">
                          <b-row class="m-0 p-0">
                            <b-col cols="4"><h6 class="m-0 p-0">Kode Batch</h6></b-col>
                            <b-col cols="8"><h6 class="m-0 p-0">: {{value.kode_batch}}</h6></b-col>
                            <b-col cols="4"><h6 class="m-0 p-0">Jumlah</h6></b-col>
                            <b-col cols="8"><h6 class="m-0 p-0">: {{value.qty_item_obat_jual}}</h6></b-col>
                            <b-col cols="4"><h6 class="m-0 p-0">Kadaluarsa</h6></b-col>
                            <b-col cols="8"><h6 class="m-0 p-0">: {{$moment(value.tanggal_kadaluarsa).format("LL")}}</h6></b-col>
                          </b-row>
                        </b-card>
                      </div>
                    </b-col>
                  </b-row>
                </b-card>
              </td>
              <td class="text-center">
                <div class="d-flex">
                  <b-button
                    v-if="item.type == 'head'"
                    variant="info"
                    class="mr-1"
                    v-c-tooltip.hover.click="'Detail Racikan'"
                    @click="is_data_detail = {...item, head_racikan_id: item.head_racikan_id || true}, $bvModal.show('modal-kunjungan-obat-resep')"
                  ><CIcon name="cil-info" /></b-button>
                  <b-button
                    v-if="item.type == 'sub'"
                    variant="info"
                    class="mr-1"
                    v-c-tooltip.hover.click="'Detail Sub Obat'"
                    @click="detailSub(index)"
                  ><CIcon name="cil-info" /></b-button>
                  <b-button
                    v-if="item.type == 'sub' && !kunjunganSelesai"
                    variant="danger"
                    class="mr-1"
                    v-c-tooltip.hover.click="'Delete Sub Obat'"
                    @click="is_data = item, $bvModal.show('modal-delete-sub-resep')"
                  ><CIcon name="cil-trash" /></b-button>
                </div>
              </td>
            </tr>
          </b-tbody>
        </b-table-simple>
      </b-col>
      <b-col v-if="dataForm.id" cols="12">
        <b-card>
          <div class="d-flex" style="gap: 10px;">
            <CetakRoll
              :nama_tombol="'Cetak Roll'"
              :is_disabled="!dataForm.id"
              :printResep="printDataRoll"
            />
            <CetakResep
              :itemResep="itemResep"
              :itemObat="itemObat"
              :listAlergiPasien="listAlergiPasien"
              :dataKunjungan="kunjungan"
              :resepPasien="dataForm"
            />
          </div>
        </b-card>
      </b-col>
    </b-row>
    <ModalResep
      :kunjungan="kunjungan"
      :is_data_detail="is_data_detail"
      :dataResep="dataForm"
      :dataSubObat="is_data"
      :listBarang="listBarang"
      :listKomposisi="listKomposisi"
      :listAlergiPasien="listAlergiPasien"
      :itemsResep="itemsResep"
      :itemsRekonsiliasi="itemsRekonsiliasi"
      @alertFromChild="triggerAlert($event), getDatas()"
    />
    <ModalDelete
      :data_delete="is_data"
      @alertFromChild="triggerAlert($event), getDatas()"
    />
    <ModalDeleteRekonsilisasi
      :data_delete="is_data_rekonsilisasi"
      @alertFromChild="triggerAlert($event), getDatas()"
    />
    <ModalDeleteHead 
      :data_delete="is_data_detail" 
      @alertFromChild="triggerAlert($event), getDatas()"
    />
  </b-card>
</template>
<script>
import moment from 'moment';
moment.locale('id');
import ModalDelete from "./modal_delete_sub.vue";
import ModalDeleteRekonsilisasi from "./modal_delete_rekonsiliasi.vue";
import ModalDeleteHead from "./modal_delete_head.vue"
import ModalResep from "./modal_resep.vue";
import { validationMixin } from "vuelidate";
import { required, numeric, minValue } from "vuelidate/lib/validators";
import CetakRoll from "./cetak_roll.vue"
import CetakResep from "./cetak_resep.vue"
export default {
  components:{
    CetakResep,
    CetakRoll,
    ModalDeleteRekonsilisasi,
    ModalResep,
    ModalDelete,
    ModalDeleteHead,
  },
  props: [
    'kunjungan',
    'dataAnamnesa',
    'listAlergiPasien',
    'listDokter',
    'listPerawat',
    'kunjunganSelesai',
  ],
  data() {
    return {
      penampungPrint: [],
      printDataRoll: {},
      itemResep: [],
      itemObat: [],
      listGudang: [],
      listKomposisi: [],
      listBarang: [],
      dataForm: {
        dokter_id: null,
        perawat_id: null,
        ms_gudang_id: null,
        status_prioritas: null,
        no_antrian: null,
        cppt_soap: {},
        no_resep : null,
      },
      dataFormRekonsiliasi: {
        nama_obat: null,
        signa_rekonsiliasi: null,
        frekuensi: null,
        cara_pemberian: null,
        status_pemberian_obat: null,
        perubahan_aturan_pakai: null,
        keterangan: null,
      },
      is_data: {},
      is_data_detail: {},
      is_data_rekonsilisasi: {},
      itemsResep: [],
      itemsRekonsiliasi: [],
      listPrioritas: [
        { text: 'Bukan Prioritas', value: 0 },
        { text: 'Prioritas', value: 1 },
      ],
      fieldsObat: [
        {
          key: "no",
          label: "No",
          sortDirection: "desc",
          sortable: true,
          class: "table-number text-center",
        },
        {
          key: "nama_barang",
          label: "Obat",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "nama_qty_obat",
          label: "Diberikan",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "nama_qty_permintaan",
          label: "Permintaan",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "aturan_pakai_obat",
          label: "Pakai",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "signa_obat",
          label: "Signa",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "nama_harga_sub_obat_jual",
          label: "Harga",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        // {
        //   key: "nama_tanggal_kadaluarsa_obat",
        //   label: "Kadaluarsa",
        //   sortable: true,
        //   sortDirection: "desc",
        //   class: "text-left",
        // },
        {
          key: "actions",
          label: "Actions",
          class: "table-option-2 text-center",
        },
      ],
      fieldsRekonsiliasi: [
        {
          key: "no",
          label: "No",
          sortDirection: "desc",
          sortable: true,
          class: "table-number text-center",
        },
        {
          key: "nama_obat",
          label: "Obat",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "signa_rekonsiliasi",
          label: "Signa",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "frekuensi",
          label: "Frekuensi",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "cara_pemberian",
          label: "Pemberian",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "status_pemberian_obat",
          label: "Status",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "actions",
          label: "Actions",
          class: "table-option-2 text-center",
        },
      ],
      busy: false,
    }
  },
  async mounted() {
    const vm = this
    await vm.reset()
    await vm.getList()
  },
  computed: {
    tanggal_sesi(){
      return this.$store.state.tanggal_sesi
    },
    isValid() {
      return !this.$v.dataForm.$invalid;
    },
    isDirty() {
      return this.$v.dataForm.$anyDirty;
    },
    isValidRekonsiliasi() {
      return !this.$v.dataFormRekonsiliasi.$invalid;
    },
    isDirtyRekonsiliasi() {
      return this.$v.dataFormRekonsiliasi.$anyDirty;
    },
  },
  mixins: [validationMixin],
  validations: {
    dataForm: {
      dokter_id: { required },
      perawat_id: { required },
      ms_gudang_id: { required },
      status_prioritas: { required },
      no_antrian: {  },
      cppt_soap: {  },
      no_resep : {  },
    },
    dataFormRekonsiliasi: {
      nama_obat:  { required },
      signa_rekonsiliasi:  {  },
      frekuensi:  { numeric, minValue: minValue(0) },
      cara_pemberian:  {  },
      status_pemberian_obat:  { required },
      perubahan_aturan_pakai:  {  },
      keterangan:  {  },
    },
  },
  watch: {
    'kunjungan': {
      handler() {
        this.onRefresh()
      },
      deep: true
    },
    'dataAnamnesa': {
      handler() {
        this.onRefresh()
      },
      deep: true
    },
  },
  methods: {
    async onRefresh(){
      const vm = this
      await vm.reset()
      await vm.getList()
      await vm.getDatas()
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async clickSelectorAll(selector){
      let domAll = document.querySelectorAll(selector)
      // console.log('domAll', domAll)
      for (let i = 0; i < domAll.length; i++) {
        const x = domAll[i];
        x.click()
      }
    },
    detailSub(idx){
      const vm = this
      if(!vm.itemsResep[idx+1]) vm.itemsResep[idx+1] = {}
      vm.itemsResep[idx+1].type == 'detail' ? vm.itemsResep.splice(idx+1, 1) : vm.itemsResep.splice(idx+1, 0, {...vm.itemsResep[idx], type: 'detail'})
    },
    createDataPrint() {
      const vm = this
      vm.printDataRoll = {...vm.dataForm}
      vm.printDataRoll.nama_lengkap = vm.kunjungan.nama_lengkap
      vm.printDataRoll.nik = vm.kunjungan.nik
      vm.printDataRoll.no_asuransi_lain = vm.kunjungan.no_asuransi_lain
      vm.printDataRoll.alamat_sekarang = vm.kunjungan.alamat_sekarang
      vm.printDataRoll.tempat_lahir = vm.kunjungan.tempat_lahir
      vm.printDataRoll.tanggal_lahir = vm.kunjungan.tanggal_lahir
      vm.printDataRoll.umur = vm.kunjungan.umur
      vm.printDataRoll.berat_badan = vm.kunjungan.berat_badan
      vm.printDataRoll.tinggi_badan = vm.kunjungan.tinggi_badan
      vm.printDataRoll.tanggal_daftar = vm.kunjungan.tanggal_daftar
      vm.printDataRoll.nama_poli = vm.kunjungan.nama_poli

      vm.printDataRoll.itemObat = vm.itemObat
      vm.printDataRoll.itemResep = vm.penampungPrint
    },    
    formatPrintRoll(dataRacikan) {
      const vm = this
      let objAwal = dataRacikan || [];
      vm.penampungPrint = []
      vm.itemResep = []
      vm.itemObat = []
      let penampungHead = {}
      vm.panjangHead = 0
      for (let i = 0; i < objAwal.length; i++) {
        const el1 = objAwal[i];
        if (el1.head_racikan_id) {
          vm.panjangHead++
          penampungHead._no = i + 1
          penampungHead.head_racikan_id = el1.head_racikan_id
          penampungHead.no_racikan = el1.no_racikan
          penampungHead.indikasi_head_racikan = el1.indikasi_head_racikan
          penampungHead.jumlah_bungkus = el1.jumlah_bungkus
          penampungHead.resep_id = el1.resep_id
          vm.itemResep.push(penampungHead)
          penampungHead = {}
    
          // nampung buat print
          vm.penampungPrint.push(el1)
          for (let j = 0; j < el1.racikan.length; j++) {
              const el2 = el1.racikan[j];
              vm.itemResep.splice(vm.itemResep.map(el => el.head_racikan_id).indexOf(el2.head_racikan_id) + 1, 0, el2)
          }
        } else {
          vm.itemObat.push(el1);
        }
      }
    },
    async getDatas(){
      const vm = this
      vm.busy = true
      try {
        if(vm.kunjungan && vm.kunjungan.kunjungan_id){
          let resep = await vm.$axios("/resep/details_by_kunjungan/" + vm.kunjungan.kunjungan_id);
          // console.log('resep/details_by_kunjungan', resep)
          if(resep.data.status == 200 && resep.data.data.length > 0){
            const x = resep.data.data[0]
            vm.dataForm = {
              dokter_id: vm.$findKey(vm.listDokter, x.dokter_id, true, 'tenaga_medis_id'),
              perawat_id: vm.$findKey(vm.listPerawat, x.perawat_id, true, 'tenaga_medis_id'),
              ms_gudang_id: vm.$findKey(vm.listGudang, x.ms_gudang_id, true, 'ms_gudang_id'),
              status_prioritas: vm.$findKey(vm.listPrioritas, x.status_prioritas, true, 'value'),
              no_antrian: x.no_antrian,
              cppt_soap: x.kunjuncppt_soapgan_id,
              no_resep: x.no_resep,
              kunjungan_id: x.kunjungan_id,
              id: x.resep_id,
            }
            vm.getBarang()
            vm.formatPrintRoll(x.data_racikan)
            // rekonsiliasi
            vm.itemsRekonsiliasi = x.data_rekonsiliasi.map((x, i) => { return {
              ...x,
              no: i + 1,
            }})
            // sub resep
            vm.itemsResep = [] 
            const listRacikan = []
            const listTanpaRacikan = []
            // console.log('listRacikan', listRacikan)
            //menyamakan format list
            for (let i = 0; i < x.data_racikan.length; i++) {
              const obat = x.data_racikan[i];
              if(obat.head_racikan_id){
                listRacikan.push(obat)
              }else{
                listTanpaRacikan.push(obat)
              }
            }
            listRacikan.push({ racikan: listTanpaRacikan })
            //menyamakan format tabel
            for (let i = 0; i < listRacikan.length; i++) {
              const obat = listRacikan[i];
              vm.itemsResep.push({
                no: i + 1,
                ...obat,
                // racikan: undefined,
                type: 'head',
              })
              for (let x = 0; x < obat.racikan.length; x++) {
                const sub = obat.racikan[x];
                vm.itemsResep.push({
                  no: x + 1,
                  ...sub,
                  type: 'sub',
                  nama_qty_obat: vm.$numberFormat(sub.qty_barang),
                  nama_qty_permintaan: vm.$numberFormat(sub.qty_permintaan),
                  nama_harga_sub_obat_jual: vm.$numberFormat(sub.harga_satuan_racikan, 'idr'),
                  nama_tanggal_kadaluarsa_obat: sub.tanggal_kadaluarsa_racikan ? vm.$moment(sub.tanggal_kadaluarsa_racikan).format('LL') : '-',
                })
              }
            }
            vm.createDataPrint()
            vm.totalRows = vm.itemsResep.length
          }
        }
      } catch (err) {
        console.log(err)
        if (err.message) {
          vm.triggerAlert({ variant: "danger", msg:err.message.toUpperCase(), showing: true });
        } else {
          vm.triggerAlert({ variant: "danger", msg:err.toUpperCase(), showing: true });
        }
      } finally {
        vm.busy = false;
      }
    },
    async getList(){
      const vm = this
      vm.busy = true
      try {
        // console.log('vm.kunjungan', vm.kunjungan)
        // if (vm.kunjungan.ms_poli_id) {
          // komposisi
          let listKomposisi = await vm.$axios.post('/ms_komposisi_barang/list', { order_by_name: "true" })
          // console.log('listKomposisi', listKomposisi)
          vm.listKomposisi = listKomposisi.data.status == 200 ? listKomposisi.data.data.map(x => { return {
            ...x,
          }}) : []
          // gudang
          if (vm.kunjungan.ms_poli_id) {
          //gudang
            let listGudang = await vm.$axios.post('/ms_gudang/list')
            vm.listGudang = []
            for (let i = 0; i < listGudang.data.data.length; i++) {
              let x = listGudang.data.data[i];
              if(x.ms_poli_id == vm.kunjungan.ms_poli_id.ms_poli_id){
                vm.listGudang.push(x)
              }else if(!x.ms_gudang_utama_id){
                vm.listGudang.push(x)
              }
            }
            
            // vm.listGudang.map(x => { return {
            //   ...x,
            //   nama: x.nama_gudang + ' - ' + x.tipe_gudang,
            // }})
            // console.log(vm.listGudang, 'ini list gudang');
            // if(!vm.dataForm.ms_gudang_id && vm.listGudang.length > 0) vm.dataForm.ms_gudang_id = vm.listGudang[0]
          }
        // }
      } catch (err) {
        if (err.message) {
          vm.triggerAlert({ variant: "danger", msg:err.message.toUpperCase(), showing: true });
        } else {
          vm.triggerAlert({ variant: "danger", msg:err.toUpperCase(), showing: true });
        }
      } finally {
        vm.busy = false;
      }
    },
    async getBarang(){
      const vm = this
      vm.busy = true
      try {
        if(vm.dataForm.ms_gudang_id){
          //barang
          // let listBarang = await vm.$axios.post('/stock/list_stock_by_gudang', {
          let listBarang = await vm.$axios.post("/stock/list_stock_by_komposisi", {
            ms_gudang_id: vm.dataForm.ms_gudang_id.ms_gudang_id,
            nama_jenis_barang: 'OBAT',
          })
          vm.listBarang = listBarang.data.status == 200 ? listBarang.data.data.map(x => { return {
            ...x,
            nama: x.kode_produk + ' - ' + x.nama_barang + ' - ' + vm.$numberFormat(x.qty_stock)
          }}) : []
          // console.log('list_stock_by_gudang', listBarang)
        }else{
          vm.listBarang = []
        }
      } catch (err) {
        if (err.message) {
          vm.triggerAlert({ variant: "danger", msg:err.message.toUpperCase(), showing: true });
        } else {
          vm.triggerAlert({ variant: "danger", msg:err.toUpperCase(), showing: true });
        }
      } finally {
        vm.busy = false;
      }
    },
    async simpan(){
      const vm = this
      vm.busy = true
      try {
        await this.$v.dataForm.$touch();
        if (vm.isValid && vm.isDirty) {
          let res = await vm.$axios.post('/resep/register_resep', {
            ...vm.dataForm,
            dokter_id: vm.dataForm.dokter_id.tenaga_medis_id,
            perawat_id: vm.dataForm.perawat_id.tenaga_medis_id,
            ms_gudang_id: vm.dataForm.ms_gudang_id.ms_gudang_id,
            status_prioritas: vm.dataForm.status_prioritas.value,
            kunjungan_id: vm.kunjungan.kunjungan_id,
            rm_id: vm.kunjungan.rm_id,
            bulk_racikan: [],
            bulk_rekonsiliasi: [],
          })
          // console.log('register resep', res)
          if(res.data.status == 200){
            vm.triggerAlert({ variant: "success", msg: 'Berhasil Membuat Resep', showing: true });
            vm.reset()
          }else{
            vm.triggerAlert({ variant: "danger", msg: 'Gagal Membuat Resep', showing: true });
          }
        }else{
          vm.triggerAlert({ variant: "warning", msg: 'Data Tidak Valid', showing: true });
        }
      } catch (error) {
        console.log(error)
        if (error.message) {
          vm.triggerAlert({ variant: "danger", msg: error.message.toUpperCase(), showing: true });
        } else {
          vm.triggerAlert({ variant: "danger", msg: error.toUpperCase(), showing: true });
        }
      } finally {
        vm.busy = false
      }
    },
    async update(){
      const vm = this
      vm.busy = true
      try {
        await this.$v.dataForm.$touch();
        if (vm.isValid && vm.isDirty) {
          let res = await vm.$axios.post('/resep/update', {
            ...vm.dataForm,
            dokter_id: vm.dataForm.dokter_id.tenaga_medis_id,
            perawat_id: vm.dataForm.perawat_id.tenaga_medis_id,
            ms_gudang_id: vm.dataForm.ms_gudang_id.ms_gudang_id,
            status_prioritas: vm.dataForm.status_prioritas.value,
            kunjungan_id: vm.kunjungan.kunjungan_id,
            rm_id: vm.kunjungan.rm_id,
            bulk_racikan: [],
            bulk_rekonsiliasi: [],
          })
          // console.log('update resep', res)
          if(res.data.status == 200){
            vm.triggerAlert({ variant: "success", msg: 'Berhasil Mengubah Resep', showing: true });
            vm.reset()
          }else{
            vm.triggerAlert({ variant: "danger", msg: 'Gagal Mengubah Resep', showing: true });
          }
        }else{
          vm.triggerAlert({ variant: "warning", msg: 'Data Tidak Valid', showing: true });
        }
      } catch (error) {
        console.log(error)
        if (error.message) {
          vm.triggerAlert({ variant: "danger", msg: error.message.toUpperCase(), showing: true });
        } else {
          vm.triggerAlert({ variant: "danger", msg: error.toUpperCase(), showing: true });
        }
      } finally {
        vm.busy = false
      }
    },
    async simpanRekonsiliasi(){
      const vm = this
      vm.busy = true
      try {
        await this.$v.dataFormRekonsiliasi.$touch();
        // console.log('vm.dataForm', vm.dataForm)
        // console.log('dataFormRekonsiliasi', vm.dataFormRekonsiliasi)
        if (vm.isValidRekonsiliasi && vm.isDirtyRekonsiliasi) {
          let res = await vm.$axios.post('/rekonsiliasi_obat/register', {
            ...vm.dataFormRekonsiliasi,
            resep_id: vm.dataForm.id,
            kunjungan_id: vm.kunjungan.kunjungan_id,
          })
          // console.log('register diagnosa', res)
          if(res.data.status == 200){
            vm.triggerAlert({ variant: "success", msg: 'Berhasil Membuat Rekonsiliasi Resep', showing: true });
            vm.reset()
          }else{
            vm.triggerAlert({ variant: "danger", msg: 'Gagal Membuat Rekonsiliasi Resep', showing: true });
          }
        }else{
          vm.triggerAlert({ variant: "warning", msg: 'Data Tidak Valid', showing: true });
        }
      } catch (error) {
        console.log(error)
        if (error.message) {
          vm.triggerAlert({ variant: "danger", msg: error.message.toUpperCase(), showing: true });
        } else {
          vm.triggerAlert({ variant: "danger", msg: error.toUpperCase(), showing: true });
        }
      } finally {
        vm.busy = false
      }
    },
    async reset(){
      const vm = this
      vm.dataForm = {
        dokter_id: vm.$findKey(vm.listDokter, vm.dataAnamnesa.ms_dokter_id, true, 'tenaga_medis_id'),
        perawat_id: vm.$findKey(vm.listPerawat, vm.dataAnamnesa.ms_perawat_id, true, 'tenaga_medis_id'),
        ms_gudang_id: null,
        status_prioritas: vm.$findKey(vm.listPrioritas, false, true, 'value'),
        no_antrian: null,
        cppt_soap: {},
        no_resep : null,
      }
      vm.dataFormRekonsiliasi = {
        nama_obat: null,
        signa_rekonsiliasi: null,
        frekuensi: null,
        cara_pemberian: null,
        status_pemberian_obat: null,
        perubahan_aturan_pakai: null,
        keterangan: null,
      }
      vm.$v.$reset()
      await vm.getList()
      await vm.getDatas()
    },
    triggerAlert(event) {
      let vm = this;
      vm.$store.commit("set_alert", event);
    },
  },
}
</script>


<style scoped>
.table-color {
  background-color: #D52F65 !important ;
  color: #fff;
  font-weight: 600;
}
.hidden-header {
  display: none;
}
.cetak-rol-btn {
  background-color: #9C4098 !important;
  color: #FFFFFF;
}
.cetak-btn {
  background-color: #F2994A !important;
  color: #FFFFFF;
}
</style>